import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../styles/PortfolioDetail.css";
import saveempty from "../../images/saveempty.png";
import shareIcon from "../../images/share.png";
import rectangle1 from "../../images/Rectangle_84.png"
import rectangle2 from "../../images/Rectangle_85.png"
import rectangle3 from "../../images/Rectangle_86.png"
import rectangle4 from "../../images/Rectangle_87.png"
import rectangle5 from "../../images/Rectangle_88.png"
import rectangle6 from "../../images/Rectangle_89.png"
import rectangle7 from "../../images/Rectangle_90.png"
import rectangle8 from "../../images/Rectangle_91.png"
import defaultlogo from "../../images/default-logo.png"
import "../../styles/Brandlisting.css"
import process from "../../images/process.png";
import scrolltotop from "../../images/scrolltotop.png";
import AgencyReviews from "../agency/AgencyReviews";
import CaseStudies from "../agency/CaseStudies";
import { Link, useHistory } from "react-router-dom";

import axios from "../../axios.js";
// components



function BrandListing() {
    const history = useHistory()
    const [allBrands, setAllBrands] = useState([]);
    
    const fetchBrands = async() => {
      axios.post("/getAllclientsDetails")
      .then(res => {setAllBrands(res.data);
                    setFiltered(res.data);
                    console.log("brands", res.data)})
      .then(console.log("State", allBrands))
    };
    
    useEffect(() => {
        fetchBrands();
      }, []);

    const [searchField, setSearchFiled] = useState("")
    const [filtered, setFiltered] = useState([])

    const searchChange = (e) => {
        setSearchFiled(e.target.value)
        var temp = allBrands.filter(brand =>{
                    if(!brand.clientName){
                        return false
                    }
                    return  brand.clientName.toLowerCase().includes(e.target.value.toLowerCase())
                    })
        setFiltered(temp)
    }

    const clearSearch = () => {
        setSearchFiled("");
        setFiltered(allBrands);
    }



    return (
        <div className="portdet__cont">
            {/* Scoll to top btn */}

            <div className="pd__top__cont">
                <div className="pd__top">
                    <h2 style={{ color: "#FF656A" }}>
                        Brands
                    </h2>

                    <span className="color__white" style={{ marginBottom: "50px" }}>
                        Explore agencies that worked with specific brands
                    </span>
                </div>

            </div>
            <div className="brandListing">
                <div className="search_section">
                    <input
                        type="text"
                        placeholder="Search Brands"
                        onChange={searchChange}
                        value = {searchField}>

                    </input>
                    <span className="color__orange fs-6 fw-bolder mx-4">Search</span>
                </div>
                <h6 className="color__orange fs-6 fw-bolder mx-4" id="reset"  onClick={clearSearch}>Reset</h6>

                <div className="images">

                    {!allBrands.length ? <h2>Loading.... </h2>
                        :
                        filtered.length ? filtered.map((brand, i) => {
                        return (
                                <div className="image_text">
                                    <Link to={"/brandProfile/" + brand.clientName.split(" ").join("-").toLowerCase() }>
                                        <img src={ brand.clientLogo ? brand.clientLogo : defaultlogo } ></img>
                                    </Link>
                                        <label>{brand.clientName}</label>
                                </div>
                        );
                    })
                    :
                    <h2> Sorry, No brands Found </h2>
                    }
                    

                </div>
                <button className="btn"> 
                    Show More
                </button>


            </div>


            {/* Search agencies */}
            <div className="mar5__cont">
                <div className="searchagencies__cont ">
                    <div className="searchagencies">
                        <p className="mwidthsmall">
                            Increase the chances of your project success, partner with the{" "}
                            <span className="color__orange">right agency</span>
                        </p>
                        <div>
                            <button onClick={() => history.push("/search")}>
                                Search Agencies
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BrandListing;
