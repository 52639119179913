import React from 'react'
import "./mediacomponent.css"
import { Swiper, SwiperSlide } from "swiper/react";
// import { FreeMode } from "swiper";
import "swiper/swiper-bundle.min.css";
import 'swiper/swiper.min.css'
const mediacomponent = ({images,imageType,videos}) => {
    function getYouTubeKey(url) {
        let key = "";
        if(url) {
            if(url.includes('watch')) {
                key = url.split('=').pop();
            } else {
                key = url.split('/').pop();
            }
        }

        return key;
    }

    function getEmbdLink(url) {
        if (url) {
            if (url.includes('youtu')) {
                return `https://www.youtube.com/embed/${getYouTubeKey(url)}`;
            } else {
                let key = url.split('/').pop() 
                return `//player.vimeo.com/video/${key}`;
            }
        }
    }
    
    if(imageType==="landscape100"){
        return <div className='landscape100'>
            {images.map(key=>(<img src={key} alt="" />))}
            {videos&&videos.map(url=>{
            return(<iframe 
                src={getEmbdLink(url) || ''}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"></iframe>)})}
                
        </div>
    }else if(imageType==="landscape80"){
        return <div className='landscape80'>
            {images.map(key=>(<img src={key} alt="" />))}
            {videos&&videos.map(url=>{
            return(<iframe 
                src={getEmbdLink(url) || ''}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"></iframe>)})}
        </div>
    }else if(imageType==="grid"){
        return <div className='grid'>
            {images.map(key=>(<img src={key} alt="" />))}
        </div>
    }else if(imageType==="vertical"){
        return <div className='vertical'>
            {images.map(key=>(<img src={key} alt="" />))}
        </div>
    }else if(imageType==="landscapeGrid"){
        return <Swiper
        slidesPerView={"auto"} 
        spaceBetween={10}
        freeMode={true}
        grabCursor={true}
        className="landscapeGrid"
      >
        {images.map((key,index)=>(
            <SwiperSlide className="image__slide">
            <img
                className="image__slide"
                src={key}
                alt={index}
            />
            </SwiperSlide>
        ))}
      </Swiper>
    }
    else{return (
    <></>
  )}
}

export default mediacomponent