import React, { useEffect, useRef, useState } from 'react'
import magnify from '../../Talent/TalentPool/demoImages/magnify.svg';
import chevronDown from '../../../UserProfileCompo/UserPageContent.js/DemoImages/Individual Profile/chevron-down.svg'
import './Experience.css'
import DateSection from './DateSection/DateSection';
import plusIcon from './image/plus-box.svg'
import cancelIcon from './image/cancel-box.svg'
import axios from "../../../../axios"
import ProfileeImage from '../../profileImage/ProfileImage';
import { ToastContainer, toast } from 'react-toastify';
import ChecklistOfExperienceType from './ChecklistOfExperienceType/ChecklistOfExperienceType';
import { Redirect } from '../../../HomePageComponents/images';

const Experience = ({ userData, caseStudies }) => {
  const Marketer = JSON.parse(localStorage.getItem("marketer"));
  const [filteredCompanies, setFilterdCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [showCreateNewComponyProfileSection, setShowCreateNewComponyProfileSection] = useState(false);
  const [searchCompanyName, setSearchCompanyName] = useState('');
  const [experienceArray, setExperienceArray] = useState([]);
  const [cropData, setCropData] = useState("")
  const [isError, setIsError] = useState(true);
  const [isTypeSelected, setIsTypeSelected] = useState(false);
  const [isBrandOrAgency, setIsBrandOrAgency] = useState(false);
  const [showSelectType, setShowSelectType] = useState(false);

  const defaultCompanyDetails = {
    companyName: '',
    isMarketingAgency: false,
    companyWebsite: '',
    companyLogo: null,
    role: '',
    dates: {
      from: {
        month: '',
        year: ''
      },
      till: {
        month: '',
        year: ''
      }
    },
    description: ''
  }
  const [newCompanyDetails, setNewCompanyDetails] = useState(defaultCompanyDetails)

  const mainContainerRef = useRef();

  useEffect(() => {
    if (isTypeSelected) {
      mainContainerRef.current.scrollIntoView({
        behavior: "smooth",
      })
    }
  }, [isTypeSelected]);

  useEffect(() => {
    setExperienceArray([...userData.experience])
  }, [userData.experience])

  async function handleSearch(e) {
    try {
      setSelectedCompany({});
      setSearchCompanyName(e.target.value);
      if (e.target.value.length === 1 || e.target.value.length > 1) {
        if (isBrandOrAgency === 'brand') {
          const { data } = await axios.post("/getAllclientsDetails", { clientNameStartWith: e.target.value.toLowerCase() })
          if (data) {
            setFilterdCompanies(data)
          }
        }
        else {
          const { data } = await axios.post("/getAllAgencyBasicDetail", { AgencyNameStartWith: e.target.value.toLowerCase() })
          if (data) {
            setFilterdCompanies(data)
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleAddNewDatails(e) {
    setNewCompanyDetails({ ...newCompanyDetails, [e.target.name]: e.target.value })
  }

  function handleShowCreateNewCompanyProfile() {
    setNewCompanyDetails({ ...newCompanyDetails, companyName: searchCompanyName });
    setSearchCompanyName('');
    setShowCreateNewComponyProfileSection(true);
  }

  function handleIsMarketingAgency(e) {
    const value = e.target.value === 'Yes' ? true : false;
    setNewCompanyDetails({
      ...newCompanyDetails,
      isMarketingAgency: value,
    });
  }

  function handleSelectCompany(filteredCompany) {
    let company = {
      companyName: isBrandOrAgency === 'agency' ? filteredCompany.agencyName : filteredCompany.clientName,
      isMarketingAgency: isBrandOrAgency === 'agency' ? true : false,
      companyWebsite: isBrandOrAgency === 'agency' ? filteredCompany.agencyWebsite : filteredCompany.clientURL,
      companyLogo: isBrandOrAgency === 'agency' ? filteredCompany.agencyLogo : filteredCompany.clientLogo,
    }
    setSelectedCompany(company);
    setNewCompanyDetails({
      ...newCompanyDetails,
      ...company
    })
    setSearchCompanyName('');
    setShowCreateNewComponyProfileSection(false);
  }

  function handleSelectedDates(value) {
    setNewCompanyDetails({ ...newCompanyDetails, dates: value })
  }


  function setUserProfileFormData(url) {
    // console.log(url);
    setNewCompanyDetails(prevValue => ({ ...prevValue, companyLogo: url }))
  }


  async function handleSubmit(e) {
    console.log('this is submt');
    try {
      let { data } = await axios.post("/marketers/updateMarketerExperience", newCompanyDetails, {
        headers: {
          Authorization:
            "Bearer " +
            Marketer.token,
        },
      });

      setIsError(false);
      toast.success("Experience Added Successfully")
      setIsTypeSelected(false);

      setNewCompanyDetails(defaultCompanyDetails);
      setExperienceArray([...experienceArray, data.addedExperience]);
      setShowCreateNewComponyProfileSection(false);
      setSearchCompanyName('')
      setSelectedCompany()
    } catch (error) {
      console.log(error);
      setIsError(true);
      toast.error("Please fill all the fields");
    }
  }

  function handleSaveExp(e) {
    setCropData("");
    handleSubmit(e);
  }

  useEffect(() => {
    setIsTypeSelected(false);
  }, [])


  return (
    <main className={`experience_main_container `}>
      {showSelectType && <ChecklistOfExperienceType setIsBrandOrAgency={setIsBrandOrAgency} setShowSelectType={setShowSelectType} setIsTypeSelected={setIsTypeSelected} />}
      {!showSelectType && (
        <form className="experience_form formScrollBarDisplay">
          <div className='innerExperienceSection' style={!isTypeSelected ? { paddingBottom: '130px' } : { paddingBottom: '30px' }}>
            {experienceArray ? (
              experienceArray.map((experience, index) => (
                <>
                  <div key={index} className="projectSectionContainer">
                    <span>
                      <img src={experience.companyLogo} width={50} alt="" />
                    </span>
                    <div className="projectSection">
                      {/* <div> */}
                      <p>
                        <span className="post">{experience.role}</span> at{" "}
                        <span className="company">{experience.companyName}</span>
                      </p>
                      <p className="datePeriod">
                        {experience?.dates?.from?.month} {experience?.dates?.from?.year} -{" "}
                        {experience?.dates?.till?.month} {experience?.dates?.till?.year}
                      </p>
                      {/* </div> */}
                      <a target='_blank' href={`/agencyprofile/${experience.companyName}`}>
                        View all {caseStudies?.length} project(s) at{" "}
                        <span className="company" style={{ fontWeight: "normal" }}>
                          {experience.companyName}
                        </span>
                        <img src={Redirect} alt="" />
                      </a>
                      <p className="fontStart">{experience.description}</p>
                    </div>
                  </div>
                </>
              ))
            ) : (
              <div>No Experience</div>
            )}
          </div>

          {/* <img src='C:\\fakepath\\WhatsApp Image 2023-11-16 at 21.41.48_4fed62f1.jpg' alt="" /> */}
          {isTypeSelected && <div ref={mainContainerRef} className='mainContainer'>
            <div className='companyDetails'>
              <p className='label'>Company Name<sup>*</sup></p>
              <div className='searchBarWrapper inputWrapper'>
                <img src={magnify} alt="" />
                <input type="text" name="" id="" value={selectedCompany ? selectedCompany.companyName : searchCompanyName} onChange={handleSearch} placeholder='Add company name' />
                <img src={chevronDown} alt="chevronDown icon" />
              </div>
              <div className='searchedResult' style={searchCompanyName ? { display: 'flex', flexDirection: 'column' } : { display: 'none' }}>
                {
                  filteredCompanies.length !== 0 ? (filteredCompanies.map((filteredCompany) => (
                    <>
                      {isBrandOrAgency === "agency" ? (
                        <div key={filteredCompany._id} className='searchedCompany' onClick={() => handleSelectCompany(filteredCompany)}>
                          <div className='searchedCompanyNameAndLogo'>
                            <img src={filteredCompany.agencyLogo} alt="" />
                            <p>{filteredCompany.agencyName}</p>
                          </div>
                          <p className='searchedCompanyWebsite'>{filteredCompany.agencyWebsite}</p>
                        </div>
                      ) : (
                        <div key={filteredCompany._id} className='searchedCompany' onClick={() => handleSelectCompany(filteredCompany)}>
                          <div className='searchedCompanyNameAndLogo'>
                            <img src={filteredCompany.clientLogo} alt="" />
                            <p>{filteredCompany.clientName}</p>
                          </div>
                          <p className='searchedCompanyWebsite'>{filteredCompany.clientURL}</p>
                        </div>
                      )}
                      <hr />
                    </>
                  ))) : (
                    <div>
                      <div className='noCompanyExistWrapper cancelIcon'>
                        <img src={cancelIcon} alt='cancelIcon' />
                        <p>No company found</p>
                      </div>
                      <hr />
                      <button type='button' className='noCompanyExistWrapper plusIcon' onClick={handleShowCreateNewCompanyProfile}>
                        <img src={plusIcon} alt='plusIcon' />
                        <p>Create New Company Profile</p>
                      </button>
                    </div>
                  )
                }
              </div>
            </div>

            <div className='createNewCompanyWrapper' style={showCreateNewComponyProfileSection ? { display: 'flex' } : { display: 'none' }}>
              {/* company name */}
              <div className='companyNameWrapper'>
                <p className='label'>Company Name<sup>*</sup></p>
                <div className='searchBarWrapper inputWrapper'>
                  <input type="text" name="companyName" disabled={showCreateNewComponyProfileSection} value={newCompanyDetails.companyName} onChange={handleAddNewDatails} />
                </div>
              </div>

              {/* is marketing agency */}
              <div className='isMarketingAgencyWrapper'>
                <p className='isMarketingAgencyHeading'>Is your company a Marketing Agency?</p>
                <div>
                  <div className='radioBtnWrapper'>
                    <input type="radio" id="yes" name="isMarketingAgency" value='Yes' checked={newCompanyDetails.isMarketingAgency === true} onChange={handleIsMarketingAgency} />
                    <label for="yes">Yes</label><br />
                  </div>
                  <div className='radioBtnWrapper'>
                    <input type="radio" id="no" name="isMarketingAgency" value='No' checked={newCompanyDetails.isMarketingAgency === false} onChange={handleIsMarketingAgency} />
                    <label for="no">No</label>
                  </div>
                </div>
              </div>

              {/* company website */}
              <div className='companyWebsiteWrapper'>
                <p className='label'>Company Website<sup>*</sup></p>
                <div className='searchBarWrapper inputWrapper'>
                  <input type="text" name="companyWebsite" value={newCompanyDetails.companyWebsite} onChange={handleAddNewDatails} id="" />
                </div>
              </div>

              {/* company logo */}
              <div className='companyLogoWrapper'>
                <p className='label'>Company Logo<sup>*</sup></p>
                <div className='inputWrapper'>
                  <ProfileeImage name="profilePhoto" setUserProfileFormData={setUserProfileFormData} cropData={cropData} setCropData={setCropData} />
                </div>
              </div>
            </div>

            {/* role */}
            <div style={{ width: '100%' }}>
              <p className='label'>Role</p>
              <div className='inputWrapper'>
                <input type="text" name="" id="" value={newCompanyDetails.role} onChange={(e) => setNewCompanyDetails({ ...newCompanyDetails, role: e.target.value })} placeholder='Choose a Role' />
              </div>
            </div>

            <div style={{ width: '100%' }}>

              {/* from date */}
              <DateSection selectedDate={newCompanyDetails.dates} setSelectedDate={handleSelectedDates} dateType={'from'} />

              {/* till date */}
              <DateSection selectedDate={newCompanyDetails.dates} setSelectedDate={handleSelectedDates} dateType={'till'} />
            </div>

            {/* about section */}
            <div style={{ width: '100%', marginBottom: '100px' }}>
              <p className='label'>Write something about your time at this company</p>
              <div className='inputWrapper textareaWrapper'>
                <textarea type="text" name="" id="" rows='6' value={newCompanyDetails.description} onChange={(e) => setNewCompanyDetails({ ...newCompanyDetails, description: e.target.value })} />
              </div>
            </div>
          </div>}

          {!isTypeSelected && (
            <div className='submitButtonWrapper'>
              <button
                type='submit'
                className='addExperienceBtn'
                onClick={() => {setShowSelectType(true) }}
              >
                Add new experience
              </button>
            </div>
          )}
        </form>
      )}
      {isTypeSelected && (
        <div className='formButtons'>
          <div className='innerFormContainer'>
            <button className='formBtn' onClick={() => setIsTypeSelected(false)}>Cancel</button>
            <button onClick={() => handleSaveExp()} type='button' className='formBtn'>Save</button>
          </div>
        </div>
      )}

      <ToastContainer />
    </main>
  )
}

export default Experience