import React, { useState, useEffect } from "react";
import notificationg from "../../images/notificationg.png";
import dotg from "../../images/dotg.png";
import messageg from "../../images/messageg.png";
import ASidebar from "./ASidebar";
import "../../styles/ASubmitReferences.css";
import ASidebarAlt from "./ASidebarAlt";
import axios from "../../axios.js";

function ASubmitReferences() {
  const id= (JSON.parse(localStorage.getItem('user')).user.agencyId)
  const [uploadbtn, setUploadbtn] = useState(false);
  const [mes, setMes] = useState("");
  const submitHandler = async () => {
    // loading icon starts
    setUploadbtn(true);
    // loading icon ends
    console.log("Sending:", references)
    try {
      const res = await axios.post("/submitReferences", references);
      setReferences({
        agencyId: id,
        clientFirstName: "",
        clientLastName: "",
        clientEmailId: "",
        clientContactNumber: "",
        servicesOffered: "",
        start: "",
        end: "",
        ongoing: false
      });
      setMes(res.data);
      setUploadbtn(false);
    } catch (e) {
      console.log(e);
    }
  };
  const [references, setReferences] = useState({
    agencyId: id,
    clientFirstName: "",
    clientLastName: "",
    clientEmailId: "",
    clientContactNumber: "",
    servicesOffered: "",
    start: "",
    end: "",
    ongoing: false
  });

  const referencesChangeHandler = e => {
    const name = e.target.name;
    const value = e.target.value;
    setReferences(p => {
      return {
        ...p,
        [name]: value
      };
    });
  };

  const ongoingChangeHandler = e => {
    const value = e.target.checked;
    setReferences(p => {
      return { ...p, ongoing: value };
    });
  };

  return (
    // <div className="aonboarding__cont asubmitreferences__cont">
    //   <ASidebar />
    <div className="aonboarding asubmitreferences">
      <ASidebarAlt />
      <div className="section__cont">
        <div className="section">
          <div className="part__left">
            <h3 className="aonboarding__title text__left">Submit References</h3>
          </div>
          <div className="part__right">
            <div className="img__cont">
              <img className="msg__not" src={messageg} alt="" />
              <img className="dotg" src={dotg} alt="" />
            </div>
            <div className="img__cont">
              <img className="msg__not" src={notificationg} alt="" />
              <img className="dotg" src={dotg} alt="" />
            </div>
            <p className="date__day para adgrey"> </p>
          </div>
        </div>
      </div>

      <div className="contact__details">
        <div className="contact__details__data">
          <div className="contact__details__forms ">
            <form className="form" onChange={referencesChangeHandler}>
              <div className="inp__fields flx4">
                <input
                  name="clientFirstName"
                  value={references.clientFirstName}
                  className="input adgrey"
                  placeholder="Client First Name"
                  type="name"
                ></input>
              </div>
              <div className="inp__fields flx4">
                <input
                  name="clientLastName"
                  value={references.clientLastName}
                  className="input adgrey"
                  placeholder="Client Last Name"
                  type="text"
                ></input>
              </div>
            </form>
            <form className="form" onChange={referencesChangeHandler}>
              <div className="inp__fields flx4">
                <input
                  name="clientEmailId"
                  value={references.clientEmailId}
                  className="input adgrey"
                  placeholder="Client Email ID"
                  type="email"
                ></input>
              </div>
              <div className="inp__fields flx4">
                <input
                  name="clientContactNumber"
                  value={references.clientContactNumber}
                  className="input adgrey"
                  placeholder="Client Contact Number"
                  type="tel"
                ></input>
              </div>
            </form>
            <form className="form">
              <div className="inp__fields flx4">
                <input
                  onChange={referencesChangeHandler}
                  name="servicesOffered"
                  value={references.servicesOffered}
                  className="input adgrey"
                  placeholder="Service(s) offered to the client/Project Name"
                  type="text"
                ></input>
              </div>
              <div className="startendinpcont inp__fields flx4">
                <div className="part1">
                  <input
                    onChange={referencesChangeHandler}
                    type="date"
                    name="start"
                    value={references.start}
                    className="input adgrey"
                    placeholder="Start"
                  ></input>
                  <p className="para">to</p>
                  <input
                    onChange={referencesChangeHandler}
                    type="date"
                    name="end"
                    value={references.end}
                    className="input adgrey"
                    placeholder="End"
                  ></input>
                </div>
                <div className="part2">
                  <label className="labelf para">
                    <input
                      onChange={ongoingChangeHandler}
                      name="ongoing"
                      checked={references.ongoing}
                      type="checkbox"
                    ></input>
                    Ongoing
                  </label>
                </div>
              </div>
            </form>
            <div className="dflex justify__between">
              <button onClick={submitHandler} className="done__btn">
                {uploadbtn && (
                  <i className="fas fa-circle-notch donebtnclient"></i>
                )}
                {uploadbtn && " Submiting"}
                {!uploadbtn && "Submit"}
              </button>{" "}
            </div>
            &nbsp; &nbsp;
          </div>
          <p>{mes}</p>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default ASubmitReferences;
