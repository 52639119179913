import React, { useEffect } from "react";
import "../../styles/NavbarEditProfile.css";
import { Link } from "react-router-dom";

function Navbar(props) {
  const switchTab = (e) => {
    const tabItem = e.target;

    // For btn activation
    const alltablinks = document.querySelectorAll(".anavlink");

    alltablinks.forEach((link) => {
      link.classList.remove("actives"); //deactivating rest of the links
    });

    tabItem.classList.add("actives");
    const sidebarComp = document.querySelector(".anavbartranslate");
    sidebarComp.classList.toggle("asidebarshowtranslate");
  };

  useEffect(() => {
    
    const alltablinks = document.querySelectorAll(".anavlink");
    alltablinks.forEach((link) => {
      link.classList.remove("actives"); 
      if(link.classList.contains(props.currentFocus)){
        link.classList.add("actives");
      }
    });

  }, [props.currentFocus]);

  return (
    <section className="navbar__position anavbartranslate">
      <div className="navbar__style ">
        <a
          style={{marginRight: "3rem"}}
          href="#basicinfo"
          className={"anavlink para cursor__pointer fw-bolder basicinfo actives"}
          onClick={switchTab}
        >
          Basic Info
        </a>
        <a
          href="#Casestudies"
          className="anavlink para cursor__pointer  fw-bolder Casestudies"
          onClick={switchTab}
        >
          Portfolio
        </a>
      </div>
    </section>
  );
}
export default Navbar;
