import React, { useState } from "react";
import "../styles/ContactUs.css";
import phone from "../images/ContactUs/ContactPhone.png";
import location from "../images/ContactUs/ContactLocation.png";
import email from "../images/ContactUs/ContactMail.png";
import axios from "../axios.js";
import emailjs from "emailjs-com"

function ContactUs() {
  const [showMessage, setShowMessage] = useState(false);
  const [notification, setNotification] = useState("");
  const [newContact, setNewContact] = useState({
    fullName: "",
    contactNumber: "",
    emailAddress: "",
    message: "",
  });

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setNewContact((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const submitHandler = async (e) => {
    
    e.preventDefault();
    emailjs.sendForm("service_4j1w7r8","template_qy4kp0z",e.target,"user_Qz3ZUWII5Gmrp5J8XWxo2")
    .then(res=>{
      console.log(res);
    })
    .catch(err=>console.log(err))
    const saveContact = newContact;
    try {
      const response = await axios.post("/contactus/", saveContact);
      setNewContact({
        fullName: "",
        contactNumber: "",
        emailAddress: "",
        message: "",
      });
      setShowMessage(true);
      setNotification(response.data);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="contactUs">
      <div className="cub1 has-text-centered">
        <div>
          <h1 className="color__white contactUs__title">
            <span className="color__orange fw__600">Contact</span> us
          </h1>
          <p className="color__white para">
            upgrades are as simple as installing new plugins
          </p>
        </div>
      </div>
      <div className="contact__info__cont">
        <div
          className="contact__info"
        
        >
          <div className="section">
            <img src={phone} alt="" className="contact__logo" />
            <p className="para">+91-9824291969</p>
          </div>
          <div className="section">
            <img src={location} alt="" className="contact__logo" />
            <p className="para">
              G-14, Sector-3, Noida, 201302, Uttar Pradesh, India
            </p>
          </div>
          <div className="section">
            <img src={email} alt="" className="contact__logo" />
            <p className="para">support@unispade.com</p>
          </div>
        </div>
      </div>
      <div className="sec__right">
        <form className="login__form" onSubmit={submitHandler}>
          <label>Full Name</label>
          <input
            onChange={changeHandler}
            name="fullName"
            value={newContact.fullName}
            placeholder="Enter your name here"
            className="epass"
            required
          />
          <label>Contact Number</label>
          <input
            onChange={changeHandler}
            name="contactNumber"
            value={newContact.contactNumber}
            placeholder="Enter your number here"
            required
            className="epass"
          />
          <label>Email Address</label>
          <input
            onChange={changeHandler}
            name="emailAddress"
            value={newContact.emailAddress}
            type="text"
            placeholder="Enter your email id"
            required
            className="epass"
          />
          <label>Your Message</label>
          <textarea
            style={{ height: "7rem" }}
            onChange={changeHandler}
            name="message"
            type="text"
            value={newContact.message}
            placeholder="Write your message here"
            required
            className="epass"
          />
          {showMessage && <p>{notification}</p>}
          <button type="submit" className="login__btn">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
