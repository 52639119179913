import React, { useState, useEffect } from "react";
import "../../styles/ANewBusiness.css";
import notificationg from "../../images/notificationg.png";
import dotg from "../../images/dotg.png";
import messageg from "../../images/messageg.png";
import one from "../../images/one.png";
import stars from "../../images/stars.png";
import addimg from "../../images/addimg.png";
import axios from "../../axios.js";
import arrowdown from "../../images/arrowdown.png";
import ASidebar from "./ASidebar";
import AResCard from "./AResCard";
import ABuisCard from "./ABuisCard";
import { isEmptyObject } from "jquery";

function ANewBusiness() {
  //////////  Filters open functionality for mobile view starts////////
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const openFilterPart = () => {
    setIsOpenFilter(prev => setIsOpenFilter(!prev));

    // closing all others open dropdown filters
    setIsOpenprotype(false);
    setIsOpenBrand(false);
    setIsOpenBudget(false);
  };

  const [isOpenprotype, setIsOpenprotype] = useState(false);
  const isProtype = () => {
    setIsOpenprotype(prev => setIsOpenprotype(!prev));
  };
  const [isOpenBrand, setIsOpenBrand] = useState(false);
  const isBrandtype = () => {
    setIsOpenBrand(prev => setIsOpenBrand(!prev));
  };
  const [isOpenBudget, setIsOpenBudget] = useState(false);
  const isBudgettype = () => {
    setIsOpenBudget(prev => setIsOpenBudget(!prev));
  };
  const [projectList, setProjectList] = useState([])
  //////////  Filters open functionality for mobile view ends //////////

  //////////// Switch tab functionality starts//////////
  // const switchTabs = e => {
  //   const tabItem = e.target;

  //   // For btn activation
  //   const alltablinks = document.querySelectorAll(".babtns");

  //   alltablinks.forEach(link => {
  //     link.classList.remove("active"); //deactivating rest of the links
  //   });

  //   tabItem.classList.add("active"); //activating the link which is clicked

  //   //for component activation
  //   const destid = e.target.getAttribute("dest"); //getting the destination id which needs to be activated.
  //   const tabelement = document.getElementById(destid); //element which we need to activate

  //   const jobdescitems = document.querySelectorAll(".badatapart");

  //   jobdescitems.forEach(item => {
  //     item.classList.remove("activetab");
  //   });

  //   tabelement.classList.add("activetab"); //activating the link which is clicked
  // };
  //////////// Switch tab functionality ends //////////
  
  const [type, setType] = useState()
  
  const typeChange = event => {
    console.log("Type", event.target.value)
    setType(event.target.value);
    getProjects(event.target.value)
  }; 

  const [projects, setProjects] = useState([]);


  const getProjects = (type) => {
    if(localStorage.getItem("user")!=null){
      axios.post("/newBusiness/getProjectStatus", {
                        agencyId: JSON.parse(localStorage.getItem('user')).user.agencyId,
                        type: type
                        // agencyId: "60ab7217b4bcd8baf02d0d0c" 
                        } )
      .then(response => {
        setProjects(response.data)
        setProjectList(response.data)
        console.log("agency", JSON.parse(localStorage.getItem('user')).user.agencyId)
        console.log("resp", response.data)
        budgetFilter(response.data)
      })
      .then(resp => {
        console.log("Projects", projects)
      })
      .catch(error => {
        console.log(error)
      })
    }
    
  }

  useEffect(() => {
    getProjects("All")
  }, [])
  
  // console.log("User", JSON.parse(localStorage.getItem('user')).user.agencyId )
  
  // console.log("Project List", projectList)
  const [budget, setBudget] = useState([])
  const budgetFilter = (projects) => {
    if(!(budget.length)){
      setProjectList(projects)
      console.log("1111")
    } else {
      console.log("2222")
      var final =[]
      projects.forEach(item => {
        for(var i=0; i<budget.length; i++){
          if(item.budget && budget[i].min){
            if(item.budget.min >= budget[i].min && item.budget.max <=budget[i].max){
              final.push(item)
            }
          }
          if(!item.budget && budget[i]=="notmentioned"){
            final.push(item)
          }
        }
        
      })
      setProjectList (final)
    }
    console.log("finally", projectList)
  }

  const budgetChange = event => {
    const value = JSON.parse(event.target.value)
    const checked = event.target.checked;
    var temp = budget;
    if(checked){
      temp.push(value)
    } else {
      var index = temp.indexOf(value); 

      var i = temp.length;
      while(i--){
        if( temp[i].min == value.min && temp[i].max == value.max ){ 
            temp.splice(i,1);
            console.log("Index: ", i)
            break;
        }
      }
    }
    setBudget(temp)
    budgetFilter(projects)
  };


  return (
    <div className="anbusiness__cont">

      <div className="anbusiness">
        <div className="section__cont">
          <div className="section">
            <div className="part__left">
              <h3 className="anbusiness__title text__left">New Business</h3>
            </div>
            <div className="part__right">
              <div className="img__cont">
                <img className="msg__not" src={messageg} alt="" />
                <img className="dotg" src={dotg} alt="" />
              </div>
              <div className="img__cont">
                <img className="msg__not" src={notificationg} alt="" />
                <img className="dotg" src={dotg} alt="" />
              </div>
             
            </div>
          </div>
        </div>

        <div className="filter__res__sec" >
          <div className="filter__sec" style={{ width: "370px" }}>
            <div
              className="filter__top cursor__pointer"
              onClick={openFilterPart}
            >
              <p className="text__left fw__600">Filters</p>{" "}
              <img
                className={`arrow__down ${isOpenFilter && "rotate180"}`}
                src={arrowdown}
              />
            </div>
            <div
              className={`filter__parttab filter__pro__type  ${isOpenFilter &&
                "dflex"}`}
            >
              <div
                className={`part__top cursor__pointer ${isOpenFilter &&
                  "dflex"}`}
                onClick={isProtype}
              >
                <p className="text__left">Project Type</p>{" "}
                <img
                  className={`arrow__down ${isOpenprotype && "rotate180"}`}
                  src={arrowdown}
                />
              </div>

              <div className={`part__bottom  ${isOpenprotype && "dflex"}`}>
                {/* <div className="inp__field">
                  <input type="radio" id="requested" />
                  <label htmlFor="requested" className="para">
                    Requested
                  </label>
                </div>
                <div className="inp__field">
                  <input type="radio" id="open" />
                  <label htmlFor="open" className="para">
                    Open
                  </label>
                </div>
                <div className="inp__field">
                  <input type="radio" id="leadfom" />
                  <label htmlFor="leadfom" className="para">
                   All
                  </label>
                </div> */}
                <div className="inp__field">
  <input type="radio" id="Requested" name="drone" value="Requested" onChange={typeChange} ></input>
  <label for="Requested">Requested</label>
</div>

<div className="inp__field">
  <input type="radio" id="open" name="drone" value="Open" onChange={typeChange} ></input>
  <label for="open">Open</label>
</div>

<div className="inp__field">
  <input type="radio" id="all" name="drone" value="All" onChange={typeChange} ></input>
  <label for="all">All</label>
</div>
              </div>
            </div>
            {/* <div
              className={`filter__parttab filter__pro__type  ${isOpenFilter &&
                "dflex"}`}
            >
              <div
                className={`part__top cursor__pointer ${isOpenFilter &&
                  "dflex"}`}
                onClick={isBrandtype}
              >
                <p className="text__left">Brand Ratings</p>{" "}
                <img
                  className={`arrow__down ${isOpenBrand && "rotate180"}`}
                  src={arrowdown}
                />
              </div>

              <div className={`part__bottom  ${isOpenBrand && "dflex"}`}>
                <div className="inp__field">
                  <input type="checkbox" id="any" />
                  <label htmlFor="any" className="para">
                    Any
                  </label>
                </div>
                <div className="inp__field">
                  <input type="checkbox" id="2stars" />
                  <label htmlFor="2stars" className="para">
                    2 stars
                  </label>
                </div>
                <div className="inp__field">
                  <input type="checkbox" id="3stars" />
                  <label htmlFor="3stars" className="para">
                    3 stars
                  </label>
                </div>
                <div className="inp__field">
                  <input type="checkbox" id="4stars" />
                  <label htmlFor="4stars" className="para">
                    4 stars
                  </label>
                </div>
                <div className="inp__field">
                  <input type="checkbox" id="5stars" />
                  <label htmlFor="5stars" className="para">
                    5 stars
                  </label>
                </div>
              </div>
            </div> */}
            <div
              className={`filter__parttab filter__pro__type  ${isOpenFilter &&
                "dflex"}`}
            >
              <div
                className={`part__top cursor__pointer ${isOpenFilter &&
                  "dflex"}`}
                onClick={isBudgettype}
              >
                <p className="text__left">Budget</p>{" "}
                <img
                  className={`arrow__down ${isOpenBudget && "rotate180"}`}
                  src={arrowdown}
                />
              </div>

              <div className={`part__bottom  ${isOpenBudget && "dflex"}`}>
                <div className="inp__field">
                  <input type="checkbox" 
                         id="₹15000-₹35000" 
                         value={JSON.stringify({min : '15000', max : '35000'})}
                         onChange={budgetChange} />
                  <label htmlFor="₹15000-₹35000" className="para">
                    ₹15000-₹35000
                  </label>
                </div>
                <div className="inp__field">
                  <input type="checkbox" 
                  id="₹35001-₹50000"
                  value={JSON.stringify({min : '35001', max : '50000'})}
                  onChange={budgetChange} />
                  <label htmlFor="₹35001-₹50000" className="para">
                    ₹35001-₹50000
                  </label>
                </div>
                <div className="inp__field">
                  <input type="checkbox" id="₹50001-₹75000" 
                  value={JSON.stringify({min : '50001', max : '75000'})}
                  onChange={budgetChange}/>
                  <label htmlFor="₹50001-₹75000" className="para">
                    ₹50001-₹75000
                  </label>
                </div>
                <div className="inp__field">
                  <input type="checkbox" id="₹75001-₹100000" 
                  value={JSON.stringify({min : '75001', max : '100000'})}
                  onChange={budgetChange}/>
                  <label htmlFor="₹75001-₹100000" className="para">
                    ₹75001-₹100000
                  </label>
                </div>
                <div className="inp__field">
                  <input type="checkbox" id="₹100001-₹125000" 
                  value={JSON.stringify({min : '100001', max : '125000'})}
                  onChange={budgetChange}/>
                  <label htmlFor="₹100001-₹125000" className="para">
                    ₹100001-₹125000
                  </label>
                </div>
                <div className="inp__field">
                  <input type="checkbox" id="₹125001-₹150000" 
                  value={JSON.stringify({min : '125001', max : '150000'})}
                  onChange={budgetChange}/>
                  <label htmlFor="₹125001-₹150000" className="para">
                    ₹125001-₹150000
                  </label>
                </div>
                <div className="inp__field">
                  <input type="checkbox" id="₹150001-₹175000" 
                  value={JSON.stringify({min : '150001', max : '175000'})}
                  onChange={budgetChange}/>
                  <label htmlFor="₹150001-₹175000" className="para">
                    ₹150001-₹175000
                  </label>
                </div>
                <div className="inp__field">
                  <input type="checkbox" id="₹175001-₹200000" 
                  value={JSON.stringify({min : '175001', max : '200000'})}
                  onChange={budgetChange}/>
                  <label htmlFor="₹175001-₹200000" className="para">
                    ₹175001-₹200000
                  </label>
                </div>
                <div className="inp__field">
                  <input type="checkbox" id=">₹200000" 
                  value={JSON.stringify({min : '200000', max : Number.MAX_SAFE_INTEGER})}
                  onChange={budgetChange}/>
                  <label htmlFor=">₹200000" className="para">
                    ₹200000
                  </label>
                </div>
                <div className="inp__field">
                  <input type="checkbox" id="Notmentioned" 
                  value={JSON.stringify("notmentioned")}
                  onChange={budgetChange} />
                  <label htmlFor="Notmentioned" className="para">
                    Not mentioned
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="res__sec">

            {projectList.map((project, i) => {

                  return (

                    <ABuisCard key= {i} project={projectList[i]} />
                  );
                  }
            )}

            {/* <div className="bri__att__cont">
              <div className="bri__att__top">
                <div className="part1">
                  <p
                    className=" babtns active briefbtn para"
                    onClick={switchTabs}
                    dest="briefTab"
                  >
                    Brief
                  </p>
                  <p
                    className=" babtns para"
                    onClick={switchTabs}
                    dest="attachmentTab"
                  >
                    Attachment(s)
                  </p>
                </div>
                <div className="part1">
                  <p className=" babtns para">Chat</p>
                  <p className=" babtns sharebtn para">Share Proposal</p>
                  <p className="para width__100">
                    (<span className="fw__600">Edit proposal</span> if saved
                    halfway)
                  </p>
                </div>
              </div>
              <div
                className="badatapart brief__data dnoneall activetab"
                id="briefTab"
              >
                <div className="dflex justify__between">
                  <p className="para color__orange cursor__pointer">
                    View brief in full page
                  </p>
                </div>
                <h4>Website Design & Development</h4>
                <p className="para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  volutpat amet convallis non sit nunc. Amet diam urna, sem
                  rhoncus, ultricies aliquet. In sit arcu id sed tincidunt
                  ultrices. Blandit iaculis libero vulputate et mus eget vitae
                  tincidunt. Nunc vulputate a erat mi diam at. Tempus maecenas
                  mauris, lobortis a egestas. Ac mauris amet, a faucibus ut.
                  Sollicitudin odio non cras sit.
                </p>
                <h4>Advertising Across all mediums</h4>
                <p className="para">
                  Consectetur massa penatibus enim purus vitae, vulputate nisi.
                  Suspendisse augue enim lobortis massa venenatis orci aliquam.
                  Proin dui, aenean ipsum sed. Morbi sit morbi malesuada cras
                  magna eget. Morbi proin tristique lacus nam egestas. Arcu
                  tristique pellentesque enim magna tellus convallis.
                  Suspendisse fermentum feugiat et ac. Congue quis interdum enim
                  nisi.
                </p>
                <h4>PR Campaign for Product</h4>
                <p className="para">
                  Aliquet bibendum pretium lorem feugiat blandit. Aliquet morbi
                  morbi amet, aliquam, aenean aenean venenatis risus. Mauris
                  viverra eget ut pulvinar morbi leo ac condimentum mattis.
                </p>{" "}
              </div>
              <div
                className="badatapart assign__data dnoneall"
                id="attachmentTab"
              >
                <div className="assign__task">
                  <p className="para">Attachment 1</p>
                  <p className="para">11 Nov 2020</p>
                </div>
                <div className="assign__task">
                  <p className="para">Attachment 2</p>
                  <p className="para">11 Nov 2020</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ANewBusiness;