import React, { useState } from "react";
import { FaFilePdf } from "react-icons/fa";
import axios from "axios";
import "./UserResume.css";

export const UserResume = ( { resume }) => {
  const [fileName, setFileName] = useState("Choose a file...");
  const [resumeToSend, setResumeToSend] = useState(null);
  const [userResume , setUserResume] = useState(resume)

  const handleResume = (event) => {
    const file = event.target.files[0];
    setResumeToSend(file);
    setFileName(file ? file.name : "Choose a file...");
  };

  const editResume = () => {
    setUserResume(null)
    setResumeToSend(null)
    setFileName("Choose a file...")
  }

  const submitResume = async () => {
    const dataToSend = new FormData();

    dataToSend.append("File", resumeToSend);

    console.log(JSON.parse(localStorage.getItem("marketer")));
    const {data} = await axios.post(
      "https://unispade.com/api/uploadResume/" +
        JSON.parse(localStorage.getItem("marketer")).user._id,
        dataToSend,
      {
        headers: {
          authorization:
            "Bearer " + JSON.parse(localStorage.getItem("marketer")).token,
        },
      }
    );

    setUserResume(data?.url)
  };
  return (
    <div className="file-upload-wrapper">
      {userResume ? (
        <>

        <div>
        <a
            href={userResume}
            target="_blank"
            rel="noopener noreferrer"
          >
                        <FaFilePdf style={{
                width : "50px",
                height : "50px",
                color : "#4484e3"
            }}></FaFilePdf>
          </a>

        </div>

          <button type="button" className="file-upload-button" onClick={editResume}>
            Change
          </button>
        </>
      ) : (
        <>
          <input
            type="file"
            id="resume"
            name="resume"
            accept=".pdf, .doc, .docx"
            className="file-upload-input"
            onChange={handleResume}
          />
          <label htmlFor="resume" className="file-upload-label">
            {fileName}
          </label>

          <button type="button" className="file-upload-button" onClick={submitResume}>
            Submit
          </button>
        </>
      )}
    </div>
  );
};
