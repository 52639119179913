import React, { useEffect, useState } from "react";
import "./YourSessions.css";
import SessionForm from "./SessionForm";
import { SlCalender } from "react-icons/sl";
import { FaArrowRight } from "react-icons/fa";
import { IoMdMore } from "react-icons/io";
import axios from "../../../../../axios";
import NewLoader from "../../../../loader/NewLoader/NewLoader";


function YourSessions() {
  const [loader, setLoader] = useState(true);

  const [showForm, setShowForm] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [editDeleteDropdown, setEditDeleteDropdown] = useState({});
  const [currentSessionData, setCurrentSessionData] = useState(null);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`/mentoring/get-all-mentor-sessions`, {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      });

      console.log("The data is ", data);
      setSessions(data);
      setLoader(false);
    })();
  }, []);

  const handleCreateNewSessionClick = () => {
    setShowForm(true);
  };

  const handleFormSubmit = async (newSession) => {
    console.log("The new session is ", newSession);

    const { data } = await axios.post(
      "/mentoring/create-session/",
      {
        ...newSession,
        mentor_id: Marketer.user._id,
      },
      {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      }
    );

    console.log("the data is ", data);

    setSessions([...sessions, data?.session]);
    setShowForm(false);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setCurrentSessionData(null);
    setEditDeleteDropdown({});
  };

  const onEditSession = async (id, newSession) => {
    console.log("The new session is ", newSession);
    const { data } = await axios.post(
      `/mentoring/update-mentoring-session/${id}`,
      newSession,
      {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      }
    );

    console.log("the data is ", data?.data);
    setShowForm(false);
    setEditDeleteDropdown((prevData) => ({ ...prevData, [id]: false }));
    setSessions((prevData) => prevData.map((session) => session._id === id ? data?.data : session));

  }

  if (showForm) {
    return (
      <SessionForm onSubmit={handleFormSubmit} onClose={handleCloseForm} currentSessionData={currentSessionData} onEditSession = {onEditSession}/>
    );
  }

  const handleEditDelete = (id) => {
    console.log("The id is ", id);
    setEditDeleteDropdown((prevData) => ({ ...prevData, [id]: !prevData[id] }));
  };

  const handleSessionEdit = async (id) => {
    setShowForm(true);
    const data = sessions.find((session) => session._id === id);
    setCurrentSessionData(data);
  }
  const handleSessionDelete = async (id) => {
    console.log("The id is ", id);

    const { data } = await axios.delete(`/mentoring/delete-mentoring-session/${id}`, {
      headers: {
        authorization: "Bearer " + Marketer.token,
      },
    });

    console.log("The data is ", data);

    setSessions((prevData) => prevData.filter((session) => session._id !== id));
   }

  return (
    <>
      {loader ? (
        <NewLoader></NewLoader>
      ) : (
        <div className="your-sessions-container">
          {sessions.map((session, index) => (
            <div key={index} className="session-item">
              <div className="sessionTypeAndEdit">
                <div className="session-type">{session.type}</div>
                <div className="edit-session">
                  <IoMdMore
                    style={{
                      width: "25px",
                      height: "25px",
                    }}
                    onClick={() => handleEditDelete(session?._id)}
                  ></IoMdMore>
                </div>
                {editDeleteDropdown[session?._id] &&                 <div className="editDeleteDropdown">
                  <button className="session-edit-button" onClick={() => handleSessionEdit(session?._id)}>Edit</button>
                  <button className="session-delete-button" onClick={() => handleSessionDelete(session?._id)}>Delete</button>
                </div>}


              </div>
              <div className="session-title">{session.title}</div>
              <div className="session-description">{session.description}</div>
              <div className="session-details">
                <div className="session-duration">
                  <SlCalender color="black" /> {session.duration}
                </div>
                <div className="session-price">
                  ₹{session.price} <FaArrowRight className="right-icon" />
                </div>
              </div>
            </div>
          ))}
          <div className="create-session-box">
            <button
              className="create-session-button"
              onClick={handleCreateNewSessionClick}
            >
              + Create New Session
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default YourSessions;
