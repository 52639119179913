import React, { useState, useEffect } from "react";
import ASidebar from "./BSidebar";
import "../../styles/ATeam.css";
import { v4 as uuidv4 } from "uuid";
import notificationg from "../../images/notificationg.png";
import dotg from "../../images/dotg.png";
import messageg from "../../images/messageg.png";
import ATeamCard from "./BTeamCard";
import ASidebarAlt from "./BSidebarAlt";
import axios from "../../axios.js";
import authHeader from "../../login-auth.js";
import { useHistory } from "react-router-dom";

function ATeam({brandId}) {
  const history = useHistory();
  const demoid = authHeader().User.agencyId;
  const [teammembs, setTeammembs] = useState([]);
  const [deleteMember, setDeleteMember] = useState(0);
  // To get the list of team members based on agency id
  useEffect(() => {
    const getTeammembers = async () => {
      try {
        const res = await axios.post("/brands/teamMember/getAllMembers", {
          brandId: brandId,
        });
        console.log("team members => ", res);

        setTeammembs(res.data);
      } catch (e) {
        console.log(e);
      }
    };
    getTeammembers();
  }, [deleteMember]);

  const fun = () => {
    setDeleteMember((p) => p + 1);
  };
  const addMem = () => {
    console.log("adding new member");
    history.push("/adashboard/ateam/addMemb");
  };
  return (
    // <div className="ateam__cont">
    //   <ASidebar />
    <div className="ateam">
      
      <div className="section__cont">
        <div className="section">
          <div className="part__left">
            <h3 className="aonboarding__title">Team</h3>
          </div>
          <div className="part__right">
            <div className="img__cont">
              <img className="msg__not" src={messageg} alt="" />
              <img className="dotg" src={dotg} alt="" />
            </div>
            <div className="img__cont">
              <img className="msg__not" src={notificationg} alt="" />
              <img className="dotg" src={dotg} alt="" />
            </div>
            <p className="date__day para adgrey"> </p>
          </div>
        </div>
        <div className="search__cont">
          <input className="search__inp" type="text" placeholder="Search" />
        </div>
      </div>

      <div style={{ textAlign: "right" }}>
        <p
          onClick={addMem}
          className="para color__orange cursor__pointer fw__600"
        >
          + Add/Invite Member
        </p>
      </div>
      <div className="teammembers">
        {teammembs.map((memb) => {
          return (
            <ATeamCard
              deleteMem={fun}
              key={uuidv4()}
              name={memb?.firstName+"  "+memb?.lastName}
              email={memb?.officialMailId}
              isadmin={memb?.role == "Admin" ? true : false}
            />
          );
        })}
        {/* <ATeamCard />
        <ATeamCard isadmin />
        <ATeamCard /> */}
      </div>
    </div>
    // </div>
  );
}

export default ATeam;
