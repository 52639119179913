import React, { useState, useEffect } from "react";
import "../../styles/ASidebar.css";
import { Link, useHistory } from "react-router-dom";
import logo from "../../images/logos.png"
import AWS from 'aws-sdk';
import {uploadImage} from "../../uploadImage"

function ASidebar({brandData,logoUpdate}) {

  const [brandName, setBrandName] = useState(brandData.brandName);
  const history = useHistory();
  const [brandLogo, setBrandLogo] = useState(brandData.brandLogo);
  const [submitlogo, setsubmitlogo] = useState(false);

  useEffect(()=>{setBrandLogo(brandData.brandLogo)},[brandData])

  // quatations swtichtab
  const switchTab = (e) => {
    const tabItem = e.target;

    // For btn activation
    const alltablinks = document.querySelectorAll(".asidelink");

    alltablinks.forEach((link) => {
      link.classList.remove("active"); //deactivating rest of the links
    });

    tabItem.classList.add("active"); //activating the link which is clicked

    const destid = e.target.getAttribute("dest"); //getting the destination id which needs to be activated.
    history.push(`/bdashboard/${destid}`);

    // To deactivation sidebar after clicking
    const sidebarComp = document.querySelector(".asidebartranslate");
    sidebarComp.classList.toggle("asidebarshowtranslate");
  };


  const ChangeBrandLogo = async (e) => {
    const { value, name } = e.target;
    const res=await uploadImage(e.target.files[0],"/brandProfile")
    setBrandLogo(res);
  };


  const updateBrandLogo = async (e) => {
    setsubmitlogo(true);

    const res = await logoUpdate(brandLogo)

    
    console.log(res.data);
    setsubmitlogo(false)
  };

  // useEffect(() => {
  //   const getInfo = async () => { 
  //     try {
  //       const getBrandData=await axios.post("/brands/getBrandProfile",{id:branduser.user.brandId})
  //       setBrandName(getBrandData.data.Brand.brandName);
  //       setBrandLogo(getBrandData.data.Brand.brandLogo);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };
  //   getInfo();
  // }, []);

  return (
    <div className="asidebar asidebartranslate" >
      <div className="a__sidebar__mob"></div>
      <div className="asidebar__top">
        <div class="profile-image">
          {brandLogo ? <img src={brandLogo} className="asidelogo" alt="" /> :
            <img src={logo} className="asidelogo" alt="" />}
          {/* <img src={brandLogo} className="asidelogo" alt="" /> */}
          <div class="image_hover_bg"> <label htmlFor="filePicker" style={{ cursor: "pointer", padding: "55px 50px" }}>
           
          </label></div>
        </div>
        <p className="adgrey fw-bolder">{brandName}</p>
        <div className="btn">
         
          <input
            id="filePicker" 
            style={{ visibility: "hidden" }}
            multiple="multiple"
            type="file"
            onChange={(e) => {
              ChangeBrandLogo(e);
            }}
            name="clientLogo"
          ></input>

          <button
            style={{ background: "rgba(255, 0, 0, 0.1)", color: "#FFFFFF", border: "none", padding: "5px 10px",marginTop:"-20px" }}
            type="button"
            onClick={() => {
              updateBrandLogo();
            }}
            name="clientLogo"
            value="Update Logo"
          > {submitlogo && (
            <i className="fas fa-circle-notch donebtnclient"></i>
          )}
            {submitlogo && "Updating"}
            {!submitlogo && "Update Logo"}</button>

        </div>
      </div>
      <div className="asidelinks">

        <p

          className="asidelink para adgrey cursor__pointer text__left active fw-bolder "
          onClick={switchTab}
          dest="beditprofile"
        >
          Edit Profile
        </p>
        {/* <p
          className="asidelink para adgrey cursor__pointer text__left"
          // onClick={switchTab}
          dest="aonboarding"
        >
          Partner Program
        </p> */}
        <p
          className="asidelink para adgrey cursor__pointer text__left"
          onClick={switchTab}
          dest="bteam"
        >
          Team
        </p>
      </div>
    </div>
  );
}

export default ASidebar;