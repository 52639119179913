import React from 'react';
import doneIcon from "../img/check_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import calendar from "../img/calendar_month_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import time from "../img/schedule_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import "./paymentDone.css";


const PaymentDone = ({ selectedDate, selectedTimeSlot , sessionDetails}) => {

  const redirectToDashBoard = () => {
    window.location.href = `http://localhost:3000/user-dashboard/profile/mentoring/`
  }

  const redirectToProfile = () => {
    window.location.reload();
  } 

  const formatDate = (date) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-IN", options);
  };
  return (
    <div className="userPaymentDoneSingleSession">
      <div className="sessionHeader">
        <div className="sessionHeader-title">
          <button className="backButton">
            <img src={doneIcon} alt="Done" />
          </button>
          <h1 className="sessionHeader-title heading">{sessionDetails?.title}</h1>
        </div>
        <div className="duration-slot">
          <div className="duration-item">
            <span>
              <img src={calendar} alt="calendar" />{" "}
              {formatDate(selectedDate)}
            </span>
          </div>
          <div className="duration-item">
            <span>
              <img src={time} alt="time" /> {selectedTimeSlot}
            </span>
          </div>
        </div>
      </div>
      <div className="paymentDone-section">
        <div className="confirmationMessage">
          <p>Your session request has been sent to <strong>{sessionDetails?.mentor_id?.name}</strong></p>
          <p>Please wait for <strong>{sessionDetails?.mentor_id?.name}</strong> to confirm your call.</p>
          <p>You can reschedule the session or edit session details from your dashboard.</p>
        </div>
      </div>
      <div className="paymentdoneFooter">
        <div className="paymentBtm">
          <button onClick={redirectToDashBoard}>Go to dashboard</button>
        </div>
        <button className="bookButton" onClick={redirectToProfile}>Explore mentors</button>
      </div>
    </div>
  );
};

export default PaymentDone;
