import React, { useEffect, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./ImageCropper.css";
// import uploadIcon from "../../MarketerDashbCompo/img/profile.png"
import {uploadImage,deleteImage} from "../../../uploadImage"
import uploadIcon from '../../../images/upload.png';

const ProfileeImage = ({setUserProfileFormData,caseStudyForm}) => {
    const [image, setImage] = useState();
    const [imageName, setImageName] = useState();
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState();
    const [buttonName,setButtonName]=useState("Upload")



    const onChange = (e ) => {
        setImageName(e.target.files[0].name)
        console.log(e.target.files[0].name);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
        files = e.dataTransfer.files;
        } else if (e.target) {
        files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
        setImage(reader.result  );
        };
        reader.readAsDataURL(files[0]);
    };
    useEffect(()=>{console.log("done");},[cropData])
  
    const getCropData = async() => {
        setButtonName("Uploading...")
      if (typeof cropper !== "undefined") {
        let cropredData=cropper.getCroppedCanvas().toDataURL()
        try {
         
            const file = dataUrlToFile(cropredData, imageName);
            const uploadedUrl = await uploadImage(file, "clientMedia/");
            let caseStudyFormCopy = { ...caseStudyForm };
            caseStudyFormCopy.coverImg = uploadedUrl;
            // props.setCaseStudyFormData(caseStudyFormCopy);
            setUserProfileFormData(caseStudyFormCopy)
            // Additional actions with the uploaded image URL 
            setButtonName("Uploaded")
            setImageName()
            
          } catch (error) {
            console.error("Upload error:", error);
            // Handle the error as needed
            setButtonName("Upload")
          }
      }
    };

    const dataUrlToFile = (dataUrl, filename) => {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
      
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
      
        return new File([u8arr], filename, { type: mime });
      };
      
      const uploadButtonHandler = (field) => {
        let elem = document.getElementById('imageInput');
        if (elem) elem.click();
    }
  
      
  
    return (
      <div className="coverImage">
        <div className="uploadSection" >
            <img src={uploadIcon} alt="" />
            <button onClick={uploadButtonHandler}>Upload</button>
            <span className='formats'>.jpeg | .png | .gif | .jpg</span>
            <input  id="imageInput" type="file" onChange={onChange} multiple="single" accept={".jpeg,.png,.gif,.mov,.mp4"} />
        </div>
        {!imageName&&<div style={{marginRight:"auto",display:"flex",gap:"20px"}}>
          <img style={{width:"100px",borderRadius:"5px"}} src={caseStudyForm.coverImg} alt="" />
          <span>{caseStudyForm.coverImg.substring(caseStudyForm.coverImg.indexOf('_') + 1)}</span>
        </div>}
        
        {imageName&&<><div >
          <Cropper
            style={{marginTop:"20px", height: 400, width: "100%",marginRight:"auto" }}
            zoomTo={0}
            initialAspectRatio={27/20}
            aspectRatio={27/20}
            preview=".img-preview"
            src={image}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />
        </div>
        <div style={{display:"flex",gap:"10px"}} className="box" > 
            <button style={{marginRight:"10px",background:"transparent"}} onClick={()=>{setImage();setImageName()}} >Discard</button>           
            <button  onClick={(e)=>{getCropData(e);e.preventDefault()}}>
                {buttonName}
            </button>
          </div>
        <div>
        </div>
        
        </>
        }
        
      </div>
    );
}

export default ProfileeImage