import React, { useEffect, useState } from "react";
import "./credit.css";
const Credit = ({
  inputId,
  inputName,
  addCredits,
  from,
  dropDownData,
  preSetInput,
  resetCredit
}) => {
  const [data, setdata] = useState("");
  const [dataListHide, setdataListhide] = useState(false);

  useEffect(() => {
    setdata('');
  }, [resetCredit]);

  const handleDataName = (event) => {
    let { name, value, hideList } = event.target;
    let message = value.replace(/\s{3,}/g, " ");
      if (!/\S/.test(message)) {
        message = message.replace(/\s+/g, "");
      }
    setdata(message);
    setdataListhide(hideList ? true : false);
    addCredits({ target: { name: name, value: message} }, from);
  };


  const handleDataList = (value) => {
    setdataListhide(true);
    handleDataName({
      target: { name: inputName, value: value, hideList: true },
    });
  };
  return (
    <>
      <>
        <input autoComplete="off" className="creditInput" value={   preSetInput===null?"":   data === "" ? (preSetInput != undefined ? preSetInput : "") : data } onChange={(event) => {   handleDataName(event); }} placeholder="" type="text" name={inputName} id={inputId}
        />
        {inputName !== ("Name" || "Agency Website") && data !== "" && (
          <div
            className="CreditsDataList"
            style={{
              display: dataListHide && "none",
            }}
          >
 
            {dropDownData.map(
              (key) =>(
                (from === "individualCredit" ? key.email : key.agencyName).toLowerCase().includes(data) && (
                  <p
                    onClick={() => {
                      handleDataList(
                        from === "individualCredit" ? key.email : key.agencyName
                      );
                    }}
                  >
                    {from === "individualCredit"
                      ? `${key.name}  ${key.email}`
                      : `${key.agencyName}  ${key.agencyWebsite}`}
                  </p>
                ))
            )}
          </div>
        )}
      </>
    </>
  );
};

export default Credit;
