import React from "react";

function UEditProfile() {
  return (
    <div className="usertab ueditprofile" id="edittab">
      <div className="level">
        <div className="part">
          <div className="pl">
            <label className="labell para">First Name</label>
            <input placeholder="Pooja" className="inputt para" type="text" />
          </div>
          <div className="pr"></div>
        </div>
        <div className="part">
          <div className="pl">
            <label className="labell para">Last Name</label>
            <input placeholder="Sharma" className="inputt para" type="text" />
          </div>
          <div className="pr"></div>
        </div>
        <div className="part">
          <div className="pl">
            <label className="labell para">Personal Mail ID</label>
            <input
              placeholder="pooja.sharma@gmail.com"
              className="inputt para"
              type="text"
            />
          </div>
          <div className="pr"></div>
        </div>
      </div>
      <div className="level">
        <div className="part">
          <div className="pl">
            <label className="labell para">Company</label>
            <input placeholder="Nike" className="inputt para" type="text" />
          </div>
          <div className="pr">
            <label className="labell color__orange para">Change</label>
          </div>
        </div>
        <div className="part">
          <div className="pl">
            <label className="labell para">Designation</label>
            <input
              placeholder="Chief Marketing Officer"
              className="inputt para"
              type="text"
            />
          </div>
          <div className="pr"></div>
        </div>
        <div className="part">
          <div className="pl">
            <label className="labell para">Official Mail ID</label>
            <input
              placeholder="pooja.sharma@gmail.com"
              className="inputt para"
              type="text"
            />
          </div>
          <div className="pr"></div>
        </div>
      </div>
      <div className="level">
        <div className="part">
          <div className="pl">
            <label className="labell para">User ID</label>
            <input
              placeholder="pooja.sharma"
              className="inputt para"
              type="text"
            />
          </div>
          <div className="pr"></div>
        </div>
        <div className="part">
          <div className="pl">
            <label className="labell para">Contact Number</label>
            {/* <input
              placeholder="XXXX291969"
              className="inputt para"
              type="text"
            /> */}

            <div className="mobnocont">
              <select className="countrycode inputt" type="tel">
                <option defaultValue>+91</option>
                <option>+44</option>
              </select>
              -
              <input className="mobno inputt" type="tel" />
            </div>
          </div>
          <div className="pr">
            <label className="labell color__orange para">Verify</label>
          </div>
        </div>
        <div className="part">
          <div className="pl">
            <label className="labell para">LinkedIn Profile</label>
            <input
              placeholder="www.linkedin.com/pooja.sharma"
              className="inputt para"
              type="text"
            />
          </div>
          <div className="pr"></div>
        </div>
      </div>
      <div className="dflex justify__between">
        <p className="para color__orange cursor__pointer">
          Request Password Change
        </p>
      </div>
    </div>
  );
}

export default UEditProfile;
