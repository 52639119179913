import React from 'react';
import './ServicePageBanner.css';

const ServicePageBanner = (props) => {
    return <div className='service-page-banner'>
        <div className='banner-text'><h1>{props.bannerText}</h1></div>
        {/* <div className='sub-banner-text'>You can refine filters to find the best agency for your business needs</div> */}
    </div>
}

export default ServicePageBanner;