import React from 'react';
import './AgencyCard.css';

const AgencyCard = (props) => {
    const getInitialAgencyName = (agencyName) => {
        let nameArr = agencyName.split(' ');
        let initial = '';
        if (nameArr.length === 1) {
            initial = nameArr[0][0].toUpperCase();
        } else if (['/', '\\', '&'].includes(nameArr[1][0])) {
            initial = nameArr[0][0].toUpperCase();
        } else {
            initial = `${nameArr[0][0].toUpperCase()} . ${nameArr[1][0].toUpperCase()}`;
        }
        return initial;
    }

    const onAgencyClick = () => {
        props.selectAgencyHandler(props.agency)
    }
    const onAgencyMobileClick = () => {
        props.selectAgencyHandler(props.agency)
        props.scrollElem(props.agency._id);
    }
    

    return (
        <div
            className="agency-card"
            onClick={() => {
                if (window.innerWidth <= 768) {
                onAgencyMobileClick();
                } else {
                onAgencyClick();
                }
            }}
        >
            <div className='image-container'>
            {props.agency.agencyLogo.includes("unispade-react-js-s3")?<p className="agency-img agencylogoInitials">{props.agency.agencyName[0].toUpperCase()}</p>:<img src={props.agency.agencyLogo} className="agency-img" />}
                {/* {props.agency.agencyLogo ? <img src={props.agency.agencyLogo} className="agency-img" /> :
                    <div className="agency-initials">{getInitialAgencyName(props.agency.agencyName)}</div>} */}
            </div>
            <div className='desc-container'>
                <div className='agency-name-location'>
                    <span className='agency-name'>{props.agency.agencyName}</span>
                    {props.agency.agencyWebsite && <span className='agency-website'>{props.agency.agencyWebsite}</span>}
                    {props.agency.locations[0] && <span className='agency-location'>{props.agency.locations[0]}</span>}
                </div>
                <div className='services-container'>
                    {props.agency.services.length !== 0 && props.agency.services.map((service, index) => {
                        return index <= 2 && <span className='services'>{service}</span>
                    })}
                </div>
                <div className='client-container'>
                    {props.agency?.agencyClients && props.agency?.agencyClients?.length !== 0 && props.agency.agencyClients.map((client, index) => {
                        return index <= 2 && <div className="agency-client-logo_name" key={"client_card_" + index}>
                            {client.clientLogo.includes("unispade-react-js-s3")?<p className="agency-client-logo agency-client-logoInitials">{client.clientName[0].toUpperCase()}</p>:<img src={client.clientLogo} className="agency-client-logo" alt="agency-client-logo" />}
                            {/* <img className="agency-client-logo" alt="agency-client-logo" src={client.clientLogo} /> */}
                            <div className="agency-client-name">{client.clientName}</div>
                        </div>
                    })}
                </div>
                {/* <div className='agency-desc'>{props.agency.aboutAgency}</div> */}
            </div>
        </div>
    )
}

export default AgencyCard;