import React from "react";
import NotifyText from "./NotifyText";

function UNotifyTab() {
  return (
    <div className="usertab unotifytab activetab" id="notifytab">
      <NotifyText />
      <NotifyText />
      <NotifyText />
      <NotifyText />
      <NotifyText />
      <NotifyText />
    </div>
  );
}

export default UNotifyTab;
