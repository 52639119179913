import React, { useState } from "react";
import "../../styles/BNewProject.css";
import { Link, useHistory } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import calendericon from "../../images/calendericon.png";
import axios from "../../axios.js";

function BNewProjectForm() {
    const history = useHistory();
    const [cOpen, setCOpen] = useState(false);
    const [date, setDate] = useState(new Date());
    const [addNewProjectForm, setaddNewProjectForm] = useState({
        brandId: JSON.parse(localStorage.getItem('branduser')).user.brandId,
        projectName: "",
        type: "",
        budget: {
            min: "",
            max: "",
          },
        isAssistanceNeeded: "",
        deadline: "",
        services: [""],
        projectBrief: "",
      });

    const ondatechange = date => {
        setDate(date);
        setCOpen(prev => setCOpen(!prev));
        setaddNewProjectForm(prev => {
            return {
              ...prev,
              deadline: date
            };
          });
    };
    const openCalender = () => {
        setCOpen(prev => setCOpen(!prev));
    };

    const gotoprojectbrief = () => {
        history.push("/newprojectbrief");
    };

    const submitHandler = async () => {
   
        var updateProject = addNewProjectForm;
        console.log('Check Data', updateProject)
        try {
            if(!updateProject.projectName ||
                !updateProject.type ||
                !updateProject.budget.min ||
                !updateProject.isAssistanceNeeded || 
                !updateProject.budget.min ||
                !updateProject.services ||
                !updateProject.projectBrief){
                  throw new Error("All Fields are Mandatory")
                }
          const response = await axios.post("/brands/addNewProject", updateProject);
          console.log(response);
          history.push("/brandlogin");
        } catch (err) {
            window.alert(err)
          console.log(err);
        }
      };

      const changeHandler = event => {
        const { name, value } = event.target;
        setaddNewProjectForm(prev => {
          return {
            ...prev,
            [name]: value
          };
        });
    
      console.log('check updated data', addNewProjectForm);
      }; 

      const changeBudget = event => {
        const value = JSON.parse(event.target.value);
        setaddNewProjectForm(prev => {
          return {
            ...prev,
            budget: { ...prev.budget, min: value.min, max:value.max }
          };
        });
    
      }; 

      const selectHandle = event => {
        const options = event.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
            value.push(options[i].value);
            }
        }
        setaddNewProjectForm(prev => {
          return {
            ...prev,
            services: value
          };
        });
    
      console.log('Selected Services:', value);
      };

    return (
        <div className="bnewproject">
            <div className="level">
                <p className="labell">What would you like to name your project?</p>
                <input className="inputt" 
                type="text" 
                onChange={changeHandler}
                name="projectName"
                value={addNewProjectForm.projectName}

                />
            </div>
            <div className="level">
                <p className="labell">How are you going to post the project?</p>
                <div className=" radio__inp__main__cont flxs">
                    <div className="radio__inp__cont2">
                        <div className="radio__inp__cont">
                            {" "}
                            <input
                                className="input adgrey"
                                onChange={changeHandler}
                                id="open"
                                name="type"
                                value="open"
                                type="radio"
                            ></input>{" "}
                            &nbsp;
                            <label htmlFor="open" className="para fw__600">
                                Open
                            </label>
                        </div>
                        <p className="para adgrey">
                            Just post a brief & all relevant agencies will be notified
                        </p>
                    </div>
                    <div className="radio__inp__cont2">
                        <div className="radio__inp__cont">
                            <input
                                className="input adgrey"
                                onChange={changeHandler}
                                id="request"
                                name="type"
                                value="request"
                                type="radio"
                            ></input>{" "}
                            &nbsp;
                            <label htmlFor="request" className="para fw__600">
                                Request
                            </label>
                        </div>
                        <p className="para adgrey">
                            Request for Proposal only from your shortlisted Agencies
                        </p>
                    </div>
                </div>
            </div>
            <div className="level">
                <p className="labell">What is your budget range for this project?</p>
                <div className="selecttextcont">
                    <select className="selectt"
                            name="budget" 
                            id="budget" 
                            onChange={changeBudget} >
                        <option >Select Budget</option>
                        <option value={JSON.stringify({min : '25000', max : '50000'})}>₹25000-₹50000</option>
                        <option value={JSON.stringify({min : '50000', max : '100000'})}>₹50000-₹100000</option>
                    </select>
                    <p className="para adgrey">
                        This will help agencies to come up with ideas that fir your budget.{" "}
                    </p>
                </div>
            </div>
            <div className="level">
                <p className="labell">Service(s)</p>
                <div className="selecttextcont">
                    <select className="selectt"
                            name="services" 
                            id="services" 
                            multiple={true}
                            onChange={selectHandle}>
                        <option>Select Services</option>
                        <option value="Advertising">Advertising & Marketing</option>
                        <option value="Design">Design</option>
                        <option value="Creativity">Creativity</option>
                        <option value="Content Strategy">Content Strategy</option>
                    
                    </select>
                    <p className="para adgrey">
                        You can select multiple services.
                    </p>
                </div>
            </div>
            <div className="level">
                <p className="labell">
                    Would you like assistance in your agency search?
                </p>
                <div className=" radio__inp__main__cont bigcolgap flxs">
                    <div className="radio__inp__cont2">
                        <div className="radio__inp__cont">
                            {" "}
                            <input
                                className="input adgrey"
                                id="appreciate"
                                name="isAssistanceNeeded"
                                value="true"
                                type="radio"
                                onChange={changeHandler}
                            ></input>{" "}
                            &nbsp;
                            <label htmlFor="appreciate" className="para fw__600">
                                Yes, I will appreciate the help
                            </label>
                        </div>
                    </div>
                    <div className="radio__inp__cont2">
                        <div className="radio__inp__cont">
                            <input
                                className="input adgrey"
                                id="itsok"
                                name="isAssistanceNeeded"
                                value="false"
                                type="radio"
                                onChange={changeHandler}
                            ></input>{" "}
                            &nbsp;
                            <label htmlFor="itsok" className="para fw__600">
                                It’s okay, I think I will do it myself
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="level">
                <p className="labell">Set a deadline to receive proposals</p>
                <div className="datecalendercont ">
                    <div className="datecalender" onClick={openCalender}>
                        {moment(date).format("DD/MM/YYYY")}{" "}
                        <img src={calendericon} alt="" />
                    </div>
                    {cOpen && <Calendar onChange={ondatechange} value={date} />}

                    {/* {console.log(date)} */}
                </div>
            </div>
            <div className="level">
                <p className="labell">Describe your project requirements in a few lines</p>
                <input className="input-t" 
                       type="text"
                       onChange={changeHandler}
                       name="projectBrief"
                       value={addNewProjectForm.projectBrief}/>
            </div>
            <div className="level">
                <p className="labell">Attach supporting documents</p>
                <div className="btn" style={{background:"white" , color:"black"}}>
                
                    <input type="file" id="actual-btn" hidden/>
                    <label for="actual-btn" style={{cursor:"pointer"}}>+ Add File(s)</label>
                    
                </div>
            </div>
            <div className="level">
            <div className="submitt">
              

                    <button className="btn" onClick={submitHandler}>
                        Submit </button>
                    <span>or</span>
                    <span
                        className="para color__orange cursor__pointer"
                        
                        onClick={gotoprojectbrief}
                    >
                        Create the Project Brief
                    </span>
                </div>
            
            </div>
        </div>
    );
}

export default BNewProjectForm;
