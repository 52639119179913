import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ContactUsForm from './contactUsForm';
import {Labonita, MaxLife, Camp, Cta, Ebay,} from "../HomePageLatestComponents/images";
import {Design, Performance, Branding, Advertising} from "./images/index"
import "./landingPage.css";


const LandingPage = () => {
    const brandLogos = [
        MaxLife, Camp, Ebay, Labonita
    ];
    const startupData = [
        {
        imgSrc: Performance,
        title: 'Performance Marketing',
        link: '/performance-marketing-agencies',
        },
        {
        imgSrc: Advertising,
        title: 'Advertising',
        link: '/advertising-agencies',
        },
        {
        imgSrc: Design,
        title: 'Design',
        link: '/design-agencies',
        },
        {
        imgSrc: Branding,
        title: 'Branding',
        link: '/branding-agencies',
        },
    ];
    const testimonialsData = [
        {
          logo: Labonita,
          stars: 5,
          data:
            "In a time crunch, we sought a marketing agency. With Unispade’s invaluable assistance, we connected with the ideal agency swiftly. Their impeccable understanding of our needs resulted in a tailor-made strategy that exceeded expectations. Grateful for the seamless collaboration that amplified our brand and message. Kudos to the Unispade team for their remarkable service!",
        },
        {
          logo: Camp,
          stars: 5,
          data:
            "Unispade's expertise was pivotal in linking us with an exceptional SEO agency for our new website at CampK12. Their seamless support ensured optimal online visibility, driving our success. Highly grateful!",
        },
      ];
      const faqData = [
        {
          id: 1,
          question: "What is agency search consultation?",
          answer:
            "Agency search consultation is a service that helps businesses find the right marketing and advertising agencies to meet their specific needs. We provide expert guidance and recommendations to connect you with agencies that can best serve your goals.",
        },
        {
          id: 2,
          question: "Why should I use agency search consultation services?",
          answer:
            "Our agency search consultation services save you time and effort by identifying agencies that match your requirements. We leverage our industry knowledge to ensure you connect with agencies that are well-suited for your business.",
        },
        {
          id: 3,
          question: "How does the consultation process work?",
          answer:
            "The consultation process involves understanding your business needs and objectives. We then use our industry expertise to recommend agencies that align with your goals. The final choice is yours, and we facilitate introductions.",
        },
        {
          id: 4,
          question: "What types of marketing and advertising services do you cover?",
          answer:
            "We cover a wide range of marketing and advertising services, including digital marketing, SEO, PPC advertising, social media marketing, branding, content marketing, and more. We can connect you with agencies that specialize in your desired services.",
        },
        {
          id: 5,
          question: "How do you ensure agencies are a good match for my business?",
          answer:
            "We conduct in-depth assessments of agencies in our network, considering their expertise, track record, and client reviews. We match your requirements with agency capabilities to ensure a good fit for your business.",
        },
        {
          id: 6,
          question: "Is there a cost for your consultation services?",
          answer:
            "Our consultation services are typically free for businesses seeking agency recommendations. We earn a referral fee from agencies when a successful connection is made. There is no direct cost to you for using our services.",
        },
        {
          id: 7,
          question: "What if I'm not satisfied with the recommended agencies?",
          answer:
            "If you're not satisfied with the agencies we recommend, we can provide additional options based on your feedback. Our goal is to ensure you find the right agency for your needs.",
        },
        {
          id: 8,
          question: "Can I conduct the agency search on my own?",
          answer:
            "Of course, you can conduct your agency search independently. However, our consultation services can save you time and provide valuable insights and connections within the industry.",
        },
        {
          id: 9,
          question: "How long does the agency search process usually take?",
          answer:
            "The duration of the agency search process can vary depending on your specific requirements and the agencies' availability. On average, it may take a few weeks to complete the process.",
        },
        {
          id: 10,
          question: "Do you offer ongoing support after connecting us with an agency?",
          answer:
            "Yes, we offer ongoing support to ensure a successful collaboration between your business and the chosen agency. We are here to assist with any questions or issues that may arise during your partnership.",
        },
      ];
      const [openId, setOpenId] = useState(null);

      const toggleQuestion = (id) => {
        if (openId === id) {
          // Clicked on the same question, close it
          setOpenId(null);
        } else {
          // Clicked on a different question, open it
          setOpenId(id);
        }
      };
    const cta = {
      text: 'If you are a marketing agency, create your profile to connect with the top clients in the industry',
      buttonText: 'Register here',
      image: Cta,
    };

  return (
    <div className='landingPage'>
      <div className="contactUsForm">
        <div className="landingHero">
            <h2>
                Get the top marketing agencies to pitch for your next big project
            </h2>
            <p>
            Quickest way to get free proposals from the best marketing and advertising agencies in the industry
            </p>
        </div>
        <div className="cUsForm">
            <h2>Get started</h2>
            <p>
                Book a meeting to get quick agency recommendations
            </p>
            <ContactUsForm />
        </div>
    </div>
    <div className="brandLeveraging">
        <div className="headingSection">
            <h2>
                Brands leveraging Unispade 
            </h2>
        </div>
        <div className="brandLogosContainer">
            {brandLogos.map((logo, index) => (
                <img key={index} src={logo} alt={`Logo ${index + 1}`} className="brandLogo" />
            ))}
        </div>
    </div>
    <div className='headingSection'>
        <h2>Free agency search consultation for startups</h2>
    </div>
    <div className="aSeachStartups"> 
      {startupData.map((startup, index) => (
        <div key={index} className="startup-item">
          <img src={startup.imgSrc} alt={startup.title} />
          <h3>{startup.title}</h3>
          <a href={startup.link}>Know More</a>
        </div>
      ))}
    </div>
    <div className="testimonials">
        <div className='headingSection'>
            <h2>
                Testimonials
            </h2>
        </div>
        <div className="testimonial-list">
        {testimonialsData.map((testimonial, index) => (
          <div key={index} className="testimonial">
            <img src={testimonial.logo} alt={`Logo ${index}`} />
            <div className="rating">
              {Array.from({ length: testimonial.stars }).map((_, i) => (
                <span key={i} className="star">&#9733;</span>
              ))}
            </div>
            <p>{testimonial.data}</p>
          </div>
        ))}
        </div>
    </div>
    <div className="fAQs">
      <div className="headingSection">
        <h2>FAQs</h2>
      </div>
      <div className="faq-list">
        {faqData.map((faq) => (
          <div key={faq.id} className={`faq-item ${openId === faq.id ? 'open' : ''}`}>
            <button className='faqBtn' onClick={() => toggleQuestion(faq.id)}>
              <div className='question-text'>
                <span>{faq.question}</span>
              </div>
              <div className='landingSvg'>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className={`rotate-svg ${openId === faq.id ? 'rotate' : ''}`}
              >
                <path d="M5.83398 8.33325L10.0007 12.4999L14.1673 8.33325H5.83398Z" fill="black"/>
              </svg>
              </div>
            </button>
            <p className={`answer ${openId === faq.id ? 'visible' : ''}`}>{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
        <div className="ctaSection">
        <div className="leftSection">
            <h2>{cta.text}</h2>
            <Link to="/signup">
            <button>{cta.buttonText}</button>
        </Link>
        </div>
        <div className="rightSection">
            <img src={cta.image}/>
        </div>                
    </div>
</div>
  );
};

export default LandingPage;