import React, {useEffect, useState} from "react";
import "../../styles/ADashboard.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect, useHistory
} from "react-router-dom";
import notificationg from "../../images/notificationg.png";
import dotg from "../../images/dotg.png";
import messageg from "../../images/messageg.png";
import ASidebar from "./ASidebar";
import ASidebarAlt from "./ASidebarAlt";
import ADashboardComp from "./ADashboardComp";
import AOnboarding from "./AOnboarding";
import AProposals from "./AProposals";
import ANewBusiness from "./ANewBusiness";
import AEditProfile from "./AEditProfile";
import ASubmitReferences from "./ASubmitReferences";
import ATeam from "./ATeam";
import ATeamAddMemb from "./ATeamAddMemb";
import AYourProfile from "./AYourProfile";
import AProposalTemplate from "./AProposalTemplate";
import ScrollToTop from "../ScrollToTop";
import ANewProject from "./ANewProject";

function ADashboard() {

  const history = useHistory();

  useEffect( () => {
    if( localStorage.getItem("user") === null ) {
      history.push('/login');
      return <></>;
    }
  }, [])


  const [LogoChange,setLogoChange]=useState("")

  return (
    <Router>
      <div className="adashboard__cont">
        <ScrollToTop />
        <ASidebar onUpdateLogo={LogoChange} />
        <Switch>
          <Route path="/adashboard/dashboard" exact>
            <ADashboardComp />
          </Route>
          <Route path="/adashboard/aonboarding" exact>
            <AOnboarding />
          </Route>

          <Route path="/adashboard/aproposals" exact>
            <AProposals />
          </Route>
          <Route path="/adashboard/anewbusiness" exact>
            <ANewBusiness />
          </Route>
          <Route path="/adashboard/anewproject" exact>
            <ANewProject />
          </Route>
          <Route path="/adashboard/aeditprofile" exact>
            <AEditProfile updateLogo={setLogoChange} />
          </Route>
          <Route path="/adashboard/asubmitreferences" exact>
            <ASubmitReferences />
          </Route>
          <Route path="/adashboard/ateam" exact>
            <ATeam />
          </Route>
          <Route path="/adashboard/ateam/addMemb" exact>
            <ATeamAddMemb />
          </Route>
          <Route path="/adashboard/ayourprofile" exact>
            <AYourProfile />
          </Route>

          <Route path="/adashboard/">
            <ADashboardComp />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default ADashboard;
