import React, { useEffect, useState } from 'react';
import './FilterDropDown.css';

const FilterDropDown = (props) => {
    const [input, setInput] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [showDropDown, setShowDropDown] = useState(false);

    useEffect(() => {
        setFilteredOptions(props.dropdownOptions);
    }, [])

    useEffect(()=>{
        props.setDropDownValues(props.placeholder.toLowerCase(), input)
    },[input])

    const changeInputHandler = (event) => {
        setInput(event.target.value);
        let filterArr = props.dropdownOptions.filter((option) => {
            return (
                option
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase())
            );
        })
        setFilteredOptions(filterArr)
    };
    return (
        <div className="filter-dropdown">
            <input
                value={input}
                type="text"
                onChange={changeInputHandler}
                placeholder={props.placeholder}
                // onKeyPress={enterHandler}
                className="search-input"
                onFocus={() => setShowDropDown(true)}
                onBlur={() => setShowDropDown(false)}
            />
            {showDropDown && input && input !== 0 && <div className="dropdown-options-container">
                {(filteredOptions.length !== 0? filteredOptions.map((option, ind) => {
                    return <div key={props.placeholder + "_" + ind} className="dropdown-option" onMouseDown={() => setInput(option)}>{option}</div>
                }) : <div className="dropdown-option">No {props.placeholder} found</div>)}
            </div>}
        </div>
    )
}

export default FilterDropDown;