import React, { useState } from "react";
import "../styles/RatingStar.css";
import Star from "./Star";

const RatingStars = () => {
  const [gradeIndex, setGradeIndex] = useState();
  const GRADES = ["Poor", "Fair", "Good", "Very good", "Excellent"];
  const activeStar = {
    fill: "yellow"
  };

  const changeGradeIndex = index => {
    setGradeIndex(index);
  };

  return (
    <div className="rscontainer">
      <p className="rsresult">
        {GRADES[gradeIndex] ? GRADES[gradeIndex] : "You didn't review yet"}
      </p>
      <div className="rsstars">
        {GRADES.map((grade, index) => (
          <Star
            index={index}
            key={grade}
            changeGradeIndex={changeGradeIndex}
            style={gradeIndex >= index ? activeStar : {}}
          />
        ))}
      </div>
    </div>
  );
};

export default RatingStars;
