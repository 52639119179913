import React, { useState } from "react";
import "../../styles/Login.css";
import boy2 from "../../images/boy2.png";
import dots from "../../images/dots.png";
import axios from "../../axios.js";
import { useHistory } from "react-router-dom";

function Register() {
  const history = useHistory();

  const [submitbtn, setSubmitbtn] = useState(false);

  const [newAgency, setNewAgency] = useState({
    name: "",
    agencyName: "",
    phoneNumber: 0,
    businessEmail: "",
    agencyWebsite: "",
    password: "",
    isUpdated: false,
  });

  const changeHandler = (event) => {
    const { name, value } = event.target;
    setNewAgency((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const submitHandler = async (e) => {
    // loading icon starts
    setSubmitbtn(true);
    // loading icon ends

    e.preventDefault();
    const saveAgency = newAgency;
    try {
      const response = await axios.post("/agencies/register", saveAgency);
      history.push("/login");
      // console.log(response);
    } catch (err) {
      console.log(err);
    }
    // loading icon starts
    setSubmitbtn(false);
    // loading icon ends
  };

  return (
    <div className="login__cont">
      <div className="login"> 
        <div className="sec__left" style={{display: "none"}}>
          <img src={boy2} alt="" className="girlimg" />
          <img src={dots} alt="" className="dots" />
        </div>
        <div className="sec__right agencyRegSec">
          <h1 className="fw__600 logreg__title">
            Agency <span className="color__black">Registration</span>
          </h1>
          <form className="login__form" onSubmit={submitHandler}>
            <label>Name</label>
            <input
              onChange={changeHandler}
              name="name"
              value={newAgency.name}
              placeholder="Enter your name here"
              className="epass"
              required
            />
            <label>Agency Name</label>
            <input
              onChange={changeHandler}
              name="agencyName"
              value={newAgency.agencyName}
              placeholder="Enter your agency name here"
              required
              className="epass"
            />
            <label>Phone number</label>
            <input
              onChange={changeHandler}
              name="phoneNumber"
              value={newAgency.phoneNumber}
              type="number"
              placeholder="Enter your number"
              required
              className="epass"
            />
            <label>Business E-mail</label>
            <input
              onChange={changeHandler}
              name="businessEmail"
              type="email"
              value={newAgency.businessEmail}
              placeholder="Enter your work email id"
              required
              className="epass"
            />
            <label>Agency Website</label>
            <input
              onChange={changeHandler}
              name="agencyWebsite"
              placeholder="Enter Website URL"
              value={newAgency.agencyWebsite}
              className="epass"
              required
            />
            <label>Password</label>
            <input
              onChange={changeHandler}
              name="password"
              value={newAgency.password}
              type="password"
              placeholder="Generate your password"
              required
              className="epass"
            />
            <div className="log__for">
              <label>
                *You will be contacted from our team within 24 hrs for profile
                activation.
              </label>
            </div>
            <button type="submit" className="login__btn">
              {submitbtn && (
                <i className="fas fa-circle-notch donebtnclient"></i>
              )}
              {submitbtn && " Registering"}
              {!submitbtn && "Register"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Register;
