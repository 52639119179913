import React from 'react';
import './Button.css';

const Button = (props) => {
    return (
        <div className={`btnStyle ${props.customClass ? props.customClass : ""} ${props.isSecondary ? "secondaryButton" : "primaryBtn"}`} onClick={()=>props.onBtnClick()}>
            {props.buttonText}
        </div>
    )
}

export default Button;