import React, { useEffect, useRef, useState } from "react";
import "./SingleTalentPool.css";
import img1 from "../DemoImages/1.png";
import img2 from "../DemoImages/2.png";
import img3 from "../DemoImages/3.png";
import img4 from "../DemoImages/4.png";
import img5 from "../DemoImages/5.png";
import img6 from "../DemoImages/6.png";
import img7 from "../DemoImages/7.png";
import member1 from "../DemoImages/Individual Profile/member1.svg";
import member2 from "../DemoImages/Individual Profile/member2.svg";
import member3 from "../DemoImages/Individual Profile/member3.svg";
import member4 from "../DemoImages/Individual Profile/member4.svg";
import checkCircle from "../DemoImages/Individual Profile/check-circle.svg";
import closeCircle from "../DemoImages/Individual Profile/close-circle.svg";
import freelance from "../DemoImages/Individual Profile/account.svg";
import fullTime from "../DemoImages/Individual Profile/account-group.svg";
import remote from "../DemoImages/Individual Profile/wifi.svg";
import relocate from "../DemoImages/Individual Profile/airplane.svg";
import mapMarker from "../DemoImages/Individual Profile/map-marker.svg";
import chevronUp from "../DemoImages/Individual Profile/chevron-up.svg";
import chevronDown from "../DemoImages/Individual Profile/chevron-down.svg";
import linkedInLogo from "../../img/linkedin.png";
import axios from "../../../../axios";
import currencyInr from "./images/currency-inr.svg";
import Loader from "../../../loader/Loader";
import {
  getProfileRedirect,
  setProfileRedirect,
} from "../../../../common/service";
import { useHistory } from "react-router-dom";
import BasicPopover from "../../../popover/CustomPopover";
import ResumeForm from "../../../ResumeForm";
import {
  MdDoneAll,
  MdMemory,
  MdOutlineWavingHand,
  MdFormatListBulleted,
  MdGridView,
  MdWavingHand,
} from "react-icons/md";
import { FaCheckCircle, FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { IoIosAddCircleOutline, IoMdArrowDropdown } from "react-icons/io";
import { GiDominoMask } from "react-icons/gi";

import { WaveHandConfirmation } from "../../../WaveHandConfirmation/WaveHandConfirmation";

export default function SingleTalentPool({
  user,
  talentPool,
  detailedTalentPool,
  setDetailedTalentPool,
  isPoolOpen,
  handleHidePool,
}) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [waveHand, setWaveHand] = useState({});
  const [wavehandFunction, setWavehandFunction] = useState(null);
  const [wavehandFunctionParams, setWaveHandFunctionParams] = useState();
  const [selectAllUsers, setSelectAllUsers] = useState({});
  const [selectedMessageIndividual, setSelectedMessageIndividual] = useState(
    {}
  );
  const [currentSelectedUsers, setCurrentSelectedUsers] = useState(); // for setting dynamic price in UI
  const [previewUserId, setPreviewUserId] = useState({});
  const [showPreviewPopOver, setShowPreviewPopOver] = useState({});
  const [talentPoolUser, setTalentPoolUser] = useState([]);

  const [expandedMembers, setExpandedMembers] = useState({});

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const toggleMemberInfo = (id) => {
    setExpandedMembers((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log("the size is ", windowSize);

  useEffect(() => {
    const getSelectedIds = (selectedMessageIndividual) => {
      return Object.keys(selectedMessageIndividual).filter(
        (key) => selectedMessageIndividual[key]
      );
    };
    const selectedIds = getSelectedIds(selectedMessageIndividual);

    let ans = {};
    selectedIds?.map((unique_id) => {
      const categoryId = unique_id.split(" ")[0];
      if (ans[categoryId]) {
        ans[categoryId]++;
      } else {
        ans[categoryId] = 1;
      }
    });

    console.log("The anwer is ", ans);

    setCurrentSelectedUsers(ans);
  }, [selectedMessageIndividual]);

  function handleSelectMessageCategory(e) {
    const categoryId = e.target.id;

    const filteredMembers = talentPool
      ?.map((talent) =>
        talent?.talentPoolMembers?.filter(
          (member) => member.category_id === categoryId
        )
      )
      .flat();

    // categoryId + user_id will generate a unique id of each other, then it will be easy to selected and dis-select them

    const uniqueIdArray = filteredMembers
      ?.filter((member) => !member?.user_id?.payment_status) // The one who is already selected, shouldn't include in any list
      .map((member) => {
        return categoryId + " " + member?.user_id?._id;
      });

    // uniqueArray has all the user of a category. And now all be set true/false based to select all condition
    uniqueIdArray?.map((id) => {
      setSelectedMessageIndividual((prevState) => ({
        ...prevState,
        [id]: selectAllUsers[categoryId] ? false : true,
      }));
    });

    setSelectAllUsers({ [categoryId]: !selectAllUsers[categoryId] });
  }

  // Here individual can be selected
  function handleSelectMessageIndividual(cate_id, user_id) {
    const unique_id = cate_id + " " + user_id;
    setSelectedMessageIndividual((prevState) => ({
      ...prevState,
      [unique_id]: !prevState[unique_id],
    }));
  }

  const sendMessageToUser = async (param) => {
    const unique_id = param?.category_id + " " + param?.receiver_id;

    console.log("the unique id is ", unique_id);

    setWaveHand((prevData) => ({
      ...prevData,
      [unique_id]: true,
    }));

    closeModel();

    let { data } = await axios.post(`/nudges/create-nudge`, param, {
      headers: {
        authorization: "Bearer " + Marketer.token,
        "Content-Type": "application/json",
      },
    });
  };

  async function sendMultipleMessagesToUsers() {
    const getSelectedIds = (selectedMessageIndividual) => {
      return Object.keys(selectedMessageIndividual).filter(
        (key) => selectedMessageIndividual[key]
      );
    };

    const selectedIds = getSelectedIds(selectedMessageIndividual);
    const request_body = selectedIds?.map((unique_id) => {
      return {
        category_id: unique_id.split(" ")[0],
        receiver_id: unique_id.split(" ")[1],
        pool_owner: user._id,
        sender_id: Marketer?.user?._id,
      };
    });

    console.log("the request body is ", request_body);
    request_body?.map((element) => {
      let unique_id = element?.category_id + " " + element?.receiver_id;

      setWaveHand((prevData) => ({
        ...prevData,
        [unique_id]: true,
      }));
    });

    closeModel();
    let { data } = await axios.post(
      `/nudges/create-multiple-nudges`,
      request_body,
      {
        headers: {
          authorization: "Bearer " + Marketer.token,
          "Content-Type": "application/json",
        },
      }
    );
  }

  const handleCreatePayment = async (data) => {
    const categoryId = data?.talentPoolMembers[0]?.category_id;
    const pricePerUser = data?.categoryPricePerUser;

    const getSelectedIds = (selectedMessageIndividual) => {
      return Object.keys(selectedMessageIndividual).filter(
        (key) => selectedMessageIndividual[key]
      );
    };

    const selectedIds = getSelectedIds(selectedMessageIndividual);

    const membersToBeUnlocked = selectedIds
      ?.filter((key) => key.split(" ")[0] === categoryId)
      .map((unique_id) => {
        return unique_id.split(" ")[1];
      });

    console.log("Members to be unlocked", membersToBeUnlocked);
    const categoryPrice = pricePerUser * membersToBeUnlocked?.length;

    const gstAmount = (categoryPrice * 18) / 100;

    const payload = {
      talentPoolCategoriesId: categoryId,
      membersToBeUnlocked: membersToBeUnlocked,
      amount: categoryPrice,
      gst: gstAmount,
      pool_owner: user._id,
      totalAmount: categoryPrice + gstAmount,
    };

    console.log("the razor pay payload is ", payload);

    try {
      const response = await axios
        .post("/create-order", payload, {
          headers: { authorization: "Bearer " + Marketer?.token },
        })
        .then((res) => {
          if (res?.status === 200) {
            const options = {
              key: process.env.REACT_APP_RAZORPAY_KEY_ID,
              amount: res?.data?.totalAmount,
              currency: "INR",
              name: "Unispade",
              description: "RazorPay",
              order_id: res?.data?.razorPayOrderId,
              async handler(response) {
                const verifydata = {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                };
                try {
                  setLoading(true);
                  console.log("Verify payment ke just uppar");
                  const verifyResponse = await axios.post(
                    "/verify-payment",
                    verifydata,
                    {
                      headers: { authorization: "Bearer " + Marketer?.token },
                    }
                  );

                  // console.log(verifyResponse, "verifyResponse");

                  if (verifyResponse?.status === 200) {
                    setLoading(false);
                    console.log(
                      "updating talent payment after razorpay success"
                    );

                    const { data } = await axios.post(
                      "/update-talent-payment",
                      {
                        pool_owner: user._id,
                        buyer_id: Marketer?.user?._id,
                        category_id: categoryId,
                        membersToBeUnlocked,
                      },
                      {
                        headers: { authorization: "Bearer " + Marketer?.token },
                      }
                    );

                    history.push(
                      `/paymentsuccess?username=${user.username}?category=${categoryId}`
                    );
                  } else {
                    setLoading(false);
                    history.push("/payment-failed");
                  }
                } catch (verifyError) {
                  // console.error('Error while verifying payment:', verifyError);
                }
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#121212",
              },
            };
            const razor = new window.Razorpay(options);
            razor.open();
          }
        });
    } catch (error) {
      // console.error('Error while creating payment:', error);
    }
  };

  const handleMobileToggle = user;

  const handToggle = (user_id, category_id) => {
    console.log("the user is ", user_id);

    const unique_id = category_id + " " + user_id;
    console.log("the unique id is ", unique_id);
    setPreviewUserId(unique_id);
    setShowPreviewPopOver({ [unique_id]: true });
  };

  const handleClosePopover = (unique_id) => {
    setPreviewUserId(unique_id);
    setShowPreviewPopOver({ [unique_id]: false });
    setTalentPoolUser([]);
    // setShowPreviewPopOver((prevState) => ({
    //   ...prevState,
    //   [user_id]: false,
    // }));
  };

  const handlegetUserDetailsinfoByID = async (category_id, userID) => {
    try {
      console.log("the user id is ", userID);

      const result = await axios.get(
        `marketers/getMarketerExperienceById/${category_id}/${userID}`,
        {
          headers: { authorization: "Bearer " + Marketer.token },
        }
      );
      setTalentPoolUser(result.data); // Access the data property
      console.log("Print api preview ", result.data); // Log the data property
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  function closeModel() {
    setWavehandFunction(null);
  }

  console.log("details are ", detailedTalentPool);
  console.log("The selected ones are ", selectedMessageIndividual);
  console.log("the nubmer of user selected are ", currentSelectedUsers);
  return (
    <div>
      {loading ? (
        <Loader> </Loader>
      ) : (
        <>
          <div className="userProfileDetailedTalentPool">
            <div
              key={detailedTalentPool?.talentPoolMembers[0]?.category_id}
              className="singleCategory"
              style={{
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                borderBottom: "0", // Remove lower border when condition is true
                backgroundColor: "#F7F7F7",
              }}
            >
              <div
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "400",
                }}
                className="categoryHeader"
              >
                {isPoolOpen[
                  detailedTalentPool?.talentPoolMembers[0]?.category_id
                ] && (
                  <FaArrowLeft
                    onClick={() =>
                      handleHidePool(
                        detailedTalentPool?.talentPoolMembers[0]?.category_id
                      )
                    }
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "15px",
                      color: "black",
                    }}
                  ></FaArrowLeft>
                )}
                <div className="categoryInfo">
                  <span className="categoryName">
                    {detailedTalentPool?.categoryName}
                  </span>
                  <span className="theExtraBar">{" | "}</span>
                  <span className="categoryNumber">
                    {detailedTalentPool?.talentPoolMembers.length}
                  </span>
                </div>
              </div>

              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "600",
                }}
                className="categoryPrice"
              >
                {detailedTalentPool?.categoryPricePerUser}
              </div>
            </div>

            {isPoolOpen[
              detailedTalentPool?.talentPoolMembers[0]?.category_id
            ] && (
              <div className="togglePool">
                <div className="entirePoolActivities">
                  <div className="poolSelectAndSend">
                    <div>
                      <input
                        type="checkbox"
                        id={
                          detailedTalentPool?.talentPoolMembers[0]?.category_id
                        }
                        name="select-all"
                        className="iconSize20"
                        onChange={handleSelectMessageCategory}
                        checked={
                          !!selectAllUsers[
                            detailedTalentPool?.talentPoolMembers[0]
                              ?.category_id
                          ]
                        }
                      />
                      <label
                        htmlFor={
                          detailedTalentPool?.talentPoolMembers[0]?.category_id
                        }
                      >
                        {" "}
                        Select All
                      </label>
                    </div>
                    <div
                      className="wavingHand"
                      onClick={() =>
                        setWavehandFunction(() => sendMultipleMessagesToUsers)
                      }
                    >
                      <MdOutlineWavingHand className="iconSize20" />{" "}
                      <span>Check availability</span>
                    </div>
                  </div>

                  <div className="poolSelectionInfo">
                    <div className="numberOfSelection">
                      <span>
                        {currentSelectedUsers?.[
                          detailedTalentPool?.talentPoolMembers[0]?.category_id
                        ] || 0}
                        {"/"}
                        {detailedTalentPool?.talentPoolMembers.length}{" "}
                        candidates selected
                      </span>
                    </div>
                    {/* <div className="extraFunctions">
                      <MdGridView className="iconSize20"></MdGridView>
                      <MdFormatListBulleted className="iconSize20"></MdFormatListBulleted>
                    </div> */}
                  </div>
                </div>

                <div className="poolMemberList">
                  {detailedTalentPool?.talentPoolMembers.map((singleMember) => {
                    let isExpanded =
                      expandedMembers[singleMember?.user_id?._id] || false;
                    return (
                      <>
                        {!singleMember?.user_id?.payment_status ? (
                          <div
                            key={singleMember?.user_id?._id}
                            className={`members ${
                              isExpanded ? "expanded" : ""
                            }`}
                          >
                            <div className="memberInfo">
                              <div className="memberNameInfo">
                                <div
                                  className="image-container"
                                  onClick={() =>
                                    handleSelectMessageIndividual(
                                      singleMember?.category_id,
                                      singleMember?.user_id?._id
                                    )
                                  }
                                >
                                  {selectedMessageIndividual[
                                    singleMember?.category_id +
                                      " " +
                                      singleMember?.user_id?._id
                                  ] ? (
                                    <FaCheckCircle className="select-icon"></FaCheckCircle>
                                  ) : (
                                    <>
                                      <img
                                        className="image"
                                        src={member1}
                                        alt="my_pic"
                                      />
                                      <span>
                                        <IoIosAddCircleOutline className="icon"></IoIosAddCircleOutline>
                                      </span>
                                    </>
                                  )}
                                </div>
                                <div>
                                  {singleMember?.user_id?.ref_id || "Unknown"}
                                </div>
                                <IoMdArrowDropdown
                                  className={`dropdownButton`}
                                  onClick={() =>
                                    toggleMemberInfo(singleMember?.user_id?._id)
                                  }
                                />
                              </div>

                              <div className="memberOtherInfo">
                                {singleMember?.user_id?.workDetails
                                  ?.location && (
                                  <div className="memberSingleOtherInfo">
                                    <img src={mapMarker} alt="" />
                                    <span>
                                      {
                                        singleMember?.user_id?.workDetails
                                          ?.location
                                      }
                                    </span>
                                  </div>
                                )}

                                <div className="memberSingleOtherInfo">
                                  <img
                                    src={
                                      !singleMember?.user_id?.workDetails
                                        ?.canRelocate
                                        ? remote
                                        : relocate
                                    }
                                    alt=""
                                  />
                                  <span>
                                    {!singleMember?.user_id?.workDetails
                                      ?.canRelocate
                                      ? "Remote"
                                      : "Willing to relocate"}
                                  </span>
                                </div>

                                {singleMember?.user_id?.workDetails
                                  ?.workType && (
                                  <div className="memberSingleOtherInfo">
                                    <img
                                      src={
                                        singleMember?.user_id?.workDetails
                                          ?.workType === "Freelance"
                                          ? freelance
                                          : fullTime
                                      }
                                      alt=""
                                    />
                                    <span>
                                      {
                                        singleMember?.user_id?.workDetails
                                          ?.workType
                                      }
                                    </span>
                                  </div>
                                )}

                                {singleMember?.user_id?.workDetails
                                  ?.workTime && (
                                  <div className="memberSingleOtherInfo">
                                    {
                                      singleMember?.user_id?.workDetails
                                        ?.workTime
                                    }
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="memberOtherInfo md-screen">
                              {singleMember?.user_id?.workDetails?.location && (
                                <div className="memberSingleOtherInfo">
                                  <img src={mapMarker} alt="" />
                                  <span>
                                    {
                                      singleMember?.user_id?.workDetails
                                        ?.location
                                    }
                                  </span>
                                </div>
                              )}

                              <div className="memberSingleOtherInfo">
                                <img
                                  src={
                                    !singleMember?.user_id?.workDetails
                                      ?.canRelocate
                                      ? remote
                                      : relocate
                                  }
                                  alt=""
                                />
                                <span>
                                  {!singleMember?.user_id?.workDetails
                                    ?.canRelocate
                                    ? "Remote"
                                    : "Willing to relocate"}
                                </span>
                              </div>

                              {singleMember?.user_id?.workDetails?.workType && (
                                <div className="memberSingleOtherInfo">
                                  <img
                                    src={
                                      singleMember?.user_id?.workDetails
                                        ?.workType === "Freelance"
                                        ? freelance
                                        : fullTime
                                    }
                                    alt=""
                                  />
                                  <span>
                                    {
                                      singleMember?.user_id?.workDetails
                                        ?.workType
                                    }
                                  </span>
                                </div>
                              )}

                              {singleMember?.user_id?.workDetails?.workTime && (
                                <div className="memberSingleOtherInfo">
                                  {singleMember?.user_id?.workDetails?.workTime}
                                </div>
                              )}
                            </div>

                            <div className="md-screen-function poolMemberFunctions">
                              <div
                                className="memberProfilePreview"
                                onClick={() => {
                                  handlegetUserDetailsinfoByID(
                                    singleMember?.category_id,
                                    singleMember?.user_id?._id
                                  );
                                  handToggle(
                                    singleMember?.user_id?._id,
                                    singleMember?.category_id
                                  );
                                }}
                              >
                                <GiDominoMask className="iconSize20"></GiDominoMask>
                                {windowSize?.width < 600 && isExpanded ? (
                                  <p>{isExpanded ? "Proflie Preview" : ""}</p>
                                ) : (
                                  ""
                                )}
                              </div>

                              {Marketer && (
                                <div className="wavingHand">
                                  {waveHand[
                                    singleMember?.category_id +
                                      " " +
                                      singleMember?.user_id?._id
                                  ] ? (
                                    <MdDoneAll className="iconSize20" />
                                  ) : singleMember?.user_id?.message_status ===
                                    "SENT" ? (
                                    <MdDoneAll className="iconSize20" />
                                  ) : singleMember?.user_id?.message_status ===
                                    "NOT-AVAILABLE" ? (
                                    <MdOutlineWavingHand
                                      className="iconSize20"
                                      style={{ color: "red" }}
                                    />
                                  ) : singleMember?.user_id?.message_status ===
                                    "AVAILABLE" ? (
                                    <MdOutlineWavingHand
                                      className="iconSize20"
                                      style={{ color: "green" }}
                                    />
                                  ) : (
                                    <div
                                      className="activeThisForMobile"
                                      onClick={() => {
                                        setWaveHandFunctionParams({
                                          receiver_id:
                                            singleMember?.user_id?._id,
                                          category_id:
                                            singleMember?.category_id,
                                          pool_owner: user._id,
                                          sender_id: Marketer.user._id,
                                        });
                                        setWavehandFunction(
                                          () => sendMessageToUser
                                        );
                                      }}
                                    >
                                      <MdOutlineWavingHand className="iconSize20" />
                                      {windowSize?.width < 600 && isExpanded ? (
                                        <p>
                                          {isExpanded
                                            ? "Check Availability"
                                            : ""}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="members">
                            <div className="memberInfo">
                              <div className="memberNameInfo">
                                <div className="image-container">
                                  <img
                                    className="imageClear"
                                    src={singleMember?.user_id?.profilePhoto}
                                    alt="my_pic"
                                  />
                                </div>
                                <div>{singleMember?.user_id?.name}</div>
                              </div>

                              <div className="paidData">
                                {singleMember?.user_id?.socialLinks
                                  ?.linkedIn && (
                                  <div className="linkedIn-container">
                                    <a
                                      href={
                                        singleMember?.user_id?.socialLinks
                                          ?.linkedIn
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src={linkedInLogo}
                                        alt=""
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                        }}
                                      />
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                        {showPreviewPopOver[
                          singleMember?.category_id +
                            " " +
                            singleMember?.user_id?._id
                        ] && (
                          <ResumeForm
                            talentPoolUser={talentPoolUser}
                            previewUserId={previewUserId}
                            handleClosePopover={handleClosePopover}
                            setWaveHandFunctionParams={() =>
                              setWaveHandFunctionParams({
                                receiver_id: singleMember?.user_id?._id,
                                category_id: singleMember?.category_id,
                                pool_owner: user._id,
                                sender_id: Marketer.user._id,
                              })
                            }
                            setWavehandFunction={() =>
                              setWavehandFunction(() => sendMessageToUser)
                            }
                            handleSelectMessageIndividual={() =>
                              handleSelectMessageIndividual(
                                singleMember?.category_id,
                                singleMember?.user_id?._id
                              )
                            }
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            )}

            {isPoolOpen[
              detailedTalentPool?.talentPoolMembers[0]?.category_id
            ] && (
              <div className="paymentFooter">
                <div className="individualPriceInfo">
                  {!currentSelectedUsers?.[
                    detailedTalentPool?.talentPoolMembers[0]?.category_id
                  ]  ? (
                    <span>No Profiles Selected</span>
                  ) : (
                    <>
                      <span>Unlock Selected Profiles</span>
                      <span>
                        <strong>
                          @{" "}
                          {detailedTalentPool?.categoryPricePerUser *
                            currentSelectedUsers?.[
                              detailedTalentPool?.talentPoolMembers[0]
                                ?.category_id
                            ] || 0}
                          /-
                        </strong>
                      </span>
                    </>
                  )}
                </div>

                <div
                  className="unlockButton"
                  onClick={() => handleCreatePayment(detailedTalentPool)}
                >
                  <p>Unlock</p>
                  <FaArrowRight className="iconSize20 theColor"></FaArrowRight>
                </div>
              </div>
            )}
          </div>
          {wavehandFunction ? (
            <WaveHandConfirmation
              onClose={closeModel}
              onConfirm={wavehandFunction}
              params={wavehandFunctionParams}
            ></WaveHandConfirmation>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}
