import './CaseStudyListComponent.css';
import Button from '../../Button/Button';
import Edit from '../../../images/edit.png';
import Delete from '../../../images/delete.png';
import Share from '../../../images/shareArrow.png';
import Public from '../../../images/public.png';
import Private from '../../../images/private.png';
import axios from "../../../axios.js";

const CaseStudyListComponent = (props) => {

    const hideCaseStudyHandler = async (data, status) => {
        const payload = {
            "id": data._id,
            "isHidden": status
        }
        const res = await axios.post('agencyEditProfile/reversehiddenstatus', payload)
        if (res.status === 200) {
            props.getnewCaseStudyByAgencyId();
        }
    }

    const deleteCaseStudyHandler = async (data) => {
        const payload = {
            "id": data._id,
        }
        const res = await axios.post('agencyEditProfile/deleteCaseStudybyId', payload)
        if (res.status === 200) {
            props.getnewCaseStudyByAgencyId();
        }
    }

    const addNewCaseStudy = () => {
        props.setShowCaseStudyForm(true);
    }

    const editCaseStudyHandler = (data) => {
        addNewCaseStudy();
        props.setEditCaseStudyId(data._id);
    }

    return (
        <div className='case-study-list'>
            <div className='list-count'>
                <span className='case-study-count'>{props.caseStudyList.length} Case Studies</span>
                <Button buttonText="Add New Case Study" onBtnClick={addNewCaseStudy} />
            </div>
            <div className='case-study-list-table'>
                <table>
                    <tr>
                        <th>Brand</th>
                        <th>Services</th>
                        <th>Industry</th>
                        <th>Visibility</th>
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>Share</th>
                    </tr>
                    {
                        props.caseStudyList.length > 0 && props.caseStudyList.map((data, ind) => {
                            return (
                                <tr key={"case-study-list" + ind}>
                                    <td>{data.brand}</td>
                                    <td>{`${data.services[0] ? data.services[0] : ""} ${data.services.length > 1 ? " + " + (data.services.length - 1) + " more" : ""}`}</td>
                                    <td>{`${data.industries[0] ? data.industries[0] : ""} ${data.industries.length > 1 ? " + " + (data.industries.length - 1) + " more" : ""}`}</td>
                                    {data.isHidden ? <td onClick={() => hideCaseStudyHandler(data, false)}><img src={Private} /> Private</td> : <td onClick={() => hideCaseStudyHandler(data, true)}><img src={Public} /> Public</td>}
                                    <td><img src={Edit} onClick={() => editCaseStudyHandler(data)} /></td>
                                    <td><img src={Delete} onClick={() => deleteCaseStudyHandler(data)} /></td>
                                    <td><img onClick={() => {navigator.clipboard.writeText(`https://www.unispade.com/case-study/${data.uniqueKey}`)}} src={Share} /></td>
                                </tr>
                            )
                        })
                    }
                </table>
            </div>
        </div>
    )
}

export default CaseStudyListComponent;