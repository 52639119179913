import React, { useState } from "react";
import "../../styles/Login.css";
import girl2 from "../../images/girl2.png";
import dots from "../../images/dots.png";
import axios from "../../axios.js";
import { useHistory } from "react-router-dom";

function BrandLogin(props) {
  const history = useHistory();

  const [submitbtn, setSubmitbtn] = useState(false);

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const [newBrand, setNewBrand] = useState({
    businessEmail: "",
    password: "",
  });

  const changeHandler = (event) => {
    setIsEmailValid(true);
    setIsPasswordValid(true);
    const { name, value } = event.target;
    setNewBrand((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const submitHandler = async (e) => {
    // loading icon starts
    setSubmitbtn(true);
    // loading icon ends

    e.preventDefault();
    // const loginBrand = newBrand;
    var loginBrand = {
      businessEmail: newBrand.businessEmail,
      password: newBrand.password,
      key: "brand",
    };  
    try {

        const response = await axios.post("/brands/login", loginBrand);
        // console.log("login Res", response);
        if (response.data.message !== undefined) {
          setIsEmailValid(false);
          if (response.data.message === "Incorrect email or password")
            setIsPasswordValid(false);
        }
        if (response.data.token) {
          // console.log("login Res token", response);
          props.loginHandler(true);
          localStorage.setItem("branduser", JSON.stringify(response.data));
          history.push("/beditprofile");
          
        }
 
    } catch (err) {
      console.log(err);
    }

    // loading icon starts
    setSubmitbtn(false);
    // loading icon ends
  };

  return (
    <div className="login__cont">
      <div className="login">
        <div className="sec__left" style={{display: "none"}}>
          <img src={girl2} alt="" className="girlimg" />
          <img src={dots} alt="" className="dots" />
        </div>
        <div className="sec__right agencyRegSec">
          <h1 className="fw__600 logreg__title">
            Brand <span className="color__black">Login</span>
          </h1>
          <form className="login__form" onSubmit={submitHandler}>
            <label>Business E-mail</label>
            <input
              style={{ backgroundColor: !isEmailValid ? "#F9B0B0" : "" }}
              onChange={changeHandler}
              name="businessEmail"
              type="email"
              value={newBrand.businessEmail}
              placeholder="Enter your work email id"
              className="epass"
              required
            />
            <label>Password</label>
            <input
              style={{ backgroundColor: !isPasswordValid && "#F9B0B0" }}
              onChange={changeHandler}
              name="password"
              value={newBrand.password}
              type="password"
              placeholder="Enter your password"
              className="epass"
              required
            />
            {(!isEmailValid || !isPasswordValid) && (
              <p>Incorrect Username or Password</p>
            )}
            <div className="log__for">
              <label>
                <input type="checkbox" />
                Keep me logged in
              </label>
              <label className="color__black">Forgot Password?</label>
            </div>
            <button type="submit" className="login__btn">
              {submitbtn && (
                <i className="fas fa-circle-notch donebtnclient"></i>
              )}
              {submitbtn && " Logging In"}
              {!submitbtn && "Login"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BrandLogin;
