 import React, { useState, useEffect } from "react";
import axios from "../../../axios.js";
import "./mainContent.css"
import MediaComponent from '../mediaComponent/mediacomponent';
import "../mediaComponent/mediacomponent.css"
import { Swiper, SwiperSlide } from "swiper/react";
// import { FreeMode } from "swiper";
import "swiper/swiper-bundle.min.css";
import 'swiper/swiper.min.css'

const App=({projectTittle,brandName,agencyName,industries,services,overview,statistics,challenges,research,process,result,id,teamMmembers,agencyData=[]})=>{
    
    const profileImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
    const [clients, setClients] = useState([]);
    const [userLogo, setLogo] = useState({});


    useEffect(() => {
    const fetchUserProfile = async () => {
        teamMmembers.individual_credit.map(async (key) => {
        if (key.marketers_userName) {
            const userProfileDetails = await axios.get(`/marketers/getMarketerByUsername/${key.marketers_userName}`);
            setLogo((prevLogo) => {
            return {
                ...prevLogo,
                [key.marketers_userName]: userProfileDetails.data.profilePhoto
            };
            });
        }
        });
    };

    fetchUserProfile();
    }, []);


  const fetchagencyLogo = (agencyName) => {

    const agency = agencyData.find((item) => item.agencyName === agencyName);
    return agency?.agencyLogo;
  };

    return (
        <div className="mainContent">
            <div className='partOne'>
                <div className='pageTitle'>Case Study</div>
                <div className='caseStudyTitle'>
                    <h1>{projectTittle}</h1>
                </div>
                <div className='agencyBrand'>
                    <p>for</p>
                    <a>{brandName}</a>
                    <p>by</p>
                    <a href={`../agencyprofile/${agencyName}`} target="_blank" style={{color:"black"}}>{agencyName}</a>
                </div>
                <div className='basicInfo'>
                     <div>
                        <p>Industry</p>
                        <div className="fieldWrapper">
                            {industries.map((key,index)=>{
                                if(index!==industries.length-1){
                                    key=key+","
                                }
                                return(
                                    <h4>{key}</h4>
                            )})}
                        </div>
                     </div>
                     <div>
                        <p>Services</p>
                        <div className="fieldWrapper">
                        {services.map((key,index)=>{
                                if(index!==services.length-1){
                                    key=key+","
                                }
                                return(
                                    <h4>{key}</h4>
                            )})}
                        </div>
                        
                        
                     </div>
                     {/* <div>
                        <p>Year</p>
                        <h4>2020</h4>
                     </div> */}
                      
                      
                </div>
                <div className='overview'>
                <p
                    dangerouslySetInnerHTML={{
                        __html: overview.text?.replaceAll("\\", ""),
                    }}
                ></p>   
                <MediaComponent 
                    images={overview.image}
                    imageType={overview.imgType}
                    videos={overview.videos}
                    />            
                </div>

                
                 {statistics.length !== 0 && (
                <div className='statastics'>
                {statistics.map((key) => (<>
                    <p className='statFigure'>{key?.text}</p>
                    <p className='statTitle'>{key?.value}</p>
                </>))}
                    
                </div>
                )}
                <div className='caseStudydata'>
                {challenges.image.length !== 0 || challenges.text.length !== 0 ? (
                    <div className="caseStudytags ">
                        {challenges.text.length !== 0 && (
                            <h2 className="caseStudyheadings">Requirement | Challenges | Objectives</h2>
                        )}
                    {challenges.text.length !== 0 && (
                        <div
                            className="caseStudycontent"
                            dangerouslySetInnerHTML={{
                            __html: challenges.text?.replaceAll("\\", ""),
                            }}
                        ></div>
                    )}
                    {challenges.image.length !== 0 && (
                        <MediaComponent
                            images={challenges.image}
                            imageType={challenges.imgType}
                        />
                    )}
                    </div>
                    ) :null}

                    {research.image.length !== 0 || research.text.length !== 0 ? (
                        <div className="caseStudytags ">
                            {research.text.length !== 0 && (
                                <h2 className="caseStudyheadings">Research | Insights</h2>
                            )}
                        {research.text.length !== 0 && (    
                            <div
                                className="caseStudycontent"
                                dangerouslySetInnerHTML={{
                                __html: research.text?.replaceAll("\\", ""),
                                }}
                            ></div>
                        )}
                        {research.image.length !== 0 && (
                            <MediaComponent
                                images={research.image}
                                imageType={research.imgType}
                            />
                        )}
                        </div>
                    ) :null}

                    {process.image.length !== 0 || process.text.length !== 0 ? (
                        <div className="caseStudytags ">
                            {process.text.length !== 0 && (
                                <h2 className="caseStudyheadings">Process | Solutions | Approach</h2>
                            )}
                        {process.text.length !== 0 && (                             
                            <div
                                className="caseStudycontent"
                                dangerouslySetInnerHTML={{
                                    __html: process.text?.replaceAll("\\", ""),
                                }}
                            ></div>
                        )}
                            {process.image.length !== 0 && (
                                <MediaComponent images={process.image} imageType={process.imgType} />
                            )}
                        </div>
                    ) : null}
                    
                    {result.image.length !== 0 || result.text.length !== 0 ? (
                        <div className="caseStudytags ">
                        {result.text.length !== 0 && (
                            <h2 className="caseStudyheadings">Result | Outcome | Benefit</h2>
                        )}
                        {result.text.length !== 0 && (
                            <div
                                className="caseStudycontent"
                                dangerouslySetInnerHTML={{
                                __html: result.text?.replaceAll("\\", ""),
                                }}
                            ></div>
                        )}
                        {result.image.length !== 0 && (
                            <MediaComponent images={result.image} imageType={result.imgType} />
                        )}
                        </div>
                    ) :null}                    
                </div>
            </div>
            
            <div className='teamMembers'>
            {teamMmembers.agency_credit.map((key, index) => (
                <div className='creditCard'>
                <img className='creditProfileimg' src={fetchagencyLogo(key.name)}></img>
                <div className='creditProfileinfo'>
                    <p className='creditName'>{key.name}</p>
                    <p className='creditRole'>{key.role}</p>
                    <p className='creditUsername'>{key.agencyWebsite}</p>                
                </div>                
            </div>
            ))}

            {teamMmembers.individual_credit.map((key, index) => (
                <div className='creditCard'>
                <img 
                className='creditProfileimg' 
                src={userLogo[key.marketers_userName]?userLogo[key.marketers_userName]:profileImage}
                alt="Member"
                style={{cursor:"pointer"}}
                onClick={()=>{
                "agency"&&window.open(`/userprofile/${key.marketers_userName}`, "_blank")
                }}
                />
                <div className='creditProfileinfo'>
                    <p className='creditName'>{key.name}</p>
                    <p className='creditRole'>{key.role}</p>
                    {key.marketers_userName&&<p className='creditUsername'>@{key.marketers_userName}</p>   }             
                </div>                
            </div>
            ))}
            </div>
        </div>
      );
    }
export default App; 

