import React, { useState } from "react";
import "../styles/HeaderMarketplace.css";
import logo from "../images/logo.png";
// import searchicon from "../images/searchiconw.png";
import { Link, useHistory } from "react-router-dom";

function HeaderMarketPlace({ headerbgblue }) {
    const history = useHistory();

    const [openMobBar, setOpenMobBar] = useState(false);

    // When clicking anywhere, close the navbar

    return (
        <div className={`header__cont ${headerbgblue && "headerbgblue"}`}>
            
            <div className="headers">
             


                <img
                    onClick={() => {
                        setOpenMobBar(false);
                        history.push("/");
                    }}
                    src={logo}
                    alt=""
                    className="logo"
                />


                <div className="header__secs">
                    {localStorage.getItem("branduser")!=null ? <Link to="/homepage">
                    <button>Discover Agencies</button>
                    </Link>:
                   <Link to="/marketplace">
                   <button>Discover Agencies</button>
                   </Link> }
                   
                </div>
            </div>

        </div>
    );
}

export default HeaderMarketPlace;
