import React, { useState } from "react";
import "../../styles/BNewProject.css";
import { Link, useHistory } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import calendericon from "../../images/calendericon.png";
import "../../styles/ANewProject.css"
import ASidebar from "./ASidebar";

function ANewProject() {
    const history = useHistory();
    const gotoprojectbrief = () => {
        history.push("/newprojectbrief");
    };
    return (
        <>

            <div className="bnewproject" >

                {localStorage.getItem("user") === null
                    ?
                    history.push('/login') : ""}
                {localStorage.getItem("user") === null
                    ?
                    window.location.reload() : ""}
                    
               
                <div className="logout_btn">
                    <button
                        style={{ background: "rgba(255, 0, 0, 0.1)", color: "#ff656a", border: "none"}}
                        className=""
                        type="submit"
                        name="action"
                        id="navbar"
                        onClick={() => {

                            history.push('/')
                            localStorage.clear()
                            window.location.reload()

                        }
                        }>Log Out

                    </button>
                </div>
                <div className="newproject_heading">
                    <h2  >New Project</h2>
                    <h6 className="color__orange">Discard project</h6>


                </div>
                <div className="levels">
                    <p className="labell">Name of the Project <span className="color__orange">*</span></p>
                    <input className="inputt" type="text" />
                </div>
                
                <div className="levels">
                    <p className="labell">Select The Category of Content <span className="color__orange">*</span></p>
                    <div className="radio__inp__main__conts">
                        <div className="radio__inp__cont2s">
                            <div className="radio__inp__cont">
                                <fieldset>
                                    <label>
                                        <input type="radio" name="radio-button" value="css"  />
                                        
                                        <span>Writing</span>
                                    </label>
                                   
                                </fieldset>
                            </div>

                        </div>

                        <div className="radio__inp__cont2s">
                            <div className="radio__inp__cont">
                                <label>
                                        <input type="radio" name="radio-button" value="request"
                                    type="radio"  className="input adgrey"
                                    id="request"  />
                                        <span> Visual Graphics</span>
                                    </label>
                       
                            </div>

                        </div>
                        <div className="radio__inp__cont2s">
                            <div className="radio__inp__cont">
                            <label>
                                        <input type="radio" name="radio-button" value="request2"
                                    type="radio"  className="input adgrey"
                                    id="request"  />
                                        <span> Video</span>
                                    </label>
                                {/* <input
                                    className="input adgrey"
                                    id="request2"
                                    name="type"
                                    value="request"
                                    type="radio"
                                ></input>{" "}

                                <label htmlFor="request2" className="para">
                                    Video
                                </label> */}
                            </div>

                        </div>

                        <div className="radio__inp__cont2s">
                            <div className="radio__inp__cont">
                            <label>
                                        <input type="radio" name="radio-button" value="request3"
                                    type="radio"  className="input adgrey"
                                    id="request"  />
                                        <span> Audio</span>
                                    </label>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="levels">
                    <p className="labell">Select the type of Content <span className="color__orange">*</span></p>
                    <div className=" radio__inp__main__conts">
                        <div className="radio__inp__cont2s">
                            <div className="radio__inp__cont">
                                {" "}
                                <label>
                                        <input type="radio" name="radio-button" value="request4"
                                    type="radio"  className="input adgrey"
                                    id="request"  />
                                        <span> Blog Article</span>
                                    </label>
                            </div>

                        </div>
                        <div className="radio__inp__cont2s">
                            <div className="radio__inp__cont">
                            <label>
                                        <input type="radio" name="radio-button" value="request5"
                                    type="radio"  className="input adgrey"
                                    id="request"  />
                                        <span> Website Content</span>
                                    </label>
                            </div>

                        </div>
                        <div className="radio__inp__cont2s">
                            <div className="radio__inp__cont">
                            <label>
                                        <input type="radio" name="radio-button" value="request6"
                                    type="radio"  className="input adgrey"
                                    id="request"  />
                                        <span> Product Descriptions</span>
                                    </label>
                            </div>

                        </div>
                        <div className="radio__inp__cont2s">
                            <div className="radio__inp__cont">
                            <label>
                                        <input type="radio" name="radio-button" value="request7"
                                    type="radio"  className="input adgrey"
                                    id="request"  />
                                        <span> News Letters</span>
                                    </label>
                            </div>


                        </div>

                        <div className="radio__inp__cont2s">
                            <div className="radio__inp__cont">
                                <label>
                                        <input type="radio" name="radio-button" value="request8"
                                    type="radio"  className="input adgrey"
                                    id="request"  />
                                        <span> E-book</span>
                                    </label>
                            </div>

                        </div>
                        <div className="radio__inp__cont2s">
                            <div className="radio__inp__cont">
                            <label>
                                        <input type="radio" name="radio-button" value="request6"
                                    type="radio"  className="input adgrey"
                                    id="request9"  />
                                        <span>Copy Writing </span>
                                    </label>
                            </div>

                        </div>
                        <div className="radio__inp__cont2s">
                            <div className="radio__inp__cont">
                            <label>
                                        <input type="radio" name="radio-button" value="request6"
                                    type="radio"  className="input adgrey"
                                    id="request10"  />
                                        <span>Case Studies</span>
                                    </label>
                            </div>

                        </div>
                        <div className="radio__inp__cont2s">
                            <div className="radio__inp__cont">
                            <label>
                                        <input type="radio" name="radio-button" value="request6"
                                    type="radio"  className="input adgrey"
                                    id="request11"  />
                                        <span> White Papers</span>
                                    </label>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="level">
                    <div className="levels">
                        <p className="labell">Content Title</p>
                        <input className="input__1" type="number" />
                    </div>
                    <div className="levels">
                        <p className="labell">Word Count</p>
                        <input className="input__1" type="number" />
                    </div>
                </div>
                <div className="level">
                    <div className="levels">

                        <p className="labell">Select Indutry</p>
                        <div className="selecttextcont">
                            <select className="selectt">
                                <option></option>
                                <option>  </option>
                            </select>

                        </div>

                    </div>

                    <div className="levels" id="">
                        <p className="labell">Skill Level of Creator</p>
                        <div className="selecttextcont">
                            <select className="selectt" >
                                <option>a</option>
                                <option>         </option>
                                <option>         </option>
                                <option></option>
                            </select>

                        </div>

                    </div>
                </div>
                <div>
                    <p className="labell">Estimate</p>
                    <p className="color__orange">₹2200</p>
                </div>
                <div className="levels">
                    <p className="labell">FOCUS KEYWORDS</p>
                    <div className="keyword">
                        <button>KEYWORDS</button>
                        <button>KEYWORDS</button>
                        <button>KEYWORDS</button>
                        <button>KEYWORDS</button>
                        <button className="color__orange">+Add New</button>
                    </div>
                </div>
                <p className="color__orange" >OR</p>
                <div className="levels">
                    <p className="labell">Share Link to your project doc</p>
                    <p>In case of multiple/bulk content requirements, share a document link with complete content details</p>
                    <input className="inputt" type="text" />
                </div>
                <div className="levels">
                    <p className="labell">Project Brief</p>
                    <p>Tell us about your objective for this content. Your target audience, and additional comments.</p>
                    <input className="input-t" type="text" />
                </div>

                <div className="level">
                    <div className="submitt">


                        <button className="btn">
                            Submit </button>
                        <span>or</span>
                        <span
                            className="para color__orange cursor__pointer"

                            onClick={gotoprojectbrief}
                        >
                            Create the Project Brief
                        </span>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ANewProject;
