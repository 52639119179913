import React from "react";
import "./WaveHandConfirmation.css";

export const WaveHandConfirmation = ({  onClose, onConfirm, params}) => {
  console.log({ onConfirm });
  return (
    <div className="confirmation-box">
      <div className="confirmation-modal-content">
        <h4>You want to check availability?</h4>
        <div className="modalButtons">
          <button onClick={() => onConfirm(params)}>Yes</button>
          <button onClick={() => onClose()}>
            No
          </button>
        </div>
      </div>
    </div> 
  );
};
