import React from "react";
import tmembimg from "../../images/tmembimg.png";
import { Link, useHistory } from "react-router-dom";
import authHeader from "../../login-auth.js";
import axios from "../../axios.js";

function ATeamCard({ name, email, isadmin, deleteMem }) {
  const isLoginUserAdmin = authHeader().User.role === "Admin";
  const loginEmail = authHeader().User.businessEmail;

  const giveAdminAccess = async () => {
    try {
      const res = await axios.post(
        "/team/giveAdminAccess",
        { businessEmail: email },
        {
          headers: { authorization: "Bearer " + authHeader().Token },
        }
      );
      deleteMem();
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const remMemb = async () => {
    try {
      const res = await axios.post(
        "/team/removeMember",
        { businessEmail: email },
        {
          headers: { authorization: "Bearer " + authHeader().Token },
        }
      );
      deleteMem();
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="teammember">
      <img className="teammember__img" src={tmembimg} alt="" />

      <div className="part1">
        <p className="para">{name}</p>
        <p className="para">{email}</p>
        <p className="para">{!isadmin ? "General" : "Admin"}</p>
        <p className="para">Gurgaon</p>
      </div>
      <div className="part2">
        {isLoginUserAdmin && loginEmail !== email && !isadmin && (
          <p className="para cursor__pointer" onClick={giveAdminAccess}>
            Give Admin Access
          </p>
        )}
        {email !== loginEmail && isLoginUserAdmin && (
          <p
            className="para color__orange cursor__pointer fw__600"
            onClick={remMemb}
          >
            Remove
          </p>
        )}
      </div>
    </div>
  );
}

export default ATeamCard;
