import React from "react"; 
import "./brand_details.css"
import Verify from "../img/verified-icon.png"

const App=({brandLogo,agencyLogo,agencyName,brands, industries,services })=>{
    return <div className="brand_details">
        <div className="brand_agency_name">
            <div className="all_tags">
            <div className="tag">
                    <img className="Imagetags" src={agencyLogo} alt="" />
                    <p>{agencyName}</p>
                </div> 
                <div className="tag">
                    <img className="Imagetags" src={brandLogo} alt="" />
                    <p>{brands}</p>
                </div> 
            </div>
            <div className="tag verify_tag">
                <img className="Imagetags" src={Verify} alt="" />
                <p>Verify case study</p>
            </div>
        </div>
        <>
        {
            industries?.length===0?<></>:
            <div className="style_ind_service industry">
                <h3>Industry</h3>
                <div className="serviceName">
                {industries.map((industry) => (<p>{industry}</p>))}
                </div>
            </div>
        }
        </> 
        <>
         {
            services?.length===0?<></>:<div className="style_ind_service services ">
            <h3>Services</h3>
            <div className="serviceName">
                {services.map((service) => (<p>{service}</p>))}
            </div>
        </div>
        }
    </>

    </div>
     
}
export default App;