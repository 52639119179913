import React, { useEffect, useRef, useState } from "react";
import deleteIcon from "./demoImages/delete-outline.svg";
import reviewsIcon from "./demoImages/reviews.svg";
import magnify from "./demoImages/magnify.svg";
import userDefault from './demoImages/userDefault.jpg'
import "./TalentPool.css";
import axios from "../../../../axios";
import DeleteConfirmation from "../../../DeleteConfirmation/DeleteConfirmation";
import { ContactSupportOutlined } from "@material-ui/icons";
import { toast } from "react-toastify";
import UploadCsv from "./UploadCsv/UploadCsv";

const TalentPool = ({
  selectedCategory,
  categoryArray,
  addedUser,
  setAddedUser, 
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [rejectionComment, setRejectionComment] = useState({})
  const [comment, setComment] = useState({});
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [usersWithNoCategory, setUsersWithNoCategory] = useState({});
  const [idToDelete, setIdToDelete] = useState("");
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const dialogRef = useRef();
  const dialog = dialogRef.current;

  const [openCsvComponent, setOpenCsvComponent] = useState(false)

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  
  const toast_style = {
    position: "top-right",
    autoClose: 4000,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    width: "10rem",
  }

  console.log("selected array is ", selectedCategory)

  useEffect(() => {
    addedUser?.map((user) => {
      setRejectionComment((prevData) => ({
        ...prevData,
        [user?._id] : user?.rejectionComment
      }))
    })

  }, [addedUser])

  async function handleSearch(e) {
    setSearchValue(e.target.value);
    if (e.target.value.length === 1) {
      const { data } = await axios.post(
        "/marketers/getAllMarketers",
        { userNameStartWith: e.target.value.toLowerCase() },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );
      setUsers(data);
      setFilteredUsers(data);
    } else {
      let user = users.filter((key) =>
        key.name.toLowerCase().startsWith(e.target.value.toLowerCase())
      );
      setFilteredUsers(user);
    }
  }

  async function handleAddUser(email) {
    try {
      const { data } = await axios.post(
        "/talentPool/create_talent_pool_users",
        {
          category_id: selectedCategory._id,
          userEmail: email,
        },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );

      setAddedUser(prev => {
        return [...prev, data?.data?.talentPoolUser]
      });
      
      // if(data?.success){
      //   toast.success(data.message, toast_style)

      // }
      // else {
      //   toast.error(data.message, toast_style)
      // }
      setSearchValue("");
      
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDeleteAddedUser(id) {
    console.log(id);
    dialog.close();
    let { data } = await axios.delete(
      `/talentPool/delete_talent_pool_users/${id}`,
      { headers: { authorization: "Bearer " + Marketer.token } }
    );
    setAddedUser(addedUser.filter((user) => user?._id !== id));
  }

  async function handleInviteUser() {
    // try {
    //   const { data } = await axios.post(
    //     "/talentPool/invitation_mail",
    //     {
    //       category_id: selectedCategory._id,
    //       email: searchValue,
    //     },
    //     { headers: { authorization: "Bearer " + Marketer.token } }
    //   );
    //   setSearchValue("");
    //   console.log(data);
    // } catch (error) {
    //   console.log(error);
    // }
 
    try {
      const { data } = await axios.post(
        "/talentPool/invite_talent_pool_users",
        {
          category_id: selectedCategory._id,
          userEmail: searchValue,
        },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );

      if(data.success === false) {
        toast.error(data.message, toast_style)
      }
      else if(data.success) {
        setAddedUser((prev) => {
          return [...prev, data?.data || []];
        });
        toast.success(data.message, toast_style)
      }
      console.log(data);
      setSearchValue("");
    } catch (error) {
      console.log(error);
    }
  }

  function handleShowDialog(e) {
    setIdToDelete(e.target.id);
    dialog.showModal();
  }

  const handleOpenCommentModal = (userId) => {
    setSelectedUserId(userId);
    setShowCommentModal(true);
  };

  const handleCloseCommentModal = () => {
    setShowCommentModal(false);
  };

  const handleSubmitComment = async (id) => {
    try {
      const { data } = await axios.post(
        `/talentPool/getRejectionComment/${id}`,
        {
          rejectionComment: comment[id],
        },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );
      console.log(data);
      setShowCommentModal(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Talent pool user not found.");
      } else {
        console.error("An error occurred while submitting the comment:", error);
      }
    }
  };

  console.log("All the added users are ", addedUser)

  return (
    <>
    <div className="talentPool_table">
      <DeleteConfirmation
        dialog={dialog}
        dialogRef={dialogRef}
        removeFunc={handleDeleteAddedUser}
        id={idToDelete}
      />
      <table>
        <tr>
          <th className="nameHeading">Name</th>
          <th className="roleHeading">Role Category</th>
          <th className="deleteUser"></th>
          <th className="review"></th>
        </tr>

        <tr>
          <td
            className="searchBar"
            colSpan="2"
            style={{ position: "relative" }}
          >
            <div>
              <img src={magnify} alt="" />
              <input
                type="text"
                name=""
                id=""
                value={searchValue}
                onChange={handleSearch}
                placeholder="Add people to your talent pool. Type the Name to search or invite via Email ID."
              />
              {filteredUsers.length === 0 && searchValue && (
                <button className="inviteBtn" onClick={handleInviteUser}>
                  Invite
                </button>
              )}
            </div>
            <div
              className="searchedResult"
              style={
                searchValue
                  ? { display: "flex", flexDirection: "column" }
                  : { display: "none" }
              }
            >
              {filteredUsers.map((filteredUser) => (
                <div key={filteredUser?._id} className="searchedUser">
                  <div className="searchedUserDetails">
                    <img src={filteredUser?.profilePhoto} alt="" />
                    <p>{filteredUser.name}</p>
                  </div>
                  <div className="searchedUsernameAndBtn">
                    <p>{filteredUser?.email}</p>
                    <button onClick={() => handleAddUser(filteredUser?.email)}>
                      Add
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </td>
          {/* <td className="uploadMarketerSheet" colSpan="2">
            <button onClick={() => {
              setOpenCsvComponent(!openCsvComponent)
            }}>Add CSV</button>
          </td> */}
        </tr>

        {addedUser?.map((user) => (
          <tr className="talentPoolAddedUser" key={user?.user_id?._id}>
            <td className="nameData">
              <div className="photoName">
                <img
                  className="talentDP"
                  src={user?.user_id?.profilePhoto || userDefault}
                  alt=""
                />
                <p>
                  {user?.user_id?.email ? user?.user_id?.name : user?.user_id?.email}
                </p>
              </div>
              {user?.user_id?.email ? (
                <p className="username">{user?.user_id?.email}</p>
              ) : (
                <p className="resendInvite">Resend Invite</p>
              )}
            </td>
            <td className="roleData">
              <div></div>
              <p>{selectedCategory.name}</p>
            </td>
            <td className="deleteUserIcon">
              <img
                src={deleteIcon}
                id={user?._id}
                alt=""
                onClick={handleShowDialog}
              />
            </td>
            <td className="reviewIcon">
              <img
                src={reviewsIcon}
                id={user?._id}
                alt=""
                onClick={() => handleOpenCommentModal(user?._id)}
              />
            </td>
          </tr>
        ))}
      </table>

      {showCommentModal && (
        <div className="comment-modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseCommentModal}>
              &times;
            </span>
            <h2 style={{ fontSize: "1rem" }}>Add Comment</h2>
            <textarea
              defaultValue={rejectionComment[selectedUserId]}
              value={comment[selectedUserId]}
              onChange={(e) => setComment({[selectedUserId] : e.target.value})}
              placeholder=" Enter your comment here..."
            />
            <button
              className="submit-btn"
              onClick={() => handleSubmitComment(selectedUserId)}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
    {/* {openCsvComponent && <UploadCsv 
    setOpenCsvComponent={setOpenCsvComponent}
    category_id = {selectedCategory?._id}
    user_id = {Marketer?.user?._id}
    ></UploadCsv>} */}
    </>


  );
};

export default TalentPool;
