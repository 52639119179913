import React, { useState } from "react";
import "./SessionForm.css";
import axios from "../../../../../axios";

const SessionForm = ({ onSubmit, onClose, onEditSession ,currentSessionData }) => {
  const [type, setType] = useState("Video Call");
  const [title, setSessionTitle] = useState(currentSessionData?.title || "");
  const [duration, setDuration] = useState(currentSessionData?.duration || "");
  const [price, setPrice] = useState(currentSessionData?.price || "");
  const [description, setDescription] = useState(
    currentSessionData?.description || ""
  );

  const Marketer = JSON.parse(localStorage.getItem("marketer"));


  const handleSubmit = (e) => {
    e.preventDefault();
    const newSession = {
      type,
      title,
      duration,
      price,
      description,
    };
    if (currentSessionData) {
      onEditSession(currentSessionData._id, newSession);
    } else {
      onSubmit(newSession);
    }
    setType("Video Call");
    setSessionTitle("");
    setDuration("");
    setPrice("");
    setDescription("");
  };

  const disabledTypes = [
    "Webinar",
    "Email",
    "Long-term Mentoring",
    "Package",
    "Subscription",
  ];

  const handleTypeChange = (sessionType) => {
    setType(sessionType);
  };


  return (
    <div className="session-form-overlay">
      <div className="session-form-container">
        <form className="session-form" onSubmit={handleSubmit}>
          <div className="session-form-tabs">
            <div
              className={`session-type-button ${
                type === "Video Call" ? "active" : ""
              }`}
              onClick={() => handleTypeChange("Video Call")}
            >
              Video Call
            </div>
            {disabledTypes.map((sessionType) => (
              <div
                key={sessionType}
                className={`session-type-button ${
                  type === sessionType ? "active" : ""
                } disabled`}
              >
                {sessionType}
              </div>
            ))}
          </div>
          <div className="session-form-content">
            <div className="session-form-field">
              <label>Session Title:</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setSessionTitle(e.target.value)}
                required
                placeholder="  Session title"
              />
            </div>
            <div className="session-form-field">
              <label>Session duration:</label>
              <input
                type="text"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                required
                placeholder="  Session duration                                                          in minutes"
              />
            </div>
            <div className="session-form-field">
              <label>Session price:</label>
              <input
                type="text"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
                placeholder="  Session price                                                                  in INR (₹)"
              />
            </div>
            <div className="session-form-field">
              <label>Session description:</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                style={{ height: "250px" }}
                placeholder="  Session description"
              ></textarea>
            </div>
            <div className="session-form-buttons">
              <button
                type="button"
                onClick={onClose}
                className="cancel-button1"
              >
                Cancel
              </button>
              <button type="submit" className="save-button">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SessionForm;
