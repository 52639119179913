import React from "react";

import "./Awards.css";
 

const Awards = ({ awards }) => {
  const awardImage="https://baramulla.nic.in/wp-content/themes/district-theme-3/images/award.jpg"
  return ( <>
  {awards.length!=0?<div className="awards">
      <div className="awards__heading">
        <h2 className="awards__title font__lexend">Awards</h2>
      </div>
      <div className="awards__container">
        {awards.map((award) => (
          <div className="award">
            <div>
              <img
                className="award__photo"
                src="https://i.ibb.co/1LBrsNq/image-1.png"
                alt="Award"
              />
            </div>
            <div>
              <div className="award__info">
                <h3 className="award__name font__lexend">{award.award}</h3>
                <a className="award__link" href="http://www.example.com/">
                  <img className="link__icon" src={awardImage} alt="link icon" />
                </a>
              </div>
              <h6 className="award__year font__lexend">{award.year}</h6>
            </div>
          </div>
        ))}
      </div>
    </div>:<></>}
  </>
    
    
  );
};

export default Awards;
