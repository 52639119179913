import React, { useState, useEffect } from "react";
import { BsArrowDownLeft } from "react-icons/bs";
import { IoIosAdd, IoIosStar, IoIosStarOutline } from "react-icons/io";
import { FaInfo, FaArrowRight } from "react-icons/fa";
import { MdOutlineReviews } from "react-icons/md";
import { BiBuildings } from "react-icons/bi";
import { MdOutlineAccountCircle } from "react-icons/md";
import { AiOutlineDatabase } from "react-icons/ai";
import { FaArrowRightToBracket } from "react-icons/fa6";

import axios from "../../../../../axios.js";
import moment from "moment";
import { toast } from "react-toastify";


import "./History.css";
import { CodeGuruProfiler } from "aws-sdk";
import NewLoader from "../../../../loader/NewLoader/NewLoader.js";

function History() {
  const [loader, setLoader] = useState(true)
  const [visibleDetailsId, setVisibleDetailsId] = useState(null);
  const [visiblePoolId, setVisiblePoolId] = useState(null);
  const [visibleRateId, setVisibleRateId] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [categoryArray, setCategoryArray] = useState([]);
  const [currentOpenedHistory, setCurrentOpenedHistory] = useState(null);

  const [filledStars, setFilledStars] = useState(0);
  const [review, setReview] = useState("");
  const [rejectionComment, setRejectionComment] = useState("")
  const [selectedPool, setSelectedPool] = useState(null);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const toast_style = {
    position: "top-right",
    autoClose: 4000,
    draggable: true,
    theme: "light",
    width: "10rem",
  }

  useEffect(() => {
    (async () => {
      const { data } = await axios.get("/mentoring/get-appointment-history/", {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      });

      setHistoryData(data);
      setLoader(false)
    })();
  }, []);

  const toggleDetails = (id) => {
    if (visibleDetailsId === id) {
      setVisibleDetailsId(null);
    } else {
      setVisibleDetailsId(id);
      setVisiblePoolId(null);
      setVisibleRateId(null);
    }
  };

  const togglePool = async (id, mentor_id) => {
    if (visiblePoolId === id) {
      setVisiblePoolId(null);
    } else {
      const current_mentor_id = mentor_id?._id || mentor_id;
      console.log("the current mentor id is ", current_mentor_id);
      const { data } = await axios.get(
        `/talentPool/get_talent_pool_categories/${Marketer.user._id}`
      );
      setCategoryArray(data);

      setVisiblePoolId(id);
      setVisibleDetailsId(null);
      setVisibleRateId(null);
      historyData?.map((element) => {
        if (element._id === id) {
          setCurrentOpenedHistory(element);
        }
      });
    }
  };

  console.log("The category array is ", categoryArray);

  const toggleRate = (id) => {
    if (visibleRateId === id) {
      setVisibleRateId(null);
    } else {
      setVisibleRateId(id);
      setVisibleDetailsId(null);
      setVisiblePoolId(null);

      historyData?.map((element) => {
        if (element._id === id) {
          setCurrentOpenedHistory(element);
        }
      });
    }
  };

  console.log("The current history is ", currentOpenedHistory);

  // Sample pool data for demonstration
  const formatDateUsingMoment = (dateStr) => {
    return moment(dateStr).format("DD MMMM YYYY"); // '23 June 2024'
  };

  const handleStarClick = (index) => {
    setFilledStars(index + 1);
    console.log(`Clicked star ${index + 1}`);
  };
  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };
  const handleRejectionComment = (event) => {
    setRejectionComment(event.target.value)
  }

  console.log("the current comment is ", rejectionComment)

  const cancelReview = (id) => {
    setFilledStars(0);
    setReview(null);
    setVisibleRateId(id);
  };

  const createReview = async () => {
    const requestBody = {
      mentor_id: currentOpenedHistory?.mentor_id?._id,
      session_id: currentOpenedHistory?.session_id?._id,
      reviewer: Marketer.user._id,
      rating: filledStars,
      review: review,
    };

    
    const { data } = await axios.post("/mentoring/post-reviews/", requestBody, {
      headers: {
        authorization: "Bearer " + Marketer.token,
      },
    });

    setVisibleRateId(null)

    if (data.success === false) {
      toast.error(data.message, toast_style);
    } else if (data.success) {
      toast.success(data.message, toast_style);
    }


  };

  const addUserToPool = async (category_id) => {
    const { data } = await axios.post(
      "/talentPool/create_talent_pool_users",
      {
        category_id: category_id,
        userEmail: currentOpenedHistory?.user_id?.email,
        rejectionComment : rejectionComment
      },
      { headers: { authorization: "Bearer " + Marketer.token } }
    );

    setVisiblePoolId(null)
    if (data.success === false) {
      toast.error(data.message, toast_style);
    } else if (data.success) {
      toast.success(data.message, toast_style);
    }
  };

  return (
    <>
    {loader ? <NewLoader></NewLoader> : <div className="requests-container">
      {historyData.map((request) => (
        <div key={request._id} className="request-container">
          <div className="request-item">
            <div className="request-header-prof request-prof">
              <div className="request-header-prof ">
                <div className="profile-img"></div>
                <p>
                  {request?.user_id?.name || request?.mentor_id?.name} |{" "}
                  <span>
                    {request?.user_id?.email || request?.mentor_id?.email}
                  </span>
                </p>
              </div>
            </div>
            <div className="request-actions">
              <div
                className={`action-item new-class ${visiblePoolId === request._id ? "active1" : ""
                  } ${!request?.incoming_request ? "pointerNone" : ""}`}
                onClick={() => togglePool(request._id, request?.mentor_id)}
              >
                <FaArrowRight />
                <p>Add to pool</p>
              </div>
              <button
                className={`action-item new-class ${visibleRateId === request._id ? "active1" : ""
                  }`}
                disabled={request?.incoming_request}
                onClick={() => toggleRate(request._id)}
              >
                <MdOutlineReviews />
                <p>Rate and review</p>
              </button>
              <button
                className={`action-button new-class ${visibleDetailsId === request._id ? "active1" : ""
                  }`}
                onClick={() => toggleDetails(request._id)}
              >
                <FaInfo />
              </button>
            </div>
          </div>

          {visibleDetailsId === request?._id && (
            <div className="details-section2">
              <p>
                <span>{request?.session_id?.type} </span>|{" "}
                <span>{request?.session_id?.duration} minutes</span>
              </p>
              <p>
                {formatDateUsingMoment(request?.date)} | {request?.startTime}
              </p>
              <br></br>
              <p className="description">{request?.agenda_insights}</p>
            </div>
          )}

          {visiblePoolId === request._id && (
            <div className="details-section2 new-class">
              <div className="pool">
                <p className="pool-title">Select a pool</p>
                <div className="pool-column">
                  {categoryArray.map((item) => (
                    <div key={item._id} className="pool-item-wrapper">
                      <div
                        className={`pool-item ${selectedPool === item._id ? 'selected' : ''}`}
                        onClick={() => setSelectedPool(item._id === selectedPool ? null : item._id)}
                      >
                        <p className={`${selectedPool === item._id ? 'selected-text' : ''}`}>
                          {item.name} | {item?.user_count || 0}
                        </p>
                      </div>
                      {selectedPool === item._id && (
                        <div className="comment-section">
                          <p className="comment-title">Comments</p>
                          <textarea placeholder="Add your comment" onChange={handleRejectionComment} value={rejectionComment}></textarea>
                          <div className="comment-buttons">
                            <button className="comment-cancel" onClick={() => setSelectedPool(null)}>Cancel</button>
                            <button className="comment-add-to-pool"  onClick={() => addUserToPool(item._id)}><FaArrowRightToBracket />
                            Add to Pool</button>
                          </div>
                          

                        </div>
                      )}

                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {visibleRateId === request._id && (
            <div className="details-section2 new-class rate">
              <div className="rate-container">
                <p className="fonts">Rating</p>
                <div className="rating-stars">
                  {/* Assuming you want 5 stars */}
                  <div className="rating-container">
                    {[...Array(5)].map((star, index) => (
                      <span
                        key={index}
                        className="star"
                        onClick={() => handleStarClick(index)}
                      >
                        {index < filledStars ? (
                          <IoIosStar />
                        ) : (
                          <IoIosStarOutline />
                        )}
                      </span>
                    ))}
                  </div>
                </div>

                <div className="review-section">
                  <p>Review</p>
                  <div className="review-input">
                    <textarea
                      name="message"
                      rows="3"
                      cols="40"
                      style={{ resize: "none" }}
                      value={review}
                      onChange={handleReviewChange}
                    />
                  </div>

                  <div className="review-buttons">
                    <button
                      className="cancel-button"
                      onClick={() => cancelReview(request._id)}
                    >
                      Cancel
                    </button>
                    <button
                      className="publish-button"
                      onClick={() => createReview()}
                    >
                      Publish {review ? "Review" : "Rating"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>}
    </>
    
  );
}

export default History;
