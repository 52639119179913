import React from "react";
import "../styles/ForAgencies.css";
import brandimg from "../images/forBrands.png";
import Reviews from "./Reviews";
import SearchAgencyForBrands from "./SearchAgencyForBrands";
import tick from "../images/TickPricing.png";
import { Link, useHistory } from "react-router-dom";

function ForBrands() {
  const history = useHistory();

  return (
    <div className="forAgencies">
      <div className="fab1">
        <h1 style={{ fontSize: "32px" }}>
          <span className="spanOut">The Business Generation Platform </span>
          <span className="spanIn">For Agencies</span>
        </h1>
        <h3 className="tagline">
          Discover projects from across the industry. Never miss out on the
          opportunities.
        </h3>
        <button
          className="button join-btn"
          onClick={() => history.push("/signup")}
        >
          Register Now
        </button>
      </div>

      <div className="fab2 columns is-mobile has-text-centered">
        <div className="column left">
          <h1 className="tags">1000+</h1>
          <p>Brands looking for agencies</p>
        </div>
        <div className="column">
          <h1 className="tags">20x</h1>
          <p>More visibility</p>
        </div>
        <div className="column right">
          <h1 className="tags">10%</h1>
          <p>Faster lead conversion</p>
        </div>
      </div>

      <div className="fab3">
        <div className="has-text-centered">
          <h1>
            <span className="b">Why agencies use </span>
            <span className="p">Unispade?</span>
          </h1>
        </div>

        <div className="columns fab3c">
          <div className="column fab3c1">
            <h2 className="marb1">
              <span className="rel">The Agency</span>
              <span className="rec"> profile</span>
            </h2>
            <h3>
              Share your complete agency profile including major industry and
              service expertise, clients, projects, news mentions in a
              structured manner
            </h3>
          </div>
          <div className="column fab-img">
            <img className="fb-img" src={brandimg} alt="k" />
          </div>
        </div>

        <div className="columns fab3c">
          <div className="column fab-img">
            <img className="fb-img" src={brandimg} alt="k" />
          </div>
          <div className="column fab3c2">
            <h2 className="marb1">
              <span className="rec">Get in touch</span>
              <span className="rel"> directly with the client</span>
            </h2>
            <h3>
              Communicate with the decision makers directly who are in charge of
              the project on the brand side from the Dashboard
            </h3>
          </div>
        </div>

        <div className="columns fab3c">
          <div className="column fab3c1">
            <h2 className="marb1">
              <span className="rec">Pitching</span>
              <span className="rel"> for projects</span>
            </h2>
            <h3>
              Choose which projects fall in line with your agency's expertise
              and budget scopes and respond accordingly
            </h3>
          </div>
          <div className="column fab-img">
            <img className="fb-img" src={brandimg} alt="k" />
          </div>
        </div>

        <div className="columns fab3c">
          <div className="column fab-img">
            <img className="fb-img" src={brandimg} alt="k" />
          </div>
          <div className="column fab3c2">
            <h2 className="marb1">
              <span className="rel">Build credibility via </span>
              <span className="rec">Reviews</span>
            </h2>
            <h3>
              Client reviews posted on agency profiles are verified by Unispade
              from the previously executed client projects to assure trust and
              credibility when the brands view the profiles
            </h3>
          </div>
        </div>

        <div className="columns fab3c">
          <div className="column fab3c1">
            <h2 className="marb1">
              <span className="rel">Get project</span>
              <span className="rec"> notifications</span>
            </h2>
            <h3>
              Get notified about new projects in the market. Not just leads but
              detailed project briefs are shared by brands to give a better idea
              about what kind of collaboration agency will be getting into with
              the brand.
            </h3>
          </div>
          <div className="column fab-img">
            <img className="fb-img" src={brandimg} alt="k" />
          </div>
        </div>

        <div className="columns fab3c">
          <div className="column fab-img">
            <img className="fb-img" src={brandimg} alt="k" />
          </div>
          <div className="column fab3c2">
            <h2 className="marb1">
              <span className="rel">Brand </span>
              <span className="rec">Scores</span>
            </h2>
            <h3>
              Rate brands on the basis of various different parameters after
              project completion or refer to brand scores before getting into
              project collaboration with them.
            </h3>
          </div>
        </div>
        <Reviews className="reviews" />

        <div className="fab5">
          <div className="has-text-centered">
            <h2 className="pricing">Pricing</h2>
          </div>
          <div className="columns">
            <div className="onecard freecard column card is-transparent mr-4">
              <div>
                <div className="card-content">
                  <h1 className="free">Free</h1>
                  <h2 className="starter">Starter</h2>
                </div>
                <div className="card-content">
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">Full Profile (3 Reviews)</h3>
                    </div>
                  </div>
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">Profile Analytics</h3>
                    </div>
                  </div>
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">Upto 5 organic Leads</h3>
                    </div>
                  </div>
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">1 Guest Blogging</h3>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "10.5rem" }} className="card-content">
                  <button className="button starter-btn">Choose Starter</button>
                </div>
              </div>
            </div>
            <div className="onecard column card is-transparent mr-4">
              <div>
                <div className="card-content">
                  <h1>
                    <span className="rs">₹3500</span>
                    <span className="perm">/month</span>
                  </h1>
                  <h2 className="basic">Basic</h2>
                </div>
                <div className="card-content">
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">Full Profile (upto 10 reviews)</h3>
                    </div>
                  </div>
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">Profile Analytics</h3>
                    </div>
                  </div>
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">Upto 10 organic Leads</h3>
                    </div>
                  </div>
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">2 Guest Blogging</h3>
                    </div>
                  </div>
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">Marketing Support</h3>
                    </div>
                  </div>
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">Community Access</h3>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "3.7rem" }} className="card-content">
                  <button className="button starter-btn">Choose Basic</button>
                </div>
              </div>
            </div>
            <div className="onecard column card is-transparent">
              <div>
                <div className="card-content">
                  <h1>
                    <span className="rs5">₹5000</span>
                    <span className="perm5">/month</span>
                  </h1>
                  <h2 className="premium">Premium</h2>
                </div>
                <div className="card-content">
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">Full Profile (10+ reviews)</h3>
                    </div>
                  </div>
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">Profile Analytics</h3>
                    </div>
                  </div>
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">Unlimited organic Leads</h3>
                    </div>
                  </div>
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">4 Guest Blogging</h3>
                    </div>
                  </div>
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">Marketing Support</h3>
                    </div>
                  </div>
                  <div className="columns is-mobile">
                    <div className="column is-1">
                      <img src={tick} alt="" />
                    </div>
                    <div className="column">
                      <h3 className="rev">Community Access</h3>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "3rem" }} className="card-content">
                  <button className="button starter-btn">Choose Premium</button>
                </div>
              </div>
            </div>
          </div>
          <div className="showmore">
            <a
              onClick={() => history.push("/pricing")}
              style={{ color: "#FF656A", cursor: "pointer" }}
            >
              Show more features 
            </a>
          </div>
        </div>

        <SearchAgencyForBrands
          black="Increase the chances of your project success, partner with the "
          redAfter="right agency"
        />
      </div>
    </div>
  );
}

export default ForBrands;
