import React, { useState, useEffect, useRef } from "react";
import MediaComponent from "../MediaComponent/MediaComponent";
import ImageComponent from "../MediaComponent/ImageComponent";
import "./secondCaseStudyPortfolioCompo.css";
import CreditInput from "../creditCompo/credit";
import { Editor } from "@tinymce/tinymce-react";
import axios from "../../../axios";
import ImageCropper from "../MediaComponent/ImageCropper"


const SecondCaseStudyPortfolioComponent = (props) => {
  const init = {
    overview: {
      text: "",
      imgType: "",
      image: [],
      videos: [],
    },
    challenges: {
      text: "",
      imgType: "",
      image: [],
      videos: [],
    },
    research: {
      text: "",
      imgType: "",
      image: [],
      videos: [],
    },
    process: {
      text: "",
      imgType: "",
      image: [],
      videos: [],
    },
    results: {
      text: "",
      imgType: "",
      image: [],
      videos: [],
    },
    statistics: [],
    testimonial: {
      text: "",
      data: [],
    },
    credits: {
      individual_credit: [],
      agency_credit: [],
    },
    client_Testimonial_input: {
      name: "",
      designation: "",
      email_id: "",
    },
    awards: [],
    news_mention: [],
    partner_certificate: [],
    coverImg: "",
  };

  // for storing all the inputs
  const [inputfield, setInputfield] = useState(init);
  // for storing the current field
  const [currentfield, setcurrentfield] = useState();
  const [resetCredit, doResetCredit] = useState(0);

  // for toggle

   const [inputfieldHide, setInputfieldHide] = useState({
    overview: (inputfield.overview.text!=="")?false:true,
    challenges: (inputfield.challenges.text!=="")?false:true,
    research: (inputfield?.research.text!=="")?false:true,
    process: (inputfield?.process.text!=="")?false:true,
    results: (inputfield?.results.text!=="")?false:true,
    statistics: (inputfield?.statistics.length)>0?false:true,
    testimonial: (inputfield?.testimonial.text)?false:true,
    awards: true,
    news_mention: true,
    partner_certificate: true,
    credits:( inputfield?.credits.individual_credit.length>0)||(inputfield?.credits.agency_credit.length>0)?false:true,
    individualCredit: true,
    agencyCredit: true,
  });



  const [inputfieldSave, setInputfieldSave] = useState({
    overview: false,
    challenges: false,
    research: false,
    process: false,
    results: false,
    statistics: false,
    testimonial: false,
    awards: false,
    news_mention: false,
    partner_certificate: false,
    credits: false,
    individualCredit: false,
    agencyCredit: false,
  });

  useEffect(() => {
    if (props.caseStudyForm) {
      setInputfield(props.caseStudyForm);
      setInputfieldHide({ 
        overview: (props.caseStudyForm.overview.text!=="")?false:true,
      challenges: (props.caseStudyForm.challenges.text!=="")?false:true,
      research: (props.caseStudyForm?.research.text!=="")?false:true,
      process: (props.caseStudyForm?.process.text!=="")?false:true,
      results: (props.caseStudyForm?.results.text!=="")?false:true,
      statistics: (props.caseStudyForm?.statistics.length)>0?false:true,
      testimonial: (props.caseStudyForm?.testimonial.text)?false:true,
      awards: true,
      news_mention: true,
      partner_certificate: true,
      credits:( props.caseStudyForm?.credits.individual_credit.length>0)||(props.caseStudyForm?.credits.agency_credit.length>0)?false:true,
      individualCredit: true,
      agencyCredit: true,})
    }
  }, [props.caseStudyForm]);

  const [individualCredit, setIndividualCredit] = useState();
  const [agencyCredit, setAgencyCredit] = useState();
  const [statistics, setStatistics] = useState();
  const [testimonial, setTestimonial] = useState();
  const [awards, setAwards] = useState();
  const [newsMentions, setNewsMentions] = useState();
  const [partnersCertificate, setPartnersCertificate] = useState();

  // Editor

  const [addButtonDisabled, setAddButtonDisabled] = useState(true)

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) { 
      console.log(editorRef.current.getContent());
    }
  };

  // details that are displayed
  const inputField_detail = [
    
    {
      name: "overview",
      heading: "Overview*",
      description:
        "A good starting point to engage the reader into the conversation.",
      text_editor: true,
      demands: [
        "Provide a general information about the client",
        "Set up the context about the project",
      ],
      inputs: [],
      showImagesUpload: true,
    },
    {
      name: "challenges",
      heading: "Requirements | Objectives | Challenges",
      description:
        "Provide the context regarding your past client and the project. If the prospect is facing the same issues, the they will find the content relatable and this leads to higher chances of conversion.",
      text_editor: true,
      demands: [
        " What was the client requirement?",
        " Why did that requirement arise for the client? or",
        " What problems were they struggling with before they employed your services? (Be specific.)",
        "How were these problems affecting their business?",
      ],
      inputs: [],
      showImagesUpload: true,
    },
    {
      name: "research",
      heading: "Research | Insights",
      description:
        "Whatever solution your agency comes up with, it has to be backed by solid research. This shows how your agency is not just a bunch of creative folks but also a strong team of researchers who know their stuff!",
      text_editor: true,
      demands: [
        "Give some elementary insights about the research that your agency conducted before brainstorming possible solutions",
        "What were the significant insights that helped your agency to strategize a solution?",
        "What was your assessment about the client's situation then? or",
        "Where did you see the scope of improvement",
      ],
      inputs: [],
      showImagesUpload: true,
    },
    {
      name: "process",
      heading: "Process | Solution | Approach",
      description:
        "This part helps the Brands in understanding how your agency undertakes a project. This boosts the prospect's confidence in your agency.",
      text_editor: true,
      demands: [
        "What was the solution that your agency came up with? Elaborate the major aspects of the project that aggregated into a complete solution.",
        "What were your project tracking and project success metrics?",
        "What was your project management and communication approach?",
      ],
      inputs: [],
      showImagesUpload: true,
    },
    {
      name: "results",
      heading: "Result | Outcome | Benefits",
      description:
        "This is the point of conversion and strengthens the prospect's confidence in your agency",
      text_editor: true,
      demands: [
        "What was the benefit that your client gained out of the solution that your agency devised for them?",
        "How was your agency able to add value to your client's brand?",
        "Provide proofs if possible.",
      ],
      inputs: [],
      showImagesUpload: true,
    },
    {
      name: "statistics",
      heading: "Statistics",
      description:
        "Statistics bring absolute clarity about the efforts you put in the project.",
      text_editor: false,
      demands: [
        "Provide as many statistics as possible",
        "Prospects prefer core metrics over vanity metrics",
      ],
      inputs: ["", ""],
      input_name: ["text", "value"],
      customeSectionStyle: "section-style",
      showImagesUpload: false,
    },
    // {
    //   name: "testimonial",
    //   heading: "Client Testimonial(s)",
    //   description: "",
    //   text_editor: true,
    //   demands: [],
    //   inputs: ["Name", "Designation", "Mail ID"],
    //   input_name: ["name", "designation", "email_id"],
    // },
    {
      name: "credits",
      heading: "Credits(s)",
      description: "",
      text_editor: false,
      demands: [],
      inputs: ["Partner", "Link"],
      input_name: ["partener", "link"],
      customeSectionStyle: "credit-section-style",
      individualCredit: ["email", "name", "role"],
      individualCreditLabel: ["Mail ID", "Name", "Role"],
      individualCreditdata:{email:props.userDetails,name:[],role:[]},
      agencyCredit: ["name", "role", "agencyWebsite"],
      agencyCreditLable: ["Agency Name", "Role", "Agency Website"],
      agencyCreditData:{name:props.agencyDetail, role:[], agencyWebsite:[]}
    },
  ];

  // it will only be used to store the value of text editor
  const handleDescriptionChange = (event) => {
    const {name, value } = event.target;
    let inputFieldCopy = { ...inputfield };
    inputFieldCopy[name].text = value;
    setInputfield(inputFieldCopy);
  };

  // it is used to store all the other field
  const handleInputs = (event, identifier) => {
    const { name, value } = event.target;
    if (identifier === "statistics") {
      let statisticsCopy = { ...statistics };
      statisticsCopy[name] = value;
      setStatistics(statisticsCopy);
    } else if (identifier === "testimonial") {
      let testimonialCopy = { ...testimonial };
      testimonialCopy[name] = value;
      setTestimonial(testimonialCopy);
    } else if (identifier === "awards") {
      let awardsCopy = { ...awards };
      awardsCopy[name] = value;
      setAwards(awardsCopy);
    } else if (identifier === "news_mention") {
      let newsMentionCopy = { ...newsMentions };
      newsMentionCopy[name] = value;
      setNewsMentions(newsMentionCopy);
    } else if (identifier === "partner_certificate") {
      let partnerCertificateCopy = { ...partnersCertificate };
      partnerCertificateCopy[name] = value;
      setPartnersCertificate(partnerCertificateCopy);
    }

  };

  // it is handling the button add up and downwards
  const handleButton = (key) => {
    const value = key.name;
    if (inputfield[value] === false) {
      return (
        <div
          className="arrow down"
          onClick={() => {
            setInputfieldHide((prevValue) => {
              return { ...prevValue, [key.name]: false };
            });
          }}
        ></div>
      );
    } else if (!inputfieldHide[key.name]) {
      return (
        <div
          className="arrow up"
          onClick={() => {
            setInputfieldHide((prevValue) => {
              return { ...prevValue, [key.name]: true };
            });
          }}
        ></div>
      );
    } else {
      return (
        <div
          className="arrow down"
          onClick={() => {
            setInputfieldHide((prevValue) => {
              return { ...prevValue, [key.name]: false };
            });
          }}
        ></div>
      );
    }
  };

  const addCredits = (event, creditType) => {
    const { name, value } = event.target;
    let credit =
      creditType === "individualCredit"
        ? { ...individualCredit }
        : { ...agencyCredit };
    credit[name] = value;
    if (creditType === "individualCredit") {
      if(name==="email"){
        var input_name="name"
        var setName_input = inputField_detail[6] && inputField_detail[6].individualCreditdata && inputField_detail[6].individualCreditdata[name].find(item => item.email === value);
        setName_input&&(credit[input_name]=setName_input.name)
      }
      setIndividualCredit(credit);
    } else {
      if(name==="name"){
        var input_name="agencyWebsite"
        var setName_input = inputField_detail[6] && inputField_detail[6].agencyCreditData &&inputField_detail[6].agencyCreditData[name].find(item => item.agencyName ===value)
        setName_input&&(credit[input_name]=setName_input.agencyWebsite)
      }
      setAgencyCredit(credit);
    }
  };

  const setCredits = async (creditType, fields) => {
    let inputField = { ...inputfield };
    if (creditType === "individualCredit") {
      if (inputField._id) {
        const res = await axios.post(
          "/credits/createCredits",
          {
            ...individualCredit,
            type: "individual",
            agencyId: inputField.agencyId,
            agencyName: props.agencyName,
            caseStudyId: inputField._id,
            projectTitle:props.caseStudyForm.projectTitle
          }
        );
        
        inputField.credits.individual_credit.push({...individualCredit, _id: res?.data?._id});
      } else {
        inputField.credits.individual_credit.push(individualCredit);
      }
      setIndividualCredit();
    } else {
      if (inputField._id) {
        const res = await axios.post(
          "/credits/createCredits",
          {
            ...agencyCredit,
            type: "agency",
            agencyId: inputField.agencyId,
            agencyName: props.agencyName,
            caseStudyId: inputField._id
          }
        );
        
        inputField.credits.agency_credit.push({...agencyCredit, _id: res?.data?._id});
      } else {
        inputField.credits.agency_credit.push(agencyCredit);
      }
      setAgencyCredit();
    }
    setInputfield(inputField);
    doResetCredit(prev => prev + 1);

    fields.forEach((field) => {
      let elem = document.getElementById(`${creditType}-${field}`);
      if (elem) {
        elem.value = "";
      }
    });
  };

  const deletecredit = async (creditType,data)=>{
    let inputField = { ...inputfield };
    if (creditType === "individualCredit") { 
      inputField.credits.individual_credit=inputField.credits.individual_credit.filter(key=>data!=key)
    } else {
      inputField.credits.agency_credit=inputField.credits.agency_credit.filter(key=>data!=key)
    }
    setInputfield(inputField);
    if (data._id) {
      await axios.post(`/credits/deletCreditById/${data._id}`);
    }
  }

  const setProjectTitle = (event) => {
    let formData = { ...props.caseStudyForm };
    formData.projectTitle = event.target.value;
    props.setCaseStudyFormData(formData);
  };

  const handleSave = (identifier) => {
    console.log(...props.caseStudyForm[identifier]);
    let formData = { ...props.caseStudyForm };
    formData[identifier] = inputfield[identifier];
    props.setCaseStudyFormData(formData);

    let inputfieldSaveCopy={...inputfieldSave}
    inputfieldSaveCopy[identifier]=true
    setInputfieldSave(inputfieldSaveCopy)
  };

  const discardSection = (identifier) => {
    let inputFieldCopy = { ...inputfield };
    inputFieldCopy[identifier] = init[identifier];
    setInputfield(inputFieldCopy);
  };

  const addValuesHandler = (identifier, fieldIndex) => {
    let inputFieldCopy = { ...inputfield };
    if (identifier === "statistics") {
      if (!statistics) return;
      if (!inputFieldCopy[identifier]) {
        inputFieldCopy[identifier] = [statistics];
      } else {
        inputFieldCopy[identifier].push(statistics);
      }
      setInputfield(inputFieldCopy);
      setStatistics();
    } else if (identifier === "testimonial") {
      if (!testimonial) return;
      inputFieldCopy[identifier].data.push(testimonial);
      setInputfield(inputFieldCopy);
      setTestimonial();
    } else if (identifier === "awards") {
      if (!awards) return;
      inputFieldCopy[identifier].push(awards);
      setInputfield(inputFieldCopy);
      setAwards();
    } else if (identifier === "news_mention") {
      if (!newsMentions) return;
      inputFieldCopy[identifier].push(newsMentions);
      setInputfield(inputFieldCopy);
      setNewsMentions();
    } else if (identifier === "partner_certificate") {
      if (!partnersCertificate) return;
      inputFieldCopy[identifier].push(partnersCertificate);
      setInputfield(inputFieldCopy);
      setPartnersCertificate();
    }

    inputField_detail[fieldIndex].input_name.forEach((name) => {
      let elem = document.getElementById(identifier + "-" + name);
      if (elem) {
        elem.value = "";
      }
    });
  };

  const getItemsArr = (identifier) => {
    if (
      identifier === "statistics" ||
      identifier === "awards" ||
      identifier === "partner_certificate" ||
      identifier === "news_mention"
    ) {
      return inputfield[identifier];
    } else if (identifier === "testimonial") {
      return inputfield[identifier].data;
    } else {
      return [];
    }
  };

  const cover_div = () => {
    return (
      <>
        <div className="inputfield">
          <div className="textField">
            <div>
              <p>Cover</p>
            </div>
            
            <ImageCropper 
              caseStudyForm={props.caseStudyForm}
              setUserProfileFormData={props.setCaseStudyFormData}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="Second_compo">
      <div className="project_tittle">
        <p> Project Title *</p>
        <input
          type="text"
          onChange={(e) => setProjectTitle(e)}
          value={props.caseStudyForm?.projectTitle}
        />
      </div>

      <div className="basic_details">
        {cover_div()}
        {inputField_detail.map((key, fieldIndex) => (
          <>
            {key.name !== "credits" ? (
              <div className="inputfield">
                <div   
                  className="textField"
                  onClick={() => {
                    setcurrentfield(key);
                  }}
                >
                  <div onClick={() => {
                      !inputfieldHide[key.name]?setInputfieldHide((prevValue) => {
                        return { ...prevValue, [key.name]: true };
                      }):
                      setInputfieldHide((prevValue) => {
                        return { ...prevValue, [key.name]: false };
                      });
                    }}> 
                    <p>{key.heading}</p>
                    {handleButton(key)}
                  </div>
                  {/* {console.log(key)} */}
                  {!inputfieldHide[key.name] && (
                    <>
                      <span>{key.description} </span>
                      <ul>
                        {key.demands.map((demands) => (
                          <li>{demands}</li>
                        ))}
                      </ul>
                      <td>
                        {key.text_editor ? (
                          <Editor
                            apiKey="tj55rrirq2vmb4xn34qzdaq4enr7sc1ltzvg6w4talwguw0y"
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            name={key.name}

                            value={inputfield[key.name].text}
                            onEditorChange={(newValue, editor) => {
                                handleDescriptionChange({target: {name: key.name, value: newValue}})

                            }}
                            init={{
                              height: 200,
                              menubar: false,

                              plugins: [
                                "advlist autolink lists link image charmap print preview anchor",
                                "searchreplace visualblocks code fullscreen",
                                "insertdatetime media table paste code help wordcount",
                              ],
                              toolbar:
                                "undo redo | formatselect | " +
                                "bold italic backcolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | " +
                                "removeformat | help | fullscreen",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <section
                        className={`${
                          key.customeSectionStyle ? key.customeSectionStyle : ""
                        } inputs`}
                      >
                        {key.inputs.map((input_tag, index) => (
                          <div>
                            <span>{input_tag}</span>
                            <input
                              id={key.name + "-" + key.input_name[index]}
                              name={key.input_name[index]}
                              onChange={(e) => handleInputs(e, key.name)}
                              type="text"
                            />
                          </div>
                        ))}
                        {key.inputs.length !== 0 ? (
                          <button
                            className="btn-style"
                            onClick={() =>
                              addValuesHandler(key.name, fieldIndex)
                            }
                          >
                            Add
                          </button>
                        ) : (
                          <></>
                        )}
                        {getItemsArr(key.name).length > 0 && (
                          <div className="added-credits w-100">
                            <table className="w-100 d-flex flex-column">
                              {getItemsArr(key.name).map((data) => {
                                return (
                                  <tr className="d-flex justify-content-between w-100 row-style">
                                    {Object.values(data).map((value) => {
                                      return <td>{value}</td>;
                                    })}
                                    <td >delete</td>
                                  </tr>
                                );
                              })}
                            </table>
                          </div>
                        )}
                      </section>
                      <div className="save_delete_button">
                        <button onClick={() => discardSection(key.name)}>
                          Discard Section
                        </button>
                         
                      </div>
                    </>
                  )}
                </div>
                <div className="imageField"></div>
              </div>
            ) : (
              <div className="inputfield">
                <div className="textField" onClick={() => setcurrentfield(key)}>
                  <div onClick={() => {
                      !inputfieldHide[key.name]?setInputfieldHide((prevValue) => {
                        return { ...prevValue, [key.name]: true };
                      }):
                      setInputfieldHide((prevValue) => {
                        return { ...prevValue, [key.name]: false };
                      });
                    }} >
                    <p>{key.heading}</p>
                    {handleButton(key)}
                  </div>
                  {inputfieldHide[key.name] ? (
                    <></>
                  ) : (
                    <>
                      <div className="d-flex flex-column credit-div">
                        <div
                          class="d-flex justify-content-between credit-heading"
                          onClick={() =>
                            setInputfieldHide((prevValue) => {
                              return {
                                ...prevValue,
                                individualCredit: !prevValue.individualCredit,
                              };
                            })
                          }
                        >
                          <span>Individual Credits</span>
                          <span>
                            {inputfield["credits"]["individual_credit"].length}{" "}
                            Credit(s)
                          </span>
                        </div>
                        {
                          <div className="added-credits w-100">
                            <table className="w-100 d-flex flex-column">
                              {(
                                inputfield?.credits.individual_credit || []
                              ).map((data) => {
                                return (
                                  <tr className="d-flex justify-content-between w-100 row-style ">
                                    <td>{data!=undefined&&data.email}</td>
                                    <td>{data !=undefined&&data.name}</td>
                                    <td>{data!=undefined&&data.role}</td>
                                    <td style={{cursor:"pointer"}} onClick={()=>deletecredit("individualCredit",data)} >delete</td>
                                  </tr>
                                );
                              })}
                            </table>
                          </div>
                        }
                        <section
                          className={`${
                            key.customeSectionStyle
                              ? key.customeSectionStyle
                              : ""
                          } inputs`}
                        >
                          {key.individualCreditLabel.map((input_tag, index) => (
                            <div className="d-flex align-items-start flex-column mr-2 pt-3">
                              <span>{input_tag}</span>
                              <CreditInput
                                inputId={`individualCredit-${key.individualCredit[index]}`}
                                inputName={key.individualCredit[index]}
                                addCredits={addCredits}
                                preSetInput={individualCredit&&individualCredit[key.individualCredit[index]]?(individualCredit[key.individualCredit[index]]):""}
                                from={"individualCredit"}
                                resetCredit={resetCredit}
                                dropDownData={key.individualCreditdata[key.individualCredit[index]]}
                              />
                            </div>
                          ))}
                          {key.inputs.length !== 0 ? (
                            <button
                              className="btn-style pt-4"
                              onClick={() =>
                                individualCredit&&individualCredit.email&&individualCredit.name&&individualCredit.role&&setCredits(
                                  "individualCredit",
                                  key.individualCredit
                                )
                              }
                            >
                              Add
                            </button>
                          ) : (
                            <></>
                          )}
                        </section>
                      </div>
                      <div className="d-flex flex-column credit-div">
                        <div
                          class="d-flex justify-content-between credit-heading"
                          onClick={() =>
                            setInputfieldHide((prevValue) => {
                              return {
                                ...prevValue,
                                agencyCredit: !prevValue.agencyCredit,
                              };
                            })
                          }
                        >
                          <span>Agency Credits</span>
                          <span>
                            {inputfield["credits"]["agency_credit"].length}{" "}
                            Credit(s)
                          </span>
                        </div>
                        {
                          <div className="added-credits w-100">
                            <table className="w-100 d-flex flex-column">
                              {(inputfield?.credits.agency_credit || []).map(
                                (data) => {
                                  return (
                                    <tr className="d-flex justify-content-between w-100 row-style">
                                      <td>{data.name}</td>
                                      <td>{data.role}</td>
                                      <td>{data.agencyWebsite}</td>
                                      <td style={{cursor:"pointer"}} onClick={()=>deletecredit("agencyCredit",data)}  >delete</td>
                                    </tr>
                                  );
                                }
                              )}
                            </table>
                          </div>
                        }
                        <section
                          className={`${
                            key.customeSectionStyle
                              ? key.customeSectionStyle
                              : ""
                          } inputs`}
                        >
                          {key.agencyCreditLable.map((input_tag, index) => (
                            <div className="d-flex align-items-start flex-column mr-2 pt-3">
                              <span>{input_tag}</span>
                              <CreditInput
                                inputId={`agencyCredit-${key.agencyCredit[index]}`}
                                inputName={key.agencyCredit[index]}
                                addCredits={addCredits}
                                preSetInput={agencyCredit?agencyCredit[key.agencyCredit[index]]:""}
                                from={"agencyCredit"}
                                resetCredit={resetCredit}
                                dropDownData={key.agencyCreditData[key.agencyCredit[index]]}
                              />
                            </div>
                          ))}
                          {key.inputs.length !== 0 ? (
                            <button
                              className="btn-style pt-4"
                              onClick={() => 
                                agencyCredit&&agencyCredit.name&&agencyCredit.role&&agencyCredit.agencyWebsite&&setCredits("agencyCredit", key.agencyCredit)
                              }

                            >
                              Add
                            </button>
                          ) : (
                            <></>
                          )}
                        </section>
                      </div>
                      <div className="save_delete_button">
                        <button onClick={() => discardSection(key.name)}>
                          Discard Section
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {key.showImagesUpload && (
              <MediaComponent
                name={key.name}
                setCaseStudyFormData={props.setCaseStudyFormData} 
                caseStudyForm={props.caseStudyForm}
              />
            )}
          </>
        ))}
      </div>
    </div>
  );
};
export default SecondCaseStudyPortfolioComponent;
