import React, { useEffect, useState } from "react";
import FirstComponent from "../CaseStudyPortfolioComponents/firstCaseStudyPortfolioCompo/firstCaseStudyPortfolioCompo";
import SecondComponent from "../CaseStudyPortfolioComponents/secondCaseStudyPortfolioCompo/secondCaseStudyPortfolioCompo";
import axios from "../../axios";
import "./CaseStudyPortfolio.css";
import CaseStudyListComponent from "../CaseStudyPortfolioComponents/CaseStudyListComponent/CaseStudyListComponent";

const CaseStudyPortfolio = (props) => {
  const [agencyDetail, setAgencyDetail] = useState();
  const [userDetails, setUserDetails] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const agencyresponse = await axios.get(`/getDistinctAgencies`);
      setAgencyDetail(agencyresponse.data);
      const userResponse = await axios.get(`/credits/getDistinctEmailAndName`);
      setUserDetails(userResponse.data);
    };
    fetchData();
  }, []);

  const init = {
    agencyId: props.createdBy==="brand"?"":props.agencyId,
    agencyName: props.createdBy==="brand"?"":props.agencyName,
    projectTitle: "",
    brand: props.createdBy!=="brand"?"":props.brandName,
    services: [],
    industries: [],
    overview: {
      text: "",
      imgType: "",
      image: [],
      videos: [],
    },
    challenges: {
      text: "",
      imgType: "",
      image: [],
      videos: [],
    },
    research: {
      text: "",
      imgType: "",
      image: [],
      videos: [],
    },
    process: {
      text: "",
      imgType: "",
      image: [],
      videos: [],
    },
    results: {
      text: "",
      imgType: "",
      image: [],
      videos: [],
    },
    statistics: [],
    testimonial: {
      text: "",
      data: [],
    },
    credits: {
      individual_credit: [],
      agency_credit: [],
    },
    awards: [],
    news_mention: [],
    partner_certificate: [],
    coverImg: "",
  };

  const [caseStudyForm, setCaseStudyForm] = useState(init);
  const [caseStudyList, setCaseStudyList] = useState([]);
  const [showCaseStudyForm, setShowCaseStudyForm] = useState(false);
  const [editCaseStudyId, setEditCaseStudyId] = useState();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if(props.createdBy!=="brand"){
      getnewCaseStudyByAgencyId();
    }else{
      getnewCaseStudyByBrandname();
    }
  }, []);

  useEffect(() => {
    if (editCaseStudyId) {
      getCaseStudyByCaseStudyId();
    }
  }, [editCaseStudyId]);

  const setCaseStudyFormData = (data) => {
    setCaseStudyForm(data);
    // let str = JSON.stringify(caseStudyForm, null, 4); // (Optional) beautiful indented output.
  };

  const updateCaseStudy = async () => {
    try {
      setDisabled(true);
      const res = await axios.post(
        "/agencyOnboarding/newcaseStudy",
        {...caseStudyForm,createdBy:props.createdBy==="brand"?"brand":"agency"}
      );
      if (!caseStudyForm._id) {
        setCaseStudyList([...caseStudyList, res.data]);
      }
      setDisabled(false);
      setCaseStudyForm(init);
      setShowCaseStudyForm(false);
      setEditCaseStudyId(null);
      if(props.createdBy!=="brand"){
        getnewCaseStudyByAgencyId();
      }else{
        getnewCaseStudyByBrandname();
      }
      
    } catch (err) {
      setDisabled(false);
    }
  };

  const getCaseStudyByCaseStudyId = async () => {
      const res = await axios.post("/agencyProfile/getNewCaseStudyById", {
        id: editCaseStudyId,
        createdBy:props.createdBy==="brand"?"brand":"agency"
      });
      if (res.status === 200) {
        setCaseStudyForm(res.data.caseStudies);
      }
  
    
  };

  const discardChanges = () => {
    setCaseStudyForm(init);
    setShowCaseStudyForm(false);
    setEditCaseStudyId(null);
  };

  const getnewCaseStudyByAgencyId = async () => {
    const res = await axios.post("campaigns/getnewCaseStudyByAgencyId", {
      agencyId: props.agencyId,
      requestedFromDashboard:true
    });
    if (res.status === 200) {
      setCaseStudyList(res.data);
    }
  };

  const getnewCaseStudyByBrandname = async () => {
    const res = await axios.post("campaigns/getnewCaseStudyByBrandName", {
      brandName: props.brandName,
    });
    if (res.status === 200) {
      let caseStudies=res.data;
      caseStudies=caseStudies.filter((key)=>(key.createdBy==="brand"))
      setCaseStudyList(caseStudies); 
      
    } 
  };

  return (
    <>
      <CaseStudyListComponent
        caseStudyList={caseStudyList}
        setShowCaseStudyForm={setShowCaseStudyForm}
        getnewCaseStudyByAgencyId={getnewCaseStudyByAgencyId}
        setEditCaseStudyId={setEditCaseStudyId}
      />
      {showCaseStudyForm && (
        <>
          <FirstComponent
            setCaseStudyFormData={setCaseStudyFormData}
            caseStudyForm={caseStudyForm}
            clients={props.agencyClients}
            services={props.agencyServices}
            createdBy={props.createdBy}
          />
          <SecondComponent
            agencyDetail={agencyDetail}
            userDetails={userDetails}
            setCaseStudyFormData={setCaseStudyFormData}
            caseStudyForm={caseStudyForm}
            agencyName={props.createdBy==="brand"?"":props.agencyName}
            // createdBy={props.createdBy}
          />
          <div className="update_discard_button">
            <button onClick={() => updateCaseStudy()} disabled={disabled}>
              Publish Case Study
            </button>
            <button onClick={() => discardChanges()}>Discard Changes</button>
          </div>
        </>
      )}
    </>
  );
};

export default CaseStudyPortfolio;
