import React, {useEffect, useState} from "react";
import "../../styles/BNewProject.css";
import { Link, useHistory } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import calendericon from "../../images/calendericon.png";
import axios from "../../axios.js";
import { Today } from "@material-ui/icons";

function BNewProject() {
  const history = useHistory();

  useEffect( () => {
    if( localStorage.getItem("branduser") === null ) {
      history.push('/brandlogin');
      return <></>;
    }
  }, [] )

  const [cOpen, setCOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [addNewProject, setaddNewProject] = useState({
    projectName: "",
    type: "",
    budget: {
      min: "",
      max: "",
    },
    isAssistanceNeeded: "",
    deadline: "",
  });


  const ondatechange = date => {
    setDate(date);
    setCOpen(prev => setCOpen(!prev));
  };
  const openCalender = () => {
    setCOpen(prev => setCOpen(!prev));
  };

  const gotoprojectbrief = async () => {
    // history.push("/newprojectbrief");
    var newProject = {
      brandId: JSON.parse(localStorage.getItem('branduser')).user.brandId,
      projectName: addNewProject.projectName,
      type: addNewProject.type,
      budget: addNewProject.budget,
      isAssistanceNeeded: addNewProject.isAssistanceNeeded,
      // deadline: addNewProject.deadline,
      deadline: date
    };
    console.log(newProject)
    
    try {
          if(!newProject.projectName ||
             !newProject.isAssistanceNeeded || 
             !newProject.type ||
             !newProject.budget.min){
               throw new Error("All Fields are Mandatory")
             }
      const response = await axios.post("/brands/addNewProject/", newProject);


      if(response.status === 200) {
        console.log('ok', response);
        localStorage.setItem("projectId", JSON.stringify(response.data));
        history.push({
          pathname: '/newprojectbrief',
          state: { detail: addNewProject }
      })
      }
    } catch (err) {
      window.alert(err);
      console.log(err);
    }
    
    ;


  };

  const changeHandler = event => {
    const { name, value } = event.target;
    setaddNewProject(prev => {
      return {
        ...prev,
        [name]: value
      };
    });

  console.log('check data', addNewProject);
  };  
  const changeBudget = event => {
    const value = JSON.parse(event.target.value);
    setaddNewProject(prev => {
      return {
        ...prev,
        budget: { ...prev.budget, min: value.min, max:value.max }
      };
    });

  }; 

  return (
    <div className="bnewproject">
      <div className="level">
        <p className="labell">What would you like to name your project?</p>
        <input 
        className="inputt" 
        type="text" 
        onChange={changeHandler}
        name="projectName"
        value={addNewProject.projectName}
        required={true}
        />
      </div>
      <div className="level">
        <p className="labell">How are you going to post the project?</p>
        <div className=" radio__inp__main__cont flxs">
          <div className="radio__inp__cont2">
            <div className="radio__inp__cont">
              {" "}
              <input
                className="input adgrey"

                onChange={changeHandler}
                name="type"
                value="open"
                id="open"
                type="radio"
              ></input>{" "}
              &nbsp;
              <label htmlFor="open" className="para fw__600">
                Open
              </label>
            </div>
            <p className="para adgrey">
              Just post a brief & all relevant agencies will be notified
            </p>
          </div>
          <div className="radio__inp__cont2">
            <div className="radio__inp__cont">
              <input
                className="input adgrey"
                onChange={changeHandler}
                name="type"
                value="request"
                id="request"
                type="radio"
              ></input>{" "}
              &nbsp;
              <label htmlFor="request" className="para fw__600">
                Request
              </label>
            </div>
            <p className="para adgrey">
              Request for Proposal only from your shortlisted Agencies
            </p>
          </div>
        </div>
      </div>
      <div className="level">
        <p className="labell">What is your budget range for this project?</p>
        <div className="selecttextcont">
          <select className="selectt" name="budget" id="budget" onChange={changeBudget}>
            <option >Select Budget</option>
            <option value={JSON.stringify({min : '25000', max : '50000'})}>₹25000-₹50000</option>
            <option value={JSON.stringify({min : '50000', max : '100000'})}>₹50000-₹100000</option>
          </select>
          <p className="para adgrey">
            This will help agencies to come up with ideas that fir your budget.{" "}
          </p>
        </div>
      </div>
      <div className="level">
        <p className="labell">
          Would you like assistance in your agency search?
        </p>
        <div className=" radio__inp__main__cont bigcolgap flxs">
          <div className="radio__inp__cont2">
            <div className="radio__inp__cont">
              {" "}
              <input
                className="input adgrey"
                id="appreciate"
                name="isAssistanceNeeded"
                value="true"
                type="radio"
                onChange={changeHandler}
              ></input>{" "}
              &nbsp;
              <label htmlFor="appreciate" className="para fw__600">
                Yes, I will appreciate the help
              </label>
            </div>
          </div>
          <div className="radio__inp__cont2">
            <div className="radio__inp__cont">
              <input
                className="input adgrey"
                id="itsok"
                name="isAssistanceNeeded"
                value="false"
                type="radio"
                onChange={changeHandler}
              ></input>{" "}
              &nbsp;
              <label htmlFor="itsok" className="para fw__600">
                It’s okay, I think I will do it myself
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="level">
        <p className="labell">Set a deadline to receive proposals</p>
        <div className="datecalendercont ">
          <div className="datecalender" onClick={openCalender}>
            {moment(date).format("DD/MM/YYYY")}{" "}
            <img src={calendericon} alt="" />
          </div>
          {cOpen && <Calendar onChange={ondatechange} value={date} />}

          {/* {console.log(date)} */}
        </div>
      </div>
      <div className="dflex  justify__end">
        <p
          className="para color__orange cursor__pointer"
          onClick={gotoprojectbrief}
        >
          Create the Project Brief 
        </p>
      </div>
    </div>
  );
}

export default BNewProject;
