import React from 'react';
import './SecondaryFooter.css';

const SecondaryFooter = () => {
    return (
        <div className='secondary-footer'>
            <span className='text1'>Stil confused about which agency to hire?</span>
            <span className='text2'>Get end-to-end <span className='text2_1'>agencies search assistance </span> with <span className='text2_2'>UNISPADE ASSIST</span></span>
        </div>
    )
}

export default SecondaryFooter;