
import React, { useState, useEffect, useContext, useRef, Component } from "react";
import "../../styles/AEditProfile.css";
import "../../styles/Autosuggest.css";
import NavbarEditProfile from "./NavbarEditProfile";
import redcross from "../../images/redcross.png";
import ASidebarAlt from "./BSidebarAlt";
import authHeader from "../../login-auth.js";
import { useHistory } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import axios from "../../axios.js";
import RatingStars from "../RatingStars";
import ReactS3 from "react-s3";
import Autosuggest from 'react-autosuggest';
import CaseStudyPortfolio from '../CaseStudyPortfolio/CaseStudyPortfolio';
// import { uploadImage } from '../../common/service'
import AWS from 'aws-sdk';
import DeleteImage from "../../images/delete.png"
import {uploadImage} from "../../uploadImage"

// const bucket = new AWS.S3({
//   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
//   secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
//   region: process.env.REACT_APP_AWS_REGION
// });

// async function uploadImage(params) {
//   return new Promise((resolve, rejatc) => {
//     bucket.upload(params, function (err, data) {
//       if (err) {
//           console.log('There was an error uploading your file: ', err);
//           return false;
//       }
//       resolve(data.Location)
//   });
//   })
// }

function AEditProfile({brandData,logoUpdate}) {


  const history = useHistory();
  const params = useParams();
  let location = useLocation();
  const [uploadbtn, setUploadbtn] = useState(false);
  const [uploadBtnStatus, setUploadBtnStatus] = useState(false);
  const info = authHeader();
  const [awards, setAwards] = useState([]);
  const [basicBranDetails, setBasicBrandDetails] = useState();


  const [allIndustries, setAllIndustries] = useState([]);
  const [newsMentions, setNewsMentions] = useState([]);
  const [locationBoxes, setLocationBoxes] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);

  const [serviceBoxes, setServiceBoxes] = useState([
    { no: 1, name: 0, check: false },
    { no: 2, name: 1, check: false },
    { no: 3, name: 2, check: false },
  ]);
  const [caseStudies, setCaseStudies] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [canSelect, setCanSelect] = useState(true);
  const [services, setServices] = useState([]);

  const [clients, setClients] = useState([{}]);
  const [flag, setFlag] = useState(0);



  const [BrandData,setBrandData]=useState(brandData)
 
  const [yearOptions, setYearOptions] = useState([]);
  const [focusNav, setFocusNav] = useState("basicinfo");
  




  const listenScrollEvent = (event) => {
    const sections = document.querySelectorAll('div[id]');
    let currentId = '';

    for (let index = 1; index < sections.length; index++) {
      let sectionTop = sections[index].offsetTop;
      let sectionHeight = sections[index].clientHeight;

      if (window.scrollY >= (sectionTop - sectionHeight / 3)) {
        currentId = sections[index].getAttribute('id');
      }
    }
    if (currentId) setFocusNav(currentId);

  };


  useEffect(() => {
    var currentYear = new Date().getFullYear();
    var years = [];
    var startYear = 1900;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    setYearOptions(years);
    window.addEventListener("scroll", listenScrollEvent);


    return () => {
      setUploadBtnStatus(false);
      window.removeEventListener("scroll", listenScrollEvent);
    }
  }, []);
 

  // useEffect(() => {
    
  //   const fetchAwards = async () => {
  //     try {
  //       const res = await axios.post(
  //         "/agencyEditProfile/getAllAwards",
  //         { agencyId: params._id ? params._id : authHeader().User.agencyId },
  //         {
  //           headers: { authorization: "Bearer " + authHeader().Token },
  //         }
  //       );

  //       setAwards(res.data[0].awardDetails);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };

  //   const fetchNewsMentions = async () => {
  //     try {
  //       const res = await axios.post(
  //         "/agencyProfile/getNewsMentions",
  //         { agencyId: params._id ? params._id : authHeader().User.agencyId },
  //         {
  //           headers: { authorization: "Bearer " + authHeader().Token },
  //         }
  //       );
  //       setNewsMentions(res.data);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };

  //   fetchAwards();
  //   fetchNewsMentions();
  //   const fetchAllIndustries = async () => {
  //     try {
  //       const res = await axios.get(
  //         "/superAdmin/filters/getAllIndustries",
  //         {
  //           headers: { authorization: "Bearer " + authHeader().Token },
  //         }
  //       );
  //       setAllIndustries(res.data.selectedIndustries);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };
  //   fetchAllIndustries();
  // }, []);

  useEffect(() => {
    var ct = 0;
    serviceBoxes.forEach((b) => {
      if (b.check == true) ct++;
      if (ct >= 3) setCanSelect(false);
    });
    if (ct >= 3) setCanSelect(false);
    else setCanSelect(true);
  }, [serviceBoxes, flag]);


  var checklocArrlength;
  const [err, seterr] = useState(false);


  const addMoreLocation = () => {


    setBasicBrandDetails((prev) => {
      seterr(false)

      checklocArrlength = prev.locations.length
      //if(prev.locations.length != )
      //prev.locations.push("");
      return prev;
    });

    setLocationBoxes((prev) => {

      const l = prev.length;
      var newAr = [...prev, parseInt(prev[l - 1]) + 1];
      // prev.forEach((i, ind) => {
      //   newAr.push(l - ind);


      // });
      // newAr.push(l + 1);
      if (newAr.length > checklocArrlength) {
        seterr(true)
      }
      else {
        seterr(false)
      }
      return newAr;
    });

  };
 

  const getAllClients = async () => {
    // const res = await axios.post(
    //   "/getClientsbyAgencyId",
    //   { agencyId: params._id ? params._id : authHeader().User.agencyId },
    //   {
    //     headers: { authorization: "Bearer " + authHeader().Token },
    //   }
    // );
    // setClients(res.data[0].agencyClients);
  };

  useEffect(() => {
    try {
      const getInfo = async () => {
        const id = params._id ? params._id : authHeader().User.agencyId;
        const res = await axios.post(
          "/agencyOnboarding/getAgencyById",
          {
            id,
          },
          { headers: { authorization: "Bearer " + authHeader().Token } }
        );
   
        const sortedServices = res?.data?.services.sort(caseInsensitiveSort);
        setServices(sortedServices);
         
        const temp1 = [];
        // { no: 1, name: 0, check: false },
        sortedServices.forEach((p, i) => {
          temp1.push({ no: i + 1, name: i, check: i < 3 ? true : false });
        });
        setServiceBoxes(temp1);
        setBasicBrandDetails({
          locations: BrandData.locations,
          
        });
        const temp = [];
        res.data.locations.forEach((p, i) => {
          temp.push(i + 1);
        });
        setLocationBoxes(temp);
      };

      const getLocationOptions = async () => {
        const res = await axios.get("/superAdmin/filters/getAllLocations", {
          headers: { authorization: "Bearer " + authHeader().Token },
        });
        setLocationOptions(res.data);
      };
      const getCaseStudyById = async () => {
        const res = await axios.post(
          "/agencyProfile/getCaseStudyById",
          { agencyId: params._id ? params._id : authHeader().User.agencyId },
          { headers: { authorization: "Bearer " + authHeader().Token } }
        );
        if (res.data != null) {
          setCaseStudies(res.data.caseStudies);
        }
      };
    
      const getAllServices = async () => {
        const res = await axios.get("/superAdmin/filters/getAllServices", {
          headers: { authorization: "Bearer " + authHeader().Token },
        });
        const sortedServices = res?.data?.selectedServices.sort(caseInsensitiveSort);
        setServiceOptions(sortedServices);
      };

      const caseInsensitiveSort = (a, b) => {
        var ret = 0;
        a = a.toLowerCase(); b = b.toLowerCase();
        if (a > b)
          ret = 1;
        if (a < b)
          ret = -1;
        return ret;
      }

      getAllClients();
      getAllServices();
      getLocationOptions();
      // getInfo();
      // getCaseStudyById();
    } catch (e) {
      console.log(e);
    }
  }, []);
  

  const basicDetailsChangeHandler = (event) => {
    const { value, name } = event.target;
    if (!name.includes("locations")) {
      setBasicBrandDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    } else {
      const ind = name.split("locations");
      setBasicBrandDetails((prev) => {
        prev.locations[ind[1]] = value;
        return prev;
      });
   
    }
  };

  const submitHandler = async () => {
    // loading icon starts
    setUploadbtn(true);
    // loading icon ends
    //console.log(basicBranDetails);
    let clientName = [];
    if (clients) {
      clients.map((c, i) => {
        clientName.push(c.clientLogo);
      })
    }


    try {
      // var temp = [...new Set(basicBranDetails.locations)];
      // temp = temp.filter((l) => l !== "");
      // setBasicBrandDetails((prev) => {
      //   prev.locations = temp;
      //   return prev;
      // });
      // const topServices = [];
      // serviceBoxes.forEach((s, i) => {
      //   if (s.check) topServices.push(services[i]);
      // });
      // const topWithoutDouble = [...new Set(topServices)];
      // const servicesWithoutDup = [...new Set(services)];
      // const servicesToSave = servicesWithoutDup.filter((s) => s !== "");
      // const finalServices = [];
      // topWithoutDouble.forEach((d) => {
      //   finalServices.push(d);
      // });
      // servicesToSave.forEach((s) => {
      //   finalServices.push(s);
      // });
      // const servicesToSend = [...new Set(finalServices)];
      const res = await axios.post(
        "/brands/BrandEditProfile",
        {
          id: BrandData._id,
          updateProfile: basicBranDetails
        },
      );
      setUploadbtn(false);
      setUploadBtnStatus(true);

    } catch (e) {
      console.log(e);
    }
  };

  const removeLocationHandler = (locationIndex, locationName) => {
    let locationArr = [...locationBoxes];
    let filteredLocationBoxes = locationArr.filter((locationInd) => {
      return locationInd !== locationIndex
    })
    setLocationBoxes(filteredLocationBoxes)

    setBasicBrandDetails(prev => {
      let filteredLocationNames = prev.locations.filter(name => {
        return name !== locationName;
      })
      prev.locations = filteredLocationNames;
      return prev;
    })
    seterr(false)
  }


  const [BrandLogo,setBrandLogo]=useState();
  const [submitlogo, setsubmitlogo] = useState(false);
  const [progress,setProgress]=useState(0);
  const [profilePhotoName,setProfilePhotoName]=useState()
  // const ChangeBrandLogo = async (e) => {
  //   const { value, name } = e.target;
  //   if (name === 'clientLogo') {
  //     try {
  //       const uniqueKey = new Date().getTime();
  //       const fileName = uniqueKey + '-' + e.target.files[0].name;
  //       setProfilePhotoName(fileName);

  //       const params = {
  //         Bucket: 'unispade-react-js-s3',
  //         Key: 'clientMedia/' + fileName,
  //         Body: e.target.files[0],
  //         ContentType: e.target.files[0].type,
  //       };

  //       // Replace the `uploadImage` function with your actual implementation
  //       const fileUrl = await uploadImage(params);
  //       setBrandLogo(fileUrl);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  // };

  const ChangeBrandLogo = async (e) => {
    setProfilePhotoName(e.target.files[0].name);
    const res=await uploadImage(e.target.files[0],"/brandProfile")
    setBrandLogo(res);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        const updatedProgress = prevProgress + 10;
        if (updatedProgress >= 100) {
          clearInterval(intervalId); // Stop the interval when progress reaches or exceeds 100
          return 100;
        }
        return updatedProgress;
      });
    }, 700);

    return () => {
      clearInterval(intervalId); // Cleanup the interval when the component unmounts
    };
  }, []);

  
  const updateBrandLogo = async (e) => {
    setsubmitlogo(true);
    let res=await logoUpdate(BrandLogo)
    if(res.data.status){
      setsubmitlogo(false)
      setProfilePhotoName()
    }else{ 

    }
    
  };
  const uploadButtonHandler = (field) => {
    let elem = document.getElementById('profileImage');
    if (elem) elem.click();
}
 

  return (
    <div className="aeditprofile">

      
      <ASidebarAlt />
      <div className="section__cont">
        <div className="section">
          <div className="part__left">
            <h1 className="aeditprofile__title text__left">Edit Profile</h1>
          </div>
          <div className="part__right">

            <p className="date__day para adgrey"> </p>
            <button
              style={{ background: "#191E22", color: "#FFFFFF", border: "none", padding: "5px 10px" }}
              className=""
              type="submit"
              name="action"
              id="navbar"
              onClick={() => {

                history.push('/')
                localStorage.clear("user")
                window.location.reload()

              }
              }>Log Out

            </button>
          </div>
        </div>

      </div>
      {/* Basic and contact details */}

      <NavbarEditProfile currentFocus={focusNav}></NavbarEditProfile>
      {/*  */}
      <div style={{ marginTop: "40px" }}>
        <div className="basdetails" id="basicinfo">
          <p className="para text__left fw__600">Basic Brand Details</p>
          <div className="basdetails__data">
            <div className="basdetails__data__forms">
              <form className="form">
                <div className="inp__fields flx6">
                  <label className="para">Brand Name</label>
                  <input
                    onChange={basicDetailsChangeHandler}
                    name="brandName"
                    className="input adgrey"
                    placeholder="Brand name"
                    type="text"
                    defaultValue={BrandData?.brandName}
                  ></input>
                </div>
                <div className="inp__fields flx6">
                  <label className="para">Year Established</label>
                  <select
                    name="foundedYear"
                    className="select "
                    onChange={basicDetailsChangeHandler}
                  >
                    <option value="">Select a year</option>
                    {yearOptions.map((y) => (
                      <option value={y}>{y}</option>
                    ))}
                  </select>
                </div>
                <div className="inp__fields flx6">
                  <label className="para">Employees</label>
                  <input
                    name="brandSize"
                    onChange={basicDetailsChangeHandler}
                    className="input adgrey"
                    placeholder="0-20"
                    // defaultValue={BrandData?.brandSize}
                  ></input>
                </div>
                <div className="inp__fields flx6">
                  <label className="para">Website</label>
                  <input
                    name="brandWebsite"
                    onChange={basicDetailsChangeHandler}
                    className="input adgrey"
                    placeholder="https://www.redagency.com"
                    defaultValue={BrandData?.brandWebsite}
                  ></input>
                </div>
              </form>
              <form className="form wfixform">
                {locationBoxes.map((item, index) => (
                  <div className="inp__fields flx6 wfixinp__cont" key={"loc" + item + index}>
                    <div className="labelcrosscont labelStyle">
                      <label className=" labelf para labelfw">
                        {" "}
                        Office Location {index == 0 ? " 1 (Headquarter)" : index + 1}{" "}
                        {item !== 1 && <img src={redcross} className="redcross" alt="" onClick={() => removeLocationHandler(item, basicBranDetails.locations[index])} />}
                        {/* <input type="checkbox"></input> */}

                      </label>
                    </div>
                    <select
                      id="select-location"
                      value={basicBranDetails.locations[index]}
                      className="select adgrey wfixinp"
                      onChange={basicDetailsChangeHandler}
                      name={"locations" + index}
                      onClick={() => seterr(false)}
                    >
                      <option value="">Select a Location</option>
                      {locationOptions.map((loc, index) => (
                        <option value={loc} key={"opt-" + loc + index}>{loc}</option>
                      ))}
                    </select>
                  </div>
                ))}
              </form>


              <div className="dflex justify__between">

                {!err ? <p
                  className="para color__black fw__600 cursor__pointer"
                  onClick={addMoreLocation}
                //onClick = {console.log(seterr)}
                >
                  + Add More Locations
                </p> : <p
                  className="para color__black fw__600 cursor__pointer"
                  style={{ color: "grey" }}

                > + Add More Locations</p>}
              </div>

              <div className="profilePitchure" style={{textAlign:"left"}}>
            <div className="box" style={{background:"#F8F8F8",border:"1px solid lightgrey",borderRadius:"3.5px",display:"flex",flexDirection:"column",padding:"20px 0",minHeight:profilePhotoName?"160px":"80px"}}>
              <div className="inputField" style={{padding:"8px 25px",background:"rgb(255, 255, 255)",border:"1px solid lightgrey",borderRadius:"3.5px",display:"flex",justifyContent:"space-between",width:"98%",margin:"0 auto"}}>
                <p className="Tittle" style={{margin:"auto 0",color:"#6E6E6E",fontWeight:"700",fontSize:"14px"}}>Update Logo</p>
                <button  onClick={uploadButtonHandler}  style={{margin:"auto 0",color:"#191E22",border:"transparent",background:"transparent" ,width:"50px"}}>Add</button>
            </div>
            <input
                
                style={{width:"50px",margin:"-65px  40px 0 auto",visibility:"hidden",cursor:"pointer"}}
                multiple="single"
                type="file"
                onChange={(e) => {
                  ChangeBrandLogo(e);
                }}
                name="clientLogo"
                id="profileImage"
              ></input>

             {profilePhotoName && <div style={{justifyContent:"center",margin:"auto",display:"flex",gap:"20px",width:"100%",marginBottom:"0"}} >
              <img style={{width:"50px",maxHeight:"100px",borderRadius:"15px"}} src={BrandLogo?BrandLogo:"anything"} alt="" />
              <div className="progressBar" style={{margin:"auto 0",width:"80%",borderBottom:"2px solid #6E6E6E"}}>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                  <p style={{color:"#6E6E6E"}}>{profilePhotoName}</p>
                  <img style={{height:"20px"}} onClick={()=>{setBrandLogo();setProfilePhotoName()}} src={DeleteImage} alt="" />
                </div>
                
                <p style={{position:"relative",margin:"auto 0",marginTop:"2px",width:`${progress}%`,height:"4px",background:"#ff6573",zIndex:"2"}}  ></p></div>
              </div>}
          </div>
          {/* <button>Update Profile</button> */} 
          {(BrandLogo && profilePhotoName)&&<button
            style={{ background: "rgba(255, 0, 0, 0.1)", color: "#FFFFFF", border: "none", padding: "5px 10px",marginTop:"20px"}}
            type="button"
            onClick={() => {
              updateBrandLogo();
            }}
            name="clientLogo"
            value="Update Logo"
          > {submitlogo && (
            <i className="fas fa-circle-notch donebtnclient"></i>
          )}
            {submitlogo && "Updating"}
            {!submitlogo && "Update Logo"}</button>}
          <div className="btn">
         
          

          

        </div>
        </div>

            </div>
          </div>
        </div>
        
      </div>
      {/* Case Study */}
      <div id="Casestudies" style={{backgroundColor:'#fff', display: 'flex', flexDirection: 'column'}}>
        <CaseStudyPortfolio
          // agencyId={"6228f307f811959ec006df70"}
          // agencyName={"Schbang"}
          brandName={BrandData.brandName}
          createdBy={"brand"}
          agencyClients={clients}
          agencyServices={services}
        />
      </div>
      {/*   */}
      {/* Awards */}
      <div className="mainupload__btn__cont">
        <button onClick={submitHandler} className="mainupload__btn">
          {uploadbtn && <i className="fas fa-circle-notch donebtnclient"></i>}

          {uploadbtn ? "Updating" : uploadBtnStatus ? "Updated" : "Update Profile"}
          {/* {!uploadbtn && "Update Profile"} */}
        </button>
      </div>
    </div>
  );
}

export default AEditProfile;