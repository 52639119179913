import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom"
import {useHistory} from "react-router-dom"
import axios from "../../axios.js";

function ABuisCard({ type, project }) {
    //////////// Switch tab functionality starts//////////
    
  const switchTabs = e => {
    const tabItem = e.target;

    // For btn activation
    const alltablinks = document.querySelectorAll(".babtns");

    alltablinks.forEach(link => {
      link.classList.remove("active"); //deactivating rest of the links
    });

    tabItem.classList.add("active"); //activating the link which is clicked

    //for component activation
    const destid = e.target.getAttribute("dest"); //getting the destination id which needs to be activated.
    const tabelement = document.getElementById(destid); //element which we need to activate

    const jobdescitems = document.querySelectorAll(".badatapart");

    jobdescitems.forEach(item => {
      item.classList.remove("activetab");
    });

    tabelement.classList.add("activetab"); //activating the link which is clicked
  };
    const [viewProject, setViewProject] = useState(false)
    const ViewProjectHandler = () => {
        setViewProject(true)
    }
    const CloseProjectHandler = () => {
        setViewProject(false)
    }
    const history=useHistory()
    const changeLink=()=>{
      history.push("/projectbrief")
      window.location.reload()
    }
    const [brandName, setBrandName] = useState("")

    // useEffect(() => {
    //   if(project){
    //     axios.post("/brands/getBrandNamebyBrandId", {
    //                       brandId: project.brandId                   
    //                       } )
    //     .then(response => {
    //       setBrandName(response.data[0].brandName)
    //       console.log("brand", response.data[0].brandName)
    //       console.log("bstate", brandName)
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    //   }
      
    // }, [])
    
    if(project){
      
      var type = project.type.toLowerCase()
    return (<div>
        {viewProject ?
        <>
          <div >
            <div className="res__card">
                <div className="res__card__left">
                    <div className="typebudget">
                        <p className={`${type} typebtn para adgrey`}>
                            {type === "leadform" ? "Lead Form" : type}
                        </p>
                        <p className="para adgrey">Budget: ₹ {project.budget.min} - {project.budget.max}</p>
                    </div>
                    <p className="text__left">
                        <span className="fw__600">{brandName ? brandName : "A brand"}</span> has requested proposal for
                        <span className="fw__600"> {project.projectName}</span> project

                    </p>
                    <div className="tags">
                        <p className="tag"> {project.services.join(' | ')}</p>
                    </div>
                </div>
                <div className="res__card__right">
                    <p className="viewproject opens para" onClick={CloseProjectHandler}>View Project</p>
                    <p className="pass opens para">Pass</p>
                    <p className="brandprofile opens para color__orange">Brand Profile</p>
                </div>

            </div>
            <div className="bri__att__cont">
              <div className="bri__att__top">
                <div className="part1">
                  <p
                    className=" babtns active briefbtn para"
                    onClick={switchTabs}
                    dest="briefTab"
                  >
                    Brief
                  </p>
                  <p
                    className=" babtns para"
                    onClick={switchTabs}
                    dest="attachmentTab"
                  >
                    Attachment(s)
                  </p>
                </div>
                {/* <div className="part1">
                  <p className=" babtns para">Chat</p>
                  <p className=" babtns sharebtn para">Share Proposal</p>
                  <p className="para width__100">
                    (<span className="fw__600">Edit proposal</span> if saved
                    halfway)
                  </p>
                </div> */}
              </div>
              <div
                className="badatapart brief__data dnoneall activetab"
                id="briefTab"
              >
                <div className="dflex justify__between">
                  {/* <Link to ="/projectbrief" > */}
                    
                  <p className="para color__orange cursor__pointer"
                    onClick={()=> changeLink()}
                  >
                    View brief in full page
                  </p>
                  {/* </Link> */}
                </div>
                <h4>Project Brief</h4>
                <p className="para">
                  {project.projectBrief ? project.projectBrief : "-"}
                </p>
                {project.projectOverview ?
                        <div>
                          <h4>Project Overview</h4>
                          <p className="para">
                          {project.projectOverview}
                          </p>
                        </div>
                        :
                        <div></div>
                }
                {" "}
              </div>
              <div
                className="badatapart assign__data dnoneall"
                id="attachmentTab"
              >
                <div className="assign__task">
                  <p className="para">Attachment 1</p>
                  <p className="para">11 Nov 2020</p>
                </div>
                <div className="assign__task">
                  <p className="para">Attachment 2</p>
                  <p className="para">11 Nov 2020</p>
                </div>
              </div>
            </div>
            </div>
            </>
            : <div className="res__card"  >
                <div className="res__card__left">
                    <div className="typebudget">
                        <p className={`${type} typebtn para adgrey`}>
                            {type === "leadform" ? "Lead Form" : type}
                        </p>
                        { project.budget ?
                          <p className="para adgrey">Budget: ₹ {project.budget.min} - {project.budget.max}
                          </p> 
                          : <></>
                        }   
                    </div>
                    <p className="text__left">
                        <span className="fw__600">{brandName ? brandName : "A brand"}</span> has requested proposal for
                        <span className="fw__600"> {project.projectName}</span> project
                      

                    </p>
                    <div className="tags">
                        <p className="tag"> {project.services.join(' | ')}</p>
                    </div>
                </div>
                <div className="res__card__right">
                    <p className="viewproject opens para" onClick={ViewProjectHandler}>View Project</p>
                    <p className="pass opens para">Pass</p>
                    <p className="brandprofile opens para color__orange">Brand Profile</p>
                </div>

            </div>}
    </div>

    );
              } else {
                  return (
                    <h1> Loading ... </h1>
                  )
              }

}

export default ABuisCard;