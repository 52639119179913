import axios from "../../axios.js";
import React, { useEffect, useState } from "react";
import CaseStudyCard from "../CaseStudyListingComponents/CaseStudyCard/CaseStudyCard";
import Loader from "../loader/Loader.js";
import ReactPaginate from "react-paginate";

import "./CaseStudyListing.css";

const CaseStudyListing = () => {
  const pageSize = 50;
  const pageRangeDisplayed = window.innerWidth >= 1300 ? 8 : 4;
  const [caseStudies, setCaseStudies] = useState([]);
 

  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    const page=1    
    getData(page,pageSize);
  }, []);
  const handlePageClick = (page) => {
    getData(page.selected+1,pageSize)
  };
  const [totalCnt, setTotalCnt] = useState(0);
  const getData = async (page,pageSize) => {
    const {data} = await axios.post(
      "/agencyProfile/getAllNewCaseStudies",{
        page:page,
        pageSize:pageSize
      }
    );
    setCaseStudies(data.data);  
    setTotalPages(Math.ceil(data.totalCount/pageSize));
    setTotalCnt(data.totalCount);
    await window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
     
  };


  if(caseStudies.length>0){
    return (
      <div className="caseStudyListingPage">
        <div className="nopSection">
          <div className="numberOfProjects">
          <span>Total Case Studies:</span>{totalCnt}
          </div>
          
        </div>
        <div className="case_study__listing">
          <div className="case__studies">
            {caseStudies
              .map((caseStudy) => (
                <CaseStudyCard key={caseStudy._id} caseStudy={caseStudy} />
              ))}
          </div>
        </div>
        <ReactPaginate
          breakLabel="....."
          nextLabel=""
          onPageChange={handlePageClick}
          pageRangeDisplayed={pageRangeDisplayed}
          pageCount={totalPages}
          previousLabel=""
          renderOnZeroPageCount={null}
          className="pagination_sec"
          pageClassName="pagination_sec_li"
          breakClassName="pagination_sec_break"
          activeClassName="pagination_sec_active"
        />
      </div>
    );
  }else{
    return <Loader/>
  }
  
};

export default CaseStudyListing;
