import React, { useState, useContext, useEffect, useRef } from "react";
import { BsArrowDownLeft, BsArrowUpRight } from "react-icons/bs";
import { IoMdTimer } from "react-icons/io";
import { MdOutlineMessage, MdOutlineDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { FaInfo } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UpcomingContext } from "../../context/UpcomingContext"; // Import the context
import axios from "../../../../../axios.js";
import "./Requests.css";
import moment from "moment";
import { SignalCellularNoSimOutlined } from "@material-ui/icons";
import { toast } from "react-toastify";
import Loader from "../../../../loader/Loader.js";
import NewLoader from "../../../../loader/NewLoader/NewLoader.js";

const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

function Requests() {
  const [loader, setLoader] = useState(true);

  const [visibleDetailsId, setVisibleDetailsId] = useState(null);
  const [visibleRescheduleId, setVisibleRescheduleId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [requests, setRequests] = useState([]);

  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [requestBody, setRequestBody] = useState({});
  const [currentOpenedRequest, setCurrentOpenedRequest] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);

  const [maxBookingDays, setMaxBookingDays] = useState(null);
  const [noticePeriod, setNoticePeriod] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const toast_style = {
    position: "top-right",
    autoClose: 4000,
    pauseOnHover: true,
    draggable: true,
    theme: "light",
    width: "10rem",
  };

  const isMounted = useIsMounted();

  useEffect(() => {
    (async () => {
      if (selectedDate && currentOpenedRequest) {
        console.log("the current opened is ", currentOpenedRequest);
        const requestMentorId =
          currentOpenedRequest?.mentor_id?._id ||
          currentOpenedRequest?.mentor_id;

        console.log("the request mentor is ", requestMentorId);
        const { data } = await axios.get(
          `/mentoring/get-availability-by-date/${selectedDate}/${currentOpenedRequest?.duration}/${requestMentorId}`,
          {
            headers: {
              authorization: "Bearer " + Marketer.token,
            },
          }
        );
        if (isMounted.current && data) {
          console.log("the slot data is ", data);
          setTimeSlots(data);
        }
      }
    })();
  }, [selectedDate]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get("/mentoring/get-appointment-request/", {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      });

      if (isMounted.current) {
        setRequests(data);
        setLoader(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (currentOpenedRequest) {
        const requestMentorId =
          currentOpenedRequest?.mentor_id?._id ||
          currentOpenedRequest?.mentor_id;

        console.log("mentor id at set available days ", requestMentorId);

        const { data } = await axios.get(
          `/mentoring/get-general-availability/${requestMentorId}`,
          {
            headers: {
              authorization: "Bearer " + Marketer.token,
            },
          }
        );
        const the_days = data?.map((element) => element?.week_day);

        console.log("The days ", the_days);
        if (isMounted.current) {
          setAvailableDays(the_days);
        }
      }
    })();
  }, [currentOpenedRequest]);

  console.log("The selected date is ", selectedDate);
  useEffect(() => {
    (async () => {
      if (currentOpenedRequest) {
        const requestMentorId =
          currentOpenedRequest?.mentor_id?._id ||
          currentOpenedRequest?.mentor_id;
        const conditionData = await axios.get(
          `/mentoring/get-availability-conditions/${requestMentorId}`,
          {
            headers: {
              authorization: "Bearer " + Marketer.token,
            },
          }
        );

        console.log("The conditional data is ", conditionData?.data);
        if (isMounted.current) {
          setMaxBookingDays(conditionData?.data?.maximum_booking_period);
          setNoticePeriod(conditionData?.data?.minimum_notice_period);
          setSelectedDate(currentOpenedRequest?.date);
        }
      }
    })();
  }, [currentOpenedRequest]);

  console.log("ALl requests are is ", requests);

  const formatDateUsingMoment = (dateStr) => {
    return moment(dateStr).format("DD MMMM YYYY"); // '23 June 2024'
  };

  const handleTimeSlotSelect = (slotInfo) => {
    setSelectedTimeSlot(`${slotInfo.startTime} - ${slotInfo.endTime}`);
    setRequestBody((prevData) => ({
      ...prevData,
      date: selectedDate,
      startTime: slotInfo.startTime,
      endTime: slotInfo.endTime,
      duration: slotInfo.duration,
    }));
  };

  const toggleDetails = (id) => {
    if (visibleDetailsId === id) {
      setVisibleDetailsId(null);
    } else {
      setVisibleDetailsId(id);
      setVisibleRescheduleId(null); // Close reschedule section if open
    }
  };

  const toggleReschedule = (id) => {
    if (visibleRescheduleId === id) {
      setVisibleRescheduleId(null);
    } else {
      setVisibleRescheduleId(id);
      setVisibleDetailsId(null); // Close details section if open

      console.log("The schedule id  is ", id);
      requests?.map((element) => {
        if (element._id === id) {
          setCurrentOpenedRequest(element);
        }
      });
    }
  };

  console.log("The current opened is ", currentOpenedRequest);

  const handleReschedule = async (requestId) => {
    const { data } = await axios.post(
      `mentoring/reschedule-appointment/${requestId}`,
      requestBody,
      {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      }
    );

    if (data.success === false) {
      toast.error(data.message, toast_style);
    } else if (data.success) {
      toast.success(data.message, toast_style);
      setVisibleRescheduleId(null);
    }

    ///${requestId} , setRequests(updatedRequests);
  };

  const handleDone = async (requestId) => {
    const { data } = await axios.post(
      `/mentoring/update-appointment/${requestId}/`,
      {
        request_accepted: true,
      },
      {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      }
    );

    if (data.success === false) {
      toast.error(data.message, toast_style);
    } else if (data.success) {
      toast.success(data.message, toast_style);
      let requestsUpdated = requests.filter((key) => key._id !== requestId);
      setRequests(requestsUpdated);
    }

    console.log("TTH eodne data is ", data);
  };

  const UsingMoment = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":");
    const hour = parseInt(hours, 10);
    const formattedHour = (hour % 12 || 12).toString(); // Convert to 12-hour format
    const period = hour >= 12 ? "PM" : "AM";
    return `${formattedHour}:${minutes} ${period}`;
  };

  const getDayName = (date) => {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  };

  const getDayClassName = (date) => {
    const dayName = getDayName(date);
    if (!availableDays.includes(dayName)) {
      return "sunday-holiday";
    }
    return "";
  };

  const filterDates = (date) => {
    const dayName = getDayName(date);
    return !availableDays.includes(dayName);
  };

  const maxSelectableDate = new Date();
  maxSelectableDate.setDate(maxSelectableDate.getDate() + maxBookingDays);

  console.log("The time slots are ", timeSlots);

  return (
    <>
      {loader ? (
        <NewLoader></NewLoader>
      ) : (
        <div className="requests-container">
          {requests.map((request) => (
            <div key={request?._id} className="request-container">
              <div className="request-item1">
                <div className="request-header-prof request-prof">
                  {request?.user_id?.name ? (
                    <>
                      {" "}
                      <div className="request-header-prof">
                        <div className="profile-img"></div>
                        <p>
                          {request?.user_id?.name} |{" "}
                          <span>{request?.user_id?.email}</span>
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="request-header-prof">
                        <div className="profile-img"></div>
                        <p>
                          {request?.mentor_id?.name} |{" "}
                          <span>{request?.mentor_id?.email}</span>
                        </p>
                      </div>
                    </>
                  )}

                  {request?.incoming_request ? (
                    <BsArrowDownLeft
                      style={{ color: "green" }}
                      className="icon-prof top-right"
                    />
                  ) : (
                    <BsArrowUpRight
                      style={{ color: "red" }}
                      className="icon-prof top-right"
                    />
                  )}
                </div>
                <div className="request-actions-icon">
                  <div
                    className={`action-item-res ${
                      visibleRescheduleId === request?._id ? "active" : ""
                    } ${!request?.incoming_request ? "disableDiv" : ""}`}
                    onClick={() => toggleReschedule(request?._id)}
                  >
                    <IoMdTimer className="iconSize" />
                    <p>Reschedule</p>
                  </div>
                  {/* <button
                className="action-button1"
                disabled={!request.incoming_request}
              >
                <MdOutlineMessage className="iconSize" />
              </button> */}
                  <button
                    className={`action-button1`}
                    disabled={!request.incoming_request}
                    onClick={() => handleDone(request?._id)}
                  >
                    <MdOutlineDone className="iconSize" />
                  </button>
                  <button
                    className="action-button1"
                    disabled={!request.incoming_request}
                  >
                    <RxCross2 className="iconSize" />
                  </button>
                  <button
                    className={`action-button1 ${
                      visibleDetailsId === request?._id ? "active" : ""
                    }`}
                    onClick={() => toggleDetails(request?._id)}
                  >
                    <FaInfo />
                    {request?.rescheduled && <span className="red-dot"></span>}
                  </button>
                </div>
              </div>

              {visibleDetailsId === request?._id && (
                <div className="details-section2">
                  <p>
                    <span>{request?.session_id?.type} </span>|{" "}
                    <span>{request?.session_id?.duration} minutes</span>
                  </p>

                  <p>
                    {request?.rescheduled && (
                      <span>
                        {"( "}Reschedule Request{" )"}
                      </span>
                    )}
                  </p>

                  <p>
                    {formatDateUsingMoment(request?.date)} |{" "}
                    {request?.startTime}
                  </p>
                  <br></br>
                  <p className="description">{request?.agenda_insights}</p>
                </div>
              )}

              {timeSlots.length > 0 && visibleRescheduleId === request._id && (
                <div className="details-section2">
                  <div className="details-section-container1">
                    <div className="reschedule-section2">
                      <div className="calendar-container">
                        <p>Select day</p>
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          dateFormat="dd/MM/yyyy"
                          inline
                          dayClassName={getDayClassName}
                          filterDates={filterDates}
                          minDate={new Date()} // Set the start date to today
                          maxDate={maxSelectableDate} // Set the end date to 2 weeks from today
                        />
                      </div>

                      <div className="time-slot-container-req">
                        <p>Select time slots</p>
                        <div className="time-selector">
                          {timeSlots.length > 0 &&
                            timeSlots?.map((element, index) => (
                              <div
                                key={index}
                                className={`time-slot2 ${
                                  selectedTimeSlot ===
                                  `${element.startTime} - ${element.endTime}`
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => handleTimeSlotSelect(element)}
                              >
                                  {element.startTime} {" - "}{element.endTime}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>

                    <div className="buttons-container">
                      <button
                        className="cancel-button"
                        onClick={() => setVisibleRescheduleId(null)}
                      >
                        Cancel
                      </button>
                      <button
                        className="reschedule-button"
                        onClick={() => handleReschedule(request?._id)}
                      >
                        Reschedule
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default Requests;
