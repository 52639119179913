import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../styles/AgencyProfile.css";
import lexicon from "../../images/lexicon.png";
import star from "../../images/star.png";
import defaultlogo from "../../images/default-logo.png";
import starempty from "../../images/starempty.png";
import heartempty from "../../images/heartempty.png";
import bagw from "../../images/bagw.png";
import locationw from "../../images/locationw.png";
import watchw from "../../images/watchw.png";
import worldurl from "../../images/worldurl.png";
import arrowright from "../../images/arrowright.png";
import scrolltotop from "../../images/scrolltotop.png";
import wo1 from "../../images/wo1.png";
import wo2 from "../../images/wo2.png";
import wo3 from "../../images/wo3.png";
import study1 from "../../images/study1.png";
import study2 from "../../images/study2.png";
import study3 from "../../images/study3.png";
import glass from "../../images/glass.png";
import { Link, useHistory,useLocation } from "react-router-dom";
import AgencyReviews from "../agency/AgencyReviews";
import TeamMembers from "../agency/TeamMembers";
import CaseStudies from "../agency/CaseStudies";
import ResCard from "../ResCard";
import BResCard from "./BResCard";
import axios from "../../axios.js";

// components
// import AgencyReviews from "../agency/AgencyReviews";
// import TeamMembers from "../agency/TeamMembers";
// import CaseStudies from "../agency/CaseStudies";

function BrandProfile() {
  const history = useHistory();
  const location = useLocation();
  const [bName, setBName] = useState("");
  const [brandId, setBrandId] =  useState("");
  const [client, setClient] = useState({
        clientStatus: true,
        _id: "",
        clientName: "",
        clientURL: "",
        clientLogo: "",
        __v: 0
  });
  const [agencies, setAgencies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [agenciesOpen, setAgenciesOpen] = useState(false);
  const [projectsOpen, setProjectsOpen] = useState(false);
  const params = useParams();

  const showAgencies = () => {
    setAgenciesOpen(!agenciesOpen)
  }

  const showProjects = () => {
    setProjectsOpen(!projectsOpen)
  }


  useEffect(() => {
    console.log("Hi", params.brandId);
    if(!params.brandId){
      history.push('/brandlisting');
    } else {
      const name = params.brandId
      var id;
      setBName(name);

      axios.post(
          "/getClientDetailsbyclientsName",
          { clientname: name },
        ).then(res => {
          console.log("Client Details", res.data)
          setClient(res.data);
          id = res.data._id
          setBrandId(res.data._id)
        }).catch(err => {
          console.log(err);
          history.push('/brandlisting');
        })
        .then( () => {


          axios.post(
            "/getBrandProjectsbyClientId",
            { clientId: id },
          ).then(res => {
            console.log("projects", res.data)
            setProjects(res.data)
          })

          axios.post(
              "/getAgenciesbyClientId",
              { clientId: id },
            ).then(res => {
              console.log("Agencies", res.data)
              setAgencies(res.data)
            })

          
          })
        


    }
  }, []);


  const teamMembers = [
    {
      name: "Shrishti Jha",
      designation: "Marketing Lead"
    },
    {
      name: "Shrishti Jha",
      designation: "Marketing Lead"
    },
    {
      name: "Shrishti Jha",
      designation: "Marketing Lead"
    },
    {
      name: "Shrishti Jha",
      designation: "Marketing Lead"
    }
  ];

  // To perform scroll
  const performScroll = e => {
    e.preventDefault();

    const alinkitem = e.target; //btn which is clicked

    const id = e.target.getAttribute("href").slice(1);

    const idcont = document.getElementById(id); //element we have to reach
    const headerHeight = 70; //height of header
    const position = idcont.offsetTop - headerHeight - 20; //also dereasing some height to look good

    const alllinks = document.querySelectorAll(".alinkstoscroll");
    // console.log("allLinks -> ", alllinks);

    alllinks.forEach(link => {
      link.classList.remove("active"); //deactivating rest of the links
    });

    alinkitem.classList.add("active"); //activating the link which is clicked

    window.scrollTo({
      left: 0,
      top: position
    });
  };

  // Scroll to top functionality starts
  const [sttvisible, setsttvisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setsttvisible(true);
    } else if (scrolled <= 300) {
      setsttvisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
      /* you can also use 'auto' behaviour
           in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  //Scroll to top functionality ends

  return (
    <div className="agencyprofile__cont">
      {/* Scoll to top btn */}
      <img
        onClick={scrollToTop}
        src={scrolltotop}
        className="scrolltotopbtn"
        alt=""
        style={{ display: sttvisible ? "inline" : "none" }}
      />
      <div className="ap__top__cont">
        <div className="ap__top">
          <div className="sec__left">
            <img src={client.clientLogo ? client.clientLogo : defaultlogo} className="res__logo" alt="" />
            <div className="details">
              <h3 className="agency__title">
                {client.clientName}
                <img src={heartempty} alt="" className="heartempty" />
              </h3>
              {/* <div className="stars">
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={star} alt="" />
                <img src={starempty} alt="" />
                <p className="num__reviews para">35 reviews</p>
              </div> */}
              <p className="para adgrey cursor__pointer">{client.clientURL}</p>
            </div>
          </div>
          <div className="sec__right">
            <button className="twobtn">Pitch</button>
          </div>
        </div>
      </div>
      <div className="agencyprofile">
        <div className="sec__left">
          <a
            href="#reviews"
            onClick={performScroll}
            className="alinkstoscroll active"
          >
            Reviews
          </a>
          <a
            href="#projects"
            onClick={performScroll}
            className="alinkstoscroll "
          >
            Projects
          </a>
          <a
            href="#agencies"
            onClick={performScroll}
            className="alinkstoscroll "
          >
            Agencies
          </a>
          <a
            href="#teammembers"
            onClick={performScroll}
            className="alinkstoscroll "
          >
            Members
          </a>
        </div>
        <div className="sec__right">
          <div className="para__cont">
            {/* Reviews */}
            <AgencyReviews noaddrev />

            {/* Projects */}
            <div className="services bprojects" id="projects">
              <h5 className="services__title">Projects</h5>

              <div className="study__imgs" style={{ flexWrap: "Wrap"}}>
                { projects[0]&&(projectsOpen || projects.length < 4) ?
                       projects.map((project, i) => {
                            return (
                                  <div>
                                    <div className="study__img__cont">
                                      <img className="study__img" src={defaultlogo} alt="" />
                                      <p className="study__text para">India’s first AI portal</p>
                                    </div>
                                    <p className="para fw__600 text__left">{project.projectName}</p>
                                  </div>                                                                
                            );
                        })
                        : projects[0] ?
                         <>
                              <div className="study__imgs">
                                  <div>
                                    <div className="study__img__cont">
                                      <img className="study__img" src={defaultlogo} alt="" />
                                      <p className="study__text para">India’s first AI portal</p>
                                    </div>
                                    <p className="para fw__600 text__left">{projects[0].projectName}</p>
                                  </div>
                                  <div>
                                    <div className="study__img__cont">
                                      <img className="study__img" src={defaultlogo} alt="" />
                                      <p className="study__text para">India’s first AI portal</p>
                                    </div>
                                    <p className="para fw__600 text__left">{projects[1].projectName}</p>
                                  </div>
                                  <div>
                                    <div className="study__img__cont">
                                      <img className="study__img" src={defaultlogo} alt="" />
                                      <p className="study__text para">India’s first AI portal</p>
                                    </div>
                                    <p className="para fw__600 text__left">{projects[2].projectName}</p>
                                  </div>
                              </div>
                         </>  : ""                        
                  } 
                </div>

              
              <div className="showmorebtn__cont">
                <button className="para color__orange fw__600" onClick={showProjects}>
                  {projects.length ? projectsOpen ? "Hide Projects" : "Show more" : "No Projects to Display"}
                </button>
                <img src={arrowright} alt="" />
              </div>
            </div>

            {/* Agencies */}
            <div className="agencies" id="agencies">
              <h5 className="services__title">Agencies</h5>

              <div className="brescards">
                { agencies[0] ?
                     agencies.map((agency, i) => {
                          return (
                                <>
                                   {/* <BResCard agency={agency} liked={[]} /> */}
                                  <ResCard agency={agency} liked={[]} key={i} />  
                                </>                              
                          );
                      })
                      : ""

                }

              </div>
              <div className="showmorebtn__cont">
                <p className="para color__orange fw__600 cursor__pointer" onClick={showAgencies} >
                  Show more 
                </p>
              </div>
            </div>

            {/* Team Members */}
            
          </div>
        </div>
      </div>

      {/* Search agencies */}
      <div className="mar5__cont">
        <div className="searchagencies__cont ">
          <div className="searchagencies">
            <p className="mwidthsmall">
              Increase the chances of your project success, partner with the{" "}
              <span className="color__orange">right agency</span>
            </p>
            <div>
              <button onClick={() => history.push("/search")}>
                Search Agencies
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrandProfile;
