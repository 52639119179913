import React, { useState } from 'react'
import './ChecklistOfExperienceType.css'

const ChecklistOfExperienceType = ({ setIsBrandOrAgency, setIsTypeSelected, setShowSelectType }) => {
    const [showNextBtn, setShowNextBtn] = useState(false);

    function handleSelect(e) {
        setIsBrandOrAgency(e.target.id)
        setShowNextBtn(true);
    }

    function handleCancel () {
        setIsTypeSelected(false);
        setShowSelectType(false)
    }

    function handleNext() {
        setShowNextBtn(false);
        setIsTypeSelected(true);
        setShowSelectType(false)
    }

    return (
        <>
            <div className='experienceTypeContainer'>
                <div className='inputContainer'>
                    <input type="radio" name="experienceType" id="brand" onClick={handleSelect} />
                    <label for="experienceType">Add Brand Experience</label>
                </div>
                <div className='inputContainer'>
                    <input type="radio" name="experienceType" id="agency" onClick={handleSelect} />
                    <label for="experienceType">Add Agency Experience</label>
                </div>
            </div>

            {showNextBtn && (
                <div className='selectionTypeBtns'>
                    <button type='button' className='cancelBtn' onClick={handleCancel}>Cancel</button>
                    <button type='button' className='saveBtn' onClick={handleNext}>Next</button>
                </div>
            )}
        </>
    )
}

export default ChecklistOfExperienceType