import React from "react";
import "./about.css";
import Mediacomponent from "../mediaComponent/mediacomponent";

const App = ({
  overview,
  challenges,
  projectTittle,
  statistics,
  research,
  process,
}) => {
  return (
    <div className="about">
      <div className="overview">
        <h1>{projectTittle} </h1>
        <p
          dangerouslySetInnerHTML={{
            __html: overview.text?.replaceAll("\\", ""),
          }}
        ></p>
        {/* <p>{overview.text}  </p> */}
        <Mediacomponent
        images={overview.image}
        imageType={overview.imgType}
        videos={overview.videos}
        />
      </div>

      {statistics.length !== 0 && (
        <div className="statistics">
          {statistics.map((key) => (
            <div>
              <h1>{key?.text}</h1>
              <span>{key?.value}</span>
            </div>
          ))}
        </div>
      )}

      

      {challenges.image.length === 0 && challenges.text.length === 0 ? (
        <></>
      ) : (
        <div className="challenges">
          <h2>Requirement | Challenges | Objectives</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: challenges.text?.replaceAll("\\", ""),
            }}
          ></div>{" "}
          <Mediacomponent
            images={challenges.image}
            imageType={challenges.imgType}
          />
        </div>
      )}

      {research.image.length === 0 && research.text.length === 0 ? (
        <></>
      ) : (
        <div className="research">
          <h2>Research | Insights</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: research.text?.replaceAll("\\", ""),
            }}
          ></div>{" "}
          <Mediacomponent
            images={research.image}
            imageType={research.imgType}
          />
        </div>
      )}

      {process.image.length === 0 && process.text.length === 0 ? (
        <></>
      ) : (
        <div className="process">
          <h2>Process | Solutions | Approach</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: process.text?.replaceAll("\\", ""),
            }}
          ></div>{" "}
          <Mediacomponent images={process.image} imageType={process.imgType} />
        </div>
      )}
    </div>
  );
};
export default App;
