import React from "react";

function NotifyText({ text, time }) {
  return (
    <div className="notify">
      <p className="para">
        {text ? text : "Message from Akash Singh in New Product Line Launch"}
      </p>
      <p className="para">{time ? time : "24/10 Mon @ 12:30pm"}</p>
    </div>
  );
}

export default NotifyText;
