import React, { useState, useEffect } from "react";
import "./Mentoring.css";
import { FaArrowRight } from "react-icons/fa";
import SingleSession from "./SingleSession/SingleSession";
import axios from "../../../../axios.js";
import ratingstar from "./img/star_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import { IoIosStar, IoIosStarOutline } from "react-icons/io";

export default function Mentoring({ user }) {
  const [isSessionOpen, setIsSessionOpen] = useState({});
  const [sessionDetails, setSessionDetails] = useState(null);
  const [sessionInfo, setSessionInfo] = useState([]);
  const [visibleReviews, setVisibleReviews] = useState(6); // State to manage visible reviews
  const [mentorReviews, setMentorReviews] = useState([]);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `/mentoring/get-all-user-sessions/${user._id}`,
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );

      console.log("The data is ", data);
      setSessionInfo(data);
    })();

    // Fetch mentor reviews on component mount
    (async () => {
      const { data } = await axios.get(
        `/mentoring/get-all-mentoring-reviews/${user._id}`,
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );

      console.log("The data is ", data);
      if (data) {
        setMentorReviews(data);
      }
    })();
  }, []);

  const handleShowSession = (session_id) => {
    setIsSessionOpen((prevState) => ({
      [session_id]: !prevState[session_id],
    }));

    const something = sessionInfo
      ?.filter((e) => e._id === session_id)
      .map((element) => {
        if (element._id === session_id) {
          return element;
        }
      });

    setSessionDetails(something[0]);
  };

  const handleHideSession = (session_id) => {
    console.log("close the pool is ", session_id);
    setIsSessionOpen((prevState) => ({
      [session_id]: !prevState[session_id],
    }));

    setSessionDetails(null);
  };

  const handleViewMoreReviews = () => {
    setVisibleReviews((prev) => prev + 6); // Increase the number of visible reviews by 6
  };

  const scrollToReviewSection = () => {
    const reviewSection = document.querySelector(".reviewSection-main");
    if (reviewSection) {
      reviewSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  console.log("the details are ", sessionDetails);

  return (
    <div className="main-container-ment">
      {sessionDetails ? (
        <SingleSession
          sessionDetails={sessionDetails}
          handleHideSession={handleHideSession}
        >
          {" "}
        </SingleSession>
      ) : (
        <>
          <div className="userProfileMentoring">
            {sessionInfo?.map((info) => {
              return (
                <div key={info?._id} className="singleSession">
                  <div className="session_type">{info?.type}</div>
                  <div className="session_name">{info?.title}</div>
                  <div className="session_desc">{info?.description}</div>
                  <div className="goIntoSessionDetail">
                    <p>{info?.duration} Minutes</p>
                    <div
                      className="unlockButton"
                      onClick={() => handleShowSession(info?._id)}
                    >
                      <p>₹ {info?.price} </p>
                      <FaArrowRight className="iconSize20"></FaArrowRight>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="theReviewButtonDiv">
            <button className="reviewButton" onClick={scrollToReviewSection}>
              Ratings and Review
            </button>
          </div>

          <div className="reviewSection-main">
            <div className="reviewSection">
              <div className="reviewHeader">
                <h2>Ratings & Reviews</h2>
                {mentorReviews.length == 0 ? (
                  <h3>No Reviews uploaded yet!</h3>
                ) : (
                  <>
                    {" "}
                    <div className="ratingSummary">
                      <div className="ratingItem">
                        <h3>40</h3>
                        <p>Ratings</p>
                      </div>
                      <div className="ratingStars">
                        {Array(5)
                          .fill()
                          .map((_, i) => (
                            <img
                              key={i}
                              src={ratingstar}
                              alt="rating star"
                              className="ratingStarIcon"
                            />
                          ))}
                      </div>
                      <div className="border-bottom"></div>
                      <div className="ratingItem">
                        <h3>23</h3>
                        <p>Testimonials</p>
                      </div>
                    </div>
                  </>
                )}
                </div>
              <div className="reviewGrid">
                {mentorReviews.map((element) => (
                  <div key={element?._id} className="reviewCard">
                    <div className="reviewRating">
                      {[...Array(5)].map((star, index) => (
                        <span key={index} className="ratingStarIcon">
                          {index < element?.rating ? (
                            <IoIosStar
                              style={{
                                color: "black",
                              }}
                            />
                          ) : (
                            <IoIosStarOutline
                              style={{
                                color: "black",
                              }}
                            />
                          )}
                        </span>
                      ))}
                    </div>
                    <p className="reviewText">{element?.review}</p>
                    <p className="reviewAuthor">{element?.reviewer?.name}</p>
                  </div>
                ))}
              </div>
            </div>
            {visibleReviews < 40 && ( // Conditionally render the "View More" button
              <button
                className="viewMoreButton"
                onClick={handleViewMoreReviews}
              >
                View More
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
