import React, { useState, useEffect } from "react";
import axios from "../../../axios.js";
import users from "../img/Users.png";
import linkedin from "../img/linkedinl.png";
import instagram from "../img/instagram.png";
import "./TeamMembers.css";


const TeamMembers = ({ id, teamMmembers, agencyData = [] }) => {
  const profileImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
  const [clients, setClients] = useState([]);
  const [userLogo, setLogo] = useState({});


useEffect(() => {
  const fetchUserProfile = async () => {
    teamMmembers.individual_credit.map(async (key) => {
      if (key.marketers_userName) {
        const userProfileDetails = await axios.get(`/marketers/getMarketerByUsername/${key.marketers_userName}`);
        setLogo((prevLogo) => {
          return {
            ...prevLogo,
            [key.marketers_userName]: userProfileDetails.data.profilePhoto
          };
        });
      }
    });
  };
  
  fetchUserProfile();
}, []);


  const fetchagencyLogo = (agencyName) => {
    const agency = agencyData.find((item) => item.agencyName === agencyName);
    return agency?.agencyLogo;
  };



  const member = (key, index, to) => {
    return (
      <div className="d-flex text-start member__info">
        <div>
          <img
            className="member__photo"
            src={to === "agency" ? fetchagencyLogo(key.name) : key.marketers_userName?(userLogo[key.marketers_userName]?userLogo[key.marketers_userName]:profileImage):profileImage}
            alt="Member"
            style={{cursor:"pointer"}}
            onClick={()=>{
              to !== "agency"&&window.open(`/userprofile/${key.marketers_userName}`, "_blank")
            }}
          />
        </div>
        <div className="d-flex flex-column justify-content-between">
          <div>
            <h4 className="fw-bold mb-0 font__lexend member__name">
              {to === "agency" ? key.name : key.name}
            </h4>
            <h4 className="mb-0 font__lexend member__designation">
              {key.role}
            </h4>
            <p className="fw-bold font__lexend member__username">
              {to === "agency" ? key.agencyWebsite : key.marketers_userName}
            </p>
          </div>
          <div className="gap-2 d-flex icon__container">
            <a href="https://www.example.com/">
              <img className="social__icon" src={linkedin} alt="linkedin" />
            </a>
            <a href="https://www.example.com/">
              <img className="social__icon" src={instagram} alt="instagram" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {teamMmembers.agency_credit.length === 0 &&
      teamMmembers.individual_credit.length === 0 ? (
        <></>
      ) : (
        <div className="team__members">
          <div className="members__heading">
            <h2 className="members__title font__lexend">Team</h2>
            <div className="members__subtitle">
              <img className="heading__icon" src={users} alt="Icon" />
              <h5 className="icon__text font__lexend">Claim Credits</h5>
            </div>
          </div>
          <div className="members__container">
            {teamMmembers.agency_credit.map((key, index) => {
              return member(key, index, "agency");
            })}
            {teamMmembers.individual_credit.map((key, index) => {
              return member(key, index, "individual");
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default TeamMembers;
