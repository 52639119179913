import React from "react"; 
import "./agencySidebar.css"


const App=({agencyLogo,agencyName,NoOfProjects})=>{
    
    return <div className="agencySidebar">
                {agencyName&&<div className="agencyDetails">
                    <div className="agencyLogo">
                        <a href={`../agencyprofile/${agencyName}`} target="_blank" > <img  src={agencyLogo} alt="" /></a>                       
                    </div>
                    <div className="agencyData">
                        <a className="agencyName" href={`../agencyprofile/${agencyName}`} target="_blank" style={{color:"black", textDecoration:"none"}}>
                            {agencyName} 
                            <img src="https://unispade-react-js-s3.s3.ap-south-1.amazonaws.com/caseStudyMedia/Vector-4.png"/>
                        </a>
                        <p className="agencyProjects">{NoOfProjects} Projects</p>
                        <div className="teamMembers">
                            <img src="https://unispade-react-js-s3.s3.amazonaws.com/clientLogos/cardImage.png"/>
                            <img src="https://unispade-react-js-s3.s3.amazonaws.com/clientLogos/cardImage.png"/>
                            <img src="https://unispade-react-js-s3.s3.amazonaws.com/clientLogos/cardImage.png"/>
                            <p>12 team members</p>
                        </div>
                    </div>
                </div>}
            </div>
}
export default App; 