import React, { useEffect, useState } from "react";
// import plus from "../../images/plus.png";
import AgcRewCard from "./AgcRewCard";
// import arrowright from "../../images/arrowright.png";
import axios from "../../axios.js";

function AgencyReviews({ title, agencyId }) {
  const [reviewList,setReviewList] = useState([]);

  useEffect(()=>{
    getReviews();   
  },[])

  const processData = (data) => {
    if(data){
      let reviewData = [];
      Object.keys(data).forEach(review => {
        if(Array.isArray(data[review])){
          data[review].forEach(rev => {
            let obj = {
              agencyLogo : '',
              agencyName : review,
              content : rev
            }
            reviewData.push(obj);
          })
        }
      })
      setReviewList(reviewData);
    }
  }

  const getReviews = async () => {
    const response = await axios.get(`/getReviewbyAgencyId?agencyId=${agencyId}`);
    processData(response.data.result[0]);   
  }

  return (
    <div className="agencyreviews" id="reviews">
      <div className="part1">
        <h5 className="services__title">{title ? title : "Reviews"}</h5>

        {/* {!noaddrev && (
          <button className="addreviewbtn fw__600 color__orange">
            <img src={plus} alt="" />
            Add a review
          </button>
        )} */}
      </div>
      <div className="part2">
        {reviewList.map((rev,ind) => {
          return <AgcRewCard review={rev} ind={ind}/>
        })}
      
      </div>
      {/* <div className="showmorebtn__cont">
        <button className="para color__orange fw__600">Show more</button>
        <img src={arrowright} alt="" />
      </div> */}
    </div>
  );
}

export default AgencyReviews;
