import React, { useEffect, useMemo, useState } from 'react';
import deleteIcon from './icons/delete.svg';
import linkedin from './icons/linkedin.png';
import twitter from './icons/twitter.png';
import instagram from './icons/instagram.png';
import facebook from './icons/facebook.png';
import youtube from './icons/youtube.png';
import tiktok from './icons/tiktok.png';
import whatsapp from './icons/whatsapp.png';
import telegram from './icons/telegram.png';
import './Links.css'
import axios from "../../../../axios"
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Links = ({ socialLinks, setUserData }) => {
  const [isAddNewLinkOpen, setIsAddNewLinkOpen] = useState(false)
  const [linkTitle, setLinkTitle] = useState('')
  const [linkUrl, setLinkUrl] = useState('')
  const socialMediaLinks = [
    {
      id: 1,
      name: 'linkedIn',
      icon: linkedin,
      placeholderName: 'LinkedIn',
    },
    {
      id: 2,
      name: 'xProfile',
      icon: twitter,
      placeholderName: 'X profile',
    },
    {
      id: 3,
      name: 'instagram',
      icon: instagram,
      placeholderName: 'Instagram',
    },
    {
      id: 4,
      name: 'facebook',
      icon: facebook,
      placeholderName: 'Facebook',
    },
    {
      id: 5,
      name: 'youtube',
      icon: youtube,
      placeholderName: 'Youtube',
    },
    {
      id: 6,
      name: 'tikTok',
      icon: tiktok,
      placeholderName: 'TikTok',
    },
  ]

  const messagingApps = [
    {
      id: 1,
      name: 'whatsapp',
      icon: whatsapp,
      placeholderName: 'WhatsApp',
    },
    {
      id: 2,
      name: 'telegram',
      icon: telegram,
      placeholderName: 'Telegram',
    },
  ]

  const [linkObj, setLinkObj] = useState(socialLinks)
  // const updatedContent = useMemo(() => ({}), []);
  const [errors, setErrors] = useState([])
  const [otherLinks, setOtherLinks] = useState(socialLinks.otherLinks)
  const Marketer = JSON.parse(localStorage.getItem("marketer"));
  const [saveText, setSaveText] = useState("Save");

  function handleAddNewLink() {
    setOtherLinks(prev => [...prev, {
      linkTitle: linkTitle,
      linkUrl: linkUrl,
    }])
    setIsAddNewLinkOpen(false)
    setLinkTitle('')
    setLinkUrl('')
  }

  function handleDeleteLink(id) {
    console.log(id);
    const filteredLinks = otherLinks.filter(link => link._id !== id);
    setOtherLinks(filteredLinks)
  }
  let verifyLinkRegex = /^(https?|ftp):\/\/[^\s\/$.?#].[^\s]*$/;


  const onChangeValueofLink = async (e) => {
    const { name, value } = e.target
    console.log(verifyLinkRegex.test(value));
    if (verifyLinkRegex.test(value)) {
      setLinkObj(prev => ({ ...prev, [name]: value }))
      if (errors.includes(name)) {
        setErrors((prevErrors) => prevErrors.filter((error) => error !== name));
      }
    }
    else if (value === "") {
      delete linkObj[name];
      if (errors.includes(name)) {
        setErrors((prevErrors) => prevErrors.filter((error) => error !== name));
      }
    } else {
      if (!errors.includes(name)) {
        setErrors((prevErrors) => [...prevErrors, name]);
      }
    }
  }

  useEffect(() => {
    if (saveText === "Saved") {
      setTimeout(() => {
        setSaveText("Save")
      }, 3000)
    }
  }, [saveText])

  useEffect(() => {
    console.log(linkObj, "linkObj");
  }, [linkObj])

  const updateUserLinks = async (e) => {
    e.preventDefault()
    setSaveText("Saved")
    if (errors.length !== 0) {
      toast.error(`Please enter valid links for ${errors.join(",")}`)
    }
    else {
      toast.success("Links Updated Successfully")
      let socialLinks = {
        ...linkObj,
        otherLinks: otherLinks
      }
      let updateMarketer = await axios.post("/marketers/updateMarketer", { socialLinks }, { headers: { authorization: "Bearer " + Marketer.token } });

        setLinkObj(updateMarketer?.data?.data?.socialLinks)
    }
  }

  return (
    <main className='links_main_container'>
      <form className="links_form formScrollBarDisplay">
        <div className='socialMediaProfile'>
          <h6>Social Media Profiles</h6>
          <div className='linksContainer'>
            {
              socialMediaLinks.map((link) => (
                <div key={link.id} className='linkDiv'>
                  <div className='linkIconAndImage'>
                    <img src={link.icon} alt={link.name} />
                    <p>{link.placeholderName}</p>
                  </div>
                  <input defaultValue={linkObj[link.name]} name={link.name} onChange={onChangeValueofLink} placeholder={`Enter your ${link.placeholderName} profile link`} />
                </div>
              ))
            }
          </div>
        </div>
        <div className='messagingApps'>
          <h6>Messaging Apps</h6>
          <div className='linksContainer'>
            {
              messagingApps.map((link) => (
                <div key={link.id} className='linkDiv'>
                  <div className='linkIconAndImage'>
                    <img src={link.icon} alt={link.name} />
                    <p>{link.placeholderName}</p>
                  </div>
                  <input defaultValue={linkObj[link.name]} name={link.name} onChange={onChangeValueofLink} placeholder={`Enter your ${link.placeholderName} profile link`} />
                </div>
              ))
            }
          </div>
        </div>
        <div className='messagingApps'>
          <h6 style={otherLinks?.length !== 0 ? { display: 'block' } : { display: 'none' }}>Other Links</h6>
          <div className='linksContainer' style={otherLinks?.length !== 0 ? { display: 'block' } : { display: 'none' }}>
            {
              otherLinks && otherLinks.map((link) => (
                <>
                  <div key={link.id} className='otherLinksContainer'>
                    <p className="otherLinkTitle">{link.linkTitle}</p>
                    <p className="otherLinkUrl">{link.linkUrl}</p>
                    <img src={deleteIcon} style={{ cursor: 'pointer' }} onClick={() => handleDeleteLink(link._id)} />
                  </div>
                  <hr />
                </>
              ))
            }
          </div>
          <div style={{ margin: '20px 0 15px', padding: '0 30px' }}>
            <div style={!isAddNewLinkOpen ? { display: 'none' } : { display: 'block' }}>
              <div className='linkDiv otherNewLink'>
                <div className='newLinkTitle'>
                  <p>Link Title</p>
                  <input value={linkTitle} onChange={(e) => setLinkTitle(e.target.value)} placeholder='Give the link to a title' />
                </div>
                <div className='newLinkUrl'>
                  <p>Link</p>
                  <input onChange={(e) => verifyLinkRegex.test(e.target.value) && setLinkUrl(e.target.value)} placeholder='Enter the link here' />
                </div>
              </div>
              <div className='save'>
                <button type="button" disabled={linkTitle === '' || linkUrl === ''} onClick={handleAddNewLink}>Save</button>
              </div>
            </div>
            <div style={isAddNewLinkOpen ? { display: 'none' } : { display: 'block' }}>
              <button type='button' className='addNewLinkBtn' onClick={() => setIsAddNewLinkOpen(true)}>Add new Link</button>
            </div>
          </div>

        </div>
      </form>
      <div className='formButtons'>
        <div className='innerFormContainer'>
          <button className='formBtn'>Cancel</button>
          <button onClick={updateUserLinks} type='submit' className='formBtn'>{saveText}</button>
        </div>
      </div>
    </main>
  )
}

export default Links