import React from "react"; 
import "./CaseStudyfirstcomponents.css"


const App=({brandLogo,agencyLogo})=>{
    
    return <div className="firstcomponent">
        <div className="brandImage">
            <img src={agencyLogo} alt="" />
            <hr/>
            <img src={brandLogo} alt="" />
        </div>
    </div>
}
export default App; 