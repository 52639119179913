import React from "react";
import GoogleLogo from '../../images/GoogleLogo.png';
import FacebookLogo from '../../images/FacebookLogo.png';
import ClutchLogo from '../../images/ClutchLogo.png';


function AgcRewCard(props) {
  let logo;
  if(props.review.agencyName.toLowerCase() === 'google'){
    logo = GoogleLogo;
  }else if(props.review.agencyName.toLowerCase() === 'facebook'){
    logo = FacebookLogo;
  }else if(props.review.agencyName.toLowerCase() === 'clutch'){
    logo = ClutchLogo;
  }
  return (
    <div className="agc__rew__card">
      <div className="agency-client-logo_name" key={"client_" + props.ind}>
        {logo && <img className="agency-client-logo" alt="agency-client-logo" src={logo} />}
        <div className="agency-client-name">{props.review.agencyName}</div>
      </div>
      <p className="para">{props.review.content}
      </p>
    </div>
  );
}

export default AgcRewCard;
