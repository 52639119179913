import React, { useEffect, useState } from 'react';
import './AgencyDetails.css';
import LocationIcon from '../../images/location2.png';
import PeopleIcon from '../../images/people-icon.png';
import BagIcon from '../../images/bag.png';
import SaveAgency from '../../images/saveAgency.png';
import ClaimAgency from '../../images/ClaimAgency.png';
import Button from '../Button/Button';
import { useHistory } from "react-router";
import axios from "../../axios.js";

let timeoutVar = null;

const AgencyDetails = (props) => {
    const history = useHistory();
    const [showStatus, setShowStatus] = useState(false);
    const [claimedStatus, setClaimedStatus] = useState(false);

    const viewProfileLink = "/agencyprofile/" + props.selectedAgency.agencyName.split(" ").join("-").toLowerCase();
    const contactUsLink = "/agencyprofile/contact/" + props.selectedAgency._id;
    console.log(props.selectedAgency._id);

    


    useEffect(() => {
        setShowStatus(false);
        let outerElem = document.getElementById('search-page');
        if(window.innerWidth <= 425){
            outerElem.addEventListener('click', showHideAgencyDetailsMob)
        }
        return () => {
            if(window.innerWidth <= 425) outerElem.removeEventListener('click', showHideAgencyDetailsMob)
            clearTimeout(timeoutVar);
        }
    }, [])

    const showHideAgencyDetailsMob = (event) => {
        let innerElem = document.getElementById('agency-details-wrapper');
        if(innerElem && !innerElem.contains(event.target) && innerElem.style.display !== 'none' ){
            event.stopPropagation();
            onCloseBtnClick();
        }
    }

    useEffect(() => {
        setShowStatus(false);
        getClaimedStatus(props.selectedAgency._id)
    }, [props.selectedAgency._id])

    const onViewProfileClick = () => {
        window.open(viewProfileLink, '_blank');
    }

    const getClaimedStatus = async (agencyId) => {
        clearTimeout(timeoutVar);
        let agencyIdCopy = agencyId;
        const response = await axios.get(`/getClaimedStatusByAgencyId?agencyId=${agencyIdCopy}`);
        if (response.data.isClaimed) {
            clearTimeout(timeoutVar);
        } else {
            timeoutVar = setTimeout(() => { getClaimedStatus(agencyIdCopy) }, 5000);
        }
        setClaimedStatus(response.data.isClaimed);
    }

    const onContactUs = () => {
        history.push(contactUsLink);
    }

    const getInitialAgencyName = (agencyName) => {
        let nameArr = agencyName.split(' ');
        let initial = '';
        if (nameArr.length === 1) {
            initial = nameArr[0][0].toUpperCase();
        } else if (['/', '\\', '&'].includes(nameArr[1][0])) {
            initial = nameArr[0][0].toUpperCase();
        } else {
            initial = `${nameArr[0][0].toUpperCase()} . ${nameArr[1][0].toUpperCase()}`;
        }
        return initial;
    }

    const yearDiff = (dt1Date) => {
        let dt1 = new Date(dt1Date);
        let dt2 = new Date();
        var diffYear = (dt2.getTime() - dt1.getTime()) / 1000;
        diffYear /= (60 * 60 * 24);
        return Math.abs(Math.round(diffYear / 365.25));
    }

    const saveAgencyHandler = () => {
        if (props.likedAgencies.includes(props.selectedAgency._id)) {
            props.getLikedAgencies(props.selectedAgency._id);
        } else {
            likeAgency();
        }
    }

    const onCloseBtnClick = () => {
        let elem = document.getElementById("agency-details-wrapper");
        if (elem) {
            elem.style.display = "none";
        }
    }

    const likeAgency = () => {
        if (localStorage.getItem("branduser") != null) {
            const payload = {
                agencyId: props.selectedAgency._id,
                brandId: JSON.parse(localStorage.getItem('branduser')).user.brandId
            }
            axios.post("/brands/likeAgency", payload)
                .then(() => {
                    props.getLikedAgencies();
                })
        }
    }

    const getAgencyWebsiteURL = (agencyWebsite) => {
        if(agencyWebsite && agencyWebsite.includes('http')){
          return agencyWebsite;
        }else {
          return 'http://www.' + agencyWebsite;
        }
      } 

    return (
        <>
            <div className='agency-details'>
                <div className='close-btn-wrapper'><span className="close-btn" onClick={() => onCloseBtnClick()}>X</span></div>
                <div className='agency-details-logo-name'>
                    <div className='image-container'>
                    {props.selectedAgency.agencyLogo.includes("unispade-react-js-s3")?<p className="agency-img slctdAgencylogoInitials" onClick={onViewProfileClick}>{props.selectedAgency.agencyName[0].toUpperCase()}</p>:<img src={props.selectedAgency.agencyLogo} className="agency-img" onClick={onViewProfileClick}/>}
                        {/* {props.selectedAgency.agencyLogo ? <img src={props.selectedAgency.agencyLogo} className="agency-img" onClick={onViewProfileClick}/> :
                            <div className="agency-initials">{getInitialAgencyName(props.selectedAgency.agencyName)}</div>} */}
                    </div>
                    <div className='agency-name-website'>
                        <div className='agency-name-save'>
                            <div className='agency-name'>{props.selectedAgency.agencyName}</div>
                            <img src={SaveAgency} alt="save agency" onClick={saveAgencyHandler} />
                        </div>
                        {/* <div className='agency-website'><a href={getAgencyWebsiteURL(props.selectedAgency.agencyWebsite)} target="_blank">{props.selectedAgency.agencyWebsite}</a></div> */}
                    </div>
                </div>
                <div className='agency-about-sec'>
                    <div className='title-text'>About</div>
                    <div className='about'>
                        {props.selectedAgency.locations.length > 0 && <div className='icon-text-wrapper'>
                            <img src={LocationIcon} alt="locationIcon" />
                            <span className='text'>{props.selectedAgency.locations.join(',')}</span>
                        </div>}
                        {props.selectedAgency.foundedYear && <div className='icon-text-wrapper'>
                            <img src={BagIcon} alt="locationIcon" />
                            <span className='text'>{yearDiff(props.selectedAgency.foundedYear) + " years"}</span>
                        </div>}
                        {props.selectedAgency.agencySize && <div className='icon-text-wrapper'>
                            <img src={PeopleIcon} alt="locationIcon" />
                            <span className='text'>{props.selectedAgency.agencySize + " people"}</span>
                        </div>}
                    </div>
                    <div className='agency-desc'>{props.selectedAgency.aboutAgency}</div>
                </div>
                {props.selectedAgency.services.length > 0 && <div className='services-sec'>
                    <div className='title-text'>Services</div>
                    <div className='services-container'>
                        {props.selectedAgency.services.map((service, ind) => {
                            return <span className='services' key={"service_" + ind}>{service}</span>
                        })}
                    </div>
                </div>}
                {props.selectedAgency.agencyClients.length > 0 && <div className='clients-sec'>
                    <div className='title-text'>Clients</div>
                    <div className='clients-container'>
                        {props.selectedAgency.agencyClients.map((client, ind) => {
                            return <div className="agency-client-logo_name" key={"client_" + ind}>
                                {client.clientLogo.includes("unispade-react-js-s3")?<p className="agency-client-logo agency-client-logoInitials" onClick={onViewProfileClick}>{client.clientName[0].toUpperCase()}</p>:<img className="agency-client-logo" alt="agency-client-logo" src={client.clientLogo} />}
                                {/* <img className="agency-client-logo" alt="agency-client-logo" src={client.clientLogo} /> */}
                                <div className="agency-client-name">{client.clientName}</div>
                            </div>
                        })}
                    </div>
                </div>}
            </div>
            <div className='button-sec'>
                <div className='button-wrapper'>
                    <Button buttonText="View full profile" onBtnClick={onViewProfileClick} />
                    <Button buttonText="Contact Agency" isSecondary customClass="contact-btn" onBtnClick={onContactUs} />
                </div>
                <div className='claim-agency-wrapper'>
                    {showStatus && claimedStatus && <div className='agency-claim-state' onClick={() => props.setShowClaimedProfile(true)}>Profile Claimed</div>}
                    {showStatus && !claimedStatus && <div className='agency-claim-state' onClick={props.showClaimProfilePopup}>Claim Profile</div>}
                    <img src={ClaimAgency} alt="claim agency" className='claim-agency' onClick={() => setShowStatus(!showStatus)} />
                </div>
            </div>
        </>
    )
}

export default AgencyDetails;
  