import React from "react";
import ASidebar from "./ASidebar";
import notificationg from "../../images/notificationg.png";
import dotg from "../../images/dotg.png";
import messageg from "../../images/messageg.png";
import tmembimg from "../../images/tmembimg.png";
import { Link, useHistory } from "react-router-dom";
import ASidebarAlt from "./ASidebarAlt";

function ATeamAddMemb() {
  const history = useHistory();
  const goBack = () => {
    console.log("go back");
    history.push("/adashboard/ateam");
  };
  return (
    // <div className="ateam__cont">
    //   <ASidebar />
    <div className="ateam">
      <ASidebarAlt />
      <div className="section__cont">
        <div className="section">
          <div className="part__left">
            <h3 className="aonboarding__title">Team</h3>
          </div>
          <div className="part__right">
            <div className="img__cont">
              <img className="msg__not" src={messageg} alt="" />
              <img className="dotg" src={dotg} alt="" />
            </div>
            <div className="img__cont">
              <img className="msg__not" src={notificationg} alt="" />
              <img className="dotg" src={dotg} alt="" />
            </div>
            <p className="date__day para adgrey"> </p>
          </div>
        </div>
      </div>
      <div className="dflex justify__between">
        <p className="para color__orange cursor__pointer" onClick={goBack}>
          {"< Back"}
        </p>
      </div>
      <div className="teammemberUser">
        <img className="teammemberUser__img" src={tmembimg} alt="" />
        <p className="para">Vishwa Vijay Rana</p>
        <p className="para">vishwavijay@redagency.com</p>
        <p className="para">Admin</p>
        <p className="para">Gurgaon</p>
      </div>
      <div className="addinvmemb">
        <p className="para text__left fw__600">Add/Invite Members</p>

        <div className="addinvmemb__data">
          <div className="part align__end">
            <div className="inp__fields flx3 giveflex1">
              <label className="para">Share an invite link</label>
              <input className="para part__inp" placeholder="https://" />
            </div>

            <div className="inp__fields flx1 text__center">
              <p className="para copybtn color__orange cursor__pointer">
                Copy(ied!)
              </p>
            </div>
          </div>
          <p className="para">or</p>
          <div className="part part2  align__end">
            <div className="inp__fields flx2">
              <label className="para">Name</label>
              <input className="para part__inp" placeholder="Ramneek Sidhu" />
            </div>
            <div className="inp__fields  flx3">
              <label className="para">Email ID</label>
              <input
                className="para part__inp"
                placeholder="ramneek@redagency.com"
              />
            </div>
            <div className="inp__fields flx1 radio__inp__main__cont">
              <label className="para">Access</label>
              <div className="radio__inp__main">
                <div className="radio__inp__cont">
                  {" "}
                  <input
                    className="input adgrey"
                    id="admin"
                    name="type"
                    value="admin"
                    type="radio"
                  ></input>{" "}
                  &nbsp;
                  <label htmlFor="admin" className="para">
                    Admin
                  </label>
                </div>
                <div className="radio__inp__cont">
                  <input
                    className="input adgrey"
                    id="executive"
                    name="type"
                    value="executive"
                    type="radio"
                  ></input>{" "}
                  &nbsp;
                  <label htmlFor="executive" className="para">
                    Executive
                  </label>
                </div>
                <div className="radio__inp__cont">
                  <input
                    className="input adgrey"
                    id="basic"
                    name="type"
                    value="basic"
                    type="radio"
                  ></input>{" "}
                  &nbsp;
                  <label htmlFor="basic" className="para">
                    Basic
                  </label>
                </div>
              </div>
            </div>
            <div className="inp__fields flx1 text__center ">
              <p className="para copybtn color__orange cursor__pointer">
                Invite(d!)
              </p>
            </div>
          </div>
          <div className="dflex justify__between">
            <p className="para fw__600 color__orange cursor__pointer">
              + Add/Invite More Members
            </p>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default ATeamAddMemb;
