import "./brandprofile.css"
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "../../axios.js";
import CaseStudyCard from "../CaseStudyListingComponents/CaseStudyCard/CaseStudyCard";


import verifiedIcon from "../../images/AgencyProfile/green-verified.png";
import verifiedIconRed from "../../images/CaseStudy/verified.png";
import soundIcon from "../../images/CaseStudy/sound.png";
import briefcaseIcon from "../../images/AgencyProfile/briefcase.png";
import groupIcon from "../../images/AgencyProfile/account-group.png";
import mapIcon from "../../images/AgencyProfile/map-marker.png";
import linkedin from "../CaseStudyComponents/img/linkedinl.png";
import instagram from "../CaseStudyComponents/img/instagram.png";
import avatar from "../../images/avatar.png";
import { useHistory } from "react-router-dom";
import Loader from "../loader/Loader";




const BrandProfile = () => {
    const history=useHistory()
  
    const params = useParams();
    // const history = useHistory();
    const [brand,setBrand]=useState({})
    const [clients, setClients] = useState([]);
    const [caseStudies, setCaseStudies] = useState([]);
    const [profileFound,setProfileFound]=useState(true)
    const [loading,setLoading]=useState(true)
    useEffect(() => {
       
      const fetchBrandData=async()=>{
        try {
            const response = await axios.post("/brands/brandProfile", {
                brandName: params.brandName 
            });
            if(response.data.status===false){
                const resp = await axios.post("/getClientDetailsbyclientsName", {clientname:params.brandName})
                if(resp.data){
                    let brand={
                        _id:resp.data._id, 
                        brandName:resp.data.clientName,
                        brandLogo:resp.data.clientLogo,
                        brandWebsite:resp.data.clientURL
                    }
                    setBrand(brand)
                }else{
                    setProfileFound(false)
                    history.push("/")
                }
                
            } else{
                setBrand(response.data);   
            }
                       
          } catch (e) {
            console.log(e);
          }
      }
      fetchBrandData()
      
    }, []);
    
    useEffect(() => {
      if(brand._id) {
        const fetchClients = async () => {
          try {
            const response = await axios.post("/getAllagencies", {
                clientId: brand._id,
                clients :brand.brandName 

            });
           setClients(response.data.agencies);
          } catch (e) {
            console.log(e);
          }
        };
  
    
  
        const fetchCaseStudies = async () => {
          try {
            const response = await axios.post("/campaigns/getnewCaseStudyByBrandName", {
                brandName:brand.brandName
            });
            if(response.data)
            setCaseStudies(response.data);
          } catch (e) {
            console.log(e);
          }
        };

        fetchClients();
        fetchCaseStudies();
        setLoading(false)
      }
    }, [brand]);
  
    
    
  
     
  
   
    function capitalize(input) {
      if (input === undefined) {
        return "";
      }
      var words = input.split(" ");
      var CapitalizedWords = [];
      words.forEach((element) => {
        CapitalizedWords.push(
          element[0].toUpperCase() + element.slice(1, element.length)
        );
      });
      return CapitalizedWords.join(" ");
    }
  
    function getBrandlogo(brandName) {
      const client = clients.find(data => data.clientName == brandName);
      return client&&client.clientLogo?client.clientLogo:brand.brandLogo
    //   return (client?.clientLogo || '');
    }

    if(loading){
      return <Loader/>
    }else return (
      <div className="agency__profile">
        <div className="agency__profile_header">
          <div className="left">
            {profileFound&&brand.brandLogo.includes("unispade-react-js-s3")?<p className="agency_logo brandlogoInitials">{brand.brandName[0].toUpperCase()}</p>:<img
              className="agency_logo"
              src={brand.brandLogo}
              alt="logo"
            />}
            <div>
              <div className="info_div">
                <h2 className="agency_name">{profileFound&&capitalize(brand.brandName)}</h2>
                {profileFound&&<img
                  src={verifiedIcon}
                  alt="verified icon"
                  className="verified_icon"
                />}
              </div>
              <div className="agency_infos">
              {brand.foundedYear && (
                <div className="agency_info">
                  <img src={briefcaseIcon} alt="briefcase icon" />
                  <p>{brand.foundedYear ? new Date().getFullYear() - brand.foundedYear?.substring(0, 4) : 0}{" "} year(s)</p>
                </div>
              )}
                {brand.locations && brand.locations.length > 0 &&(
                  <div className="agency_info">
                    <img src={mapIcon} alt="map icon" />
                    <p>{brand?.locations?.length ? brand.locations[0] : ''}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="right">
            {/* <Link to={"/agencyprofile/contact/"+agency._id}>
            <button className="agheaderBtn">Contact Agency</button>
            </Link> */}
            {profileFound&&<a href={`${brand?.brandWebsite}?utm_source=Unispade&utm_medium=Brand+Profile&utm_campaign=Referral`} target="_blank">
              <button className="agheaderBtn" onClick={brand.brandWebsite}>Visit Website</button>
            </a>}         
          </div>
        </div>
  
        <div className="agency__profile_content">
          {brand?.locations?.map((location) => (
            <div className="agency__profile_tags">
                <span className="agency__profile_tag font__lexend">{capitalize(location)}</span>
            </div>
          ))}

          {/* <div className="agency__profile_tags">
            {brand.locations.map((location) => (
              <span key={location} className="agency__profile_tag font__lexend">
                {capitalize(location)}
              </span>
            ))}
          </div> */}
  
          {/* <p className="agency__profile_description font__lexend">
            {agency.aboutAgency}
          </p> */}
  
          {/* Services */}
          {/* <h3 className="agency__profile_heading font__lexend">Services</h3>
          <div className="agency__profile_tags">
            {(agency?.services || []).map((service) => (
              <span className="agency__profile_tag font__lexend">
                {service}
              </span>
            ))}
          </div> */}
  
          {/* Clients */}
         {clients && clients.length > 0 &&<> <h3 className="agency__profile_heading font__lexend">Agencies</h3>
          <div className="agency__profile_clients">
            {clients.map((client) => (
              <div>
                {client.agencyLogo.includes("unispade-react-js-s3")?<div className="clientProfiles">
              <p className="agency_logo agencylogoInitials">{client.agencyName[0].toUpperCase()}</p>
              <span>{client.agencyName}</span>
              </div>:
                <a className="clientUrl" href={`../agency-profile/${client.agencyName}`} target="_blank">
                  <img src={client.agencyLogo} alt={client.agencyName} />
                </a>}
              </div>
            ))}
          </div></>}
  
          {/* Portfolio */}
          {caseStudies && caseStudies.length > 0 && ( 
              <h3
              className="agency__profile_heading font__lexend"
              style={{ marginTop: "30px" }}
              >
              Portfolio
              </h3>
          )}        
          <div className="case__studies" style={{ paddingTop: "30px" }}>
            {caseStudies.map((caseStudy) => (
            <CaseStudyCard key={caseStudy._id} caseStudy={caseStudy} />
            ))}
          </div>
  
          
  
          {/* Packages */}
          {/* <h3 className="agency__profile_heading font__lexend">Packages</h3> */}
          {/* {packages.map((packages) => (
            <div className="package_box">
              <h5 className="package_name font__lexend">
                Social Media Marketing
              </h5>
              <div className="pricing">
                <div className="input_field">
                  <select
                    className="field_box"
                    name="website"
                    id="website"
                    required
                  >
                    <option value="" disabled selected>
                      {packages.options[0]}
                    </option>
                    {packages.options.map((option) => (
                      <option value={option}>{option}</option>
                    ))}
                  </select>
                </div>
                <p className="price font__lexend">₹{packages.price}</p>
              </div>
              <div className="agency__profile_tags">
                {packages.tags.map((tag) => (
                  <span className="agency__profile_tag font__lexend">
                    {tag}
                  </span>
                ))}
              </div>
              <p className="agency__profile_description font__lexend">
                {packages.description}
              </p>
              <button className="font__lexend">Inquire</button>
            </div>
          ))} */}
          {/* <button className="view_all font__lexend">View All</button> */}
        </div>
      </div>
    );
}

export default BrandProfile




 

 
