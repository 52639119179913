
import React, { useState, useEffect, useContext, useRef, Component } from "react";
import "../../styles/AEditProfile.css";
import "../../styles/Autosuggest.css";
import NavbarEditProfile from "../NavbarEditProfile";
import redcross from "../../images/redcross.png";
import ASidebarAlt from "./ASidebarAlt";
import authHeader from "../../login-auth.js";
import { useHistory } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import axios from "../../axios.js";
import RatingStars from "../RatingStars";
import ReactS3 from "react-s3";
import Autosuggest from 'react-autosuggest';
import CaseStudyPortfolio from '../CaseStudyPortfolio/CaseStudyPortfolio';
import { uploadImage } from '../../uploadImage'
import DeleteImage from "../../images/delete.png"

const config = {
  bucketName: "unispade-s3",
  dirName: "clientLogos" /* optional */,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};
const configMedia = {
  bucketName: "unispade-s3",
  dirName: "caseStudyMedia" /* optional */,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};
const configAward = {
  bucketName: "unispade-s3",
  dirName: "awardsLogo" /* optional */,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};
const configNews = {
  bucketName: "unispade-s3",
  dirName: "newsLogo" /* optional */,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
};

function AEditProfile(props) {


  const history = useHistory();
  const params = useParams();
  let location = useLocation();
  const [clientloadbtn, setClientloadbtn] = useState(false);
  const [caseloadbtn, setCaseloadbtn] = useState(false);
  const [uploadbtn, setUploadbtn] = useState(false);
  const [uploadBtnStatus, setUploadBtnStatus] = useState(false);
  const info = authHeader();
  const [awards, setAwards] = useState([]);
  const [basicAgencyDetails, setBasicAgencyDetails] = useState({
    agencyName: "",
    foundedYear: "",
    agencySize: 0,
    agencyWebsite: "",
    locations: [],
    aboutAgency: "",
  });
  const [temp, setTemp] = useState(0);
  const [newClient, setNewClient] = useState({
    clientId: "",
    clientName: "",
    clientURL: "",
    clientLogo: "",
    clientReview: "",
    clientServices: "",
  });
  const [awardBoxes, setAwardBoxes] = useState([]);
  const [allIndustries, setAllIndustries] = useState([]);
  const [newsMentions, setNewsMentions] = useState([]);
  const [locationBoxes, setLocationBoxes] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [clientToEdit, setClientToEdit] = useState(-1);
  const [awardToEdit, setAwardToEdit] = useState(-1);
  const [caseStudyToEdit, setCaseStudyToEdit] = useState(-1);

  const [serviceBoxes, setServiceBoxes] = useState([
    { no: 1, name: 0, check: false },
    { no: 2, name: 1, check: false },
    { no: 3, name: 2, check: false },
  ]);
  const [caseStudies, setCaseStudies] = useState([]);
  const [checkServices, setCheckServices] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [canSelect, setCanSelect] = useState(true);
  const [services, setServices] = useState([]);
  const [updatesServices, setUpdateServices] = useState([])

  const [clients, setClients] = useState([{}]);
  const [flag, setFlag] = useState(0);
  const [addMoreNews, setAddMoreNews] = useState(false);
  const [moreNewsMention, setMoreNewsMention] = useState({
    newsHeading: "",
    newsURL: "",
    activate: true,
    image: "",
  });

  const [statisticsBoxes, setStatisticsBoxes] = useState([]);
  const [newsMentionToEdit, setNewsMentionToEdit] = useState(-1);
  const [yearOptions, setYearOptions] = useState([]);
  const [focusNav, setFocusNav] = useState("basicinfo");
  const Filemandatory = () => {
    document.getElementById("myFile").required = true;
  }

  const listenScrollEvent = (event) => {
    const sections = document.querySelectorAll('div[id]');
    let currentId = '';

    for (let index = 1; index < sections.length; index++) {
      let sectionTop = sections[index].offsetTop;
      let sectionHeight = sections[index].clientHeight;

      if (window.scrollY >= (sectionTop - sectionHeight / 3)) {
        currentId = sections[index].getAttribute('id');
      }
    }
    if (currentId) setFocusNav(currentId);

  };


  useEffect(() => {
    var currentYear = new Date().getFullYear();
    var years = [];
    var startYear = 1900;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    setYearOptions(years);
    window.addEventListener("scroll", listenScrollEvent);


    return () => {
      setUploadBtnStatus(false);
      window.removeEventListener("scroll", listenScrollEvent);
    }
  }, []);

  const addStatisticsHandler = () => {
    if (openMoreCaseStudy === true) {
      setNewCaseStudy((p) => {
        p.statistics.push({
          reach: "",
          stats1: "",
          revenue: "",
          stats2: "",
        });
        return p;
      });
    } else {
      setCaseStudies((p) => {
        p.forEach((cs) => {
          cs.statistics.push({
            reach: "",
            stats1: "",
            revenue: "",
            stats2: "",
          });
        });
        return p;
      });
    }

    setStatisticsBoxes((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name });
      });
      newAr.push({ no: l + 1, name: l });
      return newAr;
    });
  };

  const statisticsChangeHandler = (i, j, e) => {
    const { name, value } = e.target;
    setTemp((p) => p + 1);

    setCaseStudies((p) => {
      p[i].statistics[j][name] = value;
      return p;
    });
  };

  const [mediaBoxes, setMediaBoxes] = useState([
    { no: 1, name: 0 },
    { no: 2, name: 1 },
    { no: 3, name: 2 },
    { no: 4, name: 3 },
  ]);


  const addMediaHandler = () => {
    setMediaBoxes((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name });
      });
      newAr.push({ no: l + 1, name: l });
      return newAr;
    });
  };

  const [goalsMediaBox, setGoalsMediaBox] = useState([
    { no: 1, name: 0 },
  ]);

  const addGoalsMediaHandler = () => {
    setGoalsMediaBox((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name });
      });
      newAr.push({ no: l + 1, name: l });
      return newAr;
    });
  };

  const addMediaBoxHandler = (x) => {
    setMediaBox((prev) => {
      const l = prev[x].length;
      var newAr = [];
      prev[x].forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name });
      });
      newAr.push({ no: l + 1, name: l });
      return {
        ...prev,
        [x]: newAr
      }
    });
  };


  const [mediaBox, setMediaBox] = useState({
    goals: [
      { no: 1, name: 0 },
    ],
    challenges: [
      { no: 1, name: 0 },
    ],
    ideaAndSolution: [
      { no: 1, name: 0 },
    ],
    results: [
      { no: 1, name: 0 },
    ],
  });


  const [teamBoxes, setTeamBoxes] = useState([]);

  const addTeamHandler = () => {
    if (openMoreCaseStudy === true) {
      setNewCaseStudy((p) => {
        p.team.push({
          name: "",
          position: "",
          mailId: "",
          isAdmin: false,
        });
        return p;
      });
    } else {
      setCaseStudies((p) => {
        p.forEach((cs) => {
          cs.team.push({
            name: "",
            position: "",
            mailId: "",
            isAdmin: false,
          });
        });
        return p;
      });
    }
    setTeamBoxes((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name });
      });
      newAr.push({ no: l + 1, name: l });
      return newAr;
    });
  };

  var uploadLogo = "";
  useEffect(() => uploadLogo = newClient.clientLogo, [newClient.clientLogo])

  const teamChangeHandler = (i, j, e) => {
    const { name, value } = e.target;
    setTemp((p) => p + 1);

    const t = "isAdmin";
    if (value === "Admin" || value === "General") {
      if (value === "Admin") {
        setCaseStudies((p) => {
          p[i].team[j][t] = true;
          return p;
        });
      } else {
        setCaseStudies((p) => {
          p[i].team[j][t] = false;
          return p;
        });
      }
    } else {
      setCaseStudies((p) => {
        p[i].team[j][name] = value;
        return p;
      });
    }
  };

  function capitalize(input) {
    if (input === null || input === undefined || input === "") return input;
    var words = input.split(" ");
    var CapitalizedWords = [];
    words.forEach((element) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(" ");
  }
  var checkSerArrlength = 0;
  const [ServiceError, SetServiceError] = useState(false);
  const addMoreService = () => {
    setServices((prev) => {
      checkSerArrlength = prev.length
      return prev;
    });

    setServiceBoxes((prev) => {
      const l = prev.length;
      let obj = { no: l + 1, name: l, check: false }
      let newAr = [...prev, obj];
      // prev.forEach((i, ind) => {
      //   newAr.push({ no: l - ind, name: i.name, check: prev[ind].check });
      // });
      // newAr.push({ no: l + 1, name: l, check: false });
      if (newAr.length > checkSerArrlength) {
        SetServiceError(true)
      }
      else {
        SetServiceError(false)
      }
      return newAr;
    });
  };

  const serviceCheck = (event) => {
    setFlag((p) => p + 1);
    const name = event.target.name;
    const value = event.target.checked;

    if (value) {
      var ct = 0;
      serviceBoxes.forEach((s) => {
        if (s.check === true) ct++;
      });
      if (ct < 3)
        setServiceBoxes((prev) => {
          prev[name].check = true;
          return prev;
        });
    } else {
      setServiceBoxes((prev) => {
        prev[name].check = false;
        return prev;
      });
    }
  };

  const addMoreNewsMentions = () => {
    setAddMoreNews(true);
    // setNewsBoxes((prev) => {
    //   const l = prev.length;
    //   var newAr = [];
    //   prev.forEach((i, ind) => {
    //     newAr.push({ no: l - ind, name: i.name });
    //   });
    //   newAr.push({ no: l + 1, name: l });
    //   return newAr;
    // });
  };

  const newsMentionsEditHandler = (ind) => {
    if (newsMentionToEdit === -1) setNewsMentionToEdit(ind);
    else setNewsMentionToEdit(-1);
  }; // </tr></p>

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const res = await axios.get("/brands/register", {
          headers: { authorization: "Bearer " + authHeader().Token },
        });
        setBrands(res.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchBrands();

    const fetchAwards = async () => {
      try {
        const res = await axios.post(
          "/agencyEditProfile/getAllAwards",
          { agencyId: params._id ? params._id : authHeader().User.agencyId },
          {
            headers: { authorization: "Bearer " + authHeader().Token },
          }
        );

        setAwards(res.data[0].awardDetails);
      } catch (e) {
        console.log(e);
      }
    };

    const fetchNewsMentions = async () => {
      try {
        const res = await axios.post(
          "/agencyProfile/getNewsMentions",
          { agencyId: params._id ? params._id : authHeader().User.agencyId },
          {
            headers: { authorization: "Bearer " + authHeader().Token },
          }
        );
        setNewsMentions(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchAwards();
    fetchNewsMentions();
    const fetchAllIndustries = async () => {
      try {
        const res = await axios.get(
          "/superAdmin/filters/getAllIndustries",
          {
            headers: { authorization: "Bearer " + authHeader().Token },
          }
        );
        setAllIndustries(res.data.selectedIndustries);
      } catch (e) {
        console.log(e);
      }
    };
    fetchAllIndustries();
  }, []);

  useEffect(() => {
    var ct = 0;
    serviceBoxes.forEach((b) => {
      if (b.check == true) ct++;
      if (ct >= 3) setCanSelect(false);
    });
    if (ct >= 3) setCanSelect(false);
    else setCanSelect(true);
  }, [serviceBoxes, flag]);


  var checklocArrlength;
  const [err, seterr] = useState(false);


  const addMoreLocation = () => {


    setBasicAgencyDetails((prev) => {
      seterr(false)

      checklocArrlength = prev.locations.length
      //if(prev.locations.length != )
      //prev.locations.push("");
      return prev;
    });

    setLocationBoxes((prev) => {

      const l = prev.length;
      var newAr = [...prev, parseInt(prev[l - 1]) + 1];
      // prev.forEach((i, ind) => {
      //   newAr.push(l - ind);


      // });
      // newAr.push(l + 1);
      if (newAr.length > checklocArrlength) {
        seterr(true)
      }
      else {
        seterr(false)
      }
      return newAr;
    });

  };
  const serviceChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const filterdata = services.filter(a => a === value);
    if (filterdata.length !== 0) {
    } else {
      setServices((prev) => {
        prev[name] = value;
        return prev;
      });
    }
  };

  const clientEditHandler = (ind) => {
    // if (ind === -1)
    setClientToEdit(ind);
    // else setClientToEdit(-1);
  };

  const getAllClients = async () => {
    const res = await axios.post(
      "/getClientsbyAgencyId",
      { agencyId: params._id ? params._id : authHeader().User.agencyId },
      {
        headers: { authorization: "Bearer " + authHeader().Token },
      }
    );
    setClients(res.data[0].agencyClients);
  };

  useEffect(() => {
    try {
      const getInfo = async () => {
        const id = params._id ? params._id : authHeader().User.agencyId;
        const res = await axios.post(
          "/agencyOnboarding/getAgencyById",
          {
            id,
          },
          { headers: { authorization: "Bearer " + authHeader().Token } }
        );
        const sortedServices = res?.data?.services.sort(caseInsensitiveSort);
        setServices(sortedServices);
        setNewCaseStudy(prev => {
          return {
            ...prev,
            services: sortedServices
          }
        })
        const temp1 = [];
        // { no: 1, name: 0, check: false },
        sortedServices.forEach((p, i) => {
          temp1.push({ no: i + 1, name: i, check: i < 3 ? true : false });
        });
        setServiceBoxes(temp1);
        setBasicAgencyDetails({
          agencyName: res.data.agencyName,
          foundedYear: "",
          agencySize: res.data.agencySize,
          agencyWebsite: res.data.agencyWebsite,
          locations: res.data.locations,
          aboutAgency: res.data.aboutAgency,
          foundedYear:res.data.foundedYear
        });
        const temp = [];
        res.data.locations.forEach((p, i) => {
          temp.push(i + 1);
        });
        setLocationBoxes(temp);
      };

      const getLocationOptions = async () => {
        const res = await axios.get("/superAdmin/filters/getAllLocations", {
          headers: { authorization: "Bearer " + authHeader().Token },
        });
        setLocationOptions(res.data);
      };
      const getCaseStudyById = async () => {
        const res = await axios.post(
          "/agencyProfile/getCaseStudyById",
          { agencyId: params._id ? params._id : authHeader().User.agencyId },
          { headers: { authorization: "Bearer " + authHeader().Token } }
        );
        if (res.data != null) {
          setCaseStudies(res.data.caseStudies);
        }
      };
      const getAllServices = async () => {
        const res = await axios.get("/superAdmin/filters/getAllServices", {
          headers: { authorization: "Bearer " + authHeader().Token },
        });
        const sortedServices = res?.data?.selectedServices.sort(caseInsensitiveSort);
        setServiceOptions(sortedServices);
      };

      const caseInsensitiveSort = (a, b) => {
        var ret = 0;
        a = a.toLowerCase(); b = b.toLowerCase();
        if (a > b)
          ret = 1;
        if (a < b)
          ret = -1;
        return ret;
      }



      getAllClients();
      getAllServices();
      getLocationOptions();
      getInfo();
      getCaseStudyById();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const [openMoreCaseStudy, setOpenMoreCaseStudy] = useState(false);
  const [newCaseStudy, setNewCaseStudy] = useState({
    brand: "",
    coverImg: "",
    projectTitle: "",
    services: [],
    industry: "",
    overview: "",
    goals: {
      text: "",
      imgtype: "",
      image: []
    },
    challenges: {
      text: "",
      imgtype: "",
      image: []
    },
    ideaAndSolution: {
      text: "",
      imgtype: "",
      image: []
    },
    results: {
      text: "",
      imgtype: "",
      image: []
    },
    statistics: [
      {
        reach: "",
        stats1: "",
        revenue: "",
        stats2: "",
      },
    ],
    team: [
      {
        name: "",
        position: "",
        mailId: "",
        isAdmin: false,
      },
      {
        name: "",
        position: "",
        mailId: "",
        isAdmin: false,
      },
      {
        name: "",
        position: "",
        mailId: "",
        isAdmin: false,
      },
    ],
  });

  const advnewCaseStudyChangeHandler = (x, y, e) => {
    const { value } = e.target;
    setNewCaseStudy((prev) => {
      return {
        ...prev,
        [x]: {
          ...prev[x],
          [y]: value,
        }
      };
    });
  }

  const removeService = (e, s) => {
    e.preventDefault()
    let services = newCaseStudy.services;
    let filteredServices = services.filter((service) => {
      return service !== s
    })
    setNewCaseStudy(prev => {
      return {
        ...prev,
        services: filteredServices
      }
    })
  }


  const addMoreCaseStudy = () => {
    setCaseStudyToEdit(-1);
    setOpenMoreCaseStudy(true);
    setStatisticsBoxes([{ no: 2, name: 0 }]);
    setTeamBoxes([
      { no: 2, name: 0 },
      { no: 2, name: 1 },
      { no: 2, name: 2 },
    ]);
  };

  const caseStudyRemoveHandler = async (ind) => {
    try {
      const res = await axios.post(
        "agencyEditProfile/deleteCaseStudy",
        {
          agencyId: params._id ? params._id : authHeader().User.agencyId,
          studiesId: caseStudies[ind]._id,
        },
        { headers: { authorization: "Bearer " + authHeader().Token } }
      );
      setCaseStudies(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const caseStudyEditHandler = (ind) => {
    if (caseStudyToEdit === -1) {
      setOpenMoreCaseStudy(false);
      setCaseStudyToEdit(ind);
      var st = caseStudies[ind].statistics.filter(
        (s) =>
          s.reach !== "" ||
          s.stats1 !== "" ||
          s.stats2 !== "" ||
          s.revenue !== ""
      );
      setStatisticsBoxes((prev) => {
        const l = prev.length;
        var newAr = [];
        st.forEach((s, i) => {
          newAr.push({ no: l + i, name: i });
        });
        return newAr;
      });
      var sc = caseStudies[ind].team.filter(
        (s) => s.name !== "" || s.position !== "" || s.mailId !== ""
      );

      setTeamBoxes((prev) => {
        const l = prev.length;
        var newAr = [];
        sc.forEach((s, i) => {
          newAr.push({ no: l + i, name: i });
        });
        return newAr;
      });
    } else {
      setCaseStudyToEdit(-1);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([])
  function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  function getSuggestions(value) {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return [];
    }

    const regex = new RegExp('^' + escapedValue, 'i');

    return allBrands.filter(brand => regex.test(brand));
  }

  const getClientDetailsbyclientsName = async (clientname) => {
    let payload = {
      clientname,
    };
    const response = await axios.post(
      "/getClientDetailsbyclientsName",
      payload
    );
    setNewClient((prev) => {
      return {
        ...prev,
        clientId: response.data?._id,
        clientName: response.data?.clientName,
        clientURL: response.data?.clientURL,
        clientLogo: response.data?.clientLogo,
      };
    });
  }

  function getSuggestionValue(suggestion) {
    // let payload = {
    //   clientname: suggestion,
    // };
    // const response = await axios.post(
    //   "/getClientDetailsbyclientsName",
    //   payload
    // );
    setNewClient((prev) => {
      return {
        ...prev,
        clientName: suggestion,
      };
    });
    getClientDetailsbyclientsName(suggestion);
    return suggestion;
  }

  function renderSuggestion(suggestion) {
    return (
      <span>{suggestion}</span>
    );
  }
  ////
  const suggestChange = (event, { newValue, method }) => {

    setNewClient((prev) => {
      return {
        ...prev,
        clientName: newValue,
      };
    });
    setValue(newValue)
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const temp = getSuggestions(value);
    setSuggestions(temp);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  ////

  const inputProps = {
    placeholder: "Brand Name",
    value,
    onChange: suggestChange
  };
  /////////////////////////////////////////////////////////////////////////////////////////////

  const newClientHandler = async (e) => {
    const { value, name } = e.target;
    if (name === "clientLogo") {
      try {
      const fileUrl = await uploadImage(e.target.files[0]);
        setNewClient((prev) => {
          return {
            ...prev,
            clientLogo: fileUrl
          };
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      setNewClient((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };
  const [clientError, setClientError] = useState("");
  const checkClientError = () => {
    if (!newClient.clientName) {
      return "Please Enter Brand Name"
    } else if (!newClient.clientURL) {
      return "Please Enter Client's Website"
    } else if (!newClient.clientServices) {
      return "Please Select a Service"
    } else if (!newClient.clientLogo) {
      return "Please Upload Client Logo"
//     } else if (!newClient.clientReview) {
//       return "Please Enter Client Reviews"
     } else {
      return ""
    }
  }


  const [tempCounter, setTempCounter] = useState(0);
  const newClientSaveHandler = async (e) => {
    e.preventDefault();
    setClientloadbtn(true);
    var msg = checkClientError();
    setClientError(msg)
    if (msg) {
      setClientloadbtn(false);
    } else {
      try {
        if (newClient.clientId != "") {
          const res = await axios.post(
            "/addNewClientDetails",
            {
              agencyId: params._id ? params._id : authHeader().User.agencyId,
              clientId: newClient.clientId,
              clientDetails: {
                clientName: newClient.clientName,
                clientURL: newClient.clientURL,
                clientLogo: newClient.clientLogo
              },
              clientreview: newClient.clientReview,
              clientServices: newClient.clientServices

            }
            ,
            {
              headers: { authorization: "Bearer " + authHeader().Token },
            }
          );
        } else {
          const res = await axios.post(
            "/addNewClientDetails",
            {
              agencyId: params._id ? params._id : authHeader().User.agencyId,
              clientDetails: {
                clientName: newClient.clientName,
                clientURL: newClient.clientURL,
                clientLogo: newClient.clientLogo
              },
              clientreview: newClient.clientReview,
              clientServices: newClient.clientServices

            }
            ,
            {
              headers: { authorization: "Bearer " + authHeader().Token },
            }
          );
        }
        setTempCounter((p) => p + 1);
        setOpenNewClient(false);
        setClients((p) => {
          const t = p;
          t.push(newClient);
          return t;
        });
        setNewClient({
          clientId: "",
          clientName: "",
          clientURL: "",
          clientLogo: "",
          clientReview: "",
          clientServices: "",
        });
        var temp = [...new Set(clients)];
        setClients(temp);
        setClientloadbtn(false);
        setValue("");
      } catch (e) {
        console.log(e);
      }
    }
  };

  const basicDetailsChangeHandler = (event) => {
    const { value, name } = event.target;
    if (!name.includes("locations")) {
      setBasicAgencyDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    } else {
      const ind = name.split("locations");
      setBasicAgencyDetails((prev) => {
        prev.locations[ind[1]] = value;

        return prev;
      });
      // setLocationBoxes((prev) => {
      //   const l = prev.length;
      //   var newAr = [];
      //   prev.forEach((i, ind) => {
      //     newAr.push(l - ind);
      //   });
      //   return newAr;
      // });
    }
  };

  const removeClient = async (i) => {
    try {
      const res = await axios.post(
        "/deleteClients",
        {
          agencyId: params._id ? params._id : authHeader().User.agencyId,
          clientId: clients[i]._id
        }
      );
      getAllClients();
    } catch (e) {
      console.log(e);
    }
  };


  const clientChangeHandler = async (ind, e) => {
    setClientToEdit((p) => p + 1);
    const { value, name } = e.target;
    if (name === "clientLogo") {
      try {
      const fileUrl = await uploadImage(e.target.files[0]);
        setClients((p) => {
          p[ind].clientLogo = fileUrl;
          return p;
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      setTemp((p) => p + 1);
      setClients((prev) => {
        prev[ind][name] = value;
        return prev;
      });
    }
    setClientToEdit((p) => p - 1);
  };

  const clientSaveHandler = async (i) => {
    // loading icon starts
    setClientloadbtn(true);

    // loading icon ends
    try {
      const res = await axios.post(
        "/editClient",
        {
          agencyId: params._id ? params._id : authHeader().User.agencyId,
          clientId: clients[i]._id,
          clientDetails: {
            clientName: clients[i].clientName,
            clientURL: clients[i].clientURL,
            clientLogo: clients[i].clientLogo
          },
          clientreview: clients[i].clientReview,
          clientServices: clients[i].clientServices

        },
        {
          headers: { authorization: "Bearer " + authHeader().Token },
        }
      );
      setClientToEdit(-1);
      setClientloadbtn(false);
    } catch (e) {
      console.log(e);
    }
  };
  const [openNewClient, setOpenNewClient] = useState(false);

  const [allBrands, setAllBrands] = useState([]);

  const addMoreClient = async () => {
    setOpenNewClient(true);
    axios.post("/getAllDistinctClientsName")
      .then(res => setAllBrands(res.data));
  };

  const caseStudyChangeHandler = (ind, event) => {
    const { name, value } = event.target;
    setTemp((p) => p + 1);
    setCaseStudies((prev) => {
      prev[ind][name] = value;
      return prev;
    });
  };
  const newStatisticsChangeHandler = (j, e) => {
    const { name, value } = e.target;

    setNewCaseStudy((prev) => {
      prev.statistics[j][name] = value;
      return prev;
    });
  };
  const newTeamChangeHandler = (j, e) => {
    const { name, value } = e.target;
    const t = "isAdmin";
    if (name === "isAdmin") {
      if (value === "Admin") {
        setNewCaseStudy((p) => {
          p.team[j][t] = true;
          return p;
        });
      } else {
        setNewCaseStudy((p) => {
          p.team[j][t] = false;
          return p;
        });
      }
    } else {
      setNewCaseStudy((p) => {
        p.team[j][name] = value;
        return p;
      });
    }
  };
  const [farzi, setFarzi] = useState(0);
  const newCaseStudySubmitHandler = async () => {

    setCaseloadbtn(true);
    setOpenMoreCaseStudy(false);
    const temp = newCaseStudy.statistics.filter(
      (item) =>
        item.reach !== "" ||
        item.stats1 !== "" ||
        item.stats2 !== "" ||
        item.revenue !== ""
    );
    setNewCaseStudy((p) => {
      p.statistics = temp;
      return p;
    });
    const temp1 = newCaseStudy.team.filter(
      (item) => item.name !== "" || item.position !== "" || item.mailId !== ""
    );
    setNewCaseStudy((p) => {
      p.team = temp1;
      return p;
    });
    try {
      const res = await axios.post(
        "/agencyOnboarding/caseStudy",
        { agencyId: params._id ? params._id : authHeader().User.agencyId, studies: [newCaseStudy] },
        {
          headers: { authorization: "Bearer " + authHeader().Token },
        }
      );
      setFarzi((p) => p + 1);

      setCaseStudies((p) => {
        p.push(newCaseStudy);
        return p;
      });
      var temp2 = [...new Set(caseStudies)];
      setCaseStudies(temp2);
      setNewCaseStudy({
        client: "",
        coverImg: "",
        projectTitle: "",
        services: services,
        industry: "",
        overview: "",
        goals: {
          text: "",
          imgtype: "",
          image: []
        },
        challenges: {
          text: "",
          imgtype: "",
          image: []
        },
        ideaAndSolution: {
          text: "",
          imgtype: "",
          image: []
        },
        results: {
          text: "",
          imgtype: "",
          image: []
        },
        statistics: [
          {
            reach: "",
            stats1: "",
            revenue: "",
            stats2: "",
          },
        ],
        team: [
          {
            name: "",
            position: "",
            mailId: "",
            isAdmin: false,
          },
          {
            name: "",
            position: "",
            mailId: "",
            isAdmin: false,
          },
          {
            name: "",
            position: "",
            mailId: "",
            isAdmin: false,
          },
        ],
      });
    } catch (e) {
      console.log(e);
    }
    setCaseloadbtn(false);
  };
  const saveCaseStudyChangesHandler = async (ind) => {
    setCaseloadbtn(true);

    setCaseStudyToEdit(-1);
    var temp = [];
    temp = caseStudies[ind].statistics.filter(
      (s) =>
        s.reach !== "" || s.stats1 !== "" || s.stats2 !== "" || s.revenue !== ""
    );
    var temp1 = [];
    temp1 = caseStudies[ind].team.filter(
      (s) => s.name !== "" || s.position !== "" || s.mailId !== ""
    );
    var newStudies = caseStudies[ind];
    newStudies.statistics = temp;
    newStudies.team = temp1;

    try {
      const res = await axios.post(
        "/agencyEditProfile/editCaseStudy",
        {
          agencyId: params._id ? params._id : authHeader().User.agencyId,
          studiesId: caseStudies[ind]._id,
          updatedStudy: newStudies,
        },
        {
          headers: { authorization: "Bearer " + authHeader().Token },
        }
      );
      setCaseStudies(res.data.studies);
      setCaseloadbtn(false);
    } catch (e) {
      console.log(e);
    }
  };

  const awardEditHandler = (ind) => {
    if (awardToEdit === -1) {
      setAwardToEdit(ind);
    } else {
      setAwardToEdit(-1);
    }
  };

  const awardEditChangeHandler = async (ind, event) => {
    const { value, name } = event.target;
    if (name === "awardLogo") {
      try {
        const res = await uploadImage(event.target.files[0]);
        setAwards((p) => {
          p[ind].awardLogo = res;
          return p;
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      setTemp((p) => p + 1);
      setAwards((p) => {
        p[ind][name] = value;
        return p;
      });
    }
  };

  const awardEditSaveHandler = async (ind) => {
    try {
      setAwardToEdit(-1);
      const res = await axios.post(
        "/agencyEditProfile/editAward",
        {
          agencyId: params._id ? params._id : authHeader().User.agencyId,
          updatedAward: awards[ind],
          awardId: awards[ind]._id,
        },
        {
          headers: { authorization: "Bearer " + authHeader().Token },
        }
      );
      setAwards(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const removeAward = async (ind) => {
    try {
      const res = await axios.post(
        "/agencyEditProfile/removeAward",
        { agencyId: params._id ? params._id : authHeader().User.agencyId, awardId: awards[ind]._id },
        {
          headers: { authorization: "Bearer " + authHeader().Token },
        }
      );
      setAwards(res.data);
      setAwardToEdit(-1);
    } catch (e) {
      console.log(e);
    }
  };

  const [newAward, setNewAward] = useState({
    awardName: "",
    awardLink: "",
    awardLogo: "",
  });
  const [openMoreAward, setOpenMoreAward] = useState(0);
  const addMoreAwardHandler = () => {
    setOpenMoreAward(1);
  };
  const newAwardChangeHandler = async (event) => {
    const { name, value } = event.target;
    if (name === "awardLogo") {
      try {
        const res = await uploadImage(
          event.target.files[0]
        );
        setNewAward((p) => {
          p.awardLogo = res;
          return p;
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      setNewAward((p) => {
        p[name] = value;
        return p;
      });
    }
  };

  const newAwardSaveHandler = async () => {
    try {
      const res = await axios.post(
        "/agencyEditProfile/addAward",
        { agencyId: params._id ? params._id : authHeader().User.agencyId, newAward: newAward },
        {
          headers: { authorization: "Bearer " + authHeader().Token },
        }
      );
      setAwards(res.data);
      setOpenMoreAward(0);
      setNewAward({
        awardName: "",
        awardLink: "",
        awardLogo: "",
      });
    } catch (e) {
      console.log(e);
    }
  };

  const newsMentionsChangeHandler = async (ind, e) => {
    const { value, name } = e.target;

    if (name === "newsLogo") {
      try {
        const res = await uploadImage(e.target.files[0]);
        setNewsMentions((p) => {
          p[ind].image = res;
          return p;
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      setTemp((p) => p + 1);
      setNewsMentions((p) => {
        p[ind][name] = value;
        return p;
      });
    }
  };

  const newsMentionsSaveHandler = async (i) => {
    try {
      const res = await axios.post(
        "/agencyEditProfile/editNewsMention",
        {
          agencyId: params._id ? params._id : authHeader().User.agencyId,
          newsId: newsMentions[i]._id,
          editNews: newsMentions[i],
        },
        {
          headers: { authorization: "Bearer " + authHeader().Token },
        }
      );
      setNewsMentionToEdit(-1);
    } catch (e) {
      console.log(e);
    }
  };

  const removeNewsMentions = async (i) => {
    try {
      const res = await axios.post(
        "/agencyEditProfile/removeNewsMention",
        {
          agencyId: params._id ? params._id : authHeader().User.agencyId,
          newsId: newsMentions[i]._id,
        },
        {
          headers: { authorization: "Bearer " + authHeader().Token },
        }
      );
      setNewsMentions(res.data);
    } catch (e) {
      console.log(e);
    }
  };




  const moreNewsMentionsChangeHandler = async (e) => {
    const { name, value } = e.target;
    if (name === "newsLogo") {
      try {
        const res = await uploadImage(e.target.files[0]);
        setMoreNewsMention((p) => {
          p.image = res;
          return p;
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      setMoreNewsMention((p) => {
        p[name] = value;
        return p;
      });
    }
  };

  const moreNewsMentionsSaveHandler = async () => {
    try {
      const res = await axios.post(
        "/agencyEditProfile/addNewsMention",
        {
          agencyId: params._id ? params._id : authHeader().User.agencyId,
          newNewsMention: moreNewsMention,
        },
        {
          headers: { authorization: "Bearer " + authHeader().Token },
        }
      );
      setAddMoreNews(false);
      setNewsMentions(res.data);
      setMoreNewsMention({
        newsHeading: "",
        newsURL: "",
        activate: true,
        image: "",
      });
    } catch (e) {
      console.log(e);
    }
  };

  const submitHandler = async () => {
    // loading icon starts
    setUploadbtn(true);
    // loading icon ends
    //console.log(basicAgencyDetails);
    let clientName = [];
    if (clients) {
      clients.map((c, i) => {
        clientName.push(c.clientLogo);
      })
    }


    try {
      var temp = [...new Set(basicAgencyDetails.locations)];
      temp = temp.filter((l) => l !== "");
      setBasicAgencyDetails((prev) => {
        prev.locations = temp;
        return prev;
      });
      const topServices = [];
      serviceBoxes.forEach((s, i) => {
        if (s.check) topServices.push(services[i]);
      });
      const topWithoutDouble = [...new Set(topServices)];
      const servicesWithoutDup = [...new Set(services)];
      const servicesToSave = servicesWithoutDup.filter((s) => s !== "");
      const finalServices = [];
      topWithoutDouble.forEach((d) => {
        finalServices.push(d);
      });
      servicesToSave.forEach((s) => {
        finalServices.push(s);
      });
      const servicesToSend = [...new Set(finalServices)];
      const res = await axios.post(
        "/agencyEditProfile",
        {
          agencyId: params._id ? params._id : authHeader().User.agencyId,
          basicAgencyDetails: {
            agencyName: basicAgencyDetails.agencyName,
            foundedYear: basicAgencyDetails.foundedYear,
            agencySize: basicAgencyDetails.agencySize,
            agencyWebsite: basicAgencyDetails.agencyWebsite,
            locations: temp,
            clients: clientName,
            aboutAgency: basicAgencyDetails.aboutAgency,
          },
          services: servicesToSend,
        },
        {
          headers: { authorization: "Bearer " + authHeader().Token },
        }
      );
      setUploadbtn(false);
      setUploadBtnStatus(true);

    } catch (e) {
      console.log(e);
    }

  };

  const removeLocationHandler = (locationIndex, locationName) => {
    let locationArr = [...locationBoxes];
    let filteredLocationBoxes = locationArr.filter((locationInd) => {
      return locationInd !== locationIndex
    })
    setLocationBoxes(filteredLocationBoxes)

    setBasicAgencyDetails(prev => {
      let filteredLocationNames = prev.locations.filter(name => {
        return name !== locationName;
      })
      prev.locations = filteredLocationNames;
      return prev;
    })
    seterr(false)
  }

  const removeServiceHandler = (selectedService) => {
    let serviceArr = [...serviceBoxes];
    let filteredServiceBoxes = serviceArr.filter((service) => {
      return service.no !== selectedService.no
    })
    setServiceBoxes(filteredServiceBoxes)
    // setLocationBoxes(filteredLocationBoxes)

    setServices(prev => {
      let filteredServiceNames = prev.filter(name => {
        return name !== prev[selectedService.name];
      })
      return filteredServiceNames;
    })
    seterr(false)
  }

  const [AgencyLogo,setAgencyLogo]=useState();
  const [submitlogo, setsubmitlogo] = useState(false);
  const [progress,setProgress]=useState(0);
  const [profilePhotoName,setProfilePhotoName]=useState()
  // const ChangeAgencyLogo = async (e) => {
  //   const { value, name } = e.target;
  //   if (name === 'clientLogo') {
  //     try {
  //       const uniqueKey = new Date().getTime();
  //       const fileName = uniqueKey + '-' + e.target.files[0].name;
  //       setProfilePhotoName(fileName);

  //       const params = {
  //         Bucket: 'unispade-s3',
  //         Key: 'clientMedia/' + fileName,
  //         Body: e.target.files[0],
  //         ContentType: e.target.files[0].type,
  //       };

  //       // Replace the `uploadImage` function with your actual implementation
  //       const fileUrl = await uploadImage(params);
  //       setAgencyLogo(fileUrl);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  // };

  const ChangeAgencyLogo = async (e) => {
    setProfilePhotoName(e.target.files[0].name);
    const res=await uploadImage(e.target.files[0],"/clientMedia/")
    setAgencyLogo(res);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        const updatedProgress = prevProgress + 10;
        if (updatedProgress >= 100) {
          clearInterval(intervalId); // Stop the interval when progress reaches or exceeds 100
          return 100;
        }
        return updatedProgress;
      });
    }, 700);

    return () => {
      clearInterval(intervalId); // Cleanup the interval when the component unmounts
    };
  }, []);

  
  const updateAgencyLogo = async (e) => {
    try {
      setsubmitlogo(true);
      const res = await axios.post(
        "/agencyEditProfileLogo",
        {
          agencyId: params?._id ? params._id : authHeader().User.agencyId,
          basicAgencyDetails: {
            agencyLogo: AgencyLogo,
          },
        },
        {
          headers: { authorization: "Bearer " + authHeader().Token },
        }
      );
      props.updateLogo(AgencyLogo)
      
        setsubmitlogo(false)
        setProfilePhotoName()
    } catch (error) {
      
    }
    setsubmitlogo(false)
  };
  const uploadButtonHandler = (field) => {
   
    let elem = document.getElementById('profileImage');
    if (elem) elem.click();
  
}

const uploadClientLogoButtonHandler = (field) => {
  let elem = document.getElementById('ClientLogo');
  if (elem) elem.click();
}


  return (
    <div className="aeditprofile">

      {localStorage.getItem("user") === null
        ?
        history.push('/login') : ""}
      {localStorage.getItem("user") === null
        ?
        window.location.reload() : ""}
      <ASidebarAlt />
      <div className="section__cont">
        <div className="section">
          <div className="part__left">
            <h1 className="aeditprofile__title text__left">Edit Profile</h1>
          </div>
          <div className="part__right">

            <p className="date__day para adgrey"> </p>
            <button
              style={{ background: "#191E22", color: "#FFFFFF", border: "none", padding: "5px 10px"}}
              className=""
              type="submit"
              name="action"
              id="navbar"
              onClick={() => {

                history.push('/')
                localStorage.clear("user")
                window.location.reload()

              }
              }>Log Out

            </button>
          </div>
        </div>

      </div>
      {/* Basic and contact details */}

      <NavbarEditProfile currentFocus={focusNav}></NavbarEditProfile>
      {/*  */}
      <div>
        <div className="basdetails" id="basicinfo">
          <p className="para text__left fw__600 adashboardTitles">Basic Info</p>
          <div className="basdetails__data">
            <div className="basdetails__data__forms">
              <form className="form">
                <div className="inp__fields flx6">
                  <label className="para">Agency Name</label>
                  <input
                    onChange={basicDetailsChangeHandler}
                    name="agencyName"
                    className="input adgrey"
                    placeholder="Agency name"
                    type="text"
                    value={basicAgencyDetails.agencyName}
                  ></input>
                </div>
                <div className="inp__fields flx6">
                  <label className="para">Year Established</label>
                  <select
                    name="foundedYear"
                    className="select "
                    onChange={basicDetailsChangeHandler}
                  >
                    <option value="">{basicAgencyDetails?.foundedYear?basicAgencyDetails.foundedYear.split("-")[0]:"Select a year"}</option> 
                    {yearOptions.map((y) => (
                     
                      <option value={y}>{y}</option>
                    ))}
                  </select>
                </div>
                <div className="inp__fields flx6">
                  <label className="para">Employees</label>
                  <input
                    name="agencySize"
                    onChange={basicDetailsChangeHandler}
                    className="input adgrey"
                    placeholder="0-20"
                    value={basicAgencyDetails.agencySize}
                  ></input>
                </div>
                <div className="inp__fields flx6">
                  <label className="para">Website</label>
                  <input
                    name="agencyWebsite"
                    onChange={basicDetailsChangeHandler}
                    className="input adgrey"
                    placeholder="https://www.redagency.com"
                    value={basicAgencyDetails.agencyWebsite}
                  ></input>
                </div>
              </form>
              <form className="form wfixform">
                {locationBoxes.map((item, index) => (
                  <div className="inp__fields flx6 wfixinp__cont" key={"loc" + item + index}>
                    <div className="labelcrosscont labelStyle">
                      <label className=" labelf para labelfw">
                        {" "}
                        Office Location {index == 0 ? " 1 (Headquarter)" : index + 1}{" "}
                        {item !== 1 && <img src={redcross} className="redcross" alt="" onClick={() => removeLocationHandler(item, basicAgencyDetails.locations[index])} />}
                        {/* <input type="checkbox"></input> */}

                      </label>
                    </div>
                    <select
                      id="select-location"
                      value={basicAgencyDetails.locations[index]}
                      className="select adgrey wfixinp"
                      onChange={basicDetailsChangeHandler}
                      name={"locations" + index}
                      onClick={() => seterr(false)}
                    >
                      <option value="">Select a Location</option>
                      {locationOptions.map((loc, index) => (
                        <option value={loc} key={"opt-" + loc + index}>{loc}</option>
                      ))}
                    </select>
                  </div>
                ))}
              </form>

              
              <div className="dflex justify__between">

                {!err ? <p
                  className="para color__black fw__600 cursor__pointer"
                  onClick={addMoreLocation}
                //onClick = {console.log(seterr)}
                >
                  + Add More Locations
                </p> : <p
                  className="para color__black fw__600 cursor__pointer"
                  style={{ color: "grey" }}

                > + Add More Locations</p>}
              </div>

              

              
              <div className="agency__overview">
                <div className="part1">
                  <p className="para">Agency Overview</p>
                  <p className="para color__black fw__600 cursor__pointer">
                    Edit
                  </p>
                </div>
                <textarea
                  type="text"
                  name="aboutAgency"
                  className="agency__textarea"
                  onChange={basicDetailsChangeHandler}
                  value={basicAgencyDetails.aboutAgency}
                />
              </div>

              <div className="profilePitchure" style={{textAlign:"left",marginTop:"20px"}}>
            <div className="box" style={{background:"#F8F8F8",border:"1px solid #E3E7E9",display:"flex",flexDirection:"column",padding:"20px 0",minHeight:profilePhotoName?"160px":"80px"}}>
              <div className="inputField" style={{padding:"8px 25px",background:"rgb(255, 255, 255)",border:"1px solid lightgrey",borderRadius:"3.5px",display:"flex",justifyContent:"space-between",width:"98%",margin:"0 auto"}}>
                <p className="Tittle" style={{margin:"auto 0",color:"#6E6E6E",fontWeight:"700",fontSize:"14px"}}>Update Logo</p>
                <button  onClick={uploadButtonHandler}  style={{margin:"auto 0",color:"#191E22",border:"transparent",background:"transparent" ,width:"50px"}}>Add</button>
            </div>
            <input
                
                style={{width:"50px",margin:"-65px  40px 0 auto",visibility:"hidden",cursor:"pointer"}}
                multiple="single"
                type="file"
                onChange={(e) => {
                  ChangeAgencyLogo(e);
                }}
                name="clientLogo"
                id="profileImage"
              ></input>

             {profilePhotoName && <div style={{justifyContent:"center",margin:"auto",display:"flex",gap:"20px",width:"100%",marginBottom:"0"}} >
              <img style={{width:"50px",maxHeight:"100px",borderRadius:"15px"}} src={AgencyLogo?AgencyLogo:"anything"} alt="" />
              <div className="progressBar" style={{margin:"auto 0",width:"80%",borderBottom:"2px solid #191e22"}}>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                  <p style={{color:"#191E22"}}>{profilePhotoName}</p>
                  <img style={{height:"20px"}} onClick={()=>{setAgencyLogo();setProfilePhotoName()}} src={DeleteImage} alt="" />
                </div>
                
                <p style={{position:"relative",margin:"auto 0",marginTop:"2px",width:`${progress}%`,height:"4px",background:"#191E22",zIndex:"2"}}  ></p></div>
              </div>}
          </div>
          {/* <button>Update Profile</button> */} 
          {(AgencyLogo && profilePhotoName)&&<button
            style={{ background: "#191E22", color: "#FFFFFF", border: "none", padding: "5px 10px",marginTop:"20px"}}
            type="button"
            onClick={() => {
              updateAgencyLogo();
            }}
            name="clientLogo"
            value="Update Logo"
          > {submitlogo && (
            <i className="fas fa-circle-notch donebtnclient"></i>
          )}
            {submitlogo && "Updating"}
            {!submitlogo && "Update Logo"}</button>}
          <div className="btn">
         
          

          

        </div>
        </div>


            </div>
          </div>
        </div>
      </div>
      {/* Services */}
      <div id="services">
        <div className="contact__details">
          <p className="para text__left fw__600 adashboardTitles }">Services</p>
          <div className="contact__details__data" >
            <p className="para adgrey">
              Select any 3 focus services. This will help your agency to position
              as an expert in the market
            </p>
            <div className="contact__details__forms">
            <form className="form wfixform">
              {serviceBoxes.map((b, i) => {
                return (
                  <div key={b + i} className="inp__fields flxs wfixinp__cont">
                    <div className="labelcrosscont labelStyle">
                      <label className="labelf para labelfw">
                        {" "}
                        Service {i + 1}{" "}
                        {/* <input
                          disabled={!canSelect && !b.check}
                          checked={b.check}
                          onChange={serviceCheck}
                          name={b.name}
                          type="checkbox"
                        ></input> */}
                      </label>
                      <img src={redcross} className="redcross" alt="" onClick={() => removeServiceHandler(b)} />
                    </div>
                    <select
                      value={services[i]}
                      key={b + 'c' + i}
                      onChange={serviceChangeHandler}
                      name={b.name}
                      className="select adgrey wfixinp"
                      onClick={() => SetServiceError(false)}
                    >
                      <option value="">Select a Service</option>
                      {[...new Set(serviceOptions)] // Convert to Set to eliminate duplicates
                        .sort((a, b) => a.localeCompare(b)) // Sort the options alphabetically
                        .map((s) => {
                          return <option key={s} value={s}>{capitalize(s)}</option>;
                        })}
                    </select>
                  </div>
                );
              })}
            </form>
              <div className="dflex justify__between">
                {!ServiceError ? <p
                  className="para color__black fw__600 cursor__pointer"
                  onClick={addMoreService}
                //onClick = {console.log(seterr)}
                >
                  + Add More Services
                </p> : <p
                  className="para color__black fw__600 cursor__pointer"
                  style={{ color: "grey" }}

                > + Add More Services</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Client */}
      <div id="clients">
        <div className="casestudy" style={{ marginTop: "90px" }}>
          <p className="para text__left fw__600 adashboardTitles">Client</p>
          <div className="casestudy__data">
            <table className="pricing__table">
              <tbody className="">
                <tr className="table__heading__row">
                  <th className="heading__row__part">
                    <p className="theading ">Brand</p>
                  </th>
                  <th className="heading__row__part">
                    <p className="theading ">Service</p>
                  </th>
                  <th className="heading__row__part">
                    <p className="theading ">Website</p>
                  </th>
                  <th className="heading__row__part">
                    <p className="theading ">Case Study</p>
                  </th>
                </tr>
                {clients ?
                  clients?.map((c, i) => (
                    <React.Fragment>

                      <tr className="text__left">
                        <td className="para adgrey">{c.clientName}</td>
                        <td className="para adgrey">{c.clientServices}</td>
                        <td className="para adgrey">{c.clientURL}</td>
                        <td className="para adgrey">View</td>
                        <td className="editnremove__cont hideeditremove">
                          <p
                            className="para editnremove"
                            onClick={() => {
                              clientEditHandler(i);
                            }}
                          >
                            Edit
                          </p>
                          <p
                            className="para editnremove"

                            onClick={() =>

                              removeClient(i)

                            }
                          >
                            Remove
                          </p>
                        </td>
                      </tr>


                      {clientToEdit === i && (
                        <div className="contact__details__data"  >
                          <div className="contact__details__forms" style={{ backgroundColor: "#FF656A1A", padding: "30px" }} >
                            <form className="form client__form" >
                              <div className="inp__fields flx25">

                                <label className="para">Brand</label>
                                <input
                                  onChange={(e) => {
                                    clientChangeHandler(i, e);
                                  }}

                                  value={c.clientName}
                                  name="clientName"
                                  className="input adgrey"
                                  placeholder="Pepsi"
                                  type="text"
                                  required
                                ></input>
                              </div>
                              <div className="inp__fields flxl">
                                <label className="para">Website</label>
                                <input
                                  onChange={(e) => {
                                    clientChangeHandler(i, e);
                                  }}

                                  value={c.clientURL}
                                  name="clientURL"
                                  className="input adgrey"
                                  placeholder="https://www.pepsi.com"
                                  type="text"
                                  required

                                ></input>
                              </div>

                              <div className="inp__fields flxs25">
                                <label className=" labelf para"> Service</label>
                                <select
                                  onChange={(e) => {
                                    clientChangeHandler(i, e);
                                  }}
                                  value={c.clientServices}
                                  name="clientServices"
                                  className="select adgrey"
                                >
                                  <option value="">Select a Service</option>
                                  {services.map((s) => {
                                    return (
                                      <option value={s}>{capitalize(s)}</option>
                                    );
                                  })}
                                </select>
                              </div>
                            </form>
                            <form className="form client__form ">
                              <div className="inp__fields flxs">
                                <label className="para">Logo</label>
                                <div className="logo__conts">
                                  <input
                                    type="file"
                                    multiple="multiple"
                                    onChange={(e) => {
                                      clientChangeHandler(i, e);
                                    }}
                                    name="clientLogo"
                                    required
                                  />
                                  <p className="para adgrey">{c.clientLogo}</p>
                                </div>
                              </div>
                              <div className="inp__fields reviews__cont flxs none">
                                <label className="labelf para">
                                  Ratings & Reviews{" "}
                                  {/* <img
                                className="review__star__img"
                                src={stars}
                                alt=""
                              /> */}
                                  <RatingStars />
                                </label>
                                <textarea
                                  onChange={(e) => {
                                    clientChangeHandler(i, e);
                                  }}
                                  value={c.clientReview}
                                  name="clientReview"
                                  className="textarea review__textarea adgrey"
                                  placeholder="Write review (100 words max)"
                                  type="text"
                                ></textarea>
                              </div>
                              <div className="inp__fields client__para__cont flxs none">
                                <label className=" para adgrey">
                                  These ratings & Reviews are anonymous and only
                                  the average aggregate ratings are displayed on
                                  the Brand profile
                                </label>
                              </div>
                            </form>
                          </div>
                          <br></br>
                          <div className="mainupload__btn__cont">
                            <button
                              onClick={() => {
                                clientSaveHandler(i);
                              }}
                              className="mainupload__btn"
                            >
                              {clientloadbtn && (
                                <i className="fas fa-circle-notch donebtnclient"></i>
                              )}
                              {clientloadbtn && " Saving"}
                              {!clientloadbtn && "Save Changes"}
                            </button>
                          </div>
                        </div>
                      )}

                    </React.Fragment>

                  )) : null}
              </tbody>
            </table>
          </div>
          <div className="casestudy__data2">
            <div className="dflex justify__between">
              {/* <p className="para color__black cursor__pointer">Show more</p> */}
            </div>
            {openNewClient && (

              <div className="contact__details__data" style={{ backgroundColor: "#F8F8F8", padding: "30px" }}>
                <div className="contact__details__forms">

                  <div className="form client__form" >


                    <div className="inp__fields flx25">
                      <label className="para">Brand</label>

                      <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        className="input adgrey"
                        value={newClient.clientName} />

                    </div>
                    <div className="inp__fields flxl">
                      <label className="para">Website</label>
                      <input
                        onChange={(e) => {
                          newClientHandler(e);
                        }}
                        name="clientURL"
                        value={newClient.clientURL}
                        className="input adgrey"
                        placeholder="https://www.pepsi.com"
                        type="text"
                        required
                      ></input>
                    </div>

                    <div className="inp__fields flxs25">
                      <label className=" labelf para"> Service</label>
                      <select
                        required
                        onChange={(e) => {
                          newClientHandler(e);
                        }}
                        name="clientServices"
                        value={newClient.clientServices}
                        className="select adgrey"
                      >
                        <option value="">Select a Service</option>
                        {services.map((s) => {
                          return <option value={s}>{capitalize(s)}</option>;
                        })}
                      </select>
                    </div>

                    <div style={{marginBottom: " 20px"}} className="inp__fields flxs">
                      <label className="para">Logo
                      </label>
                      {newClient?.clientLogo ? <img src={newClient.clientLogo} alt="s" className="res__logo" /> : ""}
                      <div className="logo__conts">
                      <button onClick={uploadClientLogoButtonHandler} style={{display:"inline",padding:"10px",border:"none",backgroundColor:"black !important",color:" #191E22",width:"500px",background:""}}>Uplaod Logo</button>
                        <input
                          onChange={newClientHandler}
                          id="ClientLogo"
                          type="file"
                          multiple="multiple"
                          name="clientLogo"
                          className="logo"
                          alt=""
                          style={{visibility:"hidden"}}
                        />
                      </div>
                    </div>
                    <div className="inp__fields reviews__cont flxs none">
                      <label className="labelf para">
                        Ratings & Reviews{" "}
                        {/* <img className="review__star__img" src={stars} alt="" /> */}
                      </label>
                      <textarea
                        onChange={(e) => {
                          newClientHandler(e);
                        }}
                        name="clientReview"
                        className="textarea review__textarea adgrey"
                        placeholder="Write review (100 words max)"
                        type="text"
                      ></textarea>
                    </div>
                    <div className="inp__fields client__para__cont flxs none">
                      <label className=" para adgrey">
                        These ratings & Reviews are anonymous and only the average
                        aggregate ratings are displayed on the Brand profile
                      </label>
                    </div>

                    <div className="mainupload__btn__cont">
                      <button style={{color:"#191E22",background:"transparent",border:"none"}} onClick={()=>{
                        setOpenNewClient(false)
                         setNewClient({
                          clientId: "",
                          clientName: "",
                          clientURL: "",
                          clientLogo: "",
                          clientReview: "",
                          clientServices: "",
                        });
                      }} >Discard Changes</button>

                      <button
                        type="submit"
                        onClick={newClientSaveHandler}

                        className="mainupload__btn"
                      >

                        {clientloadbtn && (
                          <i className="fas fa-circle-notch donebtnclient"></i>
                        )}
                        {clientloadbtn && " Saving"}
                        {!clientloadbtn && "Save Changes"}
                      </button>
                    </div>
                    <div>{clientError}</div>
                  </div>
                </div>
                <br></br>

              </div>
            )}
            <div className="dflex justify__between mart1">
              <p
                onClick={addMoreClient}
                className="para color__black fw__600 cursor__pointer"
              >
                + Add More Clients
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Case Study */}
      <div id="Casestudies" style={{backgroundColor:'#fff', display: 'flex', flexDirection: 'column'}}>
        <CaseStudyPortfolio
          agencyId={params._id ? params._id : authHeader().User.agencyId}
          agencyName={basicAgencyDetails.agencyName ? basicAgencyDetails.agencyName : authHeader().User.name}
          agencyClients={clients}
          agencyServices={services}
        />
      </div>
      {/* Awards
      <div className="casestudy" id="awards" >
        <div className="contact__details">
          <p className="para text__left fw__600 adashboardTitles">Awards</p>
          <div className="casestudy__data">
            <table className="pricing__table">
              <tbody className="">
                <tr className="table__heading__row">
                  <th className="heading__row__part">
                    <p className="theading ">Award Name</p>
                  </th>
                  <th className="heading__row__part">
                    <p className="theading ">Award Link</p>
                  </th>
                  <th className="heading__row__part">
                    <p className="theading ">Award Logo</p>
                  </th>
                </tr>
                {awards?.map((c, i) => (
                  <React.Fragment>
                    {i < awards.length && (
                      <tr className="text__left">
                        <td className="para adgrey">{c.awardName}</td>
                        <td className="para adgrey">{c.awardLink}</td>
                        <td className="para adgrey">
                          {c.awardLogo.substr(0, 30)}...
                        </td>
                        <td className="para adgrey">View</td>
                        <td className="editnremove__cont hideeditremove">
                          <p
                            className="para editnremove"
                            onClick={() => {
                              awardEditHandler(i);
                            }}
                          >
                            Edit
                          </p>
                          <p
                            className="para editnremove"
                            onClick={() => {
                              removeAward(i);
                            }}
                          >
                            Remove
                          </p>
                        </td>
                      </tr>
                    )}
                    {awardToEdit === i && (
                      <tr>
                        <div className="contact__details__data">
                          <div className="contact__details__forms">
                            <form className=" form  award__form ">
                              <div className="inp__fields flxs25">
                                <label className=" labelf para">
                                  {" "}
                                  Award Name
                                </label>
                                <input
                                  onChange={(e) => awardEditChangeHandler(i, e)}
                                  name="awardName"
                                  className="input adgrey"
                                  placeholder="UK Agency Awards 2020"
                                  type="text"
                                  value={c.awardName}
                                ></input>
                              </div>
                              <div className="inp__fields flx3">
                                <label className=" labelf para">
                                  {" "}
                                  Link to the Article
                                </label>
                                <input
                                  onChange={(e) => awardEditChangeHandler(i, e)}
                                  name="awardLink"
                                  className="input adgrey"
                                  placeholder="https://"
                                  type="text"
                                  value={c.awardLink}
                                ></input>
                              </div>
                              <div className="inp__fields flxxs">
                                <label className=" labelf para">
                                  {" "}
                                  Award Logo
                                </label>
                                <div className="upload__btn__cont">
                                  <input
                                    type="file"
                                    multiple="multiple"
                                    name="awardLogo"
                                    onChange={(e) => {
                                      awardEditChangeHandler(i, e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="inp__fields flx2 dflex flexrow justify__between align__center width__100"></div>
                            </form>
                          </div>
                          <div className="dflex justify__between mart1">
                            <p
                              onClick={() => {
                                awardEditSaveHandler(i);
                              }}
                              className="para color__black fw__600 cursor__pointer"
                            >
                              Save Changes
                            </p>
                          </div>
                        </div>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
                {openMoreAward === 1 ? (
                  <div className="contact__details__data">
                    <div className="contact__details__forms">
                      <form className=" form  award__form ">
                        <div className="inp__fields flxs25">
                          <label className=" labelf para"> Award Name</label>
                          <input
                            onChange={newAwardChangeHandler}
                            name="awardName"
                            className="input adgrey"
                            placeholder="UK Agency Awards 2020"
                            type="text"
                          ></input>
                        </div>
                        <div className="inp__fields flx3">
                          <label className=" labelf para">
                            {" "}
                            Link to the Article
                          </label>
                          <input
                            onChange={newAwardChangeHandler}
                            name="awardLink"
                            className="input adgrey"
                            placeholder="https://"
                            type="text"
                          ></input>
                        </div>
                        <div className="inp__fields flxxs">
                          <label className=" labelf para"> Award Logo</label>
                          <div className="upload__btn__cont">
                            <input
                              type="file"
                              multiple="multiple"
                              name="awardLogo"
                              onChange={(e) => {
                                newAwardChangeHandler(e);
                              }}
                            />
                          </div>
                        </div>
                        <div className="inp__fields flx2 dflex flexrow justify__between align__center width__100"></div>
                      </form>
                    </div>
                    <div className="dflex justify__between mart1">
                      <p
                        onClick={newAwardSaveHandler}
                        className="para color__black fw__600 cursor__pointer"
                      >
                        Save Changes
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="dflex justify__between mart1">
                    <p
                      onClick={addMoreAwardHandler}
                      className="para color__black fw__600 cursor__pointer"
                    >
                      + Add More Awards
                    </p>
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/*<div className="contact__details__data">
          <div className="contact__details__forms">
            <form className=" form  award__form ">
              <div className="inp__fields flxs25">
                <label className=" labelf para"> Award Name</label>
                <input
                  className="input adgrey"
                  placeholder="UK Agency Awards 2020"
                  type="text"
                ></input>
              </div>
              <div className="inp__fields flx3">
                <label className=" labelf para"> Link to the Article</label>
                <input
                  className="input adgrey"
                  placeholder="https://"
                  type="text"
                ></input>
              </div>
              <div className="inp__fields flxxs">
                <label className=" labelf para"> Award Logo</label>
                <div className="upload__btn__cont">
                  <button className="input adgrey uploadbtn">Upload</button>{" "}
                  <label className="para adgrey">awardimage.jpeg</label>
                </div>
              </div>
              <div className="inp__fields flx2 dflex flexrow justify__between align__center width__100">
                <p className="para color__black cursor__pointer">Edit</p>
                <p className="para color__black cursor__pointer marl1">
                  Remove
                </p>
              </div>
            </form>
          </div>
          <div className="dflex justify__between mart1">
            <p className="para color__black fw__600 cursor__pointer">
              + Add More Awards
            </p>
          </div>
        </div>
        */}
      {/* </div> */}
      {/* News Mentions */}
      {/* <div className="casestudy" id="newsmentions">
        <div className="contact__details">
          <p className="para text__left fw__600">News Mentions</p>
          <div className="casestudy__data">
            <table className="pricing__table">
              <tbody className="">
                <tr className="table__heading__row">
                  <th className="heading__row__part">
                    <p className="theading ">Title of the Article</p>
                  </th>
                  <th className="heading__row__part">
                    <p className="theading ">Link to the Article</p>
                  </th>
                  <th className="heading__row__part">
                    <p className="theading ">Image</p>
                  </th>
                </tr>
                {newsMentions?.map((c, i) => (
                  <React.Fragment>
                    {i < newsMentions.length && (
                      <tr className="text__left">
                        <td className="para adgrey">{c.newsHeading}</td>
                        <td className="para adgrey">{c.newsURL}</td>
                        <td className="para adgrey">{c.image}</td>
                        <td className="para adgrey">View</td>
                        <td className="editnremove__cont hideeditremove">
                          <p
                            className="para editnremove"
                            onClick={() => {
                              newsMentionsEditHandler(i);
                            }}
                          >
                            Edit
                          </p>
                          <p
                            className="para editnremove"
                            onClick={() => {
                              removeNewsMentions(i);
                            }}
                          >
                            Remove
                          </p>
                        </td>
                      </tr>
                    )}
                    {newsMentionToEdit === i && (
                      <div className="contact__details__data">
                        <div className="contact__details__forms">
                          <form className=" form  award__form "
                            onChange={(e) => {
                              newsMentionsChangeHandler(i, e);
                            }} >
                            <div className="inp__fields flxs25">
                              <label className=" labelf para">
                                {" "}
                                Title of the Article
                              </label>
                              <input
                                name="newsHeading"
                                value={c.newsHeading}
                                className="input adgrey"
                                placeholder="UK Agency Awards 2020"
                                type="text"
                              ></input>
                            </div>
                            <div className="inp__fields flx3">
                              <label className=" labelf para">
                                {" "}
                                Link to the Article
                              </label>
                              <input
                                name="newsURL"
                                value={c.newsURL}
                                className="input adgrey"
                                placeholder="https://"
                                type="text"
                              ></input>
                            </div>
                            <div className="inp__fields flxxs">
                              <label className=" labelf para"> Image</label>
                              <div className="upload__btn__cont">
                                <input
                                  type="file"
                                  multiple="multiple"
                                  name="newsLogo"
                                  onChange={(e) => {
                                    newsMentionsChangeHandler(i, e);
                                  }}
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="dflex justify__between mart1">
                          <p
                            onClick={() => {
                              newsMentionsSaveHandler(i);
                            }}
                            className="para color__black fw__600 cursor__pointer"
                          >
                            Save Changes
                          </p>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
                <div className="contact__details__forms">
                  {addMoreNews ? (
                    <div className="contact__details__data">
                      <div className="contact__details__forms">
                        <form
                          onChange={(e) => {
                            moreNewsMentionsChangeHandler(e);
                          }}
                          className=" form  award__form "
                        >
                          <div className="inp__fields flxs25">
                            <label className=" labelf para">
                              {" "}
                              Title of the Article
                            </label>
                            <input
                              name="newsHeading"
                              className="input adgrey"
                              placeholder="UK Agency Awards 2020"
                              type="text"
                            ></input>
                          </div>
                          <div className="inp__fields flx3">
                            <label className=" labelf para">
                              {" "}
                              Link to the Article
                            </label>
                            <input
                              name="newsURL"
                              className="input adgrey"
                              placeholder="https://"
                              type="text"
                            ></input>
                          </div>
                          <div className="inp__fields flxxs">
                            <label className=" labelf para"> Image</label>
                            <div className="upload__btn__cont">
                              <input
                                type="file"
                                multiple="multiple"
                                name="newsLogo"
                                onChange={moreNewsMentionsChangeHandler}
                              />
                              <label className="para adgrey">

                              </label>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="dflex justify__between mart1">
                        <p
                          onClick={() => {
                            moreNewsMentionsSaveHandler();
                          }}
                          className="para color__black fw__600 cursor__pointer"
                        >
                          Save Changes
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="dflex justify__between mart1">
                      <p
                        onClick={addMoreNewsMentions}
                        className="para color__black fw__600 cursor__pointer"
                      >
                        + Add More News Mentions
                      </p>
                    </div>
                  )}
                </div>
              </tbody>
            </table>
          </div>
        </div>
      </div> */}

      <div className="mainupload__btn__cont">
        <button onClick={submitHandler} className="mainupload__btn">
          {uploadbtn && <i className="fas fa-circle-notch donebtnclient"></i>}

          {uploadbtn ? "Updating" : uploadBtnStatus ? "Updated" : "Update Profile"}
          {/* {!uploadbtn && "Update Profile"} */}
        </button>
      </div>
    </div>
  );
}

export default AEditProfile;
