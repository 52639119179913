import React, { useEffect, useRef, useState } from "react";
import deleteIcon from "../TalentPool/demoImages/delete-outline.svg";
import closeCircle from "../../../UserProfileCompo/UserPageContent.js/DemoImages/Individual Profile/close-circle.svg";
import checkCircle from "../../../UserProfileCompo/UserPageContent.js/DemoImages/Individual Profile/check-circle.svg";
import "./PoolCategory.css";
import axios from "../../../../axios";
import DeleteConfirmation from "../../../DeleteConfirmation/DeleteConfirmation";
import EditPoolCategory from "../EditPoolCategory/EditPoolCategory";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { MarkunreadMailboxTwoTone } from "@material-ui/icons";

import { FaRupeeSign } from "react-icons/fa";
import { FaRegCalendarCheck } from "react-icons/fa6";
import { BiSolidSpreadsheet } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

const PoolCategory = ({
  categoryArray,
  category,
  setCategory,
  setCategoryArray,
  showInput,
  setShowInput,
  handleAddCategory,
  setSelectedCategory,
}) => {
  const [idToDelete, setIdToDelete] = useState("");
  const [idToEdit, setIdToEdit] = useState("");

  const dialogRef = useRef();
  const dialog = dialogRef.current;
  const dialogEditRef = useRef();
  const dialogEdit = dialogEditRef.current;

  useEffect(() => {
    setShowInput(document.querySelector("[data-modal]"));
  }, []);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));
  function handleDiscard() {
    setShowInput(false);
  }

  async function removeCategory(id) {
    try {
      dialog.close();
      console.log("the delete id is ", id);
      let { data } = await axios.delete(
        `/talentPool/delete_talent_pool_categories/${id}`,
        { headers: { authorization: "Bearer " + Marketer.token } }
      );
      setShowInput(false);
      let categoryArrayCopy = categoryArray.filter((key) => key._id !== id);
      setCategoryArray(categoryArrayCopy);
    } catch (error) {
      console.log(error);
    }
  }

  async function editCategoryFunction(id) {
    dialogEdit.close();
    let { data } = await axios.post(
      `/talentPool/update_talent_pool_categories/${id}`,
      category,
      { headers: { authorization: "Bearer " + Marketer.token } }
    );

    let updatedCategoryArray = categoryArray?.map((item) =>
      item._id === id ? data : item
    );
    setCategoryArray(updatedCategoryArray);
  }

  function handleShowAddCategoryInput() {
    setShowInput(true);
    setCategory({
      category: "",
      pricing: "",
      accessLimit: "",
    });
  }

  function handleSetCategoryInput(e) {
    setCategory({
      ...category,
      [e.target.name]: e.target.value,
    });
  }

  function handleShowDialog(category_id) {
    setIdToDelete(category_id);
    dialog.showModal();
  }

  function handleEditDialog(category_id) {
    setIdToEdit(category_id);
    setCategory({
      name: "",
      price_per_user: "",
      accessLimit: "",
    });
    dialogEdit.showModal();
  }

  console.log("tha category array is ", categoryArray);

  return (
    <>
      <div className="poolCategory">
        <div className="d-flex flex-row justify-content-end">
          {/* <div className="addCategoryBtn">
            <button onClick={handleUploadClick}>
              <CloudUploadIcon />{" "}
              Upload Talents
            </button>
            <input id="fileInput" type="file" hidden />
          </div> */}

          <div className="addCategoryBtn">
            <button onClick={handleShowAddCategoryInput}>
              + &nbsp;Add Category
            </button>
          </div>
        </div>

        <div className="poolCategory_table">
          <DeleteConfirmation
            dialog={dialog}
            dialogRef={dialogRef}
            removeFunc={removeCategory}
            id={idToDelete}
          />

          <EditPoolCategory
            dialog={dialogEdit}
            dialogRef={dialogEditRef}
            category={category}
            handleSetCategoryInput={handleSetCategoryInput}
            id={idToEdit}
            editFunction={editCategoryFunction}
          ></EditPoolCategory>

          <table className="theActualTable">
            <tbody>
              {categoryArray?.map((category) => (
                <>
                  <tr key={category._id}>
                    <td onClick={() => setSelectedCategory(category)}>
                      <div className="poolCategoryInfo">
                        <div>
                          <span>{category.name} | </span>
                          <span>{category.user_count}</span>
                        </div>
                        <button className="addCandidatesButton">
                          Add Candidates
                        </button>
                      </div>
                    </td>
                    <td>
                      <div className="poolCategoryColumn">
                        <FaRupeeSign className="iconSign"></FaRupeeSign>
                        <span>{category.price_per_user}/talent</span>
                      </div>
                    </td>
                    <td>
                      <div className="poolCategoryColumn">
                        <FaRupeeSign className="iconSign"></FaRupeeSign>
                        <span>{category.price}</span>
                      </div>
                    </td>

                    <td>
                      <div className="poolCategoryColumn">
                        <FaRegCalendarCheck className="iconSign pointer-cursor"></FaRegCalendarCheck>
                        <span>{category.accessLimit}</span>
                      </div>
                    </td>

                    <td>
                      <div
                        className="poolCategoryColumn"
                        style={{
                          justifyContent: "space-evenly",
                        }}
                      >
                        <FaEdit
                          onClick={() => handleEditDialog(category._id)}
                          className="pointer-cursor"
                        ></FaEdit>

                        <MdDelete
                          onClick={() => handleShowDialog(category._id)}
                          className="pointer-cursor"
                          alt="deleteIcon"
                        ></MdDelete>
                      </div>
                    </td>
                  </tr>
                </>
              ))}
              {showInput && (
                <tr className="inputWrapper">
                  <td className="categoryDataWrapper">
                    <input
                      type="text"
                      name="name"
                      value={category.name}
                      onChange={handleSetCategoryInput}
                      placeholder="Enter Category Name"
                    />
                  </td>
                  <td className="categoryDataWrapper">
                    <input
                      type="number"
                      name="price_per_user"
                      value={category.price_per_user}
                      onChange={handleSetCategoryInput}
                      placeholder="Enter per user pricing"
                    />
                  </td>
                  <td className="categoryDataWrapper">
                    <input
                      type="number"
                      name="accessLimit"
                      value={category.accessLimit}
                      onChange={handleSetCategoryInput}
                      placeholder="Enter access limit of category"
                    />
                  </td>
                  <td className="iconWrapper">
                    <button className="crossIcon">
                      <img
                        src={closeCircle}
                        alt="closeCircle"
                        className="icons"
                        onClick={handleDiscard}
                      />
                    </button>
                  </td>
                  <td className="iconWrapper">
                    <button
                      className="checkIcon"
                      disabled={
                        !category.name ||
                        !category.price_per_user ||
                        !category.accessLimit
                      }
                    >
                      <img
                        src={checkCircle}
                        alt="checkCircle"
                        className="icons"
                        onClick={handleAddCategory}
                      />
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PoolCategory;
