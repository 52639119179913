import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Firstcomponent from "../CaseStudyComponents/CaseStudyfirstcomponents/CaseStudyfirstcomponents";
import Brand_details from "../CaseStudyComponents/brand_details/brand_details";
import About from "../CaseStudyComponents/about/about";
import Awards from "../CaseStudyComponents/Awards/Awards";
import NewsMentions from "../CaseStudyComponents/NewsMentions/NewsMentions";
import Result from "../CaseStudyComponents/Result/Result";
import TeamMembers from "../CaseStudyComponents/TeamMembers/TeamMembers";
import "./CaseStudy.css";
import axios from "../../axios.js";



const CaseStudy = () => {
  const [data, setData] = useState({});
  const [agencyData,setAgencyData]=useState()
  const id = useParams().id;
  let url = "";
  if (id.includes('-')) {
    url = `/agencyProfile/getnewCaseStudyByKey/${id}`;
  } else {
    url = `/agencyProfile/getnewCaseStudyById/${id}`;
  }
  
  useEffect(() => { 
    const getData = async () => {
      const data = await axios.get(url);
      setData(data.data);
      setAgencyData(data.data.allAgencies)
      // console.log(data.data);
    };
    
    getData();
     
  }, [url]); 

  const getBrandlogo=  (brand)=> {
    const client =  data.agencyData.agencyClients.find(data => data.clientName == brand);
    return (client?.clientLogo || '');
  }
  

  if (!data.caseStudyId) {
    return <h1>Loading ...</h1>;
  } else {
    return (
      <div className="components">
        <Firstcomponent
          brandLogo={getBrandlogo(data.caseStudies.brand)}
         agencyLogo={data.agencyData.agencyLogo} />
        <Brand_details
          brandLogo={getBrandlogo(data.caseStudies.brand)}
          agencyLogo={data.agencyData.agencyLogo}
          agencyName={data.agencyData.agencyName}
          brands={data.caseStudies.brand}
          industries={data.caseStudies.industries}
          services={data.caseStudies.services}
          />
        <About
          overview={data.caseStudies.overview}
          challenges={data.caseStudies.challenges}
          projectTittle={data.caseStudies.projectTitle}
          statistics={data.caseStudies.statistics}
          research={data.caseStudies.research}
          process={data.caseStudies.process}
        />

        <Result result={data.caseStudies.results} />
        <TeamMembers
          id={data.caseStudies.agencyId}
         teamMmembers={data.caseStudies.credits}   
         agencyData={agencyData}
          />
        <Awards awards={data.caseStudies.awards} />
        <NewsMentions news_mention={data.caseStudies.news_mention} />
      </div>
    ); 
  }
};

export default CaseStudy;    