import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import { FreeMode } from "swiper";
import "./Result.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Mediacomponent from "../mediaComponent/mediacomponent";
const Result = ({ result }) => {
  return (
    <>
      <div className="about">
        {result.image.length === 0 && result.text.length === 0 ? (
          <></>
        ) : (
          <div className="result">
            <h2>Result | Outcome | Benefit</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: result.text?.replaceAll("\\", ""),
              }}
            ></div>{" "}
          </div>
        )}
      </div>
      <Mediacomponent images={result.image} imageType={result.imgType} />
    </>
  );
};

export default Result;
