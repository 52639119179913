import React, { useEffect, useState } from 'react'

const DateSection = ({ dateType, selectedDate, setSelectedDate }) => {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const [filteredMonths, setFilteredMonths] = useState([]);
    const [filteredYears, setFilteredYears] = useState([]);
    const [currentlyWorking, setCurrentlyWorking] = useState(false);

    const DateArray = {
        months: [
            { id: 1, name: 'January' },
            { id: 2, name: 'February' },
            { id: 3, name: 'March' },
            { id: 4, name: 'April' },
            { id: 5, name: 'May' },
            { id: 6, name: 'June' },
            { id: 7, name: 'July' },
            { id: 8, name: 'August' },
            { id: 9, name: 'September' },
            { id: 10, name: 'October' },
            { id: 11, name: 'November' },
            { id: 12, name: 'December' },
        ],
        years: [],
    };

    let k=1;
    for(let startingYear=currentYear;startingYear>=2000;startingYear--){
        DateArray.years.push({
            id:k,
            name:startingYear
        });
        k++;
    }
    useEffect(() => {    
        if(dateType==="till" && selectedDate.from.month && selectedDate.from.year){
            let yearsArray=DateArray.years.filter((key)=>key.name>=selectedDate.from.year)
            setFilteredYears(yearsArray);
            if(selectedDate.till?.year===selectedDate.from.year){
                handeleMonthOnSameYear();
            }else{
                setFilteredMonths(DateArray.months);
            }
        }else if(dateType==="from"){
            setFilteredYears(DateArray.years);
            setFilteredMonths(DateArray.months);
        }   
        if (dateType === 'till' && selectedDate.till.month === '' && selectedDate.till.year === '') {
            setSelectedDate({
                ...selectedDate,
                till: {
                    month: DateArray.months[currentMonth - 1].name,
                    year: currentYear.toString(),
                },
            });
        }
    }, [dateType, selectedDate]);

    function handeleMonthOnSameYear(){
            let fromMonthId=(DateArray.months.find(key=>key.name===selectedDate.from.month)).id;
            let tillMonthId=(DateArray.months.find(key=>key.name===selectedDate.from.month)).id;
            if(tillMonthId<=fromMonthId){
                let filteredMonth=DateArray.months.filter(key=>key.id>fromMonthId);
                console.log(filteredMonth);
                setSelectedDate({
                    ...selectedDate,
                    till: {
                        ...selectedDate.till,
                        month: "",
                    },
                });
                setFilteredMonths(filteredMonth);
            }else{
                setFilteredMonths(DateArray.months);
            }
            
    }

    function handleChangeMonth(e) {
        if (dateType === 'from') {
            setSelectedDate({
                ...selectedDate,
                from: {
                    ...selectedDate.from,
                    month: e.target.value,
                },
            });
        }
        else {
            setSelectedDate({
                ...selectedDate,
                till: {
                    ...selectedDate.till,
                    month: e.target.value,
                },
            });
        }
    }


    function handleChangeYear(e) {
        if (dateType === 'from') {
            setSelectedDate({
                ...selectedDate,
                from: {
                    ...selectedDate.from,
                    year: e.target.value,
                },
            });
        }
        else {
            setSelectedDate({
                ...selectedDate,
                till: {
                    ...selectedDate.till,
                    year: e.target.value,
                },
            });
        }
    }
    function handleCurrentlyWorkingChange(e) {
        setCurrentlyWorking(e.target.checked);
        if (e.target.checked) {
            setSelectedDate({
                ...selectedDate,
                till: {
                    month: '',
                    year: '',
                },
            });
        }
    }

    function isFieldsFilled() {
        if (dateType === 'from') {
            return selectedDate.from.month && selectedDate.from.year;
        } else if (dateType === 'till') {
            if (currentlyWorking) {
                return true; // No need to validate if currently working
            }
            return selectedDate.till.month && selectedDate.till.year;
        }
        return false;
    }

    return (
        <div>
            <p className='label'>{dateType === 'from' ? 'From' : 'Till'}</p>
            <div className='dateWrapper'>
                <div className='inputWrapper'>
                    {/* <input type="text" name="" id="" placeholder='Month' /> */}
                    <Select options={filteredMonths} inputName="Month" value={dateType === 'from' ? selectedDate.from.month : selectedDate.till.month} onChange={handleChangeMonth} disabled={currentlyWorking && dateType === 'till'}/>
                </div>
                <div className='inputWrapper'>
                    {/* <input type="text" name="" id="" placeholder='Year' /> */}
                    <Select options={filteredYears} inputName="Year" value={dateType === 'from' ? selectedDate.from.year : selectedDate.till.year} onChange={handleChangeYear} disabled={currentlyWorking && dateType === 'till'} />
                </div>
                {dateType === 'till' && (
                    <div className='inputWrapper'>
                        <label>
                            <input
                                type='checkbox'
                                checked={currentlyWorking}
                                onChange={handleCurrentlyWorkingChange}
                            />
                            I am currently working in this role
                        </label>
                    </div>
                )}
            </div>
            {!isFieldsFilled() && <p className='error'>Please fill all the fields</p>}
        </div>
    );
};


export default DateSection



const Select = ({ options, value, onChange,inputName, disabled }) => (
    <select className='selectField' value={value} onChange={onChange} disabled={disabled}>
        <option className='optionField' >
                Select {inputName}
            </option>
        {options.map(option => (
            <option className='optionField' key={option.id} value={option.name}>
                {option.name}
            </option>
        ))}
    </select>
);