import React, { useState,useEffect } from "react";
import "../styles/ContactUs.css"
import phone from "../images/ContactUs/ContactPhone.png";
import location from "../images/4754115.png";
import email from "../images/ContactUs/ContactMail.png";
import axios from "../axios";
import authHeader from "../login-auth"
import {useParams} from "react-router-dom"

function AgencyContact() {
  const[ser,setSer]=useState([])
  var branddata=(JSON.parse(localStorage.getItem('branduser')))
  const [newContact, setNewContact] = useState({
    fullName:"",
    contactNumber: "",
    emailAddress: "",
    message: "",
    service:[]
  });

  const [serviceOptions, setServiceOptions] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceBoxes, setServiceBoxes] = useState([
    { no: 1, name: 0, check: false },
  ]);
  const serviceChangeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const filterdata = services.filter(a => a === value);
    if(filterdata.length !== 0){
    }else{
      setServices((prev) => {
        prev[name] = value;
        console.log(prev)
        return prev;
      });
    }
  };


  var checkSerArrlength=0;
  const [ServiceError,SetServiceError]=useState(false);
  const addMoreService = () => {
    setServices((prev) => {
      
      checkSerArrlength=prev.length
      
     console.log(prev.length,"dbdjbjjdbj")
      return prev;
    });
    
    setServiceBoxes((prev) => {
      const l = prev.length;
      var newAr = [];
      prev.forEach((i, ind) => {
        newAr.push({ no: l - ind, name: i.name, check: prev[ind].check });
      });
      newAr.push({ no: l + 1, name: l, check: false });
     console.log(newAr)
      if(newAr.length>checkSerArrlength){
        console.log('error, this is empty')
        SetServiceError(true)
        
      }
      
      else{
        SetServiceError(false)
        console.log('correct')
       
      }
      setSer(newAr)
      return newAr;
    });
  };



  const params=useParams().agencyId
  const [showMessage, setShowMessage] = useState(false);
  const [notification, setNotification] = useState("");

 
  const [userProfile,setprofile]=useState("")      
        useEffect(()=>{
          const fetchAgency=async()=>{
            let response = await axios.get(`/agencies/getAgencyById/${params}`);
            setprofile(response.data)
          
          }
          fetchAgency()

        },[])   
  const changeHandler = (event) => {
    const { name, value } = event.target;
    setNewContact((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const saveContact = newContact;
    try {
      const response = await axios.post("/contactus", saveContact);
      console.log(services,"aa")
      var temp = [...new Set(services)];
      console.log(temp,"aa")
      setNewContact({
        fullName: "",
        contactNumber: "",
        emailAddress: "",
        message: "",
        service:temp
       
      });
  
        
      setShowMessage(true);
      // setNotification(response.data);
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  if(!userProfile){
    return <h2>Loading...</h2>
  }else{

  

  return (
    
    <div className="contactUs">
      <div className="cub1 has-text-centered">
        <div>
          <h1 className="color__white contactUs__title">
            <span className="color__orange fw__600">Contact</span> {userProfile.agencyName}
          </h1>
+        </div>
      </div>
      <div className="contact__info__cont">
        <div
          className="contact__info"
        
        >
        
          <div className="section">
            <img src={phone} alt="" className="contact__logo" />
           
            <p className="para">{userProfile.phoneNumber}</p>
          </div>
          <div className="section">
            <img src={location} alt="" className="contact__logo" />
            <a style={{textDecoration: "none", color: "black "}} className="para" href={`${userProfile.agencyWebsite}?utm_source=Unispade&utm_medium=contact_agency&utm_campaign=Referral`} target="_blank">
            {userProfile.agencyWebsite}
            </a>
          </div>
          <div className="section">
            <img src={email} alt="" className="contact__logo"  />
            <p className="para">{userProfile.businessEmail}</p>
          </div>
        </div>
      </div>
      <div className="sec__right">
        <form className="login__form" onSubmit={submitHandler}>
          <label>Full Name</label>
          <input
            onChange={changeHandler}
            name="fullName"
            value={newContact.fullName}
            placeholder="Enter your name here"
            className="epass"
            required
          />
          <label>Contact Number</label>
          <input
            onChange={changeHandler}
            name="contactNumber"
            value={newContact.contactNumber}
            placeholder="Enter your number here"
            required
            className="epass"
          />
          <label>Email Address</label>
          <input
            onChange={changeHandler}
            name="emailAddress"
            value={newContact.emailAddress}
            type="text"
            placeholder="Enter your email id"
            required
            className="epass"
          />






<div className="contact__details">
        <p className="para text__left fw__600">Services</p>
        <div className="contact__details__data">
          <p className="para adgrey">
            Select any 3 focus services. This will help your agency to position
            as an expert in the market
          </p>
          <div className="contact__details__forms">
            <form className=" form wfixform">
              {serviceBoxes.map((b, i) => {
                return (
                  <div key={b} className="inp__fields flxs wfixinp__cont">
                    <div className="labelcrosscont">
                      <label className=" labelf para labelfw">
                        {" "}
                        Service {i + 1}{" "}
                        {/* <input
                          disabled={!canSelect && !b.check}
                          checked={b.check}
                          onChange={serviceCheck}
                          name={b.name}
                          type="checkbox"
                        ></input> */}
                      </label>
                      {/* <img src={redcross} className="redcross" alt="" /> */}
                    </div>
                    <select
                      value={services[i]}
                      key={b}
                      onChange={serviceChangeHandler}
                      name={b.name}
                      className="select adgrey wfixinp"
                      onClick={()=>SetServiceError(false)}
                    >
                      <option value="">{userProfile.services.length===0?"No Service Available":"Select a Service"}</option>
                      {userProfile.services.map((s) => {
                        return <option value={s}>{(s)}</option>;
                      })}
                    </select>
                  </div>
                );
              })}
            </form>
            <div className="dflex justify__between">
            {!ServiceError ? <p
                className="para color__orange fw__600 cursor__pointer"
                onClick={addMoreService} 
                //onClick = {console.log(seterr)}
              >
                + Add More Services
              </p>:<p
                className="para color__orange fw__600 cursor__pointer"
                style={{color:"grey"}}
              
              > + Add More Services</p>}
            </div>
          </div>
        </div>
      </div>













          <label>Project Brief</label>
          <textarea
            style={{ height: "7rem" }}
            onChange={changeHandler}
            name="message"
            type="text"
            value={newContact.message}
            placeholder="Write about your project here"
            required
            className="epass"
          />
          {showMessage && <p>{notification}</p>}
          <button type="submit" className="login__btn">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
  }
}

export default AgencyContact;
