import React from "react";

function USavedItems() {
  return (
    <div className="usertab" id="savedtab">
      saved items tab
    </div>
  );
}

export default USavedItems;
