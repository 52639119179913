import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import CaseStudyCard from "../CaseStudyListingComponents/CaseStudyCard/CaseStudyCard";

import "./AgencyProfile.css";
import axios from "../../axios.js";

import verifiedIcon from "../../images/AgencyProfile/green-verified.png";
import verifiedIconRed from "../../images/CaseStudy/verified.png";
import soundIcon from "../../images/CaseStudy/sound.png";
import briefcaseIcon from "../../images/AgencyProfile/briefcase.png";
import groupIcon from "../../images/AgencyProfile/account-group.png";
import mapIcon from "../../images/AgencyProfile/map-marker.png";
import linkedin from "../CaseStudyComponents/img/linkedinl.png";
import instagram from "../CaseStudyComponents/img/instagram.png";
import avatar from "../../images/avatar.png";
import { useHistory } from "react-router-dom";
import "./APCaseStudyCard.css";
import Loader from "../loader/Loader";

const AgencyProfile = () => {
  const history=useHistory()
  
  const params = useParams();
  // const history = useHistory();
  const [agency, setAgency] = useState({});
  const [clients, setClients] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [newsMentions, setNewsMentions] = useState([]);
  const [size, setSize] = useState("");
  const [caseStudies, setCaseStudies] = useState([]);
  const [id, setId] = useState("");
  const [reviews, setreviews] = useState([]);
  const [allReviews, setAllreviews] = useState(false);
  const [loading,setLoading]=useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post("/agencies/getAgencyByAgencyName", {
          name: params.agencyName,
        }); 
        setAgency(response.data);
        setId(response.data._id);
        if (response.data.agencySize > 0 && response.data.agencySize <= 10) {
          setSize("0-10");
        } else if (
          response.data.agencySize > 10 &&
          response.data.agencySize <= 50
        ) {
          setSize("11-50");
        } else if (
          response.data.agencySize > 50 &&
          response.data.agencySize <= 100
        ) {
          setSize("51-100");
        } else if (response.data.agencySize >= 100) {
          setSize(`>100`);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
    
  }, []);
  
  useEffect(() => {
    if(id) {
      const fetchClients = async () => {
        try {
          const response = await axios.post("/getClientsbyAgencyId", {
            agencyId: id,
          });
          await setClients(response.data[0].agencyClients);
        } catch (e) {
          console.log(e);
        }
      };

      const fetchTeamMembers = async () => {
        try {
          const response = await axios.post("/team/getTeamMembersByAgencyId", {
            agencyId: id,
          });

          setTeamMembers(response.data);
        } catch (e) {
          console.log(e);
        }
      };
      const fetchNewsMentions = async () => {
        try {
          const response = await axios.post("/agencyProfile/getNewsMentions", {
            agencyId: id,
          });
          setNewsMentions(response.data);
        } catch (e) {
          console.log(e);
        }
      };

      const fetchCaseStudies = async () => {
        try {
          const response = await axios.post("/campaigns/getnewCaseStudyByAgencyId", {
            agencyId: id,
          });
          setCaseStudies(response.data);
        } catch (e) {
          console.log(e);
        }
      };

      const fetchReviews = async () => {
        try {
          const response = await axios.get(`/getReviewbyAgencyId?agencyId=${id}`);
          mapreviewsData(response.data.result[0]);
        } catch (e) {
          console.log(e);
        }
      };

      fetchNewsMentions();
      fetchClients();
      fetchTeamMembers();
      fetchCaseStudies();
      fetchReviews();
      setLoading(false)
    }
  }, [id]);

  
  function mapreviewsData(data) {
    if(data){
      let reviewData = [];
      Object.keys(data).forEach(review => {
        if(Array.isArray(data[review])){
          data[review].forEach(rev => {
            let obj = {
              agencyLogo : '',
              agencyName : review,
              description : rev,
              reviewer: '',
              reviewerDesignation: ''
            }
            reviewData.push(obj);
          })
        }
      })
      setreviews(reviewData);
    }
  }

   

 
  function capitalize(input) {
    if (input === undefined) {
      return "";
    }
    var words = input.split(" ");
    var CapitalizedWords = [];
    words.forEach((element) => {
      CapitalizedWords.push(
        element[0].toUpperCase() + element.slice(1, element.length)
      );
    });
    return CapitalizedWords.join(" ");
  }

  function getBrandlogo(brand) {
    const client = clients.find(data => data.clientName == brand);
    return (client?.clientLogo || '');
  }

  if(loading){
    console.log("hello");
    return <Loader/>
  }else {return (
    <div className="agency__profile">
      <div className="agency__profile_header">
        <div className="left">
          {agency.agencyLogo.includes("unispade-react-js-s3")?<p className="agency_logo agencylogoInitials">{agency.agencyName[0].toUpperCase()}</p>:<img
            className="agency_logo"
            src={agency.agencyLogo}
            alt="logo"
          />}
          <div>
            <div className="info_div">
              <h2 className="agency_name">{capitalize(agency.agencyName)}</h2>
              <img
                src={verifiedIcon}
                alt="verified icon"
                className="verified_icon"
              />
            </div>
            <div className="agency_infos">
            {agency.foundedYear && (
              <div className="agency_info">
                <img src={briefcaseIcon} alt="briefcase icon" />
                <p>{agency.foundedYear ? new Date().getFullYear() - agency.foundedYear?.substring(0, 4) : 0}{" "} year(s)</p>
              </div>
            )}
              {size && (
                <div className="agency_info">
                  <img src={groupIcon} alt="group icon" />
                  <p>{size} people</p>
                </div>
              )}
              
              {agency.locations && agency.locations.length > 0 &&(
                <div className="agency_info">
                  <img src={mapIcon} alt="map icon" />
                  <p>{agency?.locations?.length ? agency.locations[0] : ''}</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="right">
          <Link to={"/agencyprofile/contact/"+agency._id}>
          <button className="agheaderBtn">Contact Agency</button>
          </Link>
          <button
            className="vistWebBtn"
            onClick={() =>
              window.open(
                `${agency.agencyWebsite}?utm_source=Unispade&utm_medium=Agency+Profile&utm_campaign=Referral`,
                "_blank"
              )
            }
          >
            Visit Website
          </button>
        </div>
      </div>

      <div className="agency__profile_content">
        <div className="agency__profile_tags">
          {agency?.locations?.map((location) => (
            <span className="agency__profile_tag font__lexend">{capitalize(location)}</span>
          ))}
        </div>

        <p className="agency__profile_description font__lexend">
          {agency.aboutAgency}
        </p>

        {/* Services */}
        <h3 className="agency__profile_heading font__lexend">Services</h3>
        <div className="agency__profile_tags">
          {(agency?.services || []).map((service) => (
            <span className="agency__profile_tag font__lexend">
              {service}
            </span>
          ))}
        </div>

        {/* Clients */}
        <h3 className="agency__profile_heading font__lexend">Clients</h3>
        <div className="agency__profile_clients">
          {clients.map((client) => (
            <div>
              {client.clientLogo.includes("unispade-react-js-s3")?<div className="clientProfiles">
              <p className="agency_logo agencylogoInitials">{client.clientName[0].toUpperCase()}</p>
              <span>{client.clientName}</span>
              </div>:
              <a className="clientUrl" href={`../brand-profile/${client.clientName}`} target="_blank">
                <img src={client.clientLogo} alt={client.clientName} />
              </a>
              }
          </div>
                              
          ))}
        </div>


        {/* Portfolio */}
        {caseStudies && caseStudies.length > 0 && (
          <div>
            <h3
              className="agency__profile_heading font__lexend"
              style={{ marginTop: "30px" }}
            >
              Portfolio
            </h3>
            <div className="case__studies" style={{ paddingTop: "30px" }}>
              {caseStudies.map((caseStudy) => (
                <CaseStudyCard key={caseStudy._id} caseStudy={caseStudy} />
              ))}
            </div>
          </div>
        )}

        {/* Reviews */}
          {reviews && reviews.length > 0 && ( 
            <h3 className="agency__profile_heading font__lexend">Reviews</h3>
          )}        
          <div className="agency__profile_reviews">
          {reviews?.length ? reviews.map((review, index) => (
            <div className="review_box">
              <div className="details">
                {/* <img className="logo" src={review.agencyLogo} alt="logo" /> */}
                <h6 className="name">{review.agencyName}</h6>
              </div>
              <p className="agency__profile_description font__lexend">
                {review.description}
              </p>
              <p className="reviewer font__lexend">{review.reviewer}</p>
              <span className="reviewer_designation font__lexend">
                {review.reviewerDesignation}
              </span>
            </div>
          )) : ''}
        </div>
        {/* { reviews.length > 4 ?  (<button onClick={getAllReviews()} className="view_all font__lexend">View All</button>) : ''} */}

        {/* Team */}
        <div
          className="team__members"
          style={{ width: "100%", paddingTop: "35px" }}
        >
          <h3
            className="agency__profile_heading font__lexend"
            style={{ marginBottom: "30px" }}
          >
            Team
          </h3>
          <div className="members__container">
            {teamMembers.length ? teamMembers.map((teamMember) => (
              <div className="d-flex text-start member__info">
                <div>
                  <img
                    className="member__photo"
                    src={avatar}
                    alt="Member"
                  />
                </div>
                <div className="d-flex flex-column gap-2">
                  <div>
                    <h4 className="fw-bold mb-0 font__lexend member__name">
                      {teamMember.name}
                    </h4>
                    <h4 className="mb-0 font__lexend member__designation">
                      {teamMember.email}
                    </h4>
                  </div>
                  <div className="gap-2 d-flex icon__container">
                    <a href="https://in.linkedin.com/">
                      <img
                        className="social__icon"
                        src={linkedin}
                        alt="linkedin"
                      />
                    </a>
                    <a href="https://www.instagram.com/">
                      <img
                        className="social__icon"
                        src={instagram}
                        alt="instagram"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )): ''}
          </div>
        </div>

        {/* Packages */}
        {/* <h3 className="agency__profile_heading font__lexend">Packages</h3> */}
        {/* {packages.map((packages) => (
          <div className="package_box">
            <h5 className="package_name font__lexend">
              Social Media Marketing
            </h5>
            <div className="pricing">
              <div className="input_field">
                <select
                  className="field_box"
                  name="website"
                  id="website"
                  required
                >
                  <option value="" disabled selected>
                    {packages.options[0]}
                  </option>
                  {packages.options.map((option) => (
                    <option value={option}>{option}</option>
                  ))}
                </select>
              </div>
              <p className="price font__lexend">₹{packages.price}</p>
            </div>
            <div className="agency__profile_tags">
              {packages.tags.map((tag) => (
                <span className="agency__profile_tag font__lexend">
                  {tag}
                </span>
              ))}
            </div>
            <p className="agency__profile_description font__lexend">
              {packages.description}
            </p>
            <button className="font__lexend">Inquire</button>
          </div>
        ))} */}
        {/* <button className="view_all font__lexend">View All</button> */}
      </div>
    </div>
  );}
};

export default AgencyProfile;
