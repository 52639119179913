import React from "react";
import "./userSection.css";
import CoverImage from "../newImg/CoverImage.png";
import ProfileImage from "../newImg/ProfileImage.png";
import ShareImage from "../newImg/share.png";
import BookmarkImage from "../newImg/bookmark.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CiChat1 } from "react-icons/ci";


const UserSection = ({ userData }) => {
  function handleCopy(userData) {
    navigator.clipboard.writeText(
      `https://www.unispade.com/userprofile/${userData.username}`
    );
    toast.success("Link Copied");
  }

  return (
    <>
      <div className="UserSection">
        <div className="userProfile">
          <img className="profile_image" src={userData?.profilePhoto} alt="profile_image" />
          <div className="UserProfileInfo">
            <p>{userData?.name}</p>
            <p>{userData?.username}</p>
          </div>
        </div>

        {/* <div className="ApplyToUserPool">
          <button>Apply to Talent Pool</button>
          <button>
            <CiChat1
              style={{
                maxWidth: "24px",
                minHeight: "24px",
              }}
            ></CiChat1>
          </button>
        </div> */}
        <div></div>
        <div></div>
      </div>
      <ToastContainer />
    </>
  );
};

export default UserSection;
