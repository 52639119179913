import React from "react"; 
import "./brandSidebar.css"


const App=({brandLogo,brandName,NoOfProjects})=>{
    
    return <div className="brandSidebar">
                <div className="brandDetails">
                    <div className="brandLogo">
                        <a href={`../brand-profile/${brandName.replace(/ /g, "-")}`} target="_blank" className="brandLogoA">
                            <img src={brandLogo&&brandLogo} alt="" />
                        </a>
                    </div>
                    <div className="brandData">
                        <a className="brandName" href={`../brand-profile/${brandName.replace(/ /g, "-")}`} target="_blank" style={{color:"black", textDecoration:"none"}}>
                            {brandName} 
                            <img style={{marginLeft:".5rem"}} src="https://unispade-s3.s3.ap-south-1.amazonaws.com/caseStudyMedia/Vector-4.png"/>
                        </a>
                        <p className="brandProjects">{NoOfProjects} Projects</p>
                        <div className="teamMembers">
                            <img src="https://unispade-s3.s3.amazonaws.com/clientLogos/cardImage.png"/>
                            <img src="https://unispade-s3.s3.amazonaws.com/clientLogos/cardImage.png"/>
                            <img src="https://unispade-s3.s3.amazonaws.com/clientLogos/cardImage.png"/>
                            <p>12 team members</p>
                        </div>
                    </div>
                </div>
            </div>
}
export default App; 