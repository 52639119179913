import React, { useEffect, useState } from "react";
import "./firstCaseStudyPortfolioCompo.css";
import redCross from '../../../images/redcross.png';
import axios from "../../../axios.js";

const FirstCaseStudyPortfolioComponent = (props) => {
    const [allServices, setAllServices] = useState([]);
    const [services, setServices] = useState([]);
    const [allIndustries, setAllIndustries] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [brand, setBrand] = useState('');
    const [allBrands, setAllBrands] = useState([]);

    useEffect(async () => {
        setAllServices((props.services || []));
        const industries = await getAllIndustries();
        setAllIndustries(industries.sort());
        const clients = getAllClients(props.clients);
        setAllBrands(clients.sort())
    }, [])

    useEffect(() => {
        if (props.caseStudyForm) {
            setBrand(props.caseStudyForm.brand);
            setServices(props.caseStudyForm.services)
            setIndustries(props.caseStudyForm.industries);
            if(props.createdBy==="brand"){
                const setServicesFunc=async()=>{
                   let allServices=await getAllServices()
                   setAllServices(allServices)
                }
                setServicesFunc()
            }else{
                setServices(props.caseStudyForm.services);
            }
           
        }
    }, [props.caseStudyForm])

    const getAllServices = async () => {
        try {
            const allServices = await axios.get("/superAdmin/filters/getAllServices");
            return allServices.data.selectedServices;
        } catch (err) {
            return err;
        }
    };

    const getAllIndustries = async () => {
        try {
            const allIndustries = await axios.get(
                "/superAdmin/filters/getAllIndustries"
            );
            return allIndustries.data.names;
        } catch (err) {
            return err;
        }
    };

    const getAllClients = (allClients = []) => {
        const allClientNameArr = allClients.map(
            (client) => client.clientName
        );
        return allClientNameArr;
    };

    const serviceChangeHandler = (event) => {
        if (event.target.value !== "") {
            let selectedServicesCopy = [...services];
            selectedServicesCopy.push(event.target.value);
            setServices(selectedServicesCopy);
            setCaseStudyFormData(selectedServicesCopy, "services");
        }
    }

    const setCaseStudyFormData = (data, type) => {
        let formData = { ...props.caseStudyForm };
        switch (type) {
            case "services":
                formData.services = data;
                break;
            case "industries":
                formData.industries = data;
                break;
            case "brand":
                formData.brand = data;
                break;
        }
        props.setCaseStudyFormData(formData);
    }

    const industryChangeHandler = (event) => {
        if (event.target.value !== "") {
            let selectedIndustriesCopy = [...industries];
            selectedIndustriesCopy.push(event.target.value);
            setIndustries(selectedIndustriesCopy);
            setCaseStudyFormData(selectedIndustriesCopy, "industries");
        }
    }

    const brandChangeHandler = (event) => {
        setBrand(event.target.value);
        setCaseStudyFormData(event.target.value, "brand")
    }

    const removeServiceHandler = (dataInd) => {
        let serviceArrCopy = [...services];
        serviceArrCopy.splice(dataInd, 1);
        setServices(serviceArrCopy);
        setCaseStudyFormData(serviceArrCopy, "services");
    }

    const removeIndustryHandler = (dataInd) => {
        let industryArrCopy = [...industries];
        industryArrCopy.splice(dataInd, 1);
        setIndustries(industryArrCopy);
        setCaseStudyFormData(industryArrCopy, "industries");
    }

    return <div className="firstcompo">
        <div className="case-study-heading">New Case Study</div>
        <div className="Brand_working">
        {props.createdBy!=="brand"&&<div className="field">
            <p>Brand *</p>
            <select className="text-capitalize" value={brand} onChange={(e) => { brandChangeHandler(e) }}>
                <option value=""></option>
                {allBrands.map((val, ind) => {
                    return <option key={val + ind} className="text-capitalize" value={val}>{val}</option>
                })}
            </select>
        </div>}
            <div className="field">
                <p>Add Industry *</p>
                <select className="text-capitalize" value={industries} onChange={(e) => { industryChangeHandler(e) }}>
                    <option value=""></option>
                    {allIndustries.map((val, ind) => {
                        return <option key={val + ind} className="text-capitalize" value={val}>{val}</option>
                    })}
                </select>
            </div>
            <div className="field">
                <p>Add Services *</p>
                <select className="text-capitalize" value={services} onChange={(e) => { serviceChangeHandler(e) }}>
                    <option value=""></option>
                    {allServices.map((val, ind) => {
                        return <option key={val + ind} className="text-capitalize" value={val}>{val}</option>
                    })}
                </select>
            </div>
        </div>
        {services?.length > 0 && <div className="service_working">
            <p>Services</p>
            <div className="Services">
                {services.length > 0 && services.map((service, ind) => {
                    return <p className="service text-capitalize" key={service + ind}>
                        <span>{service}</span>
                        <img src={redCross} onClick={() => removeServiceHandler(ind)} />
                    </p>
                })}
            </div>
        </div>}
        {industries?.length > 0 && <div className="service_working">
            <p>Industries</p>
            <div className="Services">
                {industries.length > 0 && industries.map((industry, ind) => {
                    return <p className="service text-capitalize" key={industry + ind}>
                        <span>{industry}</span>
                        <img src={redCross} onClick={() => removeIndustryHandler(ind)} />
                    </p>
                })}
            </div>
        </div>}
    </div>
}
export default FirstCaseStudyPortfolioComponent;