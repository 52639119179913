import React, { useEffect, useState } from "react";
import { MdDelete, MdExpandMore, MdExpandLess } from "react-icons/md";
import "./UserNudge.css";
import axios from "../../../axios";
import { MarketplaceCatalog } from "aws-sdk";
import { NotInterestedConfirmation } from "../../NotInterestedConfirmation/NotInterestedConfirmation";


const UserNudge = () => {


  const [messageInfo, setMessageInfo] = useState([]);
  const [showMessage, setShowMessage] = useState({});
  const [idNotInterested, setIdNotInterested] = useState()
  const [showNotInterestedModel, SetShowNotInterestedModel] = useState(false)

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const fetchMessageDate = async () => {
    const { data } = await axios.get(`/nudges/get-sent-nudges`, {
      headers: { authorization: "Bearer " + Marketer.token },
    });

    return data;
  };

  useEffect(() => {
    (async () => {
      const data = await fetchMessageDate();
      setMessageInfo(data);
    })();
  }, []);


  function hideAndShowMessage(message_id) {
    setShowMessage((prevState) => ({
      ...prevState,
      [message_id]: !prevState[message_id],
    }));
  } 




   

  const selectInterested = async(message_id) => {

    const { data } = await axios.post(`/nudges/select-available/${message_id}`, {}, {
      headers: { authorization: "Bearer " + Marketer.token },
    });
    let messageInfoCopy = messageInfo.filter((key) => key._id !== message_id);
    // console.log("The Message copy is ", messageInfo)
    setMessageInfo(messageInfoCopy);
  }
 
  const selectNotInterested = async() => {

    const { data } = await axios.post(`/nudges/select-not-available/${idNotInterested}`, {}, {
      headers: { authorization: "Bearer " + Marketer.token },
    });
    let messageInfoCopy = messageInfo.filter((key) => key._id !== idNotInterested);
    closeModel()
    setMessageInfo(messageInfoCopy);
    SetShowNotInterestedModel(false)
  }

  function openNotInterestedDialog(message_id) {
    setIdNotInterested(message_id)
    SetShowNotInterestedModel(true)
  }

  function closeModel () {
    setIdNotInterested(null)
    SetShowNotInterestedModel(false)
  }

  return (
    <>
      {messageInfo?.map((message) => {
        return (
          <div key={message._id}>
            <div
              className="theMessageHeadline"
              style={
                showMessage[message?._id]
                  ? {
                      borderTopLeftRadius: "0.5rem",
                      borderTopRightRadius: "0.5rem",
                      borderBottom: "none", // Remove lower border when condition is true
                      backgroundColor: "#d7d9d7",
                    }
                  : {
                      borderRadius: "0.5rem",
                      border: "0.5px solid gray", // Define border when the condition is false
                    }
              }
            >
              <div>
                <p>
                  Someone is interested in your profile for{" "}
                  <span className="bolder-text">
                    {message?.category_id?.name}
                  </span>
                </p>
              </div>
              <div
                onClick={() => {
                  hideAndShowMessage(message?._id);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                {!showMessage[message?._id] ? (
                  <MdExpandMore></MdExpandMore>
                ) : (
                  <MdExpandLess></MdExpandLess>
                )}
              </div>
            </div>

            <div>
              {showMessage[message?._id] && (
                <div className="theMessageDetails">
                  <div>{message?.message}</div>
                  <div className="messageResponseButtons">
                    <button onClick={() => openNotInterestedDialog(message?._id)}>Not Interested</button>
                    <button onClick={() => selectInterested(message?._id)}>Interested</button>
                  </div>
                </div>
              )}
            </div>
          </div>

        );
      })}

      { showNotInterestedModel ? <NotInterestedConfirmation
        onClose={closeModel}
        onConfirm={selectNotInterested}
      /> : "" }



    </>
  );
};

export default UserNudge;
