import React, { useState, useEffect } from "react";
import { UserResume } from "../../UserResume/UserResume";
import "./AvailabilityResume.css";

const Availability = ({
  userData,
  setUserData,
  onsubmitForm,
  currentProfileData,
}) => {
  const [openToWork, setIsOpenToWork] = useState(false);
  const [workTime, setWorkTime] = useState("");
  const [canRelocate, setCanRelocate] = useState(false);
  const [workType, setWorkType] = useState("");
  const [location, setLocation] = useState("");
  const [salary, setSalary] = useState("");
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setIsOpenToWork(currentProfileData?.workDetails?.openToWork);
    setWorkTime(currentProfileData?.workDetails?.workTime);
    setCanRelocate(currentProfileData?.workDetails?.canRelocate);
    setWorkType(currentProfileData?.workDetails?.workType);
    setLocation(currentProfileData?.workDetails?.location);
    setSalary(currentProfileData?.workDetails?.salary);
  }, [currentProfileData]);

  useEffect(() => {
    const currentData = {
      openToWork,
      workTime,
      canRelocate,
      workType,
      location,
      salary,
    };

    const isChanged = JSON.stringify(currentData) !== JSON.stringify(currentProfileData?.workDetails);
    setHasChanges(isChanged);
  }, [openToWork, workTime, canRelocate, workType, location, salary, currentProfileData]);

  const OnSubmit = (event) => {
    event.preventDefault();
    const updatedUserProfile = {
      ...userData,
      workDetails: {
        openToWork,
        workTime,
        canRelocate,
        workType,
        location,
        salary,
      },
    };

    onsubmitForm(updatedUserProfile);
  };

  const resetToPrevData = (event) => {
    event.preventDefault();
    setUserData(currentProfileData);
    setIsOpenToWork(currentProfileData?.workDetails?.openToWork);
    setWorkTime(currentProfileData?.workDetails?.workTime);
    setCanRelocate(currentProfileData?.workDetails?.canRelocate);
    setWorkType(currentProfileData?.workDetails?.workType);
    setLocation(currentProfileData?.workDetails?.location);
    setSalary(currentProfileData?.workDetails?.salary);
  };

  const toggleOpenToWork = () => {
    setIsOpenToWork(!openToWork);
  };

  const handleWorkTimeChange = (e) => {
    setWorkTime(e.target.value);
  };

  const toggleCanRelocate = () => {
    setCanRelocate(!canRelocate);
  };

  const handleWorkTypeChange = (e) => {
    setWorkType(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleSalaryChange = (e) => {
    setSalary(e.target.value);
  };

  return (
    <main className="availability_container">
      <form className="AvailabilityAndResume formScrollBarDisplay" onSubmit={OnSubmit}>
        <div className="Fields">
          <p>Working Info</p>
          <div className="additionalField">
            <div className="toggleButtonContainer">
              <p>Open To Work*</p>
              <div className="toggleButton">
                <input
                  type="checkbox"
                  id="openToWorkToggle"
                  checked={openToWork}
                  onChange={toggleOpenToWork}
                />
                <label htmlFor="openToWorkToggle"></label>
              </div>
            </div>
            <div className="workTimeDropdown">
              <label htmlFor="workTime">Work Time:</label>
              <select
                id="workTime"
                value={workTime}
                onChange={handleWorkTimeChange}
              >
                <option value="">Select</option>
                <option value="fullTime">Full Time</option>
                <option value="partTime">Part Time</option>
                <option value="contract">Contract</option>
              </select>
            </div>
            <div className="toggleButtonContainer">
              <p>Can Relocate*</p>
              <div className="toggleButton">
                <input
                  type="checkbox"
                  id="canRelocateToggle"
                  checked={canRelocate}
                  onChange={toggleCanRelocate}
                />
                <label htmlFor="canRelocateToggle"></label>
              </div>
            </div>
            <div className="workTypeDropdown">
              <label htmlFor="workType">Work Type:</label>
              <select
                id="workType"
                value={workType}
                onChange={handleWorkTypeChange}
              >
                <option value="">Select</option>
                <option value="remote">Remote</option>
                <option value="onSite">On-Site</option>
              </select>
            </div>
            <div className="inputField m-1">
              <label htmlFor="location">Location:</label>
              <input
                type="text"
                id="location"
                value={location}
                placeholder="Your current location"
                onChange={handleLocationChange}
              />
            </div>

            <div className="inputField m-1">
              <label htmlFor="salary">Salary:</label>
              <input
                type="text"
                id="salary"
                value={salary}
                placeholder="Expected salary"
                onChange={handleSalaryChange}
              />
            </div>
          </div>
          <div className="additionalField">
            <div className="InfoDiv">
              <p>Resume</p>
              <UserResume resume={userData?.resume} />
            </div>
          </div>
        </div>
      </form>
      <div className="formButtons">
        <div className="innerFormContainer">
          <button
            className="formBtn"
            onClick={(e) => resetToPrevData(e)}
          >
            Cancel
          </button>
          <button
            disabled={!hasChanges}
            onClick={OnSubmit}
            type="submit"
            className="formBtn"
          >
            Save
          </button>
        </div>
      </div>
    </main>
  );
};

export default Availability;
