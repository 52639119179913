import React, { useState, useEffect } from "react";
import "../../styles/ASidebarAlt.css";
import alumni from "../../images/alumni.png";
import logo from "../../images/logo.png";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import authHeader from "../../login-auth.js";

function ASidebarAlt() {
  const [agencyLogo, setAgencyLogo] = useState("");
  const history = useHistory();
  const params = useParams();
  // useEffect(() => {
  //   const getInfo = async () => {
  //     try {
  //       const res = await axios.post(
  //         "/agencyOnboarding/getAgencyById",
  //         {
  //           id: authHeader().User.agencyId,
  //         },
  //         { headers: { authorization: "Bearer " + authHeader().Token } }
  //       );
  //       console.log(res.data.agencyLogo,"snsdks")
  //       setAgencyLogo(res.data.agencyLogo);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };
  //   getInfo();
  // }, []);
  const showSidebar = () => {
    // For btn activation and deactivation
    const sidebarComp = document.querySelector(".asidebartranslate");
    sidebarComp.classList.toggle("asidebarshowtranslate");
  };

  return (
    <div className="asidebaralt">
      <img src={agencyLogo} alt="" className="profile__pic" />
      <img
        
        alt=""
        className="unispade__logo"
        // onClick={() => {
        //   history.push("/");
        // }}
      />
      <div className="navbar__burger" onClick={showSidebar}>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </div>
    </div>
  );
}

export default ASidebarAlt;
