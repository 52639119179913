import React, { useEffect, useState } from 'react';
import './HeaderNew.css';
import avatar from "../../images/avatar.png";
import { Link, useHistory } from "react-router-dom";
import { UnispadeBlack } from '../HomePageComponents/images';

const HeaderNew = (props) => {
    console.log("Props:", props);
    const history = useHistory();
    const [openMobBar, setOpenMobBar] = useState(false);

    useEffect(()=>{
        let outerElem = document.getElementsByClassName("App")[0];
        if(window.innerWidth <= 425){
            outerElem.addEventListener('click', hideHeaderMob)
        }
        return () => {
            if(window.innerWidth <= 425) outerElem.removeEventListener('click', hideHeaderMob)
        }
    },[])

    const hideHeaderMob = (event) => {
        setOpenMobBar(false);
    }

    const logoutHandler = () => {
        localStorage.clear()
        window.location.reload();
        props.loggedIn(false);
        history.push('/')
    }

    return (
        <>
            <div className="header_new">
                <img
                    onClick={() => {
                        setOpenMobBar(false);
                        history.push("/");
                    }}
                    src={UnispadeBlack}
                    alt="unispade_logo"
                    className="unispade_logo"
                />
                <div className="header_wrapper">
                    <div className="header_links">
                        {/* <Link to="">Marketers</Link> */}
                        {props.loggedIn && (
                            <>
                            <Link to={props.dashboardRoute}>Dashboard</Link>
                            <span></span>
                            </>
                        )}
                        <Link to="/search">Agencies</Link>
                        {!props.loggedIn && <><Link to="/user-login">Login</Link>
                        <Link to="/user-registration">Register</Link></>}
                        {props.loggedIn && <span className="logout" onClick={()=>{logoutHandler()}}>Logout</span>}
                    </div>
                    {/* <div className="profile_img_wrapper">
                        <img className="profile_img" src={avatar} alt="profile_img" />
                    </div> */}
                </div>
            </div>
            <div className="header_new_mobile">
                <img
                    onClick={() => {
                        setOpenMobBar(false);
                        history.push("/");
                    }}
                    src={UnispadeBlack}
                    alt="unispade_logo"
                    className="unispade_logo"
                />
                <div className="header_wrapper">
                    <div className="profile_img_wrapper">
                        <img className="profile_img" src={avatar} alt="profile_img" />
                    </div>
                    <div className="navbar__burger" onClick={() => setOpenMobBar((prev) => setOpenMobBar(!prev))}>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </div>
                </div>
                {openMobBar && (
                    <div className="header_new_mob_dropdown header_links">
                        {/* <Link to="">Marketers</Link> */}
                        <Link to="/search">Agencies</Link>
                        {!props.loggedIn && <><Link to="/user-login">Login</Link>
                        <Link to="/user-registration">Register</Link></>}
                        {props.loggedIn && <span onClick={()=>{logoutHandler()}}>Logout</span>}
                    </div>
                )}
            </div>
        </>
    )
}

export default HeaderNew;
