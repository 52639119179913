import React, { useState, useEffect } from "react";
import "../../styles/AProposals.css";
import redcross from "../../images/redcross.png";
import notificationg from "../../images/notificationg.png";
import dotg from "../../images/dotg.png";
import messageg from "../../images/messageg.png";
import one from "../../images/one.png";
import stars from "../../images/stars.png";
import addimg from "../../images/addimg.png";
import ASidebar from "./ASidebar";
import ASidebarAlt from "./ASidebarAlt";
import axios from "../../axios.js";
import authHeader from "../../login-auth.js";

function AProposals() {
  const [uploadbtn, setUploadbtn] = useState(false);
  const agencyId = authHeader().User.agencyId;
  const [titleAndOverview, setTitleAndOverview] = useState({
    proposalTitle: "",
    Banner: "",
    agencyOverviewHeading: "",
    agencyOverview: "",
    statistics: [
      {
        reach: "",
        stats1: "",
        revenue: "",
        stats2: ""
      }
    ]
  });
  const [clientAndProject, setClientAndProject] = useState({
    portfolioShowcaseHeading: "",
    projects: [""],
    clientShowcaseHeading: "",
    clients: [""]
  });

  const [clientOptions, setClientOptions] = useState([]);
  const [clientBoxes, setClientBoxes] = useState([0, 1, 2]);
  const [projectBoxes, setProjectBoxes] = useState([0, 1, 2]);
  const [deliverableBoxes, setDeliverableBoxes] = useState([0]);
  const [subDeliverableBoxes, setSubDeliverableBoxes] = useState([[0, 1]]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [projects, setProjects] = useState(["", "", ""]);
  const [clients, setClients] = useState(["", "", ""]);
  const [deliverables, setDeliverables] = useState([
    {
      deliverable: "",
      recurring: false,
      frequency: 0,
      timesPer: "",
      description: "",
      subDeliverables: [
        {
          subDeliverable: "",
          recurring: false,
          frequency: 0,
          timesPer: "",
          description: ""
        },
        {
          subDeliverable: "",
          recurring: false,
          frequency: 0,
          timesPer: "",
          description: ""
        }
      ]
    }
  ]);
  const [service, setService] = useState("");
  const [serviceOptions, setServiceOptions] = useState([]);
  const [agencyProposals, setAgencyProposals] = useState([]);
  const [pricing, setPricing] = useState(0);
  const [charged, setCharged] = useState("");
  const [quantity, setQuantity] = useState(0);

  const addMoreDeliverableHandler = () => {
    console.log(deliverableBoxes);
    try {
      const l = deliverableBoxes.length;
      setDeliverableBoxes(p => [...p, 1]);
      console.log(deliverableBoxes);
    } catch (e) {
      console.log(e);
    }
    setSubDeliverableBoxes(p => [...p, [0, 1]]);
    setDeliverables(p => {
      p.push({
        deliverable: "",
        recurring: false,
        frequency: 0,
        timesPer: "",
        description: "",
        subDeliverables: [
          {
            subDeliverable: "",
            recurring: false,
            frequency: 0,
            timesPer: "",
            description: ""
          },
          {
            subDeliverable: "",
            recurring: false,
            frequency: 0,
            timesPer: "",
            description: ""
          }
        ]
      });
      return p;
    });
  };
  const addMoreSubdeliverableHandler = ind => {
    const l = subDeliverableBoxes[ind].length;
    const temp = [...subDeliverableBoxes[ind], l];
    setSubDeliverableBoxes(p => {
      return p.map((b, i) => {
        if (i === ind) return temp;
        else return b;
      });
    });
    setDeliverables(p => {
      p[ind].subDeliverables.push({
        subDeliverable: "",
        recurring: false,
        frequency: 0,
        timesPer: "",
        description: ""
      });
      return p;
    });
  };

  const deliverableChangeHandler = (i, e) => {
    var { name, value, checked } = e.target;
    if (name === "recurring") {
      setDeliverables(p => {
        p[i][name] = checked;
        return p;
      });
    } else {
      setDeliverables(p => {
        p[i][name] = value;
        return p;
      });
    }
  };

  const subDeliverableChangeHandler = (i, j, e) => {
    var { name, value, checked } = e.target;
    if (name === "recurring") {
      setDeliverables(p => {
        p[i].subDeliverables[j][name] = checked;
        return p;
      });
    } else {
      setDeliverables(p => {
        p[i].subDeliverables[j][name] = value;
        return p;
      });
    }
    // setDeliverables((p) => {
    //   return p.map((del, ind) => {
    //     if (ind === i) {
    //       del.subDeliverables.map((subD, index) => {
    //         if (index === j) {
    //           return { ...subD, [name]: value };
    //         } else return subD;
    //       });
    //     } else return del;
    //   });
    // });
    console.log(deliverables);
  };

  const clientChangeHandler = (i, e) => {
    const value = e.target.value;
    setClients(p => {
      p[i] = value;
      return p;
    });
    setClientAndProject(p => {
      p.clients = clients;
      return p;
    });
    console.log(clientAndProject);
  };

  const projectChangeHandler = (i, e) => {
    const value = e.target.value;
    setProjects(p => {
      p[i] = value;
      return p;
    });
    setClientAndProject(p => {
      p.projects = projects;
      return p;
    });
  };

  const headingChangeHandler = e => {
    const { name, value } = e.target;
    setClientAndProject(p => {
      return {
        ...p,
        [name]: value
      };
    });
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const res = await axios.post(
          "/getClientByAgencyId",
          { agencyId },
          {
            headers: { authorization: "Bearer " + authHeader().Token }
          }
        );

        const projects = [];
        const clients = [];
        res.data.client.forEach(c => {
          projects.push(c.clientProjectName);
          clients.push(c.clientName);
        });
        setClientOptions(clients);
        setProjectOptions(projects);
      } catch (e) {
        console.log(e);
      }
    };
    const fetchServices = async () => {
      try {
        const res = await axios.post("/agencyProposals/getAgency", {
          agencyId
        });
        setServiceOptions(res.data.services);
      } catch (e) {
        console.log(e);
      }
    };


    fetchClients();
    fetchServices();
  }, []);

  const [statisticsBoxes, setStatisticsBoxes] = useState([{ no: 1, name: 0 }]);

  const addStatisticsHandler = () => {
    setTitleAndOverview(p => {
      var temp = p.statistics;
      temp.push({
        reach: "",
        stats1: "",
        revenue: "",
        stats2: ""
      });
      return {
        ...p,
        statistics: temp
      };
    });

    const l = statisticsBoxes.length;
    setStatisticsBoxes(prev => {
      return [...prev, { no: 1 + l, name: l }];
    });
  };

  const addMoreProjectHandler = () => {
    setProjects(p => {
      return [...p, ""];
    });
    const l = projectBoxes.length;
    setProjectBoxes(prev => {
      return [...prev, l];
    });
  };
  const addMoreClientHandler = () => {
    setClients(p => {
      return [...p, ""];
    });
    const l = clientBoxes.length;
    setClientBoxes(prev => {
      return [...prev, l];
    });
  };

  const lastDivChangeHandler = e => {
    const { name, value } = e.target;
    if (name === "service") setService(value);
    else if (name === "pricing") setPricing(value);
    else if (name === "charged") setCharged(value);
    else if (name === "quantity") setQuantity(value);
  };

  const submitProposalData = async () => {
    setUploadbtn(true);

    try {
      var temp = deliverables.filter(p => p.deliverable !== "");
      temp.forEach(t => {
        const temp1 = t.subDeliverables.filter(s => s.subDeliverable !== "");
        t.subDeliverables = temp1;
      });

      console.log(titleAndOverview);
      console.log(clientAndProject);
      console.log(temp);
      console.log(service, pricing, quantity, charged);

      const res = await axios.post("/agencyProposals/addProposal", {
        agencyId,
        proposals: {
          titleAndOverview,
          clientsAndProjects: clientAndProject,
          statementOfWork: {
            deliverables: temp
          },
          service,
          pricing,
          charged,
          quantity
        }
      });
      console.log(res.data);
      setUploadbtn(false);
    } catch (e) {
      console.log(e);
    }
  };
  const [temp, setTemp] = useState(0);
  const importProfileHandler = async () => {
    try {
      const res = await axios.post("/agencies/getAgencyById", { id: agencyId });
      setTemp(p => p + 1);
      setTitleAndOverview(p => {
        return {
          ...p,
          agencyOverview: res.data.aboutAgency
        };
      });
    } catch (e) {
      console.log(e);
    }
  };

  // title and overview change handler
  const taoChangeHandler = e => {
    const name = e.target.name;
    const value = e.target.value;
    setTitleAndOverview(p => {
      return {
        ...p,
        [name]: value
      };
    });
  };
  const taoStatisticsChangeHandler = (i, e) => {
    const name = e.target.name;
    const value = e.target.value;
    setTitleAndOverview(p => {
      p.statistics[i][name] = value;
      return p;
    });
    console.log(titleAndOverview);
  };

  return (
    // <div className="aproposal__cont">
    //   <ASidebar />
    <div className="aproposal">
      <ASidebarAlt />
      <div className="section__cont">
        <div className="section">
          <div className="part__left">
            <h3 className="aproposal__title">New Proposal</h3>
          </div>
          <div className="part__right">
            <div className="img__cont">
              <img className="msg__not" src={messageg} alt="" />
              <img className="dotg" src={dotg} alt="" />
            </div>
            <div className="img__cont">
              <img className="msg__not" src={notificationg} alt="" />
              <img className="dotg" src={dotg} alt="" />
            </div>
            <p className="date__day para adgrey"> </p>
          </div>
        </div>
      </div>

      {/* Title & overview */}
      <div className="tao__details">
        <p className="para text__left fw__600">Title & Overview</p>
        <div className="tao__details__data">
          {/* <form > */}
          <div className="tao__level1" onChange={taoChangeHandler}>
            <div className="part flx6 giveflx10">
              <label className="para">Proposal Title</label>
              <input
                name="proposalTitle"
                value={titleAndOverview.proposalTitle}
                className="lvlinput"
                type="text"
                placeholder="Product Launch branding for the latest Nike sneakers"
              />
            </div>
            <div className="part ">
              <label className="para">Upload Banner</label>
              <button className="lvlinput flx05 cursor__pointer" type="button">
                Upload
              </button>
            </div>
          </div>
          <div className="tao__level1" onChange={taoChangeHandler}>
            <div className="part flx3 giveflx10">
              <label className="para">Agency Overview Heading</label>
              <input
                name="agencyOverviewHeading"
                value={titleAndOverview.agencyOverviewHeading}
                className="lvlinput"
                type="text"
                placeholder="About Our Agency"
              />
            </div>
          </div>
          <div className="tao__level1" onChange={taoChangeHandler}>
            <div className="part flx5 giveflx10">
              <label className="para">Agency Overview</label>
              <textarea
                name="agencyOverview"
                value={titleAndOverview.agencyOverview}
                className="lvlinput lvltextarea"
                type="text"
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam fermentum maecenas donec vel mattis morbi auctor dictumst habitant. Amet, cras laoreet integer lacus. Mattis suspendisse porttitor metus, volutpat mauris sit mattis ullamcorper eget. Nec quisque praesent aliquet pellentesque. Morbi enim."
              />
            </div>
          </div>
          <div className="dflex justify__between">
            <p
              onClick={importProfileHandler}
              className="para fw__600 color__orange cursor__pointer"
            >
              Import from profile
            </p>
          </div>

          <div className="case__statics__cont">
            <div className="case__statics__title">
              <p className="para">Statistics</p>
            </div>
            {statisticsBoxes.map((s, i) => (
              <form
                onChange={e => {
                  taoStatisticsChangeHandler(i, e);
                }}
                className="case__statics__form"
              >
                <div className="part">
                  <div className="inp__fields inpsmall">
                    <input
                      name="reach"
                      className="input adgrey text__center"
                      placeholder="47k"
                      type="text"
                    ></input>
                  </div>
                  <div className="inp__fields inpbig">
                    <input
                      name="stats1"
                      className="input adgrey"
                      placeholder="Social Media Reach"
                      type="text"
                    ></input>
                  </div>
                </div>
                <div className="part">
                  <div className="inp__fields inpsmall">
                    <input
                      name="revenue"
                      className="input adgrey text__center"
                      placeholder="5M"
                      type="text"
                    ></input>
                  </div>
                  <div className="inp__fields inpbig">
                    <input
                      name="stats2"
                      className="input adgrey"
                      placeholder="Revenue in 6 months"
                      type="text"
                    ></input>
                  </div>
                </div>
              </form>
            ))}

            <div className="dflex justify__between">
              <p
                onClick={addStatisticsHandler}
                className="mart1 para fw__600 color__orange cursor__pointer"
              >
                + Add More Statistics
              </p>
            </div>
          </div>
          {/* </form> */}
        </div>
      </div>

      {/* clients and projects */}
      <div className="tao__details">
        <p className="para text__left fw__600">Clients & Projects</p>
        <div className="tao__details__data">
          <div className="tao__level1">
            <div className="part flx3 giveflx10">
              <label className="para">Portfolio Showcase Heading</label>
              <input
                onChange={headingChangeHandler}
                name="portfolioShowcaseHeading"
                className="lvlinput"
                type="text"
                placeholder="About Our Agency"
              />
            </div>
          </div>
          <div className="tao__level1  flxdircolmin wfixform">
            {projectBoxes.map((p, i) => (
              <div className="part flx3 giveflx10 wfixinp__cont">
              <div className="labelcrosscont"> 
                <label className="labelf para labelfw">Select Projects</label>
                <img src={redcross} className="redcross" alt="" />  
              </div>
                <select
                  onChange={e => {
                    projectChangeHandler(i, e);
                  }}
                  className="lvlinput wfixinp"
                >
                  {projectOptions.length > 0 ? (
                    <option value="">Select a Project</option>
                  ) : (
                    <option value="">No Projects Available</option>
                  )}
                  {projectOptions.map(p => (
                    <option value={p}>{p}</option>
                  ))}
                </select>
              </div>
            ))}
          </div>
          <div className="dflex justify__between">
            <p
              onClick={addMoreProjectHandler}
              className=" para fw__600 color__orange cursor__pointer"
            >
              + Import More Projects
            </p>
          </div>

          <div className="tao__level1">
            <div className="part flx3 giveflx10">
              <label className="para">Client Showcase Heading</label>
              <input
                onChange={headingChangeHandler}
                name="clientShowcaseHeading"
                className="lvlinput"
                type="text"
                placeholder="About Our Agency"
              />
            </div>
          </div>
          <div className="tao__level1 flxdircolmin wfixform">
            {clientBoxes.map((c, i) => (
              <div className="part flx3 giveflx10 wfixinp__cont">
                <div className="labelcrosscont"> 
                <label className="labelf para labelfw">Select Clients</label>
                <img src={redcross} className="redcross" alt="" />  
                </div>        
                <select
                  onChange={e => {
                    clientChangeHandler(i, e);
                  }}
                  className="lvlinput wfixinp"
                >
                  <option value="">Select a Client</option>
                  {clientOptions.map(c => (
                    <option value={c}>{c}</option>
                  ))}
                </select>
              </div>
            ))}
          </div>
          <div className="dflex justify__between">
            <p
              onClick={addMoreClientHandler}
              className=" para fw__600 color__orange cursor__pointer"
            >
              + Import More Clients
            </p>
          </div>
        </div>
      </div>

      {/* Statement of Work */}
      <div className="tao__details">
        <p className="para text__left fw__600 ">Statement of Work</p>

        {/* Deliverable */}
        {deliverableBoxes?.map((d, i) => (
          <div className="tao__details__data">
            <div className=" left__sowcont">
              <div className="tao__level1 saw__level1">
                <div className="saw__col ">
                  <div className="part flx6 giveflx10">
                    <label className="para ">Deliverable {i + 1}</label>
                    <input
                      onChange={e => {
                        deliverableChangeHandler(i, e);
                      }}
                      name="deliverable"
                      className="lvlinput"
                      type="text"
                      placeholder="Write deliverable"
                    />
                  </div>
                  <div className="part flx3 reccur">
                    <input
                      onChange={e => {
                        deliverableChangeHandler(i, e);
                      }}
                      name="recurring"
                      type="checkbox"
                      className="lvlinput "
                    />
                    <label className="para" htmlFor="vehicle1">
                      Recurring
                    </label>
                  </div>
                </div>

                <div className="saw__col ">
                  <div className="part flx10 giveflx10 justify__end reccur">
                    <label className="para">Frequency</label>
                    <select
                      onChange={e => {
                        deliverableChangeHandler(i, e);
                      }}
                      name="frequency"
                      className="lvlinput"
                    >
                      <option value="">Select a option</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </div>

                  <div className="part flx10 giveflx10 justify__end reccur">
                    <label className="para">time(s) per</label>
                    <select
                      onChange={e => {
                        deliverableChangeHandler(i, e);
                      }}
                      name="timesPer"
                      className="lvlinput"
                    >
                      <option value="">Select a option</option>
                      <option value="Week">Week</option>
                      <option value="Month">Month</option>
                      <option value="Year">Year</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="tao__level1 saw__level1">
                <div className="part flx10 giveflx10">
                  <label className="para">Description</label>
                  <textarea
                    onChange={e => {
                      deliverableChangeHandler(i, e);
                    }}
                    name="description"
                    className="lvlinput saw__textarea"
                    type="text"
                    placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim senectus amet sed sit platea pharetra. Faucibus aenean non vivamus fermentum. Nibh eget ac est morbi pulvinar turpis egestas vestibulum. Nisl hendrerit lectus nibh malesuada. Id ac arcu mattis porta egestas amet. Turpis vestibulum pharetra augue consectetur neque. Vel odio mauris nunc ipsum magna nec. Nisi, pulvinar mattis diam malesuada adipiscing volutpat. Varius mattis risus scelerisque feugiat eget sit faucibus urna auctor. Mollis laoreet risus quis sed fringilla pellentesque urna in suspendisse."
                  />
                </div>
              </div>
            </div>
            {/* Sub-Deliverable  */}
            {subDeliverableBoxes[i].map((s, j) => (
              <div className="tao__details__data right__sowcont">
                <div className="tao__level1 saw__level1">
                  <div className="saw__col ">
                    <div className="part flx6 giveflx10">
                      <label className="para ">Sub-Deliverable {j + 1}</label>
                      <input
                        onChange={e => {
                          subDeliverableChangeHandler(i, j, e);
                        }}
                        name="subDeliverable"
                        className="lvlinput"
                        type="text"
                        placeholder="Write deliverable"
                      />
                    </div>
                    <div className="part flx3 reccur">
                      <input
                        onChange={e => {
                          subDeliverableChangeHandler(i, j, e);
                        }}
                        type="checkbox"
                        name="recurring"
                        className="lvlinput "
                      />
                      <label className="para" htmlFor="vehicle1">
                        Recurring
                      </label>
                    </div>
                  </div>

                  <div className="saw__col ">
                    <div className="part flx4 giveflx10 justify__end reccur">
                      <label className="para">Frequency</label>
                      <select
                        name="frequency"
                        className="lvlinput"
                        onChange={e => {
                          subDeliverableChangeHandler(i, j, e);
                        }}
                      >
                        <option value="0">Select an option</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>

                    <div className="part flx4 giveflx10 justify__end reccur">
                      <label className="para">time(s) per</label>
                      <select
                        name="timePer"
                        className="lvlinput"
                        onChange={e => {
                          subDeliverableChangeHandler(i, j, e);
                        }}
                      >
                        <option value="">Select an option</option>
                        <option value="Week">Week</option>
                        <option value="Month">Month</option>
                        <option value="Year">Year</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="tao__level1 saw__level1">
                  <div className="part flx10 giveflx10">
                    <label className="para">Description</label>
                    <textarea
                      onChange={e => {
                        subDeliverableChangeHandler(i, j, e);
                      }}
                      name="description"
                      className="lvlinput saw__textarea"
                      type="text"
                      placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim senectus amet sed sit platea pharetra. Faucibus aenean non vivamus fermentum. Nibh eget ac est morbi pulvinar turpis egestas vestibulum. Nisl hendrerit lectus nibh malesuada. Id ac arcu mattis porta egestas amet. Turpis vestibulum pharetra augue consectetur neque. Vel odio mauris nunc ipsum magna nec. Nisi, pulvinar mattis diam malesuada adipiscing volutpat. Varius mattis risus scelerisque feugiat eget sit faucibus urna auctor. Mollis laoreet risus quis sed fringilla pellentesque urna in suspendisse."
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="dflex justify__between">
              <p
                onClick={() => {
                  addMoreSubdeliverableHandler(i);
                }}
                className=" para fw__600 color__orange cursor__pointer"
              >
                + Add More Sub-Deliverables
              </p>
            </div>
          </div>
        ))}

        {/* Deliverable */}

        <div className="dflex justify__between mart1">
          <p
            onClick={addMoreDeliverableHandler}
            className=" para fw__600 color__orange cursor__pointer"
          >
            + Add More Deliverables
          </p>
          <p className=" para fw__600 color__orange cursor__pointer">
            Save these deliverables as a template
          </p>
        </div>
      </div>

      {/* select services */}
      <div className="tao__details mart2 marb2 ">
        <div className="tao__details__data mar0">
          <div className="case__statics__cont">
            <form
              className="case__statics__form"
              onChange={lastDivChangeHandler}
            >
              <div className="part">
                <div className="inp__fields flx5 giveflx10">
                  <label className="para">Select Service</label>
                  <select name="service" className="select adgrey">
                    <option>Select a Service</option>
                    {serviceOptions.map(s => (
                      <option value={s}>{s}</option>
                    ))}
                  </select>
                </div>
                <div className="inp__fields flx2 ">
                  <label className="para">Pricing</label>
                  <input
                    name="pricing"
                    className="input adgrey "
                    placeholder="XXXX"
                    type="text"
                  ></input>
                </div>
              </div>
              <div className="part">
                <div className="inp__fields flx5 giveflx10">
                  <label className="para">Chargred</label>
                  <select name="charged" className="select adgrey">
                    <option value="Hourly">Hourly</option>
                    <option value="Daily">Daily</option>
                  </select>
                </div>
                <div className="inp__fields flx2">
                  <label className="para">Quantity</label>
                  <input
                    name="quantity"
                    className="input adgrey"
                    placeholder="XXXX"
                    type="text"
                  ></input>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="dflex align__start">
        <button onClick={submitProposalData} className="done__btn">
          {uploadbtn && <i className="fas fa-circle-notch donebtnclient"></i>}
          {uploadbtn && " Submiting"}
          {!uploadbtn && "Submit"}
        </button>
      </div>
    </div>
  );
}

export default AProposals;
