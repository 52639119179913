import React, { useEffect, useRef, useState } from "react";
import "./Sidebar.css";
import backarrow from "../img/backArrow.jpg";
import exclamationWarning from "../img/exclamationWarning.png";
import logout from "../img/logout.png";
import MenuIcon from "../img/menuIcon.png";
import ProfileImage from "../img/profile.png";
import PlusBox from "../img/plus-box.png";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";

const Sidebar = ({
  query,
  Fields,
  profilePhoto,
  activePannel,
  usernameExist,
  setBreadCrumbNextRoute,
  Marketersdata
}) => {
  const [userRoute, setUserRoute] = useState(window.location.pathname.split("/")[2]);
  const history = useHistory();
  const location = useLocation();

  const [isDisabled, setIsDisabled] = useState(query ? true : false);
  
  useEffect(() => {
    if (query) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [query])

  // const query = location.search.split('?')[1];

  // useEffect(() => {
  //   setQuery(location.search.split('?')[1])
  // }, [location.search])


  if (userRoute === undefined) {
    setUserRoute("credits")
  }

  const searchParams = new URLSearchParams(window.location.search);
  const [currentTab, setCurrentTab] = useState(userRoute ? userRoute : "profile")
  const fieldStyle = {
    fontWeight: "500",
    fontSize: "17px",
  };

  const handleFieldStyle = (event) => {
    const { name, value } = event.target;
    if (query) {
      setIsDisabled(true)
      return;
    }
    setBreadCrumbNextRoute(value)
    setCurrentTab(name)
    if (event.target.name === 'new-company-profiles') {
      localStorage.setItem('profile', JSON.stringify({ profile: { profileId: '' } }))
    }
  };
  const [menuIcon, setMenuIcon] = useState(false);
  const onLogOut = () => {
    localStorage.clear("marketer")
    history.push("/user-login")
  }

  function handleBrandsAndAgencies(token, callComingFrom, id) {
    if (callComingFrom === 'agency') {
      localStorage.setItem("user", JSON.stringify({ user: { agencyId: id }, token: token }));
      window.open("/adashboard/aeditprofile", "_blank");
    }
    else if (callComingFrom === 'brand') {
      localStorage.setItem("branduser", JSON.stringify({ user: { brandId: id }, token: token }));
      window.open("/bdashboard/beditprofile", "_blank")
    }
  }

  function ShowAgencyAndBrand(key, callComingFrom) {
    const { _id, agencyName, brandName, role, token, verified, agencyId, brandId } = key
    const name = callComingFrom === 'agency' ? agencyName : brandName
    const logo = callComingFrom === 'agency' ? agencyId.agencyLogo : brandId.brandLogo
    const id = callComingFrom === 'agency' ? agencyId : brandId
    return (
      <button key={_id} style={{ position: 'relative' }} onClick={() => handleBrandsAndAgencies(token, callComingFrom, id)}>
        <img src={logo} alt="image" />
        <p>{name.split('')[0].toUpperCase().concat(name.slice(1))} | </p>
        <span>{role.split('')[0].toUpperCase().concat(role.slice(1))}</span>
        {!verified &&
          (
            <span style={{ position: 'absolute', right: '0' }}>
              <img src={exclamationWarning} style={{ width: '20px' }} alt="exclamationWarning" />
            </span>
          )
        }
      </button>
    )
  }

  return (
    <div className={menuIcon ? "Sidebar active" : "Sidebar"}>
      <div className="topBottomesidebar top_sidebar">
        <img src={backarrow} alt="" />
        <a href={"/"} target="_blank">Back to website</a>
      </div>
      <img
        className="profileImage"
        src={profilePhoto ? profilePhoto : ProfileImage}
        alt=""
      />

      <div className="Fields">
        {Fields.map((key) => {
          return (
            key.name !== 'new-company-profiles' &&
            (<Link key={key.name} to={'/user-dashboard/' + key.name}>
              <button
                style={key.name === currentTab ? fieldStyle : {}}
                name={key.name}
                value={key.text}
                disabled={key.disable || isDisabled}
                onClick={(e) => handleFieldStyle(e)}
              >
                {key.text}
              </button>
            </Link>
            )
          );
        })}
      </div>
      <div className="topBottomesidebar bottom_sidebar">
        <div className="coompanyProfile">
          <button disabled={isDisabled} style={'new-company-profiles' === userRoute ? fieldStyle : {}}>Company profile <span>{">"}</span></button>
          <div className="companyProfileWrapper" >
            {
              Marketersdata && Marketersdata?.agenciesDetails?.map((key) => {
                return ShowAgencyAndBrand(key, 'agency')
              })
            }
            {
              Marketersdata && Marketersdata?.brandsDetails?.map((key) => {
                return ShowAgencyAndBrand(key, 'brand')
              })
            }
            <Link to='/user-dashboard/new-company-profiles'>
              <button disabled={isDisabled} value="New Company Profile" name="CreateProfile" style={"CreateProfile" === currentTab ? fieldStyle : {}} onClick={handleFieldStyle}>
                <img src={PlusBox} alt="" />
                <span>Create New Profile</span>
              </button>
            </Link>
          </div>
        </div>

        <div onClick={onLogOut} className="logOut">
          <img src={logout} alt="" />
          <a>Logout</a>
        </div>

      </div>
      <img
        className="mobileMenuIcon"
        src={MenuIcon}
        onClick={() => {
          menuIcon ? setMenuIcon(false) : setMenuIcon(true);
        }}
        alt=""
      />
    </div>
  );
};

export default Sidebar;
