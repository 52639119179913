import React from "react";
import NotifyText from "./NotifyText";

function UActivityTab() {
  return (
    <div className="usertab unotifytab" id="activitytab">
      <NotifyText
        text="Agency RED shared a proposal for New Product Line Launch"
        time="24/10 Mon @ 12:30pm"
      />
      <NotifyText
        text="Ashish Aggarwal shortlisted a new agency for New Product Line Launch"
        time="24/10 Mon @ 12:30pm"
      />
      <NotifyText text="Shreshtha Chakraborty deleted the project New Product Line Launch" />
      <NotifyText text="Arun Kumar has edited the project brief for New Product Line Launch" />
      <NotifyText text="Navcharan Singh changed the Project Status of New Product Line Launch" />
      <NotifyText text="Ashish Sharma added a new document to New Product Line Launch" />
      <NotifyText text="Navcharan Singh changed the Project Status of New Product Line Launch" />
      <NotifyText text="Shreshtha Chakraborty deleted the project New Product Line Launch" />
      <NotifyText text="Arun Kumar has edited the project brief for New Product Line Launch" />
    </div>
  );
}

export default UActivityTab;
