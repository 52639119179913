import React, { useEffect, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./profileImage.css";
import axios from "axios";
import uploadIcon from "../img/profile.png";
import { uploadImage, deleteImage } from "../../../uploadImage";
import authHeader from "../../../login-auth";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";

const ProfileeImage = ({
  setUserProfileFormData,
  cropData,
  setCropData,
  profilePhoto,
}) => {
  const [image, setImage] = useState();
  const [profilePic, setProfilePic] = useState(null);
  const [imageName, setImageName] = useState();
  const [cropper, setCropper] = useState();
  const [uploadStatus, setUploadStatus] = useState("Upload");

  // upload profile

  useEffect(() => {
    setProfilePic(profilePhoto)
  }, [profilePhoto])

  const uploadProfile = async (file) => {
    // let file = e.target.files[0];
    const data = new FormData();
    data.append("File", file);
    console.log(JSON.parse(localStorage.getItem("marketer")));
    const response = await axios.post(
      "https://unispade.com/api/upload/" +
        JSON.parse(localStorage.getItem("marketer")).user._id,
      data,
      {
        headers: {
          authorization:
            "Bearer " + JSON.parse(localStorage.getItem("marketer")).token,
        },
      }
    );

    setProfilePic(response?.data?.url)
    console.log("The image api response is ", response);
  };

  // optimize code

  //   const uploadProfile = async (file) => {
  //     try {
  //         const formData = new FormData();
  //         formData.append('File', file);

  //         const { user, token } = JSON.parse(localStorage.getItem("marketer"));
  //         const response = await axios.post(`http://localhost:4000/upload/${user._id}`, formData, {
  //             headers: { authorization: "Bearer " + token },
  //         });

  //         console.log(response);
  //     } catch (error) {
  //         console.error("Upload failed:", error);
  //     }
  // }

  const onChange = (e) => {
    setImageName(e.target.files[0].name);
    setUploadStatus("Upload");
    e.preventDefault();
    console.log("HELLO");
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    console.log("the file are ", files);

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
    e.target.value = null;
  };

  useEffect(() => {
    console.log("done");
  }, [cropData]);

  const getCropData = async () => {
    if (typeof cropper !== "undefined") {
      setUploadStatus("Uploading");
      // setCropData(cropper.getCroppedCanvas().toDataURL());
      let cropredData = cropper.getCroppedCanvas().toDataURL();
      try {
        const file = dataUrlToFile(cropredData, imageName);
        // setImageName(null);
        // return;
        const uploadedUrl = await uploadProfile(file);
        setUploadStatus("Uploaded");
        setUserProfileFormData(uploadedUrl);
        // console.log(uploadedUrl);
        // setCropData(uploadedUrl);
        // Additional actions with the uploaded image URL
      } catch (error) {
        console.error("Upload error:", error);
        setUploadStatus("Upload");
        // Handle the error as needed
      }
    }
  };

  const dataUrlToFile = (dataUrl, filename) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const uploadButtonHandler = (field) => {
    let elem = document.getElementById("imageInput");
    console.log("the elmeent of elem is ", elem);
    if (elem) elem.click();
  };



  return (
    <div className="markDprofile">
      {profilePic ? (
        <div className="theProfilePic">
          <img
            src={profilePic?profilePic : profilePhoto}
            alt="user_profile_pic"
            style={{
              width: "50%",
              height: "150px",
            }}
          />

          <button
            className="changeProfileButton"
            onClick={() => {
              setProfilePic("");
            }}
          >
            Change profile
          </button>
        </div>
      ) : (
        !imageName  && (
          <div className="uploadSection" onClick={uploadButtonHandler}>
            <img src={uploadIcon} alt="" />
            <h6>Browse file to upload</h6>
            <span>Image or GIF</span>
            <input
              id="imageInput"
              type="file"
              onChange={onChange}
              accept={".jpeg,.png,.gif,.mov"}
            />
          </div>
        )
      )}

      {!profilePic && imageName && (
        <div className="imageUploadDiv">
          <div>
            <Cropper
              style={{ marginTop: "20px", height: 200, width: "50%" }}
              zoomTo={0}
              initialAspectRatio={1}
              aspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />
          </div>

          <div className="btnWrapper">
            <button
              type="button"
              className="discardButton"
              onClick={(e) => {
                setImageName("");
              }}
            >
              Discard
            </button>
            <button
              disabled={uploadStatus !== "Upload"}
              className="uploadButton"
              onClick={(e) => {
                getCropData(e);
                e.preventDefault();
              }}
            >
              {uploadStatus ? uploadStatus : "Upload"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileeImage;
