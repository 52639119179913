import React from "react";

import "./NewsMentions.css";

import verifiedIcon from "../img/verified-icon.png";
import usersIcon from "../img/Users.png";
import editIcon from "../img/Edit.png";
import commentIcon from "../img/Comment.png";
import callIcon from "../img/Call.png";
 

const NewsMentions = ({ news_mention }) => {
  const newsImage="https://www.andromo.com/wp-content/uploads/schema-and-structured-data-for-wp/news-1.jpg"
  return (
    <>
    {
      news_mention.length===0?<></>:<>
      <div className="news__mentions">
        <div className="mentions__heading">
          <h2 className="mentions__title font__lexend">News Mentions</h2>
        </div>
        <div className="news__container">
          {news_mention.map((news_mention) => (
            <div className="news__mention">
              <div className="news__info">
                <h3 className="news__title font__lexend">
                  {news_mention.publisher}
                </h3>
                <a href={news_mention.link}>
                  <img
                    className="news__redirect"
                    src={newsImage}
                    alt="redirect icon"
                  />
                </a>
              </div>
              <h6 className="news__description">{news_mention.tittle}</h6>
            </div>
          ))}
        </div>
      </div>
      </>}
      <div className="case_study__footer">
        <div className="case_study__card">
          <div>
            <img
              className="case_study__icon"
              src={verifiedIcon}
              alt="Verified Icon"
            />
          </div>
          <h5 className="case_study__text font__lexend">
            Verify case study
          </h5>
        </div>

        {/* <div className="case_study__card">
          <div>
            <img
              className="case_study__icon"
              src={usersIcon}
              alt="Users Icon"
            />
          </div>
          <h5 className="case_study__text font__lexend">Claim Credits</h5>
        </div>

        <div className="case_study__card">
          <div>
            <img className="case_study__icon" src={editIcon} alt="Edit Icon" />
          </div>
          <h5 className="case_study__text font__lexend">
            Post a Testimonial
          </h5>
        </div>

        <div className="case_study__card">
          <div>
            <img
              className="case_study__icon"
              src={commentIcon}
              alt="Comment Icon"
            />
          </div>
          <h5 className="case_study__text font__lexend">
            Comment your opinion
          </h5>
        </div> */}

        <div className="case_study__card">
          <div>
            <img className="case_study__icon" src={callIcon} alt="Call Icon" />
          </div>
          <h5 className="case_study__text font__lexend">Contact Agency</h5>
        </div>
      </div>
    </>
  );
};

export default NewsMentions;
