import React from "react";
import "../../styles/AYourProfile.css";
import ASidebar from "./ASidebar";

import notificationg from "../../images/notificationg.png";
import dotg from "../../images/dotg.png";
import messageg from "../../images/messageg.png";
import creditimg from "../../images/creditimg.png";
import creditimg2 from "../../images/creditimg2.png";
import creditimg3 from "../../images/creditimg3.png";
import ASidebarAlt from "./ASidebarAlt";
import { useState, useEffect } from "react";
import authHeader from "../../login-auth.js";
import axios from "../../axios.js";

function AYourProfile() {
  const [savebtn, setSavebtn] = useState(false);

  const [memberDetails, setMemberDetails] = useState({
    name: "",
    userId: "",
    linkedInProfile: "",
    aboutMe: "",
    officialMailId: "",
    designation: "",
    businessEmail: ""
  });
  const [currentAgency, setCurrentAgency] = useState("");
  const [creditedWork, setCreditedWork] = useState([]);
  const currentAgencyChangeHanlder = event => {
    const value = event.target.value;
    setCurrentAgency(value);
  };

  const basicDetailsChangeHandler = event => {
    const name = event.target.name;
    const value = event.target.value;
    setMemberDetails(prev => {
      return { ...prev, [name]: value };
    });
  };
  const professionalDetailsChangeHandler = event => {
    const name = event.target.name;
    const value = event.target.value;
    setMemberDetails(prev => {
      return { ...prev, [name]: value };
    });
  };

  const basicDetailsSubmitHandler = async () => {
    // loading icon starts
    setSavebtn(true);
    // loading icon ends

    var basicDetails = {
      teamMemberId: authHeader().User._id,
      name: memberDetails.name,
      userId: memberDetails.userId,
      linkedInProfile: memberDetails.linkedInProfile,
      aboutMe: memberDetails.aboutMe,
      businessEmail: memberDetails.businessEmail
    };
    const res = await axios.post("/team/editProfile", basicDetails, {
      headers: { authorization: "Bearer " + authHeader().Token }
    });

    console.log(res.data);
    setSavebtn(false);
  };

  const agencySubmitHandler = async () => {
    try {
      var temp = {
        teamMemberId: authHeader().User._id,
        currentAgency
      };
      const res = await axios.post("/team/editAgencyDetails", temp, {
        headers: { authorization: "Bearer " + authHeader().Token }
      });
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const professionalChangeHandler = async () => {
    try {
      var professionalDetails = {
        teamMemberId: authHeader().User._id,
        officialMailId: memberDetails.officialMailId,
        designation: memberDetails.designation
      };
      const res = await axios.post(
        "/team/editProfessionalDetails",
        professionalDetails,
        {
          headers: { authorization: "Bearer " + authHeader().Token }
        }
      );
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchMemberDetails = async () => {
      try {
        const res = await axios.post(
          "/team/getProfile",
          {
            teamMemberId: authHeader().User._id
          },
          { headers: { authorization: "Bearer " + authHeader().Token } }
        );
        console.log(res.data);
        setMemberDetails({
          name: res.data.name,
          userId: res.data.userId,
          linkedInProfile: res.data.linkedInProfile,
          aboutMe: res.data.aboutMe,
          officialMailId: res.data.officialMailId,
          designation: res.data.designation,
          phoneNumber: res.data.phoneNumber,
          businessEmail: res.data.businessEmail
        });
      } catch (e) {
        console.log(e);
      }
    };
    const fetchAgencyById = async () => {
      try {
        const res = await axios.post(
          "/team/getAgencyById",
          { agencyId: authHeader().User.agencyId },
          { headers: { authorization: "Bearer " + authHeader().Token } }
        );
        setCurrentAgency(res.data);
      } catch (e) {
        console.log(e);
      }
    };
    const fetchCreditedWork = async () => {
      try {
        const res = await axios.post(
          "/team/getCreditedWork/",
          {
            agencyId: authHeader().User.agencyId,
            businessEmail: authHeader().User.businessEmail
          },
          { headers: { authorization: "Bearer " + authHeader().Token } }
        );
        setCreditedWork(res.data);
        console.log(res.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchCreditedWork();
    fetchMemberDetails();
    fetchAgencyById();
    fetchCreditedWork();
  }, []);
  return (
    // <div className="ayourprofile__cont">
    //   <ASidebar />
    <div className="ayourprofile">
      <ASidebarAlt />
      <div className="section__cont">
        <div className="section">
          <div className="part__left">
            <h3 className="aonboarding__title">Your Profile</h3>
          </div>
          <div className="part__right">
            <div className="img__cont">
              <img className="msg__not" src={messageg} alt="" />
              <img className="dotg" src={dotg} alt="" />
            </div>
            <div className="img__cont">
              <img className="msg__not" src={notificationg} alt="" />
              <img className="dotg" src={dotg} alt="" />
            </div>
            <p className="date__day para adgrey"> </p>
          </div>
        </div>
      </div>

      {/* Basic Details */}
      <div className="basic__details">
        <p className="para text__left fw__600">Basic Details</p>
        <div className="basic__details__data">
          <div className="basic__details__forms">
            <form onChange={basicDetailsChangeHandler} className="form">
              <div className="inp__fields flx2">
                <label className="para">Name</label>
                <input
                  name="name"
                  value={memberDetails.name}
                  className="input adgrey"
                  placeholder="Kuldeep Yadav"
                  type="text"
                ></input>
              </div>
              <div className="inp__fields flx2">
                <label className="para">User ID</label>
                <input
                  name="userId"
                  value={memberDetails.userId}
                  className="input adgrey"
                  placeholder="hellboy_kldpyadv"
                  type="text"
                ></input>
              </div>
              <div className="inp__fields flx3">
                <label className="para">LinkedIn Profile</label>
                <input
                  name="linkedInProfile"
                  value={memberDetails.linkedInProfile}
                  className="input adgrey"
                  placeholder="https://www.linkedin.com/kldpyadv"
                  type="text"
                ></input>
              </div>
              <div className="inp__fields flx3">
                <label className="para">Personal Mail ID</label>
                <input
                  name="businessEmail"
                  value={memberDetails.businessEmail}
                  className="input adgrey"
                  placeholder="kldpyadv@gmail.com"
                ></input>
              </div>
            </form>
            <div className="aboutme__overview">
              <div className="part1">
                <p className="para">About me</p>
                <p className="para color__orange cursor__pointer">Edit</p>
              </div>
              <textarea
                onChange={basicDetailsChangeHandler}
                type="text"
                name="aboutMe"
                value={memberDetails.aboutMe}
                className="aboutme__textarea"
              />
            </div>

            <div className="dflex justify__between">
              <p className="para color__orange cursor__pointer">
                Request Password Change
              </p>
            </div>

            <div className="save__add__cont">
              <button className="save__btn" onClick={basicDetailsSubmitHandler}>
                {savebtn && (
                  <i className="fas fa-circle-notch donebtnclient"></i>
                )}
                {savebtn && " Saving"}
                {!savebtn && "Save"}
              </button>{" "}
              &nbsp; &nbsp; &nbsp;
              <p className="para color__orange cursor__pointer"> Cancel</p>
            </div>
          </div>
        </div>
      </div>

      {/* Professional Details */}
      <div className="basic__details">
        <p className="para text__left fw__600">Professional Details</p>
        <div className="basic__details__data">
          <div className="basic__details__forms">
            <form className="form">
              <div className="inp__fields flx3">
                <label className="para">Current Agency</label>
                <input
                  onChange={currentAgencyChangeHanlder}
                  name="currentAgency"
                  value={currentAgency}
                  className="input adgrey"
                  placeholder="Agency Red"
                  type="text"
                ></input>
              </div>
              <div
                className="inp__fields flx2 justify__end align__start"
                onClick={agencySubmitHandler}
              >
                <label className="para color__orange cursor__pointer">
                  Change Agency
                </label>
              </div>
              <div className="inp__fields flx3">
                <label className="para">Official Mail ID</label>
                <input
                  onChange={professionalDetailsChangeHandler}
                  name="officialMailId"
                  value={memberDetails.officialMailId}
                  className="input adgrey"
                  placeholder="kuldeep@redagency.com"
                  type="text"
                ></input>
              </div>
              <div className="inp__fields flx3">
                <label className="para">Designation</label>
                <input
                  onChange={professionalDetailsChangeHandler}
                  name="designation"
                  value={memberDetails.designation}
                  className="input adgrey"
                  placeholder="Chief Creative Director"
                ></input>
              </div>
              <div
                className="inp__fields flx1 justify__end  align__start"
                onClick={professionalChangeHandler}
              >
                <label className="para color__orange cursor__pointer">
                  Edit
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="creditedworks">
        <p className="para text__left fw__600">Credited Work</p>

        <div className="creditedworks__data">
          {creditedWork.map((c, i) => (
            <div className="creditedwork">
              <div className="creditedwork__img__cont">
                <img src={c.media[0]} className="creditedwork__img" alt="" />
                <p className="creditedwork__imgtext para">{c.projectTitle}</p>
              </div>
              <div className="creditedwork__detail">
                {/* <a href={m.newsURL}>Link</a> */}
                <h5>{c.client}</h5>
                <p className="para ">
                  {c.team.map(mem => {
                    if (mem.name === authHeader().User.name)
                      return mem.position;
                  })}
                </p>
              </div>
            </div>
          ))}
          {/*<div className="creditedwork">
              <div className="creditedwork__img__cont">
                <img src={creditimg2} className="creditedwork__img" alt="" />
                <p className="creditedwork__imgtext para">
                  Dell Digital Campaign
                </p>
              </div>
              <div className="creditedwork__detail">
                {/* <a href={m.newsURL}>Link</a> */}
          {/*<h5>Dell Laptops </h5>
                <p className="para ">Creative Lead</p>
              </div>
            </div>
            <div className="creditedwork">
              <div className="creditedwork__img__cont">
                <img src={creditimg3} className="creditedwork__img" alt="" />
                <p className="creditedwork__imgtext para">Show some love</p>
              </div>
              <div className="creditedwork__detail">
                {/* <a href={m.newsURL}>Link</a>
                <h5>Ferns & Petals</h5>
                <p className="para ">Creative Lead</p>
              </div>
            </div>*/}
        </div>

        <div className="creditedworks__data2">
          <div className="dflex justify__between">
            <p className="para color__orange cursor__pointer">
              Explore Projects and request Credits 
            </p>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default AYourProfile;
