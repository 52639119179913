import React from "react";
import "./CaseStudyCard.css";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../../axios.js";


const CaseStudyCard = ({ caseStudy }) => {
  const [agencyData, setAgencyData] = useState({});  
  const [clientData, setClientData] = useState({});  

  useEffect(() => {
    const url = `/agencies/getAgencyById/${caseStudy.agencyId}`;
    const getData = async () => {
      const data = await axios.get(url);
      setAgencyData(data.data);
    };
    getData();
  }, [caseStudy.agencyId]);

  useEffect(()=>{
    const getData=async()=>{
      const {data} = await axios.post(
        "/getClientDetailsbyclientsName",
       { clientname:caseStudy.brand}
      );
      setClientData(data)
    }
    getData()
  },[caseStudy.brand])
  
  

  return (
    <div className="caseStudy__card">
      <div className="top_part">
          <div className="details">
            <img src={clientData.clientLogo} alt="" />
            <h6 className="name">{caseStudy.brand}</h6>
          </div>
      </div>
      <div onClick={()=>window.open(`/case-study/${caseStudy.uniqueKey}`, "_blank")} className="main">
        <img
          className="display_image"
          src={caseStudy.coverImg}
          alt="display_image"
        />
      </div>
      <a href={`/case-study/${caseStudy.uniqueKey}`} target="_blank">Case Study</a>
      {caseStudy?.createdBy==="brand"?
      <div className="aname">
        <img src={clientData.clientLogo} alt="" />
        <p>In-house</p>
      </div>
      :
      <div className="aname">
        <img src={agencyData.agencyLogo} alt="" />
        <p>by {agencyData.agencyName}</p>
      </div>}
      <p onClick={()=>window.open("/case-study/" + caseStudy.uniqueKey, "_blank")} className="ptitle" >
        {caseStudy.projectTitle}
      </p>

    </div>
  );
};

export default CaseStudyCard;
