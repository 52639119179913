import React, { useState, useEffect } from "react";
import ProfileCompo from "../../profileImage/ProfileImage";
import "./BasicInfo.css";

const BasicInfo = ({
  userData,
  setUserData,
  setUserProfileFormData,
  currentProfileData,
  onsubmitForm,
}) => {
  const [cropData, setCropData] = useState("");

  useEffect(() => {
    setUserData(currentProfileData);
  }, [currentProfileData]);

  const OnSubmit = (event) => {
    event.preventDefault();
    setCropData("");
    const updatedUserProfile = {
      ...userData,
    };

    onsubmitForm(updatedUserProfile);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let userProfileCopy = { ...userData };
    userProfileCopy[name] = value;
    setUserData(userProfileCopy);
  };

  const resetToPrevData = (event) => {

    event.preventDefault();
    setUserData(currentProfileData);
  }


  const MandatoryInfo = [
    {
      Tittle: "DISPLAY NAME*",
      placeHolder: "First Name + Last Name",
      type: "text",
      name: "name",
      disable: false,
    },
    {
      Tittle: "USERNAME*",
      placeHolder: "Choose a unique username",
      type: "text",
      name: "username",
      disable: false,
    },
    {
      Tittle: "PRIMARY EMAIL*",
      subtittle: "Preferably your personal email ID",
      placeHolder: "Your primary email",
      type: "email",
      name: "email",
      disable: true,
    },
    {
      Tittle: "Recovery Email*",
      subtittle: "In case you lose access to your primary mail ID",
      placeHolder: "Your secondary email",
      type: "email",
      name: "officialMailId",
      disable: false,
    },
  ];

  const addintionalInfo = [
    {
      Tittle: "DESIGNATION",
      placeHolder: "Your current role",
      type: "text",
      name: "role",
      disable: false,
    },
  ];

  return (
    <main className="basicinfo_container">
      <form className="BasicInfo formScrollBarDisplay">
        <div className="Fields">
          <p>*Mandatory fields</p>
          <div className="mandatoryField">
            {MandatoryInfo.map((key, index) => (
              <div key={index} className="InfoDiv">
                <p>{key.Tittle}</p>
                <span>{key.subtittle}</span>
                <input
                  disabled={key.disable}
                  placeholder={key.placeHolder}
                  type={key.type}
                  name={key.name}
                  onChange={handleChange}
                  value={userData[key.name]}
                />
              </div>
            ))}
          </div>
          <p>Additional profile info</p>
          <div className="additionalField">
            {addintionalInfo.map((key, index) => (
              <div key={index} className="InfoDiv">
                <p>{key.Tittle}</p>
                <span>{key.subtittle}</span>
                <input
                  placeholder={key.placeHolder}
                  type={key.type}
                  name={key.name}
                  onChange={handleChange}
                  value={userData[key.name]}
                />
              </div>
            ))}
            <div className="InfoDiv">
              <p>BIO</p>
              <textarea
                rows="3"
                cols="50"
                name="about"
                placeholder="A 100 words description about yourself"
                onChange={handleChange}
                value={userData.about}
              ></textarea>
            </div>
            <div className="InfoDiv">
              <p>PROFILE PICTURE</p>
              <ProfileCompo
                name="profilePhoto"
                profilePhoto={userData?.profilePhoto}
                setUserProfileFormData={setUserProfileFormData}
                cropData={cropData}
                setCropData={setCropData}
              />
            </div>
          </div>
        </div>
      </form>
      <div className="formButtons">
        <div className="innerFormContainer">
          <button
            className="formBtn"
            onClick={(e) => resetToPrevData(e)}
          >
            Cancel
          </button>
          <button
            disabled={currentProfileData === userData}
            onClick={OnSubmit}
            type="submit"
            className="formBtn"
          >
            Save
          </button>
        </div>
      </div>
    </main>
  );
};

export default BasicInfo;
